import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { IClaim } from '../interfaces/claim';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ClaimsService } from '../services/claims.service';
import { Claim } from '../models/claim-model';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AccountService } from '../../account/services/account.service';
import { Subject } from "rxjs";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.scss']
})
export class ClaimsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  public headerForm: UntypedFormGroup;
  public statusForm: UntypedFormGroup;
  public selectedClaim: Claim;
  private claimsId: number;
  public showMenuItems : boolean;

  private routerEventSubscription: Subscription;
  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private claimsService: ClaimsService,
    private accountService: AccountService
  ) {
    this.statusForm = this.formBuilder.group({
      status: ['Open'],
      openedBy: ['All']
    });
  }

  ngOnInit(): void {
    this.routerEventSubscription = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.rootRoute(this.activatedRoute)),
      filter((route: ActivatedRoute) => route.outlet === 'primary'),
    ).subscribe((route: ActivatedRoute) => this.getClaim(route));

    this.getClaim(this.activatedRoute);

    if (this.accountService.isClaimsManager() || this.accountService.isAssistantClaimsManager() || this.accountService.isAdjuster()) {
      this.statusForm.patchValue({openedBy: 'Self'})
    }
  }

  ngOnDestroy(): void {
    this.routerEventSubscription.unsubscribe();
    this.ngUnsubscribe$.next(true);
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges()
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  getClaim(route: ActivatedRoute) {
    let claimsId = route.snapshot.data['claimId'];
    if (claimsId) {
      if (this.claimsId !== claimsId) {
        this.claimsId = claimsId;
        this.claimsService.retrieve(this.claimsId)
          .pipe(takeUntil(this.ngUnsubscribe$))
          .subscribe(claim => {
            if (claim) {
              this.setSelectedClaim(claim);
            }
          },
          err => {
            this.setSelectedClaim(null);
            console.log(err)
          }
        );
      }
    } else {
      this.setSelectedClaim(null);
    }
  }

  setSelectedClaim(claim: IClaim) {
    if(claim != null){
      this.selectedClaim = new Claim();
      Object.assign(this.selectedClaim, claim)
    } else {
      this.claimsId = null;
      this.selectedClaim = null;
      this.claimsService.setDialogState = false
    }
    this.claimsService.setSelectedClaim(this.selectedClaim);
  }

  public get hasApprovalPermission(): boolean{
    return this.accountService.hasClaimsManagementApprove();
  }

  public get isAuditor(): boolean{
    return this.accountService.isGeneralAuditor() || this.accountService.isInsurerAuditor();
  }

  get isClaimsListPage() : boolean {
    return window.location.pathname == "/claims";
  }
}
