import { Component } from '@angular/core';
import { APP_DI_FRAMEWORK, FrameworkItem } from 'src-private/app/app.framework';

@Component({
  selector: 'framework-component',
  templateUrl: './framework.component.html',
  styleUrls: ['./framework.component.scss']
})

export class FrameworkComponent {

  funcs: string[] = [];
  componentLabel: string;


  build(component: string, label: string) {
    if (label == undefined) return;

    this.componentLabel = label;

    const matchingComponent: FrameworkItem = APP_DI_FRAMEWORK.modules.find(e => {
      if (e.componentLabel == this.componentLabel && e.componentType == component) return e
    });

    if (matchingComponent) {
      matchingComponent.functions.forEach(e => {
        this.funcs[e] = true
      })
    } else {
      console.log("Component type does not exist.")
    }
  }
}
