import { AfterViewInit, Component, Inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ClaimsRetrieve } from '../../models/claims-retrieve.model';
import { ClaimsService } from '../../services/claims.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { IClaim } from '../../interfaces/claim';
import { ClaimsComponent } from '../claims.component';
import { AppConfig, APP_CONFIG } from '../../../../app.config';
import { Claim } from '../../models/claim-model';
import { ClaimStatus } from 'src-private/app/enums/claims.enums';
import { DialogType } from 'src-private/app/enums/dialog-type';
import { ConfirmDialogComponent } from 'src-private/app/shared/confirm-dialog/confirm-dialog.component';
import { AccountService } from "src-private/app/areas/account/services/account.service";
import { environment } from 'src-private/environments/environment';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from "rxjs";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ToastrService } from "ngx-toastr";
import { DataTableDirective } from 'angular-datatables';
import { CookieService } from 'ngx-cookie-service';
import { Title } from "@angular/platform-browser";

interface IClaimColumnData{
  columnName: string,
  data: string,
  format:(val: any) => any
}

@Component({
  selector: 'app-claims-list',
  templateUrl: './claims-list.component.html',
  styleUrls: ['./claims-list.component.scss']
})
export class ClaimsListComponent implements OnInit, AfterViewInit {

  public form: UntypedFormGroup;
  public claims: IClaim[];
  public faSearch = faSearch;
  public isLoading = false;
  public dtOptions: DataTables.Settings;
  dtTrigger: Subject<any> = new Subject<any>();
  private isDatatablesLoaded = false;
  private pageNum = 1;
  private pageSize = 10;
  private column = 'claimId';
  private sortType  = 'DESC';
  public isAdjuster: boolean;
  private claimColumn : IClaimColumnData[] = [];
  private isFirstLoad: boolean = true;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  @Input() optionsHidden: boolean = false;

  public faPlus = faPlus;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private claimsService: ClaimsService,
    private formBuilder: UntypedFormBuilder,
    private claimsComponent: ClaimsComponent,
    private router: Router,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private accountService: AccountService,
    private cookieService: CookieService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.titleService.setTitle("Claims");
    this.setClaimListLengthCookie();
    this.setColumnData()
    this.form = this.formBuilder.group({
      claimId: [null],
      WarrantyIdFull: [null],
      customerFirstname: [''],
      customerLastname: [''],
      vin: [''],
      dealer: [''],
      assignedAdjuster: [''],
      repairCenter: ['']
    });
    var that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      serverSide: true,
      search: false,
      searching: false,
      pageLength: that.getClaimListLengthCookie(0, -1),
      ajax: (dataTablesParameters: any, callback) => {
        this.pageNum = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        this.pageSize = this.getClaimListLengthCookie(dataTablesParameters.length, dataTablesParameters.draw);
        this.column = dataTablesParameters.columns[dataTablesParameters.order[0].column].data
        this.sortType = dataTablesParameters.order[0].dir
        that.sendClaimsRetrieve().then(data => {
          callback({
            recordsTotal: this.claims.length > 0 ? this.claims[0].totalRecords : 0,
            recordsFiltered: this.claims.length > 0 ? this.claims[0].totalRecords : 0,
            data: [],
          });
        })
      },
      responsive:true,
      columns: [
        { data: 'warrantyId' },
        { data: 'customerFirstname' },
        { data: 'customerLastname' },
        { data: 'warrantyVIN' },
        { data: 'vendorName' },
        { data: 'repairCenterName' },
        { data: 'claimId' },
        { data: 'claimsDateEntered' },
        { data: 'claimsOwner' },
        { data: 'claimsStatus' },
      ],
    };
    this.dtOptions.order = [[6, 'desc']];
    this.claimsComponent.setSelectedClaim(null);
    // this.sendClaimsRetrieve();
    this.onChanges();

    this.isAdjuster = this.accountService.isAdjuster() || this.accountService.isAssistantClaimsManager() || this.accountService.isClaimsManager();
  }

  onClaimIdTextEscKeyDown(): void {
    this.form.get('claimId').setValue('');
  }

  onWarrantyIdTextEscKeyDown(): void {
    this.form.get('WarrantyIdFull').setValue('');
  }

  onFirstNameTextEscKeyDown(): void {
    this.form.get('firstName').setValue('');
  }

  onLastNameTextEscKeyDown(): void {
    this.form.get('lastName').setValue('');
  }

  onVinTextEscKeyDown(): void {
    this.form.get('vin').setValue('');
  }

  onDealerTextEscKeyDown(): void {
    this.form.get('dealer').setValue('');
  }

  onRepairCenterTextEscKeyDown(): void {
    this.form.get('repairCenter').setValue('');
  }

  onAssignedAdjusterTextEscKeyDown(): void {
    this.form.get('assignedAdjuster').setValue('');
  }

  sendClaimsRetrieveDebounce(status:string = null) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.page(1);
      // Destroy the table first
      dtInstance.destroy();
      this.dtOptions.order = status == 'Closed' ? [[5, 'desc']] : [[0, 'asc']]
      // Call the dtTrigger to rerender again
      this.dtTrigger.next(this.dtOptions);
    });
  }

  sendClaimsRetrieve() {
    this.isLoading = true;
    this.form.disable({ emitEvent: false });
    this.claimsComponent.statusForm.disable({ emitEvent: false });

    const claimsRetrieve = new ClaimsRetrieve();

    Object.keys(this.form.controls).forEach((key: string) => {
      const control = this.form.get(key);

      if (key in claimsRetrieve) {
        let value = control.value;

        if (typeof (value) === 'undefined' || value === '') {
          claimsRetrieve[key] = null;
        }
        else {
          claimsRetrieve[key] = value;
        }
      }
    });

    Object.keys(this.claimsComponent.statusForm.controls).forEach((key: string) => {
      const control = this.claimsComponent.statusForm.get(key);

      if (key in claimsRetrieve) {
        let value = control.value;

        if (typeof (value) === 'undefined' || value === '') {
          claimsRetrieve[key] = null;
        }
        else {
          claimsRetrieve[key] = value;
        }
      }
    });

    claimsRetrieve['pageNum'] = this.pageNum;
    claimsRetrieve['pageSize'] = this.pageSize;

    claimsRetrieve.sortColumn = this.column != '' ? this.column : null;
    claimsRetrieve.sortType = this.sortType
    if (this.optionsHidden) {
      claimsRetrieve['openedBy'] = 'All';
      claimsRetrieve['status'] = 'All';
      if (this.isFirstLoad) {
        claimsRetrieve.sortColumn = 'claimId';
        claimsRetrieve.sortType = 'DESC';
        this.isFirstLoad = false;
      }
    }
    else {
      if (claimsRetrieve['openedBy'] === 'Self') {
        claimsRetrieve['openedBy'] = this.accountService.getUserId();
      }
    }
    if (this.optionsHidden) {
      const promise = new Promise((resolve, reject) => {
        this.claimsService.retrieveAllAuditor(claimsRetrieve)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
          response => {
            const data: IClaim[] = response;
            this.claims = this.cleanData(data);
            this.isLoading = false;
            this.form.enable({ emitEvent: false });
            this.claimsComponent.statusForm.enable({ emitEvent: false });
  
            if (!this.isDatatablesLoaded) {
              this.isDatatablesLoaded = true;
              // this.dtTrigger.next(true);
            }
  
            resolve(true);
          },
          err => {
            this.isLoading = false;
            this.form.enable({ emitEvent: false });
            this.claimsComponent.statusForm.enable({ emitEvent: false });
            if (!this.optionsHidden) {
              this.toastr.error(environment.messages.apiError, "Unable to retrieve claims");
            }
            reject();
          }
        );
      });
      return promise;
    }
    else {
      const promise = new Promise((resolve, reject) => {
        this.claimsService.retrieveAll(claimsRetrieve)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
          response => {
            const data: IClaim[] = response;
            this.claims = this.cleanData(data);
            this.isLoading = false;
            this.form.enable({ emitEvent: false });
            this.claimsComponent.statusForm.enable({ emitEvent: false });
  
            if (!this.isDatatablesLoaded) {
              this.isDatatablesLoaded = true;
              // this.dtTrigger.next(true);
            }
  
            resolve(true);
          },
          err => {
            this.isLoading = false;
            this.form.enable({ emitEvent: false });
            this.claimsComponent.statusForm.enable({ emitEvent: false });
            if (!this.optionsHidden) {
              this.toastr.error(environment.messages.apiError, "Unable to retrieve claims");
            }
            reject();
          }
        );
      });
      return promise;
    }
  }

  private onChanges(): void {
    this.form.valueChanges
      .pipe(debounceTime(2000))
      .subscribe((val) => this.sendClaimsRetrieveDebounce());
    this.claimsComponent.statusForm.valueChanges.subscribe(val => this.sendClaimsRetrieveDebounce(this.claimsComponent.statusForm.controls['status'].value));
  }

  generateCSV(): void {
    if (!this.claims && !this.claims.length) return;

    let headers = Object.keys(this.claims[0]).filter(x=>this.claimColumn.map(d=>d.data).includes(x));
    let str = headers.map(x=>this.claimColumn.find(y=>y.data == x).columnName).join(',');
    str += '\r\n';

    this.claims.forEach((claim: IClaim) => {
      let line = '';
      headers.forEach((header: string) => {
        line += this.claimColumn.find(x=>x.data == header).format == undefined ? claim[header] + ',' : this.claimColumn.find(x=>x.data == header).format(claim[header]) + ',' ;
      })
      str += line.slice(0, -1) + '\r\n';
    })

    const a = document.createElement('a');
    const blob = new Blob([str], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'claims_' + new Date() + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  checkDate(date: Date) {
    var duration = moment.duration(moment().diff(date));
    var days = duration.asDays();

    if (days > this.config.claimsListHighlightRedDaysRangeStart && days < this.config.claimsListHighlightRedDaysRangeEnd) {
      return { 'claim-row-red': true };
    }
    else if (days > this.config.claimsListHighlightYellowDaysRangeStart && days < this.config.claimsListHighlightYellowDaysRangeEnd) {
      return { 'claim-row-yellow': true };
    }
    else {
      return { 'claim-row': true };
    }
  }

  cleanData(data: IClaim[]): IClaim[]{
    data.forEach(claim => {
      if(claim.customerFirstname[0] != undefined && claim.customerLastname[0] != undefined){
      claim.customerFirstname = `${claim.customerFirstname[0].toUpperCase()}${claim.customerFirstname.substr(1, claim.customerFirstname.length).toLowerCase()}`
      claim.customerLastname = `${claim.customerLastname[0].toUpperCase()}${claim.customerLastname.substr(1, claim.customerLastname.length).toLowerCase()}`
    }})
    return data
  }

  addClaim(item :IClaim) {
    let data = new Claim();
    data.warrantyId = item.warrantyId;
    data.assignedAdjuster = this.accountService.getUserId();
    data.claimsStatus = ClaimStatus[3].toString();
    data.customerId = item.customerId;
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'part-dialog',
      width: '400px',
      autoFocus: true,
      data: {
        message: "You are about to add a new claim for this warranty. Do you want to continue?",
        type: DialogType[0].toString()
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.claimsService.claimsAdd(data)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res) => {
            if (res) {
              this.toastr.success("Claim successfully added. ");
              this.router.navigateByUrl('/').then(()=>this.router.navigate(['claims/' + res.toString() + '/detail']));
            }
          },
          error => {
            this.toastr.error(environment.messages.apiError, "Unable to add claim");
          });
      }
      else {
        dialogRef.close();
      }
    });
  }

  setColumnData(){
    this.claimColumn.push({
      columnName:"Warranty ID",
      data:"warrantyIdFull",
      format: undefined
    })
    this.claimColumn.push({
      columnName:"First Name",
      data:"customerFirstname",
      format: undefined
    })
    this.claimColumn.push({
      columnName:"Last Name",
      data:"customerLastname",
      format: undefined
    })
    this.claimColumn.push({
      columnName:"VIN",
      data:"warrantyVIN",
      format: undefined
    })
    this.claimColumn.push({
      columnName:"Dealer",
      data:"vendorName",
      format: undefined
    })
    this.claimColumn.push({
      columnName:"Repair Centre",
      data:"repairCenterName",
      format: undefined
    })
    this.claimColumn.push({
      columnName:"Claim ID",
      data:"enterpriseId",
      format: undefined
    })
    this.claimColumn.push({
      columnName:"Claim Date",
      data:"claimsDateEntered",
      format: (val:string) => new Date(val).toDateString()
    })
    this.claimColumn.push({
      columnName:"Assigned Adjuster",
      data:"claimsOwner",
      format: undefined
    })
    this.claimColumn.push({
      columnName:"Status",
      data:"claimsStatus",
      format: undefined
    })
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next(this.dtOptions);
  }

  getClaimListLengthCookie(length: any, draw: number): number{
    if(draw > 1){
      this.cookieService.set("claimListLength", length.toString());
    }
    return parseInt(this.cookieService.get("claimListLength"));
  }

  setClaimListLengthCookie(){
    if(!this.cookieService.check("claimListLength")){
      this.cookieService.set("claimListLength", "10");
    }
  }
}
