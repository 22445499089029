<fieldset class="fieldset-form">
  <legend>Details</legend>
  <div *ngIf="isLoading">
    <div class="td-loading-spinner text-center" colspan="8">
        <mat-spinner diameter="25"></mat-spinner>
    </div>
  </div>
  <div *ngIf="!isLoading && customer" class="container-fluid form-group">
    <div class="row " style="margin-bottom: 5.03125px;">
      <div class="col-md-2">
        <div class="">
          <label>Customer:</label>
        </div>
      </div>
      <div class="col-md-10">
        <div *ngIf="customer">
          <div class="row">
            <div class="col-lg-12">
              <a class="link" [routerLink]="hasClaimId ? ['/claims', claimEnterpriseId, 'customer', customer.customerId] : ['/claims', 'customer', customer.customerId]"
                 routerLinkActive="active">{{customer.firstName | trimValue}} {{customer.lastName | trimValue}} ({{customer.customerId}})</a>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 customer-address">
              <span>{{customer.address | trimValue}}, {{customer.city | trimValue}} {{customer.province | trimValue}} ({{customer.countryName | trimValue}}) - {{customer.postalZipCode | mask : isCanada ? 'S0S 0S0' : '00000'}} </span>
              <span *ngIf="isCustomerContact" >Ph :- {{customer.phone | mask: '(000) 000-0000'}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <span>{{customer.driversLicense}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 5.03125px;">
      <div class="col-md-2">
        <div class="">
          <label>Vehicle:</label>
        </div>
      </div>
      <div class="col-md-10">
        <div class="row">
          <div class="col-lg">
            <div *ngIf="warranty && warranty.vehicle">
              <div class="row">
                <div class="col-md-10">
                  <span>{{warranty.vehicle.year}} {{warranty.vehicle.make}} {{warranty.vehicle.model}} (<span
                      class="link" (click)="addMaintenance()"><a
                        class="link">{{warranty.vehicle.vin}}</a></span>)</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  {{vehicleExtraDetails}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  {{warranty.vehicle.purchasePrice | currency}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row " style="margin-bottom: 5.03125px;">
      <div class="col-md-2">
        <div class="">
          <label>Claims:</label>
        </div>
      </div>
      <div class="col-md-10">
        <div *ngIf="claimTotal">
          <div class="row">
            <div class="col-lg-12">
              {{ claimTotal.totalWithoutTax | currency }} ({{ claimTotal.totalWithTax | currency }} incl tax)
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row" style="margin-bottom: 5.03125px;">
      <div class="col-md-2">
        <div class="">
          <label>Dealer:</label>
        </div>
      </div>
      <div class="col-md-10">
        <div *ngIf="warranty && dealer">
          <div class="row">
            <div class="col-lg">
              <span><a class="link" skipLocationChange replaceUrl [routerLink]="['/dealers', { dealerId: warranty.dealerId }, 'dealer-info']">{{warranty.dealerName}}</a></span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg">
              {{dealer.mailAddress | trimValue}}, {{dealer.city | trimValue}} {{dealer.province | trimValue}} ({{dealerCountry | trimValue}}) - {{dealer.postalCode | mask : isDealerCountryCanda ? 'S0S 0S0' : '00000'}} 
            </div>
          </div>
          <div class="row">
            <div class="col-lg" *ngIf="isDealerPhone || isDealerContact">
              {{ isDealerContact ? dealer.contactFirst+' '+ dealer.contactLast+' - ': 'Ph :- '}}  {{dealer.phone | mask: '(000) 000-0000' }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 5.03125px;">
      <div class="col-md-2">
        <div>
          <label>LienHolder:</label>
        </div>
      </div>
      <div class="col-md-10">
        <div>
          <!-- <span>{{lein.name}}, {{lein.city}}, {{lein.province}}</span> -->
        </div>
      </div>
    </div>

  </div>
</fieldset>