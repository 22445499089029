import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src-private/environments/environment';

/*-- Enums --*/
import { Role } from '../../../enums/role.enum';

/*-- Interfaces --*/
import { IEazeeTrakReasonGroup } from '../../reports/interfaces/eazeetrak.interface';
import { IReportAvailability, IReport, IReportParameter, ICoverageSales } from '../interfaces/reports.interface';
import { IUser } from '../../dealers/interfaces/user.interface';

/*-- Services --*/
import { RequestCacheService } from '../../../helpers/request-cache.service';
import { UtilsService } from '../../../services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  eazeeTrakReasonGroupLookup(force: boolean = false): Observable<IEazeeTrakReasonGroup[]> {
    return this.http.get<IEazeeTrakReasonGroup[]>(environment.resourceUrl + 'api/gwc/eazeetrakreasongroup/lookup',
      { headers: RequestCacheService.GetCacheHeaders(force) });
  }

  metricsCreate(reportId: number) {
    const params = {
      reportId: reportId
    };

    return this.http.post(environment.resourceUrl + 'api/gwr/metrics/create', params);
  }

  reportRetrieve(reportId: number, force: boolean = false): Observable<IReport> {
    const params = {
      reportId: reportId
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<IReport>(environment.resourceUrl + 'api/gwr/report/retrieve',
      { headers: RequestCacheService.GetCacheHeaders(force), params: queryParams });
  }

  reportAvailabilityRetrieve(force: boolean = false): Observable<IReportAvailability[]> {
    const params = {
      userId: ''
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<IReportAvailability[]>(environment.resourceUrl + 'api/gwr/reportavailability/retrievebyuser',
      { headers: RequestCacheService.GetCacheHeaders(force), params: queryParams });
  }

  reportParametersRetrieve(reportId: number, force: boolean = false): Observable<IReportParameter[]> {
    const params = {
      reportId: reportId
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<IReportParameter[]>(environment.resourceUrl + 'api/gwr/reportparameter/retrieve',
      { headers: RequestCacheService.GetCacheHeaders(force), params: queryParams });
  }

  userRetrieveByRole(name: Role, force: boolean = false): Observable<IUser[]> {
    const params = {
      name: name
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<IUser[]>(environment.resourceUrl + 'api/gwi/aspnetusers/retrievebyrole',
      { headers: RequestCacheService.GetCacheHeaders(force), params: queryParams });
  }

  retrieveCoverageSales(vendorId: number): ICoverageSales[] {
    const sales: ICoverageSales[] = [];

    sales.push(
      {
        salesPersonName: 'Gary Rubenstein',
        coverageName: 'Ultimate 5 Star 5 Yr/100,000 Kms $5000',
        amount: 50
      },
      {
        salesPersonName: 'Steven Kaiser',
        coverageName: 'Highline Road Hazard Protection Platinum Plus 12 Mth/Unl Kms',
        amount: 70
      },
      {
        salesPersonName: 'Mike Mcmillan',
        coverageName: 'Standard Tire & Wheel 24 Mth/Unl Kms',
        amount: 20
      }
    );

    return sales;
  }
}
