<fieldset class="fieldset-form">
  <legend>
    Warranty Documents
    <div *ngIf="funcs['addDocument'];else noAddDocument" class="float-right">
      <button class="btn custom-button float-right" type="button" (click)="addDocument()"
        [hidden]="isClaimClosed ? !canEditClosedClaim : !(isAdjuster || isDealerServices || canEditClosedClaim)">
        <fa-icon [icon]="faPlus"></fa-icon> Add Document
      </button>
    </div>
    <ng-template #noAddDocument>
      <framework-hidden [display]="button"></framework-hidden>
    </ng-template>
  </legend>
  <table-adapter #warrantyDocumentsTable [columns]="columns"
    [tableLabel]="'warranty-documents-table'" (context)="updateDocument($event)" (update)="viewDocument($event)" [canInteract]="canInteract" [canRenderActions]="canRenderActions">
  </table-adapter>
</fieldset>