<div class="container-fluid" *ngIf="claim">

  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div class="col-lg">
        <app-claim-info *ngIf="claim"></app-claim-info>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
      <div class="col-lg">
        <app-warranty-details *ngIf="claim" [customerId]="claim.customerId" [claimEnterpriseId]="claim.enterpriseId" [warrantyId]="claim.warrantyId"></app-warranty-details>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
      <div class="col-lg">
        <app-warranties-table *ngIf="claim" [customerId]="claim.customerId" [readOnly]="true" [smallComponent]="false"></app-warranties-table>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
      <div class="col-lg">
        <app-repair-centre-info *ngIf="claim" [repairCentreId]="claim.claimsRepairCenterId"></app-repair-centre-info>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12 col-lg-6 col-md-12 col-sm-12">
      <div class="col-lg">
        <app-complaint-info></app-complaint-info>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="col-lg">
        <parts-list *ngIf="claim" [canAddPart]="false" [warrantyId]="claim.warrantyId" (documentAdded)="onDocumentAdded($event)" [partsTableColumns]="partsTableColumns" [canRenderActions]="partsListCanRenderActions"></parts-list>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="col-lg">
        <app-claim-notes *ngIf="claim" [approval]="true"></app-claim-notes>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="col-lg">
        <app-claim-documents *ngIf="claim"></app-claim-documents>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="col-lg">
        <app-warranty-options *ngIf="claim" [warrantyId]="claim.warrantyId" [columns]="warrantyOptionsTableColumns"></app-warranty-options>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="col-lg">
        <app-warranty-notes *ngIf="claim" [warrantyId]="claim.warrantyId" [columns]="warrantyNotesTableColumns"></app-warranty-notes>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="col-lg">
        <app-warranty-documents *ngIf="claim" [warrantyId]="claim.warrantyId" [customerId]="claim.customerId" [columns]="warrantyDocumentsTableColumns"></app-warranty-documents>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="col-lg">
        <app-repair-centre-notes *ngIf="claim" [repairCentreId]="claim.claimsRepairCenterId" [columns]="repairCentreNotesTableColumns"></app-repair-centre-notes>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="col-lg">
        <app-maintenance-schedule [columns]="maintScheduleTableColumns"></app-maintenance-schedule>
      </div>
    </div>
  </div>

</div>
