import { HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import FileSaver from 'file-saver';
import moment from 'moment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BehaviorSubject, map, Subject, takeUntil } from 'rxjs';
import { AccountService } from 'src-private/app/areas/account/services/account.service';
import { DialogType } from 'src-private/app/enums/dialog-type';
import { ConfirmDialogComponent } from 'src-private/app/shared/confirm-dialog/confirm-dialog.component';
import { FrameworkComponent } from 'src-private/app/shared/framework/framework.component';
import { CellBuilder } from 'src-private/app/shared/table-adapter/cell-builder/cell-builder';
import { CellType } from 'src-private/app/shared/table-adapter/cell-builder/cell-type';
import { TableAdapterComponent } from 'src-private/app/shared/table-adapter/table-adapter.component';
import { IDocument } from '../../../claims/interfaces/document';
import { DealerDocumentService } from '../../services/dealer-document.service';
import { ClaimsService } from '../../../claims/services/claims.service';
import { DocumentAddDialogComponent } from '../../../claims/components/document-add-dialog/document-add-dialog.component';
import { environment } from "src-private/environments/environment";
import { NotificationSocketService } from 'src-private/app/services/notification-socket.service';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { CellActions } from '../../../../shared/table-adapter/cell-builder/cell-type';
import { DocumentConflictDialogComponent } from '../../../claims/components/document-conflict-dialog/document-conflict-dialog.component';
import { ToastrService } from "ngx-toastr";
import { DealersSharedService } from '../../services/dealers-shared.service';

@Component({
  selector: 'app-dealer-documents',
  templateUrl: './dealer-documents.component.html',
  styleUrls: ['./dealer-documents.component.scss']
})
export class DealerDocumentsComponent extends FrameworkComponent implements OnInit, AfterViewInit {

  public faPlus = faPlus;

  public dealerId: number;
  public checkLock$: BehaviorSubject<boolean>;

  @BlockUI() blockUI: NgBlockUI;

  private ngUnsubscribe: Subject<any> = new Subject();

  @ViewChild("dealerDocumentsTable")
  dealerDocumentsTable: TableAdapterComponent<IDocument>;
  public dealerDocumentsTableColumns: CellBuilder[] = [
    new CellBuilder(" ", "documentTypeImage", CellType.image, 'w-06'),
    new CellBuilder("Filename", "fileName", CellType.text),
    new CellBuilder("Description", "description", CellType.text),
    new CellBuilder("Created Date", "createdOn", CellType.text, "col-sm-3"),
    new CellBuilder("Uploaded by", "createdBy", CellType.text),
    new CellBuilder("Action", "action", CellType.actions, '',  CellActions.Download | CellActions.Remove)
  ];

  constructor(
    public dialog: MatDialog,
    private accountService: AccountService,
    private dealerDocumentService: DealerDocumentService,
    private claimsService: ClaimsService,
    private dealersSharedService: DealersSharedService,
    private notificationSocketService: NotificationSocketService,
    private toastr: ToastrService,
  ) { 
    super();
    this.dealersSharedService.dealer.subscribe(dealer => {
      if (dealer) {
        this.dealerId = dealer.vendorId;
        this.refreshDocuments();
      }
    });
  }

  ngOnInit(): void {
    this.checkLock$ = new BehaviorSubject<boolean>(null)
  }

  ngAfterViewInit(): void {
    super.build("DealerDocumentsComponent", "dealer-documents-component");
  }

  addDocument() {
    let dialogRef = this.dialog.open(DocumentAddDialogComponent, {
      panelClass: "dealer-add-dialogs",
      width: "600px",
      data: {
        originId: this.dealerId,
      },
    });

    dialogRef.componentInstance.onSubmit
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(dialogRes => {
        if (dialogRes.data) {
          dialogRes.data.originId = this.dealerId;
          dialogRes.data.type = "Action";

          this.blockUI.start();
          this.dealerDocumentService
            .add(dialogRes.data)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(res => {
              this.blockUI.stop();
              dialogRef.close();

              if (res.conflicts && res.conflicts.length > 0) {
                this.promptDocumentConflicts(res.conflicts);
              }

              this.toastr.success(
                "Document successfully added. ",
                "Create Document"
              );

              this.refreshDocuments();
            });
        }
      });
  }

  promptDocumentConflicts(documents: IDocument[]) {
    this.dialog.open(DocumentConflictDialogComponent, {
      panelClass: "document-conflicts-dialog",
      autoFocus: true,
      data: documents 
    });
  }

  /**
   * Update Document
   * @param data Passes an element, and an action to be taken
   */
  updateDocument(data) {
    if (data.action == "download") {
      this.downloadDocument(this.dealerId, data.element.id);
    } else if (data.action == "remove") {
      this.removeDocument(data.element.id);
    }
  }

  downloadDocument(dealerId: number, documentId: number) {
    this.dealerDocumentService
      .download(dealerId, documentId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: HttpResponse<Blob>) => {
        let contentDispositionHeader = res.headers.get("content-disposition");
        // the second part of content-disposition contains the file name
        let fileName = contentDispositionHeader
          .split(";")[1]
          .trim()
          .split("=")[1]
          .replace(/"/g, "");
        FileSaver.saveAs(res.body, fileName);
      });
  }

  removeDocument(documentId: number) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'part-dialog',
      width: '400px',
      autoFocus: true,
      data: {
        message: "Are you sure you want to remove document?",
        type: DialogType[0].toString()
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dealerDocumentService
        .remove(documentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.toastr.success(
            "Document successfully removed. ",
            "Remove Document"
          );
          this.refreshDocuments();
        });
      }
    });
  }

  refreshDocuments() {
    if(!this.dealerId) return;
    this.dealerDocumentsTable.refresh(
      this.dealerDocumentService.retrieveAll(this.dealerId).pipe<IDocument[]>(map(doc => doc.map(x=> {
        x.createdOn = `${moment(x.createdDate).format("YYYY-MM-DD h:mm A (MMMM DD)")}`
        x.documentTypeImage = this.claimsService.getDocumentTypeUrl(x.fileType);
        return x;
      })))
    );
  }

  viewDocument(response){
    const a = document.createElement('a');
    a.href = `${environment.serverUrl}dealer/${response.data.dealerId}/document/${response.data.id}`;
    a.target = "_blank"
    a.title = response.data.fileName;
    a.click();
    a.rel = "noopener"
    a.remove();
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    this.notificationSocketService.setClaimLock(null)
  }

  canRenderActions = (actions: CellActions): boolean => {
    if ((actions & CellActions.Remove) === CellActions.Remove
      || (actions & CellActions.Download) === CellActions.Download) {
      return this.isAccountingOrClientService;
    }
    return false;
  }

  public get isAccountingOrClientService(): boolean{
    return [this.accountService.isAccountant(),
    this.accountService.isDealerServicesUser(),
    this.accountService.isDealerServicesPowerUser(),
    this.accountService.isDealerServicesManager(),
    this.accountService.isClientServiceConcierge(),
    this.accountService.isClientServiceManager()].some(x => x)
  }

}
