<mat-dialog-content>
  <form [formGroup]="documentAddForm">
    <div class="container-fluid dialogContent">
      <div>
        <div class="dropzone" fileDragDrop (filesChangeEmiter)="onFileChange($event)">
          <div class="text-wrapper">
            <div class="centered">
              <input type="file" name="file" id="file" (change)="onFileChange($event.target.files)" multiple>
              <label for="file"><span class="textLink"><fa-icon class="file-upload" [icon]="faUpload"></fa-icon>Select your file</span> or Drop it here!</label>
            </div>
          </div>
        </div>
        <div class="errorMessageAlignment">
          <mat-error  *ngIf="isSubmitted && documentAddForm.get('documents').errors?.required">
            File is required
          </mat-error>
          <mat-error  *ngIf="documentAddForm.get('documents').errors?.pattern">
            Please select an image or document.
          </mat-error>
        </div>
      </div>
      <hr>
      <h3 mat-subheader>Files</h3>
      <div class="row fileItem" *ngFor="let f of selectedFile; let ind = index">
        <div class="col-sm-3 fileItemIconDiv align-left" (click)="openConfirmDialog(ind);">
          <img width="20" height="20" class="border-radius-9px" [src]="getImageUrl(f.name)">
        </div>
        <div class="col-sm-6 fileItemText align-left">
          <span> {{ f.name }}</span>
        </div>
        <div class="col-sm-3 fileItemText align-left">
          <button mat-button class="btn btnFileDelete" type="button" (click)="deleteFile(f)">
            <fa-icon [icon]="faTrash" class="pr-2 text-danger"></fa-icon> 
          </button>
        </div>
      </div>
      
      <div class="row" [hidden]="selectedFile.length > 1">
        <div class="col-md-12">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="description" rows="6"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="float-right">
  <button mat-button mat-dialog-close class="btn" type="button" (click)="cancel()">
    <fa-icon [icon]="faWindowClose" class="pr-2 text-danger"></fa-icon> Cancel
  </button>
  <button mat-button class="btn" (click)="addDocument()">
    <fa-icon [icon]="faSave" class="pr-2 text-success"></fa-icon> Save
  </button>
</mat-dialog-actions>
