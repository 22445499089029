<div [formGroup]="settingsForm" class="container-fluid">
  <div class="row">
    <div class="col-sm-2">

      <div class="form-group-static"
           *ngIf="isStatic">
        <label for="dealerStatus">Dealer Status</label>
        <input class="w-100"
               id="dealerStatus"
               type="text"
               readonly
               [disabled]="disableInput">
      </div>

      <mat-form-field class="w-100"
                      *ngIf="!isStatic">
        <mat-select placeholder="Dealer Status"
                    required
                    formControlName="vendorStatusId">
          <mat-option *ngFor="let status of dealerStatuses"
                      [value]="status.vendorStatusId">
            {{status.label}}
          </mat-option>
        </mat-select>
        <mat-error>
          <mat-error *ngIf="isSubmitted && f.vendorStatusId.errors?.required">Dealer Status is required</mat-error>
        </mat-error>
      </mat-form-field>

    </div>
    <div class="col-sm-3">

      <div class="form-group-static"
           *ngIf="isStatic">
        <label for="dealerType">Dealer Type</label>
        <input class="w-100"
               id="dealerType"
               type="text"
               readonly
               value="{{dealerType}}">
      </div>

      <mat-form-field class="w-100"
                      *ngIf="!isStatic">
        <mat-select placeholder="Dealer Type"
                    required
                    formControlName="vendorTypeId">
          <mat-option *ngFor="let type of dealerTypes"
                      [value]="type.vendorTypeId">
            {{type.label}}
          </mat-option>
        </mat-select>
        <mat-error>
          <mat-error *ngIf="isSubmitted && f.vendorTypeId.errors?.required">Dealer Type is required</mat-error>
        </mat-error>
      </mat-form-field>

    </div>
    <div class="col-sm-3">

      <div class="form-group-static"
           *ngIf="isStatic">
        <label for="dealerGroup">Dealer Group</label>
        <input class="w-100"
               id="dealerGroup"
               type="text"
               readonly
               value="{{dealerGroup}}">
      </div>

      <mat-form-field class="w-100"
                      *ngIf="!isStatic">
        <mat-select placeholder="Dealer Group"
                    formControlName="vendorGroupId">
          <mat-option *ngFor="let group of dealerGroups"
                      [value]="group.vendorGroupId">
            {{group.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <div class="col-sm-4">

      <div class="form-group-static"
           *ngIf="isStatic">
        <label for="franchise">Franchise</label>
        <input class="w-100"
               id="franchise"
               type="text"
               readonly
               value="{{franchise}}">
      </div>

      <mat-form-field class="w-100"
                      *ngIf="!isStatic">
        <mat-select placeholder="Franchise"
                    formControlName="franchiseId">
          <mat-option *ngFor="let franchise of franchises"
                      [value]="franchise.franchiseId">
            {{franchise.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
  </div>
  <div class="row">
    <div class="col-sm-3"
         *ngIf="!isDealerAccountManager">

      <div class="form-group-static"
           *ngIf="isStatic">
        <label for="dealerAccountManager">Dealer Account Manager</label>
        <input class="w-100"
               id="dealerAccountManager"
               type="text"
               readonly
               value="{{dealerAccountManager}}">
      </div>

      <mat-form-field class="w-100"
                      *ngIf="!isStatic">
        <mat-select placeholder="Dealer Account Manager"
                    required
                    formControlName="dealerAccountManagerId">
          <mat-option *ngFor="let manager of dealerAccountManagers"
                      [value]="manager.id">
            {{manager.name}}
          </mat-option>
        </mat-select>
        <mat-error>
          <mat-error *ngIf="isSubmitted && f.dealerAccountManagerId.errors?.required">Dealer Account Manager is required</mat-error>
        </mat-error>
      </mat-form-field>

    </div>
    <div class="col-sm-2">

      <div class="form-group-static"
           *ngIf="isStatic">
        <label for="convenienceFee">Convenience Fee</label>
        <input class="w-100"
               id="convenienceFee"
               type="text"
               readonly
               formControlName="convenienceFee">
        <span class="suffix">%</span>
      </div>

      <mat-form-field class="w-100"
                      *ngIf="!isStatic">
        <input autocomplete="off"
               placeholder="Convenience Fee"
               required
               formControlName="convenienceFee"
               (blur)="onConvenienceFeeBlur()"
               matInput>
        <!--<mat-icon matSuffix><i class="fal fa-percentage"></i></mat-icon>-->
        <span matSuffix>%</span>
        <mat-error>
          <mat-error *ngIf="isSubmitted && f.convenienceFee.errors?.required">Convenience Fee is required</mat-error>
        </mat-error>
      </mat-form-field>

    </div>
    <div class="col-sm-5">

      <div class="form-group-static"
           *ngIf="isStatic">
        <label for="repairCenter">Repair Centre</label>
        <input class="w-100"
               id="repairCenter"
               type="text"
               readonly
               value="{{repairCenter}}" 
               [disabled]="disableInput">
      </div>

      <mat-form-field class="w-100" *ngIf="!isStatic">
        <input type="text" matInput placeholder="Repair Centre" [matAutocomplete]="repairCentre"
          (input)="filterRepairCentres($event.target.value)" id="repairCentreSelect"  [disabled]="disableInput">
        <mat-autocomplete autoActiveFirstOption #repairCentre="matAutocomplete" [displayWith]="repairCentreAutoCompleteDisplay">
          <mat-option *ngIf="isLoading" class="spinner">
            <mat-spinner diameter="25"></mat-spinner>
          </mat-option>
          <ng-container *ngIf="!isLoading">
            <mat-option *ngFor="let repairCentre of filteredRepairCentres" [value]="repairCentre"
              (onSelectionChange)="onRepairCentreSelected(repairCentre, $event)">
              [{{repairCentre.id}}] {{repairCentre.name}}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>

    </div>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <div class="row">
        <div class="col-sm-3">

          <div class="form-group-static"
               *ngIf="isStatic">
            <label for="minVehicleYear">Vehicle Year Range (MIN)</label>
            <input class="w-100"
                   id="minVehicleYear"
                   type="text"
                   readonly
                   formControlName="minVehicleYear">
          </div>

          <mat-form-field class="w-100"
                          *ngIf="!isStatic">
            <mat-select placeholder="Vehicle Year Range (MIN)"
                        formControlName="minVehicleYear">
              <mat-option *ngFor="let item of vehicleYearRange"
                          [value]="item.value">
                {{item.text}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <div class="col-sm-3">

          <div class="form-group-static"
               *ngIf="isStatic">
            <label for="maxVehicleYear">Vehicle Year Range (MAX)</label>
            <input class="w-100"
                   id="maxVehicleYear"
                   type="text"
                   readonly
                   formControlName="maxVehicleYear">
          </div>

          <mat-form-field class="w-100"
                          *ngIf="!isStatic">
            <mat-select placeholder="Vehicle Year Range (MAX)"
                        formControlName="maxVehicleYear">
              <mat-option *ngFor="let item of vehicleYearRange"
                          [value]="item.value">
                {{item.text}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <div class="col-sm-3">

          <div class="form-group-static"
               *ngIf="isStatic">
            <label for="minVehicleKm">Vehicle Km Range (MIN)</label>
            <input class="w-100"
                   id="minVehicleKm"
                   type="text"
                   readonly
                   formControlName="minVehicleKm"
                   mask="separator.2" thousandSeparator=",">
          </div>

          <mat-form-field class="w-100"
                          *ngIf="!isStatic">
            <mat-select placeholder="Vehicle Km Range (MIN)"
                        formControlName="minVehicleKm">
              <mat-option *ngFor="let item of vehicleKmRange"
                          [value]="item.value">
                {{item.text}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <div class="col-sm-3">

          <div class="form-group-static"
               *ngIf="isStatic">
            <label for="maxVehicleKm">Vehicle Km Range (MAX)</label>
            <input class="w-100"
                   id="maxVehicleKm"
                   type="text"
                   readonly
                   formControlName="maxVehicleKm"
                   mask="separator.2" thousandSeparator=",">
          </div>

          <mat-form-field class="w-100"
                          *ngIf="!isStatic">
            <mat-select placeholder="Vehicle Km Range (MAX)"
                        formControlName="maxVehicleKm">
              <mat-option *ngFor="let item of vehicleKmRange"
                          [value]="item.value">
                {{item.text}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="row">
        <div class="col-sm-3">

          <div class="form-group-static"
               *ngIf="isStatic">
            <label for="newVehiclesOnSite">Vehicles On Site (NEW)</label>
            <input class="w-100"
                   id="newVehiclesOnSite"
                   type="text"
                   readonly
                   formControlName="newVehiclesOnSite"
                   mask="separator.2" thousandSeparator=",">
          </div>

          <mat-form-field class="w-100"
                          *ngIf="!isStatic">
            <input autocomplete="off"
                   placeholder="Vehicles On Site (NEW)"
                   formControlName="newVehiclesOnSite"
                   matInput
                   mask="separator.2" thousandSeparator=",">
          </mat-form-field>

        </div>
        <div class="col-sm-3">

          <div class="form-group-static"
               *ngIf="isStatic">
            <label for="usedVehiclesOnSite">Vehicles On Site (USED)</label>
            <input class="w-100"
                   id="usedVehiclesOnSite"
                   type="text"
                   readonly
                   formControlName="usedVehiclesOnSite"
                   mask="separator.2" thousandSeparator=",">
          </div>

          <mat-form-field class="w-100"
                          *ngIf="!isStatic">
            <input autocomplete="off"
                   placeholder="Vehicles On Site (USED)"
                   formControlName="usedVehiclesOnSite"
                   matInput
                   mask="separator.2" thousandSeparator=",">
          </mat-form-field>

        </div>
        <div class="col-sm-3">

          <div class="form-group-static"
               *ngIf="isStatic">
            <label for="newVehiclesSoldPerMonth">Vehicles Sold Per Month (NEW)</label>
            <input class="w-100"
                   id="newVehiclesSoldPerMonth"
                   type="text"
                   readonly
                   formControlName="newVehiclesSoldPerMonth"
                   mask="separator.2" thousandSeparator=",">
          </div>

          <mat-form-field class="w-100"
                          *ngIf="!isStatic">
            <input autocomplete="off"
                   placeholder="Vehicles Sold Per Month (NEW)"
                   formControlName="newVehiclesSoldPerMonth"
                   matInput
                   mask="separator.2" thousandSeparator=",">
          </mat-form-field>

        </div>
        <div class="col-sm-3">

          <div class="form-group-static"
               *ngIf="isStatic">
            <label for="usedVehiclesSoldPerMonth">Vehicles Sold Per Month (USED)</label>
            <input class="w-100"
                   id="usedVehiclesSoldPerMonth"
                   type="text"
                   readonly
                   formControlName="usedVehiclesSoldPerMonth"
                   mask="separator.2" thousandSeparator=",">
          </div>

          <mat-form-field class="w-100"
                          *ngIf="!isStatic">
            <input autocomplete="off"
                   placeholder="Vehicles Sold Per Month (USED)"
                   formControlName="usedVehiclesSoldPerMonth"
                   matInput
                   mask="separator.2" thousandSeparator=",">
          </mat-form-field>

        </div>
      </div>
    </div>
  </div>
</div>
