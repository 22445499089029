<h5 class="no-records"
    *ngIf="!affiliates.length">
  No affiliates for this dealer at this time
</h5>

<h5 class="dealer-group"
    *ngIf="dealerGroup">
  {{dealerGroup}}
</h5>

<ul class="list-unstyled affiliates"
    *ngIf="affiliates.length">
  <ng-container *ngFor="let affiliate of affiliates">
    <li class="affiliate"
        *ngIf="affiliate.vendorId !== dealerId">

      <a class="affiliate-hyperlink"
         (click) ="redirectToAffliatedDealer(affiliate.vendorId)">
        {{affiliate.friendlyName}}
      </a>

    </li>
  </ng-container>
</ul>

