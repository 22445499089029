export enum PaymentMethod {
    CHEQUE,
    VISA,
    EFT,
    CASH,
    OTHER
}

export enum TowingService{
    SUBLET=1,
    YES=2,
    NO=3
}

export enum RepairTimes{
    THRITY_DAYS = "30 DAYS",
    SIXTY_DAYS = "60 DAYS",
    NINTY_DAYS  = "90 DAYS",
    SIX_MONTHS = "6 MONTHS",
    ONE_YEAR = "1 YEAR",
    TWO_YEARS = "2 YEARS",
    THREE_YEARS = "3 YEARS",
    LIFETIME="LIFETIME"
}