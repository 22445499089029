<mat-slide-toggle *ngIf="coveragePackageArray.length > 0" (change)="selectAll($event)" [checked]="allSelected" color="primary">{{getSelectionStatus()}}</mat-slide-toggle>

<div *ngFor="let coveragePackage of coveragePackageArray">
    <mat-accordion>
        <mat-expansion-panel #matExpansionPanel [expanded]="isExpanded(coveragePackage)" [hideToggle]="!isExpanded(coveragePackage)">
            <mat-expansion-panel-header #panelH class="flex-container" (click)="expandPanel(panelH, $event)">
                <mat-slide-toggle class="parent-toggle" (change)="packageChecked($event, coveragePackage)" [checked]="covPackAllSelected(coveragePackage)" color="primary">{{coveragePackage.CoverageGroupLabel}}</mat-slide-toggle>
                <mat-form-field class="parent-bonus-bucks-amount bonus-bucks-amount">
                    <span matPrefix>$</span>  
                    <input matInput type="number" min="0" oninput="this.value = Math.abs(this.value)" (change)="onChangeBucks(coveragePackage)" placeholder="Bonus Bucks" [(ngModel)]="coveragePackage.Amount" [disabled]="isChecked(coveragePackage)">    
                </mat-form-field>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <div *ngFor="let cov of coveragePackage.Coverages">
                    <app-coverage [coverage]="cov"></app-coverage>
                </div>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
</div>