import { Component, OnInit } from '@angular/core';
import { BbFormManagementService } from '../../../services/bb-form-management.service';
import { CoveragePackage } from '../../../models/bonus-bucks.Models';

@Component({
  selector: 'app-coverages',
  templateUrl: './coverages.component.html',
  styleUrls: ['./coverages.component.scss']
})
export class CoveragesComponent implements OnInit {

  coverageGroups: any;

  constructor(private formManager: BbFormManagementService) { }

  ngOnInit(): void {
      this.coverageGroups = [...new Set(this.coverages.map(item => item.CoverageTypeGroupName))];
    }

  get coverages(): CoveragePackage[] {
    return this.formManager.currentCoveragePackages;
  }

  filterCoverage(filter) {
    return this.coverages.filter(cov => cov.CoverageTypeGroupName == filter);
  }

  get isCoverageSelected(): boolean {
    return this.coverages.some(cp => cp.Coverages.some(cov => cov.IsChecked));
  }

  public get isValid(): boolean {
    return this.formManager.isFormValid()
  }

}
