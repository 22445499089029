import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BonusBucksValue } from '../models/bonus-bucks.Models';

@Injectable()
export class BBAmountService {

  private bbValSource = new BehaviorSubject<BonusBucksValue>(null);
  public allBBVals: Array<BonusBucksValue>;
  public currentBonusBuckValue = this. bbValSource.asObservable();
  public initialBBValue : BonusBucksValue;

  constructor() { }

  public setBonusBuckValue(value: BonusBucksValue) {
    if (value == null) {
      this.bbValSource.next(null);
    } else {
      this.allBBVals.forEach(v => v.isActive = false);
      this.bbValSource.next(this.allBBVals.find(v => v.amount === value.amount));
      this.bbValSource.value.isActive = true;
    }
  }

  public setInitialValue(value: BonusBucksValue) {
    this.initialBBValue = value;
  }

  public setAllBonusBucksValues(values: BonusBucksValue[]) {
    this.allBBVals = values;
  }
}
