import { Component, EventEmitter, Output } from '@angular/core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

/*-- Enums --*/
import { Sidebar } from '../../../../enums/sidebar.enum';

/*-- Services --*/
import { DynamicComponentService } from '../../../../services/dynamic-component.service';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent {
  @Output() modalResponse: EventEmitter<boolean> = new EventEmitter<boolean>();

  private sidebar: Sidebar;

  public action: string;
  public dealer: string;
  public title: string;
  public faCheck = faCheck;
  public faTimes = faTimes;

  constructor(private dynamicComponentService: DynamicComponentService) { }

  //#region - Events
  onNoClick(): void {
    this.modalResponse.emit(false);
  }

  onYesClick(): void {
    this.modalResponse.emit(true);
  }
  //#endregion
}
