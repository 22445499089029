<mat-dialog-content>
    <legend>{{type}}</legend>
    <div class="container-fluid dialogContent">
        <div class="row">
            <div class="col-md-12">
                {{message}}
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="float-right">
    <button [mat-dialog-close]="false" class="btn custom-button-red" type="button">
        <fa-icon [icon]="faWindowClose" class="pr-2 text-danger"></fa-icon>Cancel
    </button>
    <button *ngIf="type != 'Alert'" class="btn custom-button-green" type="button" [mat-dialog-close]="true">
        <fa-icon [icon]="faSave" class="pr-2 text-success"></fa-icon>Confirm
    </button>
</mat-dialog-actions>