import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { Observable, of } from "rxjs";
import { catchError, share } from "rxjs/operators";
import { NotificationService } from "src-private/app/services/notification.service";
import { Notification } from "src-private/app/interfaces/notification.interface";
import { AccountService } from "../../account/services/account.service";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent implements OnInit {
  @Output() modalResponse: EventEmitter<string> = new EventEmitter<string>();

  public faCheck = faCheck;
  public faTimes = faTimes;
  public userId: string;

  public notifications: Notification[];
  public hasNotifications = false;
  private notifications$: Observable<any>;

  constructor(
    private notificationService: NotificationService,
    private accountService: AccountService
  ) { }

  ngOnInit() {
    this.userId = this.accountService.getUserId();
    this.build();
  }

  build() {
    this.hasNotifications = false;
    this.notifications$ = this.notificationService
      .list(false)
      .pipe(
        share(),
        catchError((error) => {
          if (error.error instanceof ErrorEvent)
            console.log(`Error: ${error.error.message}`);
          else console.log(`Error: ${error.message}`);
          return of([]);
        })
      );
    this.notifications$.subscribe((data) => {
      this.notifications = data;
      if (data.length > 0) this.hasNotifications = true;
    });
  }

  navigateFunction(param: any) {
    this.notificationService.navigateFunction(param);
    this.closeSideBar();
  }

  markAsRead(notification: Notification) {
    notification.isRead = true;
    if (notification.id != 0) {
      this.notificationService.update(notification).subscribe(() => {
        this.build();
      });
    }
  }

  markAllAsRead() {
    this.notifications.forEach((not) => {
      this.markAsRead(not);
    });
    this.closeSideBar();
  }

  closeSideBar() {
    this.modalResponse.emit();
  }
}
