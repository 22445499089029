import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from 'src-private/environments/environment';

/*-- Interfaces --*/
import { ICompetitor } from '../interfaces/competitor.interface';
import {
  IDealer, IDealerAffiliates, IDealerDealsRebates, IDealerGroup, IDealerLastApplication, IDealerStatus, IDealerType
} from '../interfaces/dealer.interface';
import { IEazeeTrakFile, IEazeeTrakReasonGroup } from '../interfaces/eazeetrak.interface';
import { IFranchise } from '../interfaces/franchise.interface';
import { IRepairCenter } from '../interfaces/repair-centre.interface';
import { INote, INoteGroup } from '../interfaces/note.interface';
import { IProvinceState } from '../interfaces/province-state.interface';
import { IRole } from '../interfaces/role.interface';
import { ITitle } from '../interfaces/title.interface';
import { IUser } from '../interfaces/user.interface';

/*-- Services --*/
import { RequestCacheService } from '../../../helpers/request-cache.service';
import { UtilsService } from '../../../services/utils.service';
import { IBatchFile } from '../interfaces/batch-file';
import { DealerStats } from '../models/dealer-stats.model';

@Injectable({
  providedIn: 'root'
})
export class DealersService {

  constructor(private http: HttpClient) { }

  competitorCreate(label: string) {
    const params = {
      label: label
    };

    return this.http.post(environment.resourceUrl + 'api/gwc/competitor/create', params);
  }

  competitorLookup(force: boolean = false): Observable<ICompetitor[]> {
    return this.http.get<ICompetitor[]>(environment.resourceUrl + 'api/gwc/competitor/lookup',
      { headers: RequestCacheService.GetCacheHeaders(force) });
  }

  convenienceFeeRetrieve(dealerId?: number, force: boolean = false): Observable<number> {
    const params = {
      vendorId: dealerId
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<number>(environment.resourceUrl + 'api/gwc/conveniencefee/retrieve',
      { params: queryParams, headers: RequestCacheService.GetCacheHeaders(force) });
  }

  dealerCreate(dealer: IDealer) {
    const params = {
      vendorJson: JSON.stringify(dealer)
    };

    return this.http.post(environment.resourceUrl + 'api/gwc/vendor/create', params);
  }

  dealerGroupLookup(force: boolean = false): Observable<IDealerGroup[]> {
    return this.http.get<IDealerGroup[]>(environment.resourceUrl + 'api/gwc/vendorgroup/lookup',
      { headers: RequestCacheService.GetCacheHeaders(force) });
  }

  dealerLatestApplication(dealerId: number, force: boolean = false): Observable<IDealerLastApplication> {
    const params = {
      vendorId: dealerId
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<IDealerLastApplication>(environment.resourceUrl + 'api/gwc/vendor/retrievelatestapplication',
      { headers: RequestCacheService.GetCacheHeaders(force), params: queryParams });
  }

  dealerLookup(force: boolean = false, lookupValue: string = null): Observable<IDealer[]> {
    const params = {
      lookupValue: lookupValue
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<IDealer[]>(environment.resourceUrl + 'api/gwc/vendor/lookup',
      { headers: RequestCacheService.GetCacheHeaders(force), params: queryParams });
  }

  dealerLookupByAlias(lookupText: string): Observable<IDealer[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('lookupText', lookupText);

    return this.http.get<IDealer[]>(environment.resourceUrl + 'api/gwc/vendor/lookupbyalias', { params: httpParams });
  }

  dealerRetrieve(dealerId: number, force: boolean = false): Observable<IDealer> {
    const params = {
      vendorId: dealerId
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<IDealer>(environment.resourceUrl + 'api/gwc/vendor/retrieve',
      { headers: RequestCacheService.GetCacheHeaders(force), params: queryParams })
  }

  dealerRetrieveAffiliates(dealerGroupId: number, force: boolean = false): Observable<IDealerAffiliates[]> {
    const params = {
      vendorGroupId: dealerGroupId
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<IDealerAffiliates[]>(environment.resourceUrl + 'api/gwc/vendor/retrieveaffiliates',
      {
        headers: RequestCacheService.GetCacheHeaders(force),
        params: queryParams
      });
  }

  dealerRetrieveDealsRebates(dealerId: number, force: boolean = false): Observable<IDealerDealsRebates[]> {
    const params = {
      vendorId: dealerId
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<IDealerDealsRebates[]>(environment.resourceUrl + 'api/gwc/vendor/retrievedealsrebates',
      { headers: RequestCacheService.GetCacheHeaders(force), params: queryParams });
  }

  dealerStatusLookup(force: boolean = false): Observable<IDealerStatus[]> {
    return this.http.get<IDealerStatus[]>(environment.resourceUrl + 'api/gwc/vendorstatus/lookup',
      { headers: RequestCacheService.GetCacheHeaders(force) });
  }

  dealerTypeLookup(force: boolean = false): Observable<IDealerType[]> {
    return this.http.get<IDealerType[]>(environment.resourceUrl + 'api/gwc/vendortype/lookup',
      { headers: RequestCacheService.GetCacheHeaders(force) });
  }

  dealerUpdate(dealer: IDealer) {
    const params = {
      vendorJson: JSON.stringify(dealer)
    };

    return this.http.post(environment.resourceUrl + 'api/gwc/vendor/update', params);
  }

  eazeeTrakFileRetrieveByDealer(vendorId: number, force: boolean = false): Observable<IEazeeTrakFile[]> {
    const params = {
      vendorId: vendorId
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<IEazeeTrakFile[]>(environment.resourceUrl + 'api/gwc/eazeetrakfile/retrievebyvendor',
      { params: queryParams, headers: RequestCacheService.GetCacheHeaders(force) });
  }

  eazeeTrakReasonGroupLookup(force: boolean = false): Observable<IEazeeTrakReasonGroup[]> {
    return this.http.get<IEazeeTrakReasonGroup[]>(environment.resourceUrl + 'api/gwc/eazeetrakreasongroup/lookup',
      { headers: RequestCacheService.GetCacheHeaders(force) });
  }

  franchiseLookup(force: boolean = false): Observable<IFranchise[]> {
    return this.http.get<IFranchise[]>(environment.resourceUrl + 'api/gwc/franchise/lookup',
      { headers: RequestCacheService.GetCacheHeaders(force) });
  }

  noteCreate(note: INote) {
    const body = {
      noteGroupId: note.noteGroupId,
      applicationId: note.applicationId,
      vendorId: note.vendorId,
      warrantyId: note.warrantyId,
      comment: note.comment,
      isPinned: note.isPinned
    };

    return this.http.post(environment.resourceUrl + 'api/gwc/note/create', body);
  }

  noteGroupLookup(force: boolean = false): Observable<INoteGroup[]> {
    return this.http.get<INoteGroup[]>(environment.resourceUrl + 'api/gwc/notegroup/lookup',
      { headers: RequestCacheService.GetCacheHeaders(force) });
  }

  noteRetrieve(vendorId: number, startdate : string, endDate : string, force: boolean = false): Observable<INote[]> {
    const params = {
      vendorId: vendorId, startDate : startdate, endDate : endDate
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<INote[]>(environment.resourceUrl + 'api/gwc/note/retrieve',
      { headers: RequestCacheService.GetCacheHeaders(force), params: queryParams });
  }

  provinceStateLookup(force: boolean = false): Observable<IProvinceState[]> {
    return this.http.get<IProvinceState[]>(environment.resourceUrl + 'api/gwc/provincestate/lookup',
      { headers: RequestCacheService.GetCacheHeaders(force) });
  }

  repairCenterLookup(force: boolean = false): Observable<IRepairCenter[]> {
    return this.http.get<IRepairCenter[]>(environment.resourceUrl + 'api/gwc/repaircentre/lookup',
      { headers: RequestCacheService.GetCacheHeaders(force) });
  }

  roleRetrieveByRoleGroup(roleGroup: string, force: boolean = false): Observable<IRole[]> {
    const params = {
      name: roleGroup
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<IRole[]>(environment.resourceUrl + 'api/gwi/aspnetroles/retrievebyrolegroup',
      { headers: RequestCacheService.GetCacheHeaders(force), params: queryParams });
  }

  titleRetrieve(name: string, force: boolean = false): Observable<ITitle[]> {
    const params = {
      name: name
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<ITitle[]>(environment.resourceUrl + 'api/gwi/aspnettitles/retrieve',
      { headers: RequestCacheService.GetCacheHeaders(force), params: queryParams });
  }

  userRetrieveByRole(name: string, force: boolean = false): Observable<IUser[]> {
    const params = {
      name: name
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<IUser[]>(environment.resourceUrl + 'api/gwi/aspnetusers/retrievebyrole',
      { headers: RequestCacheService.GetCacheHeaders(force), params: queryParams });
  }

  userRetrieveByDealer(vendorId: number, force: boolean = false): Observable<IUser[]> {
    const params = {
      vendorId: vendorId
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<IUser[]>(environment.resourceUrl + 'api/gwi/aspnetusers/retrievebyvendor',
      { headers: RequestCacheService.GetCacheHeaders(force), params: queryParams });
  }

  addBatchFile(batchfileModel:IBatchFile):Observable<any>{ 
    return this.http.post(environment.resourceUrl + 'api/gwc/vendor/createbatchfile', batchfileModel)
  }

  retrieveDealerStats(vendorId: number): Observable<DealerStats>{
    return this.http.get<DealerStats>(`${environment.resourceUrl}api/gwc/vendor/retrieveDealerStats`, {
      params: new HttpParams().set('vendorId', vendorId.toString())
    })
  }
}
