import { Component } from '@angular/core';
import {OidcSecurityService} from "angular-auth-oidc-client";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {
  constructor(private oidcSecurityService: OidcSecurityService) {
    this.oidcSecurityService.logoff().subscribe((result) => console.log(result));
  }


}
