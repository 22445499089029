<div class="container-fluid">
  <div class="row">
    <div class="col-md-7">

      <fieldset class="transparent"
                *ngIf="bp > 2 || showLatestApplication">
        <legend>Latest Application</legend>
        <app-latest-application [dealer]="dealer"></app-latest-application>
      </fieldset>

      <fieldset class="transparent"
                *ngIf="bp > 2 || showDealsRebates">
        <legend>Deals &amp; Rebates</legend>
        <app-deals-and-rebates [dealer]="dealer"></app-deals-and-rebates>
      </fieldset>

    </div>
    <div class="col-md-5">

      <fieldset class="transparent"
                *ngIf="bp > 2 || showStatistics">
        <legend>Statistics</legend>

        <app-statistics [dealer]="dealer"></app-statistics>

        <ul class="datagrid-tabs" *ngIf="vendor && vendor.vendorGroupId">
          <li>
            <div class="tab-wrap">

              <input type="radio" name="tabs" id="tab1" ng-checked="state.model.business.statistics.tab === 'Dealer'">

              <div class="tab-label-content" id="tab1-content">
                <label for="tab1" ng-click="state.model.business.statistics.tab = 'Dealer'; vm.refreshStatistics_disabled()">Dealer</label>

              </div>

              <input type="radio" name="tabs" id="tab2" ng-checked="state.model.business.statistics.tab === 'Group'">
              <div class="tab-label-content" id="tab2-content">
                <label for="tab2" ng-click="state.model.business.statistics.tab = 'Group'; vm.refreshStatistics_disabled()">Group</label>
              </div>

              <div class="slide"></div>

            </div>
          </li>
        </ul>

      </fieldset>

    </div>
  </div>
</div>
