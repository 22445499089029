import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subject } from 'rxjs';
import { faEdit, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { RepairCentreEditComponent } from '../repair-centre-edit/repair-centre-edit.component';
import { IRepairCentre } from '../../../interfaces/repair-centre.interface';
import { RepairCentreService } from '../../../services/repair-centre.service'
import { AccountService } from '../../../../account/services/account.service';
import { ClaimsService } from '../../../services/claims.service';
import { Country } from 'src-private/app/enums/country.enum';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-repair-centre-info',
  templateUrl: './repair-centre-info.component.html',
  styleUrls: ['./repair-centre-info.component.scss']
})
export class RepairCentreInfoComponent implements OnInit {

  private ngUnsubscribe$: Subject<boolean> = new Subject();

  public faEdit = faEdit
  public faPlus = faPlus

  @Input() repairCentre: IRepairCentre;
  @Input() repairCentreId: number;

  @Output() onEdit: EventEmitter<IRepairCentre> = new EventEmitter<IRepairCentre>();

  constructor(
    public dialog: MatDialog,
    private repairCentreService: RepairCentreService,
    private accountService :AccountService,
    private claimService: ClaimsService
  ) { }

  ngOnInit(): void {
    if (!this.repairCentre) {
      if (this.repairCentreId) {
        this.repairCentreService.retrieve(this.repairCentreId)
          .pipe(takeUntil(this.ngUnsubscribe$))
          .subscribe(data => {
          if (data) {
            this.repairCentre = data;
          }
        });
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }

  edit() {
    let claim = this.claimService.getSelectedClaim;
    if (claim) {
      this.repairCentre.ClaimID = claim.id;
    }
    this.repairCentre.assignedAdjuster = this.accountService.getUserId();
    let dialogRef = this.dialog.open(RepairCentreEditComponent, {
      width: '50vw',
      data: this.repairCentre
    });
    dialogRef.componentInstance.isEdit = true;
    dialogRef.afterClosed().subscribe(res => {
      var data = res?.data;
      if (data) {
        this.repairCentre = data;
        this.onEdit.emit(data);
      }
    });
  }

  formatAddress(rc: IRepairCentre): string {
    var res = '';
    if (rc) {
      if (rc.city) {
        res += rc.city;
      }
      if (rc.province) {
        if (res.length > 0) {
          res += ', ';
        }
        res += rc.province;
      }
      if (rc.country) {
        res += res.length > 0 ? ` (${rc.country})` : ` ${rc.country}`;
      }
      if (rc.postalCode) {
        let postalCode = (this.isCanada && rc.postalCode.length == 6)
          ? `${rc.postalCode.slice(0, 3)} ${rc.postalCode.slice(3, 6)}` : rc.postalCode;
        if (res.length > 0) {
          res += ' - ';
        }
        res += postalCode;
      }
    }
    return res;
  }

  get isCanada(): boolean {
    let rc = this.repairCentre;
    if (rc) {
      return rc.country.localeCompare(Country[Country.Canada], undefined, { sensitivity: 'accent' }) === 0;
    }
    return false;
  }

  get canEdit(): boolean {
    return this.IsClaimClosed ? this.CanEditClosedClaim : this.isAdjuster;
  }

  get isAdjuster() : boolean  {
    return this.accountService.isAdjuster() || this.accountService.isClaimsManager() || this.accountService.isAssistantClaimsManager();
  }

  get IsClaimClosed(): boolean{
    return this.claimService.getSelectedClaim?.IsClaimClosed;
  }

  get CanEditClosedClaim(): boolean{
    return this.accountService.isClaimsManager();
  }
}
