/*-- Interfaces --*/
import { ICustomerSearch } from '../interfaces/customer-search.interface';


export class CustomerSearch implements ICustomerSearch {
  customerId: number;
  firstName: string;
  lastName: string;
  fullName?: string;

  constructor(c = null) {
    this.customerId = c ? c.customerId : null;
    this.firstName = c? c.firstName : null;
    this.lastName = c ? c.lastName : null;
    this.fullName = c ? (c.firstName + ' ' + c.lastName) : null;
  }
}
