/*-- Interfaces --*/
import { IWarrantyRetrieve } from '../interfaces/warranty-retrieve.interface';


export class WarrantyRetrieve implements IWarrantyRetrieve {
  warrantyId: number;
  customerId: number;
  claimId: number;

  constructor() {
    this.warrantyId = null;
    this.customerId = null;
  }
}
