<mat-dialog-content>
  <form [formGroup]="documentMoveForm">
    <div class="container-fluid dialogContent container-Padding">
      <div class="row">
        <div class="col-md-12">
          <mat-list role="list" *ngFor="let kv of items | keyvalue">
            <ng-container >
              <h3 matSubheader>{{header(kv.key) | titlecase}}</h3>
              <mat-list-item role="listitem" *ngFor="let item of kv.value">
                <mat-radio-button color="primary" [checked]="false" [value]="item.id" (change)="onSelection(kv.key, item)">
                  {{display(kv.key, item)}}
                </mat-radio-button>
              </mat-list-item>
            </ng-container>
          </mat-list>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="float-right">
  <button mat-button [mat-dialog-close]="false" class="btn" type="button">
    <fa-icon [icon]="faWindowClose" class="pr-2 text-danger"></fa-icon> Cancel
  </button>
  <button mat-button class="btn" (click)="submit()">
    <fa-icon [icon]="faSave" class="pr-2 text-success"></fa-icon> Move 
  </button>
</mat-dialog-actions>

