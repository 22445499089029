export enum WarrantyStatus {
  QuoteActive = 1,
  Pending = 2,
  Active = 3,
  CancelledManual = 4,
  Expired = 5,
  QuoteExpired = 6,
  CancelledAuto = 7,
  PendingAlbertaDealer = 8,
  PendingDealerOnHold = 9,
  PendingDuplicateVIN = 10,
}


export enum WarrantyActivationStatus {
  NotActivated = 0,
  Activated = 1,
  ExplicitlyActivated = 2,
  ImplicitlyActivated = 3,
  Deactivated = 4
}