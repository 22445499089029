import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Country } from 'src-private/app/enums/bonus-bucks.enums';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EftImageComponent } from '../eft-image/eft-image.component';

@Component({
  selector: 'app-eft-selection',
  templateUrl: './eft-selection.component.html',
  styleUrls: ['./eft-selection.component.scss']
})
export class EftSelectionComponent implements OnInit, OnChanges {
  @Input() userRegistrationForm: UntypedFormGroup;

  public lastEFT: any; // TODO: make EFT what we're looking for

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.userRegistrationForm = changes.userRegistrationForm.currentValue;
  }

  onChequeUpload(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = () => {
        this.userRegistrationForm.get('eftimage').patchValue(reader.result);
      };
    }
  }

  pad(mask: string, input: string) : String {
    var ret: string = "0";
    var value = parseInt(input, 10);
    if (value > 0) {
      var len = mask.length;
      ret = String(value).padStart(len, '0');
    }
    return ret;
  }

  institutionChange() {
    var value = this.pad(this.institutionMask, this.institutionCheckControl.value)
    this.institutionCheckControl.patchValue(value);
  }

  branchChange() {
    var value = this.pad(this.branchMask, this.branchRoutingControl.value);
    this.branchRoutingControl.patchValue(value);
  }

  get institutionMask() {
    return this.isCanada ? '999' : '9999';
  }

  get branchMask() {
    return this.isCanada ? '99999' : '999999999';
  }

  viewLastEFT() {
    this.dialog.open(EftImageComponent, { data: { eftimage: this.userRegistrationForm.get('eftimage').value } });
  }

  deleteEFT() {
    this.userRegistrationForm.get('eftimage').patchValue(null);
    this.lastEFT = null;
  }

  get countryControl() { return this.userRegistrationForm.get('country'); }
  get isCanada(): boolean { return this.countryControl.value === Country.Canada; }
  get eftInputText(): string { return this.lastEFT ? 'FILE SELECTED' : 'SELECT FILE'; }
  get branchRoutingControl() { return this.userRegistrationForm.get('branchRouting'); }
  get institutionCheckControl() { return this.userRegistrationForm.get('institutionCheck'); }
  get accountControl() { return this.userRegistrationForm.get('account'); }

}
