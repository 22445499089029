<table class="table table-hover datatable">
  <thead>
    <tr>
      <th>Name</th>
      <th>Email</th>
      <th *ngIf="bp > 2">EazeeApp</th>
      <th *ngIf="bp > 2">Title</th>
      <th *ngIf="IsDealerLoaded" class="header-cell-action">
        <a href="javascript:void(0);"
           (click)="onAddClick($event)">
          <span class="icon">
            <fa-icon [icon]="faPlusSquare"></fa-icon>
          </span>
          <span class="label">
            Add
          </span>
        </a>
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let contact of contacts | orderBy : ['firstName', 'lastName']">
      <tr (click)="contact.expanded = !contact.expanded">
        <td>
          {{contact.firstName}} {{contact.lastName}}
        </td>
        <td>
          <a class="email"
             [attr.href]="'mailto:' + contact.email"
             (click)="onEmailClick($event)">{{contact.email}}</a>
        </td>
        <td *ngIf="bp > 2">{{contact.roleLabel}}</td>
        <td *ngIf="bp > 2">{{contact.title}}</td>
        <td class="data-cell-action">
          <a href="javascript:void(0);"
             (click)="onEditClick($event, contact)">
            <span class="icon">
              <fa-icon [icon]="faEdit"></fa-icon>
            </span>
            <span class="label">
              Edit
            </span>
          </a>
          <a href="javascript:void(0);"
             (click)="onRemoveClick($event, contact)">
            <span class="icon">
              <fa-icon [icon]="faTimesSquare"></fa-icon>
            </span>
            <span class="label">
              Remove
            </span>
          </a>
        </td>
      </tr>
      <tr class="responsive-row"
          *ngIf="bp < 3 && contact.expanded">
        <td colspan="5">
          <table class="responsive-table">
            <tbody>
              <tr>
                <td class="label">EazeeApp</td>
                <td>{{contact.roleLabel}}</td>
              </tr>
              <tr>
                <td class="label">Title</td>
                <td>{{contact.title}}</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
