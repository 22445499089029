<mat-dialog-content>
    <div class="container-fluid dialogContent">
        <div class="row">
            <div class="col-md-12">
                Do you wish to approve this line item?
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                Repair Centre:
            </div>
            <div class="col-md-6">
                {{partData.repairCentreName}}
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                Subtotal:
            </div>
            <div class="col-md-6">
                {{rowTotal}}
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                GST:
            </div>
            <div class="col-md-6">
                {{gstAmount}}
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                Total:
            </div>
            <div class="col-md-6 total">
                {{rowTotalWithTax}}
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                Invoice #:
            </div>
            <div class="col-md-6 form-group-static mb-0">
                <input matInput type="text" [(ngModel)]="invoiceNumber" />
            </div>
        </div>

        <hr class="line"/>        

        <div *ngIf="funcs['addDocument'];else noAddDocument" class="spacing">
            <button class="btn custom-button attach-invoice" type="button" (click)="addDocument()">Attach Invoice</button>
            <div *ngIf="documentData"><em>{{documentData[0].name}}</em></div>
        </div>
        <ng-template #noAddDocument>
            <framework-hidden [display]="button"></framework-hidden>
        </ng-template>

    </div>
</mat-dialog-content>

<mat-dialog-actions class="float-right">
    <button mat-dialog-close class="btn custom-button-red" type="button" (click)="cancel()">
        <fa-icon [icon]="faWindowClose" class="pr-2 text-danger"></fa-icon>Cancel
    </button>
    <button class="btn custom-button-green" type="button" (click)="save()">
        <fa-icon [icon]="faSave" class="pr-2 text-success"></fa-icon>Approve
    </button>
</mat-dialog-actions>