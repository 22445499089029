import { Injectable, Inject } from '@angular/core';
import { AppConfig, APP_CONFIG } from 'src-private/app/app.config';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(@Inject(APP_CONFIG) private config: AppConfig, @Inject(DOCUMENT) private document) { }

  setupMapScript(elementRef) {
    return new Promise((resolve, reject) => {
      const s = this.document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://maps.googleapis.com/maps/api/js?libraries=geometry,places&key=${this.config.mapApiKey}`;
      s.onload = () => resolve(true);
      elementRef.nativeElement.appendChild(s);
    });
  }
}
