<fieldset class="fieldset-form">
  <legend>
    Warranty Notes
    <div *ngIf="funcs['addNote'];else noAddNote" class="float-right">
      <button class="btn custom-button float-right" type="button" (click)="addNote()"
        [hidden]="isClaimClosed ? !canEditClosedClaim : (!canAddNotes)" [ngClass]="{'disableButton': !canAddNotes}">
        <fa-icon [icon]="faPlus"></fa-icon> Add Note
      </button>
    </div>
    <ng-template #noAddNote>
      <framework-hidden [display]="button"></framework-hidden>
    </ng-template>
  </legend>
  <table-adapter #warrantyNotesTable [tableLabel]="'warranty-notes-table'" [columns]="columns">
  </table-adapter>
</fieldset>