<mat-dialog-content>
  <form [formGroup]="complaintAddForm">
    <div class="container-fluid dialogContent">
      <div class="row">
        <div class="col-md-5">
          <mat-form-field class="w-100">
            <mat-label>Complainer</mat-label>
            <mat-select formControlName="complainee">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let complaineeType of complaineeTypes | keyvalue" [value]="complaineeType.value">
                {{complaineeType.key}}
              </mat-option>
            </mat-select>
              <mat-error *ngIf="complaintAddForm.get('complainee').errors?.required">
                Complainer is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4 add-complaint-pad">
          <div class="w-100 float-right">
           <mat-dialog-actions class="float-right mat-dialog-action-margin">
            <button mat-button class="btn complaint-btn" type="button" (click)="fillTextarea()" >
             Add to Complaint
            </button>
            </mat-dialog-actions> 
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <mat-form-field class="w-100">
            <mat-label>Part Block</mat-label>
            <input matInput type="text" formControlName="partGroupName" [matAutocomplete]="partGroupAutocomplete" (input)="filterPartGroups($event.target.value)" />
            <mat-autocomplete #partGroupAutocomplete="matAutocomplete" panelWidth="auto" autoActiveFirstOption >
              <mat-option *ngFor="let pb of partGroupsFiltered" [value]="pb.label" (onSelectionChange)="selectPartsGroup(pb, $event)">{{pb.label}}</mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="complaintAddForm.get('partGroupName').errors?.required">Part Block is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field class="w-100">
            <mat-label>Part Description</mat-label>
            <input matInput type="text" formControlName="description" [matAutocomplete]="partAutocomplete" (input)="filterParts($event.target.value)" />
              <mat-autocomplete #partAutocomplete="matAutocomplete" panelWidth="auto" autoActiveFirstOption>
                <mat-option *ngFor="let p of partsFiltered" [value]="p.label" >{{p.label}}</mat-option>
              </mat-autocomplete>
            <mat-error *ngIf="complaintAddForm.get('description').errors?.required">Part Description is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="w-100">
            <mat-label>Is/Are</mat-label>
            <input matInput type="text" class="input-number" formControlName="isare" />
            <mat-error *ngIf="complaintAddForm.get('isare').errors?.required">Is/Are is required.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Complaint</mat-label>
            <textarea matInput formControlName="complaint" rows="6"></textarea>
            <mat-error *ngIf="complaintAddForm.get('complaint').errors?.required">
              Complaint is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="float-right">
  <button mat-button mat-dialog-close class="btn" type="button" (click)="cancel()">
    <fa-icon [icon]="faWindowClose" class="pr-2 text-danger"></fa-icon> Cancel
  </button>
  <button mat-button class="btn" type="button" (click)="addComplaint()">
    <fa-icon [icon]="faSave" class="pr-2 text-success"></fa-icon> Save
  </button>
</mat-dialog-actions>