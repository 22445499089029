import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src-private/environments/environment';
import { IWarrantyNote } from '../interfaces/warranty-note.interface';

@Injectable({
  providedIn: 'root'
})
export class WarrantyNotesService {

  constructor(private http: HttpClient) { }

  retrieveAll(warrantyId: number): Observable<IWarrantyNote[]> {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('warrantyId', warrantyId.toString());

    return this.http.get<IWarrantyNote[]>(`${environment.resourceUrl}api/gwc/warranty/notes`, { params: httpParams });
  }

  add(note: IWarrantyNote): Observable<IWarrantyNote> {
    let httpParams = new HttpParams();

    Object.keys(note).forEach(function (key) {
      let value = note[key];
      if (key == "createdDate" && note["id"] == 0) {
        value = note[key].toISOString();
      }
      httpParams = httpParams.append(key, note[key]);
    });

    return this.http.post<IWarrantyNote>(`${environment.resourceUrl}api/gwc/warranty/note`, httpParams);
  }
}
