import { Component, Input, OnInit, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';

import { environment } from '../../../../../../environments/environment';

/*-- Components - Dynamic --*/
import { ContactFormComponent } from './contact-form/contact-form.component';
import { RemoveContactComponent } from './remove-contact/remove-contact.component';

/*-- Components - Primary --*/
import { AppComponent } from '../../../../../app.component';

/*-- Enums --*/
import { FormState } from '../../../../../enums/form-state.enum';
import { Sidebar } from '../../../../../enums/sidebar.enum';

/*-- Interfaces --*/
import { IDealer } from '../../../interfaces/dealer.interface';
import { IUser } from '../../../interfaces/user.interface';

/*-- Services --*/
import { BreakpointService } from '../../../../../services/breakpoint.service';
import { DealersService } from '../../../services/dealers.service';
import { DynamicComponentService } from '../../../../../services/dynamic-component.service';
import { IdentityService } from '../../../../../services/identity.service';

/*-- Third Party --*/
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { faEdit, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { faTimesSquare } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnChanges, OnInit {
  @BlockUI() blockUI: NgBlockUI;

  @Input() dealer: IDealer;

  private currentDealer: IDealer;
  public IsDealerLoaded:Boolean=false;

  public bp: number;
  public contacts: IUser[];
  public faPlusSquare = faPlusSquare;
  public faEdit = faEdit;
  public faTimesSquare = faTimesSquare;

  constructor(
    private appComponent: AppComponent,
    private breakpointService: BreakpointService,
    private dealersService: DealersService,
    private dynamicComponentService: DynamicComponentService,
    private identityService: IdentityService,
    private toastrService: ToastrService
  ) {
    breakpointService.bp.subscribe(x => this.bp = x);
  }

  //#region - Lifecycle
  ngOnChanges(changes: SimpleChanges): void {
    const dealer: SimpleChange = changes.dealer;

    if (dealer && dealer.currentValue) {
      this.currentDealer = dealer.currentValue;
      this.retrieveContacts();
    }

    this.IsDealerLoaded = this.currentDealer === undefined ? false : true;
  }

  ngOnInit(): void {
    if (!this.bp) {
      this.bp = this.breakpointService.getBreakpoint();
    } 
  }
  //#endregion

  //#region - Events
  onAddClick(event): void {
    const sidebar = Sidebar.Right;

    let instance: any;

    event.stopPropagation();

    this.dynamicComponentService.setRootViewContainerRef(this.appComponent.dynamicComponentRight);

    instance = this.dynamicComponentService.addDynamicComponent(ContactFormComponent);

    instance.dealerId = this.currentDealer != null || this.currentDealer != undefined ? this.currentDealer.vendorId : 0;
    instance.formState = FormState.Add;
    instance.title = 'Add Contact';

    instance.addContactResponse.subscribe(
      response => {
        if (response) {
          this.retrieveContacts();
          this.dynamicComponentService.closeAndClear(this.appComponent, sidebar);
        } else {
          this.dynamicComponentService.closeAndClear(this.appComponent, sidebar);
        }
      }
    );

    this.appComponent.sidebarRightOpened = true;
  }

  onEditClick(event, contact: IUser): void {
    const sidebar = Sidebar.Right;

    let instance: any;

    event.stopPropagation();

    this.dynamicComponentService.setRootViewContainerRef(this.appComponent.dynamicComponentRight);

    instance = this.dynamicComponentService.addDynamicComponent(ContactFormComponent);

    instance.contact = contact;
    instance.dealerId = this.currentDealer.vendorId;
    instance.formState = FormState.Modify;
    instance.title = 'Edit Contact';

    instance.addContactResponse.subscribe(
      response => {
        if (response) {
          this.retrieveContacts();
          this.dynamicComponentService.closeAndClear(this.appComponent, sidebar);
        } else {
          this.dynamicComponentService.closeAndClear(this.appComponent, sidebar);
        }
      }
    );

    this.appComponent.sidebarRightOpened = true;
  }

  onEmailClick(event): void {
    event.stopPropagation();
  }

  onRemoveClick(event, contact: IUser): void {
    const sidebar = Sidebar.Top;
    let instance: any;

    event.stopPropagation();

    this.dynamicComponentService.setRootViewContainerRef(this.appComponent.dynamicComponentTop);

    instance = this.dynamicComponentService.addDynamicComponent(RemoveContactComponent);

    instance.contact = contact.firstName + ' ' + contact.lastName;
    instance.sidebar = sidebar;
    instance.title = 'Remove Contact Confirmation';

    instance.modalResponse.subscribe(
      response => {
        if (response) {
          this.blockUI.start();

          this.deleteContact(contact).then(
            () => {
              this.retrieveContacts();
              this.dynamicComponentService.closeAndClear(this.appComponent, sidebar);

              this.blockUI.stop();
            }
          );
        } else {
          this.dynamicComponentService.closeAndClear(this.appComponent, sidebar);
        }
      });

    this.appComponent.sidebarTopOpened = true;
  }
  //#endregion

  //#region - API Methods
  private deleteContact(contact: IUser): Promise<{}> {
    const title = 'Remove Contact';

    contact.isEmployee = false;

    const promise = new Promise((resolve, reject) => {
      this.identityService.archive(contact).subscribe(
        () => {
          this.toastrService.success('Your contact has been successfully removed.', title);

          resolve(true);
        },
        () => {
          this.toastrService.error(environment.messages.apiError, title);

          reject();
        }
      );
    });

    return promise;
  }

  private retrieveContacts(): void {
    this.blockUI.start();

    this.dealersService.userRetrieveByDealer(this.currentDealer.vendorId, true).subscribe(
      response => {
        const data: IUser[] = response;

        this.contacts = data;

        this.blockUI.stop();
      }
    );
  }
  //#endregion
}
