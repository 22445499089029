<div [ngClass]="{content : !claimsId, standalone : !claimsId}">
  <div class="row">
    <div class="col-xl-3">
      <div class="col-lg">
        <fieldset class="fieldset-form">
          <legend>
            Contact &nbsp;
            <fa-icon [icon]="faEdit" [hidden]="IsClaimClosed ? !CanEditClosedClaim :!isAdjuster" (click)="isAdjuster ? editCustomer() : false" role="button" ></fa-icon>
          </legend>
          <div class="container-fluid">
            <div class="row row-cols-2">
              <div class="col-md-12">
                Customer #: {{(customer$ | async).customerId}}
              </div>
            </div>
            <div class="row row-cols-2">
              <div class="col-md-12">
                <strong>{{(customer$ | async).firstName}} {{(customer$ | async).lastName}}</strong>
              </div>
            </div>
            <div class="row row-cols-2" *ngIf="(customer$ | async).email != null && isValidEmail">
              <div class="col-md-12">
                <a [href]="'mailto:' + (customer$ | async).email">{{(customer$ | async).email}}</a>
              </div>
            </div>
            <div class="row row-cols-2" *ngIf="(customer$ | async).address != null">
              <div class="col-md-12">
                <label>{{(customer$ | async).address}}</label>
              </div>
            </div>
            <div class="row row-cols-2" *ngIf="(customer$ | async).city != null">
              <div class="col-md-12">
                {{ (customer$ | async).city }}, {{ (customer$ | async).provinceName }} ({{ (customer$ | async)?.countryName }}) - {{ isCanada ? (customer$ | async).postalZipCode.slice(0, 3) + ' ' + (customer$ | async).postalZipCode.slice(3, 6) : (customer$ | async).postalZipCode }}
              </div>
            </div>
            <div class="row row-cols-2" *ngIf="(customer$ | async).phone != null">
              <div class="col-md-12">
                <label>Phone: {{(customer$ | async).phone | mask: '(000) 000-0000'}}</label>
              </div>
            </div>
            <div class="row row-cols-2" *ngIf="(customer$ | async).driversLicense != null">
              <div class="col-md-12">
                Drivers License: {{ (customer$ | async).driversLicense }}
              </div>
            </div>

          </div>
        </fieldset>
      </div>
    </div>
    <div class="col-xl-9">
      <div class="col-lg">
        <app-warranties-table [customerId]="customerId" [readOnly]="isReadOnly"></app-warranties-table>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-5">
      <div class="col-lg">
        <app-customer-notes [customerId]="customerId"></app-customer-notes>
      </div>
    </div>

    <div class="col-xl-7">
      <div class="col-lg">
        <app-claims-table [customerId]="customerId" [columns]="claimsTableColumns"></app-claims-table>
      </div>
    </div>

    <div class="col-xl-12">
      <div class="col-lg">
        <app-customer-documents></app-customer-documents>
      </div>
    </div>

  </div>
</div>
