import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { hubConnection } from 'signalr-no-jquery';
import { environment } from 'src-private/environments/environment';
import { AccountService } from '../areas/account/services/account.service';
import { IClaimLock } from '../areas/claims/interfaces/claim-lock.interface';
import { ClaimLockNotification } from '../areas/claims/models/claim-lock-notification.model';
import { ClaimsService } from '../areas/claims/services/claims.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationSocketService {


    private hubConnection: any
    private hubProxy: any
    public refreshRequested: EventEmitter<any>
    private lockClaimFunction: BehaviorSubject<ClaimLockNotification> = new BehaviorSubject<ClaimLockNotification>(null)
    private hubConnected: boolean = false;

    constructor(private claimService:ClaimsService, private accountService:AccountService){}

    public startNotificationSocket() {
        this.refreshRequested = new EventEmitter<any>();
        this.hubConnection = hubConnection(`${environment.socketUrl}`);
        this.hubProxy = this.hubConnection.createHubProxy(`notificationhub`);

        try {
            this.hubConnection.start({ transport: 'longPolling' }).done(() => {
                console.log('connection started!');
                this.hubConnected = true;
            });

            this.hubConnection.reconnecting(() => {
                this.hubConnected = false;
            })

            this.hubConnection.reconnected(() => {
                this.hubConnected = true;
            })

            this.hubProxy.on('RefreshNotifications', () => {
                this.refreshRequested.emit(null);
            });

            this.hubProxy.on('ClaimLockNotification', (resp, userID)=>{
                const claimLockNotification = new ClaimLockNotification(resp, userID)
                if(this.claimService.getSelectedClaim && this.claimService.getSelectedClaim.id == claimLockNotification.claimLock.claimId && userID != this.accountService.getUserId()){
                    this.setClaimLock(claimLockNotification);
                }
                else{
                    this.setClaimLock(null);
                }

            })

        } catch (error) {
            console.log("ERROR SingnalR socket: " + error);
        }
    }

    public notifyHubtoEmitRefreshNotice() {
        if (this.hubConnected) {
            this.hubProxy.invoke('NotifyAllToRefreshNotifications')
        }
    }

    public setClaimLock(claimLockNotification:ClaimLockNotification | null){
        this.lockClaimFunction.next(claimLockNotification)
    }

    public get ClaimLockObservable():Observable<ClaimLockNotification>{
        return this.lockClaimFunction.asObservable()
    }
}
