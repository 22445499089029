import {
  Component,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
  OnInit,
} from "@angular/core";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { EventEmitter } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { CellBuilder } from "./cell-builder/cell-builder";
import { CellAdapter } from "./table-cell-adapter/cell-adapter";
import { CellActions } from "./cell-builder/cell-type";
import { TableDatasourceAdapter } from "./table-datasource-adapter";
import { catchError, share } from "rxjs/operators";
import {
  faEllipsisV,
  faDownload,
  faTrash,
  faShareSquare,
  faCheck
} from "@fortawesome/free-solid-svg-icons";
import { FrameworkComponent } from "../framework/framework.component";

@Component({
  selector: "table-adapter",
  templateUrl: "./table-adapter.component.html",
  styleUrls: ["./table-adapter.component.scss"],
})
export class TableAdapterComponent<T>
  extends FrameworkComponent
  implements AfterViewInit, OnInit {
  public faEllipsisV = faEllipsisV;
  public faDownload = faDownload;
  public faTrash = faTrash;
  public faShareSquare = faShareSquare;
  public faCheck = faCheck;
  public isLoading = false;

  @Input() checkLock: BehaviorSubject<boolean>
  @Input() columns: CellBuilder[];
  @Input() tableLabel: string;
  @Input() canInteract: boolean = false;
  @Input() paginationRequired : boolean = true;
  @Input() isClaimClosed: boolean = false;
  @Input() canRenderActions: Function;
  @Output() update = new EventEmitter();
  @Output() context = new EventEmitter();
  @Output() onSelectionChange = new EventEmitter();

  private dataSource: MatTableDataSource<T> = new MatTableDataSource();
  private data$: Observable<any[]>;

  public columnNames = [];
  public columnModelNames = [];

  public length = 5;
  public pageSize = 5;

  @ViewChild(MatTable) table: MatTable<T>;
  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    if (this.paginationRequired && paginator){
        paginator.pageSize = this.pageSize;
        paginator.length = this.length;
        this.dataSource.paginator = paginator;
    } 
  }
  @ViewChild(MatSort) sort: MatSort;

  public get CellActions() {
    return CellActions; 
  }

  ngOnInit(): void {
    if(this.checkLock){
      this.checkLock.asObservable().subscribe((lock)=>{
        if(lock != null){
          this.disableButton(lock)
        }
      })
    }
  }

  /**
   * Set Columns
   */
  private setColumns() {
    this.columnNames = [];
    this.columns.forEach((element) => {
      this.columnNames.push(element["cellName"]);
      this.columnModelNames.push(element["modelAttrName"]);
    });
  }

  /**
   * Row Clicked, Emitter
   * @param row
   */
  onRowClick(row, event, auxClick) {
    this.update.emit({ data: row.data, aux: auxClick, ctrlKey: event.ctrlKey });
  }

  /**
   * Update the datasource with a subscription to trigger the change.
   */
  private mapDataSource() {
    this.isLoading = true;
    this.data$.subscribe(data => {
      this.isLoading = false;
      var sourceData = this.setDataSource(data)
      this.dataSource = new MatTableDataSource(sourceData);
       if (this.paginationRequired && this.paginator) {
        this.paginator.pageSize = this.pageSize;
        this.paginator.length = this.length;
        this.dataSource.paginator = this.paginator;
      } 
      this.dataSource.sort = this.sort;
    });
  }

  private setDataSource(data) {
    var mappedData = [];
    data.forEach(datum => {
      var mappedDatum = [];
      this.columns.forEach((cb) => {
        if (cb.modelAttrName.split(".").length > 1) {
          var tdata = datum[cb.modelAttrName.split(".")[0]];
          var tvalue = tdata[cb.modelAttrName.split(".")[1]];
          var cellAdapter = new CellAdapter(tvalue, cb.cellType, cb.actions);
          mappedDatum.push(cellAdapter);
        } else {
          var cellAdapter = new CellAdapter(datum[cb.modelAttrName], cb.cellType, cb.actions);
          mappedDatum.push(cellAdapter);
        }
      });
      mappedData.push(new TableDatasourceAdapter(mappedDatum, datum));
    });

    if (data.length < 5 && data.length > 0) {
      this.length = data.length;
      this.pageSize = data.length;
    } else if (data.length == 0) {
      this.length = 0;
      this.pageSize = 1;
    } else if(data.length >= 5) {
      this.length = data.length;
      this.pageSize = 5;
    }

    return mappedData;
  }

  sortData(sort: Sort) {
    this.data$.subscribe((data) => {
      data.sort((a, b) => {
        const isAsc = sort.direction === "asc";
        return this.compare(
          a[this.columnModelNames[this.columnNames.indexOf(sort.active)]],
          b[this.columnModelNames[this.columnNames.indexOf(sort.active)]],
          isAsc
        );
      });
      this.dataSource = new MatTableDataSource(this.setDataSource(data));
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  /**
   * Set columns. Undefined check accounts for async datasource.
   * Map the datasource and update the table.
   */
  private refreshDataSource() {
    if (this.columns != undefined) {
      this.setColumns();
    }
    this.mapDataSource();
  }

  /**
   * Create the observable - required for the datasource to see a change and trigger a re-render.
   * @param source Service method results
   */
  refresh(source) {
    if (Array.isArray(source)) {
      this.data$ = of(source);
    } else {
      this.data$ = source.pipe(
        share(),
        catchError((error) => {
          if (error.error instanceof ErrorEvent)
            console.log(`Error: ${error.error.message}`);
          else console.log(`Error: ${error.message}`);
          return of([]);
        })
      );
    }
    this.refreshDataSource();
  }

  /**
   * Take an action as specified by the switch.
   * @param element Element data of the row
   * @param action
   */
  contextMenuUpdate(event, element, action): void {
    event.stopPropagation();
    this.context.emit({ element: element, action: action });
  }

  actionButtonClick(event, element, action): void {
    event.stopPropagation()
    this.context.emit({ element: element, action: action });
  }

  getRowCSS(rows){
    return {
    'rowStyle': this.update.observers.length > 0,
    'total': rows.data.total != undefined && rows.data.total, 
    'finalTotal':rows.data.finalTotal != undefined && rows.data.finalTotal,
    'goodwillTotal':rows.data.goodwillTotal != undefined && rows.data.goodwillTotal
    };
  }

  ngAfterViewInit() {
    var elems = document.getElementsByTagName("table-adapter");
    for (let i = 0; i < elems.length; i++) {
      elems[i].parentElement.classList.add("noPadding");
    }
    super.build("TableAdapterComponent", this.tableLabel);
  }

  triggerFunction(data, value){
    this.isLoading = true;
    if(this.onSelectionChange.observers.length > 0){
      this.onSelectionChange.emit({rowData: data, selectedValue: value})
    }
  }

  isTotalRow(data): boolean{
    return (data.total != undefined && data.total || data.finalTotal != undefined && data.finalTotal || data.goodwillTotal != undefined && data.goodwillTotal)
  }

  disableButton(value: boolean) {
    let keys = Object.keys(this.funcs).filter(x => !['download', 'refresh'].includes(x))
    keys.forEach(x => {
      this.funcs[x] = value
    })
  }

  hasActions(): boolean {
    if (this.canRenderActions == undefined)
      return this.getActions() !== CellActions.None;
    for (const row of this.dataSource.data)
      for (const col of row['display'])
        if (col.actions && this.canRenderActions(col.actions))
          return true;
    return false;
  }

  getActions(): CellActions {
    let actions: CellActions = CellActions.None;
    for (const row of this.dataSource.data)
      for (const col of row['display'])
        if (col.actions)
          actions |= col.actions;
    return actions;
  }

  canRender(actions: CellActions): boolean {
    if (!this.canRenderActions)
      return true;
    return this.canRenderActions(actions);
  }
}
