import { Component, OnInit, ViewChild } from "@angular/core";
import { DatePipe } from "@angular/common";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Title } from "@angular/platform-browser";
import {
  IWarranty,
} from "../../interfaces/warranty.interface";
import { WarrantyService } from "../../services/warranty.service";
import { ClaimsService } from "../../services/claims.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomerService } from "../../services/customer.service";
import { ICustomer } from "../../interfaces/customer.interface";
import { faPlus, faClock } from "@fortawesome/free-solid-svg-icons";
import { IWarrantyNote } from "../../interfaces/warranty-note.interface";
import { CellBuilder } from "src-private/app/shared/table-adapter/cell-builder/cell-builder";
import { CellType, CellActions } from "src-private/app/shared/table-adapter/cell-builder/cell-type";
import { WarrantyActivationStatus, WarrantyStatus } from "src-private/app/enums/warranty-status";
import { AccountService } from "src-private/app/areas/account/services/account.service";
import { FrameworkComponent } from "src-private/app/shared/framework/framework.component";
import { ConfirmDialogComponent } from "src-private/app/shared/confirm-dialog/confirm-dialog.component";
import { DialogType } from "src-private/app/enums/dialog-type";
import { ToastrService } from "ngx-toastr";
import { IDealerReal } from "../../interfaces/dealer.interface";
import { DealerRealService } from "../../services/dealer-service";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Country } from "src-private/app/enums/country.enum";
import { AppValidators } from "src-private/app/validators/app-validators";
import { ClaimsTransactionService } from "../../services/claims-transaction.service";
import { ClaimTransaction } from "../../models/claim-transaction";
import { environment } from "src-private/environments/environment";
import { Subject, takeUntil } from "rxjs";
import { WarrantyNotesService } from "../../services/warranty-notes.service";
import { CoverageType } from "src-private/app/enums/bonus-bucks.enums";

@Component({
  selector: "app-warranty",
  templateUrl: "./warranty.component.html",
  styleUrls: ["./warranty.component.scss"],
})

export class WarrantyComponent extends FrameworkComponent implements OnInit {
  public faPlus = faPlus;
  public faClock = faClock;

  public customer: ICustomer;
  public notes: IWarrantyNote[];
  public warranty: IWarranty;
  public status: string;
  public claimId: number;
  public warrantyId: number;
  public applicationId: number;
  public vehicleId: number;
  public isAdjuster: boolean;
  public canAddNotes: boolean;
  public vehicleExtraDetails = "";
  public isLoading = false;
  public dealerCountry: string;
  public claimEnterpriseId: number;
  public customerId: number;
  public claimTotal: ClaimTransaction;
  public isExpired : boolean;
  public isTireAndRim: boolean;
  public warrantyActivationStatusHeaderColour = "background-color: green";
  public warrantyActivationStatusHeader = "";
  public warrantyStatusHeaderColour = "background-color: green; margin-left: 2em;";
  public warrantyStatusHeader = "";

  public dealer: IDealerReal;

  private ngUnsubscribe: Subject<any> = new Subject();

  @BlockUI() blockUI: NgBlockUI;

  public warrantyOptionsTableColumns: CellBuilder[] = [
    new CellBuilder("ID", "id", CellType.text),
    new CellBuilder("Description", "description", CellType.text),
    new CellBuilder("Price", "amount", CellType.currency),
  ];

  public warrantyClaimsTableColumns: CellBuilder[] = [
    new CellBuilder("ID", "enterpriseId", CellType.text),
    new CellBuilder("Created Date", "claimsDateEntered", CellType.date),
    new CellBuilder("Closed Date", "claimsDateClosed", CellType.date),
    new CellBuilder("Assigned Adjuster", "claimsOwner", CellType.text),
    new CellBuilder("Status", "claimsStatus", CellType.text),
  ];

  public warrantyDocumentsTableColumns: CellBuilder[] = [
    new CellBuilder(" ", "documentTypeImage", CellType.image, 'w-06'),
    new CellBuilder("Filename", "fileName", CellType.text),
    new CellBuilder("Description", "description", CellType.text),
    new CellBuilder("Created Date", "createdOn", CellType.text),
    new CellBuilder("Uploaded by", "createdBy", CellType.text, "col-sm-3"),
    new CellBuilder("Action", "action", CellType.actions, '', CellActions.Download | CellActions.Remove | CellActions.Move),
  ];

  public warrantyNotesTableColumns: CellBuilder[] = [
    new CellBuilder("Created Date", "createdDate", CellType.datetime, 'col-2 col-sm-2'),
    new CellBuilder("Adjuster", "adjusterName", CellType.text),
    new CellBuilder("Note", "note", CellType.text),
  ];

  constructor(
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private warrantyService: WarrantyService,
    private customerService: CustomerService,
    private claimsService: ClaimsService,
    private claimTransactionService: ClaimsTransactionService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private dealerService: DealerRealService,
    private warrantyNotesService: WarrantyNotesService,
    private titleService: Title,
  ) {
    super();
    this.claimId = this.activatedRoute.snapshot.data['claimId']
  }

  ngOnInit() {
    this.blockUI.start();
    this.isTireAndRim = false;
    this.claimEnterpriseId = parseInt(this.route.snapshot.paramMap.get("enterpriseId")) // URL contain EnterpriseId
    this.warrantyId = +this.route.snapshot.paramMap.get("id");
    if (this.warrantyId) {
      this.warrantyService.retrieve(this.warrantyId).subscribe((warranty) => {
        this.titleService.setTitle("Warranty - " + (warranty.prefix) + (warranty.enterpriseId == null || warranty.enterpriseId == 0 ? warranty.id : warranty.enterpriseId));
        this.warranty = warranty;
        this.customerId = warranty.customerId;
        this.warrantyService.setWarranty(warranty);
        if (this.warranty.warrantyStatusLabel != "Active") {
          this.setStatusHeader(this.warranty.warrantyStatusLabel);
        }
        else {
          this.warranty.warrantyStatusLabel = null;
        }
        if (warranty.coverageTypeId == CoverageType.TireAndRim) {
          this.isTireAndRim = true;
          this.setActivationHeader(warranty.activationStatus, warranty.logDate == null ? '' : warranty.logDate.toString().split('T')[0]);
        }
        this.status = WarrantyStatus[warranty.status];
        this.applicationId = warranty.applicationId;
        if (this.warranty.vehicle !== null) {
          this.vehicleId = warranty.vehicle.id;
        }

        this.customerService.retrieve(warranty.customerId).subscribe((data) => {
          this.customer = data;
          this.isLoading = false;
		      this.customer.countryName = this.customer.countryId == Country.UnitedStates ? 'USA' :  Country[this.customer.countryId];
        },
        error => {
          this.toastr.error(environment.messages.apiError, "Unable to retrieve customer");
        });
        this.blockUI.stop();
        if (new Date(this.warranty.expiryDate) <= new Date()) {
          this.isExpired = true;
        }

        this.dealerService.retrieve(warranty.dealerId).subscribe((data) => {
          this.dealer = data;
          this.dealerCountry = this.dealer.country == Country.UnitedStates ? 'USA' :  Country[this.dealer.country]
        },
        error => {
          this.toastr.error(environment.messages.apiError, "Unable to retrieve dealer");
        });


        this.getClaimTotals();
        super.build("WarrantyComponent", "warranty-component");

      },
      error => {
        this.toastr.error(environment.messages.apiError, "Unable to retrieve warranty");
      });

    let data = { adjusterId: this.accountService.getUserId(), warrantyId: this.warrantyId, createdDate: new Date(), note: 'Viewed Warranty', id: 0, adjusterName: null, type: null}; 
    this.warrantyNotesService.add(data)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(() => {});

      this.isAdjuster = this.accountService.isAdjuster() || this.accountService.isClaimsManager() || this.accountService.isAssistantClaimsManager();
      this.canAddNotes = this.accountService.isAdjuster() || this.accountService.isClaimsManager() || this.accountService.isAssistantClaimsManager() || this.accountService.isDealerServices();
    }
  }

  getClaimTotals(){
    if (this.claimId) {
      this.claimTransactionService
        .retrieveClaimTotal(this.claimId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          if (res) {
            this.claimTotal = res[0];
          }
        },
        error => {
          this.toastr.error(environment.messages.apiError, "Unable to retrieve claim totals");
        });
    }
  }

  public get CanUnexpireWarranty(): boolean{
    return (this.accountService.isClaimsManager() || this.accountService.isAssistantClaimsManager()) && this.isExpired;
  }

  warrantyUnExpire() {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: "part-dialog",
      width: "400px",
      autoFocus: true,
      data: {
        message:
          "You are about to set expiry date to tomorrow. Do you want to continue?",
        type: DialogType[0].toString(),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.warrantyService.unExpire(this.warrantyId).subscribe((res) => {
          if(res){
            this.isLoading = false;
            this.isExpired = false;
            let today =  new Date();
            this.warranty.expiryDate = new Date(today.setDate(today.getDate() + 1));
            this.toastr.success("Warranty expiry date successfully set to tomorrow. ", "Warranty");
          }
        },
        error => {
          this.toastr.error(environment.messages.apiError, "Unable to change expiry date");
        });
      } else {
        dialogRef.close();
      }
    });
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    this.warrantyService.setWarranty(null);
  }

  thousandsSeparator(number) {
    return number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  setStatusHeader(status) {
    this.warrantyStatusHeader = status;
    if (status == "Pending (Missing In Service Date)" || status == "Pending (Alberta Dealer)" || status == "Pending (Dealer On Hold)" || status == "Pending (Duplicate VIN)") {
      this.warrantyStatusHeaderColour = "background-color: #b8860b; color:white; margin-left: 2em;";
    }
    else if (status == "Quote (Active)" || status == "Quote (Expired)") {
      this.warrantyStatusHeaderColour = "background-color: #eee8aa; color:black; margin-left: 2em;";
    }
    else if (status == "Cancelled (Manual)" || status == "Cancelled (Auto)") {
      this.warrantyStatusHeaderColour = "background-color: red; color:white; margin-left: 2em;";
    }
  }

  setActivationHeader(status, date) {
    if (status == 0) {
      this.warrantyActivationStatusHeaderColour = "background-color: #b8860b; color:white; margin-left: 2em;";
      this.warrantyActivationStatusHeader = "Not Activated";
    }
    else if (status == 1) {
      this.warrantyActivationStatusHeaderColour = "background-color: green; color:white; margin-left: 2em;";
      this.warrantyActivationStatusHeader = WarrantyActivationStatus[status] + " on " + date.toString();
    }
    else if (status == 2) {
      this.warrantyActivationStatusHeaderColour = "background-color: darkgreen; color:white; margin-left: 2em;";
      this.warrantyActivationStatusHeader = "Explicitly Activated on " + date.toString();
    }
    else if (status == 3) {
      this.warrantyActivationStatusHeaderColour = "background-color: #eee8aa; color:black; margin-left: 2em;";
      this.warrantyActivationStatusHeader = "Implicitly Activated on " + date.toString();
    }
    else if (status == 4) {
      this.warrantyActivationStatusHeaderColour = "background-color: red; color:white; margin-left: 2em;";
      this.warrantyActivationStatusHeader = WarrantyActivationStatus[status] + " on " + date.toString();
    }
  }

  public get hasClaimId(): boolean {
    return !isNaN(this.claimEnterpriseId) && this.claimEnterpriseId != null;
  }

 public get isCanada(): boolean {
    return this.customer.countryName === Country[Country.Canada]
  }

  public get isDealerCountryCanda(): boolean {
    return this.dealer.country === Country.Canada
  }

  public get isDealerContact(): boolean {
    return this.dealer.contactFirst != null && this.dealer.contactLast != null && (!AppValidators.isEmptyOrSpaces(this.dealer.contactFirst) && !AppValidators.isEmptyOrSpaces(this.dealer.contactLast))
  }

  public get isCustomerContact(): boolean {
    return this.customer != undefined && this.customer.phone != null && (!AppValidators.isEmptyOrSpaces(this.customer.phone));
  }

  public get isDealerPhone(): boolean {
    return this.dealer != undefined && this.dealer.phone != null && (!AppValidators.isEmptyOrSpaces(this.dealer.phone));
  }

  public get isClaimClosed(): boolean {
    return this.claimsService.getSelectedClaim?.IsClaimClosed
  }

  public get canEditClosedClaim(): boolean {
    return this.accountService.isClaimsManager();
  }

  public get canInteract(): boolean {
    return this.isAdjuster || this.canEditClosedClaim || this.accountService.isDealerServices();
  }

}
