<div class="container-fluid" *ngIf="claim">

  <div class="row">
    <div class="col-xl-3 col-lg-6 col-md-12 col-sm-12">
      <div class="col-lg">
        <app-customer-info *ngIf="claim" [customerId]="claim.customerId"></app-customer-info>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-md-12 col-sm-12">
      <div class="col-lg">
        <app-repair-centre-info *ngIf="claim" [repairCentreId]="claim.claimsRepairCenterId"></app-repair-centre-info>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-md-12 col-sm-12">
      <div class="col-lg">
        <app-warranties-table *ngIf="claim" [customerId]="claim.customerId" [readOnly]="true" [smallComponent]="true"></app-warranties-table>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-md-12 col-sm-12">
      <div class="col-lg">
        <app-claim-info *ngIf="claim"></app-claim-info>
      </div>
    </div>
  </div>

  <div class="row">    
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="col-lg">
        <parts-list *ngIf="claim" [canAddPart]="false" [warrantyId]="claim.warrantyId" (documentAdded)="onDocumentAdded($event)" [partsTableColumns]="partsTableColumns" [canRenderActions]="partsListCanRenderActions"></parts-list>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="col-lg">
        <app-claim-documents *ngIf="claim"></app-claim-documents>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
      <div class="col-lg">
        <app-customer-notes *ngIf="claim" [customerId]="claim.customerId"></app-customer-notes>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
      <div class="col-lg">
        <app-claim-notes *ngIf="claim" [approval]="true"></app-claim-notes>
      </div>
    </div>
  </div>

</div>
