<div class="align-baseline align-items-baseline d-flex flex-row flex-wrap justify-content-around mt-2">
    <div class="col-md-3 w-100">
        <p class="font-weight-bold">Sales</p>
        <div>
            <div class="form-group-static">
                <label>Current</label>
                <input [ngModel]="dealerStats.activeSales | currency" type="text" readonly class="w-100">
            </div>
            <div class="form-group-static">
                <label>Expired</label>
                <input [ngModel]="dealerStats.expiredSales | currency" type="text" readonly class="w-100">
            </div>
            <div class="form-group-static">
                <label>Previous 6 Months</label>
                <input [ngModel]="dealerStats.prev6MonthsSales | currency" type="text" readonly class="w-100">
            </div>
        </div>
    </div>
    <div class="col-md-3 w-100"  [hidden]="true">
        <p class="font-weight-bold">Claims</p>
        <div>
            <div class="form-group-static">
                <label>Active</label>
                <input [ngModel]="dealerStats.activeClaims | currency" type="text" readonly class="w-100">
            </div>
            <div class="form-group-static">
                <label>Expired</label>
                <input [ngModel]="dealerStats.expiredClaims | currency" type="text" readonly class="w-100">
            </div>
        </div>
    </div>
    <div class="col-md-3 w-100">
        <p class="font-weight-bold">Auto Loss Ratio</p>
        <div>
          <div class="form-group-static">
            <label>Ultimate</label>
            <input [ngModel]="dealerStats.ultimateLossRatioAuto" matSuffix="%" type="text" readonly class="w-100">
          </div>
          <div class="form-group-static">
            <label>Expired</label>
            <input [ngModel]="dealerStats.expiredLossRatioAuto" matSuffix="%" type="text" readonly class="w-100">
          </div>
        </div>
    </div>
    <div class="col-md-3 w-100">
        <p class="font-weight-bold">Tire & Rim Loss Ratio</p>
        <div>
          <div class="form-group-static">
            <label>Ultimate</label>
            <input [ngModel]="dealerStats.ultimateLossRatioTire" matSuffix="%" type="text" readonly class="w-100">
          </div>
          <div class="form-group-static">
            <label>Expired</label>
            <input [ngModel]="dealerStats.expiredLossRatioTire" matSuffix="%" type="text" readonly class="w-100">
          </div>
        </div>
    </div>
    <div class="col-md-3 w-100">
        <p class="font-weight-bold">Loss Ratio %</p>
        <div>
          <div class="form-group-static">
            <label>Ultimate</label>
            <input [ngModel]="dealerStats.ultimateLossRatio" matSuffix="%" type="text" readonly class="w-100">
          </div>
          <div class="form-group-static" style="display:none">
            <label>Active</label>
            <input [ngModel]="dealerStats.activeLossRatio" matSuffix="%" type="text" readonly class="w-100">
          </div>
          <div class="form-group-static">
            <label>Expired</label>
            <input [ngModel]="dealerStats.expiredLossRatio" matSuffix="%" type="text" readonly class="w-100">
          </div>
        </div>
    </div>
    <div class="col-md-3 w-100" [hidden]="true">
        <p class="font-weight-bold">Remaining Budget</p>
        <div>
            <div class="form-group-static">
                <label>Budget</label>
                <input [ngModel]="dealerStats.remainingBudgetActive | currency" type="text" readonly class="w-100">
            </div>
        </div>
    </div>
</div>
