import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';

/*-- Interfaces --*/
import { IDealer, IDealerLastApplication } from '../../../interfaces/dealer.interface';

/*-- Services --*/
import { BreakpointService } from '../../../../../services/breakpoint.service';
import { DealersService } from '../../../services/dealers.service';

/*-- Third Party --*/
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-latest-application',
  templateUrl: './latest-application.component.html',
  styleUrls: ['./latest-application.component.css']
})
export class LatestApplicationComponent implements OnChanges, OnInit {
  @BlockUI() blockUI: NgBlockUI;

  @Input() dealer: IDealer;

  public bp: number;
  public expanded: boolean;
  public latestApplication = {} as IDealerLastApplication;
  public initialLoadComplete = false;

  constructor(
    private breakpointService: BreakpointService,
    private dealerService: DealersService
  ) {
    breakpointService.bp.subscribe(x => this.bp = x);
  }

  //#region - Lifecycle
  ngOnChanges(changes: SimpleChanges) {
    const dealer: SimpleChange = changes.dealer;

    if (dealer && dealer.currentValue) {
      this.retrieveLatestApplication(dealer.currentValue, true);
    }
  }

  ngOnInit() {
    if (!this.bp) {
      this.bp = this.breakpointService.getBreakpoint();
    }
  }
  //#endregion

  //#region - API Methods
  private retrieveLatestApplication(dealer: IDealer, force = false) {
    if (!force) { return; }

    this.blockUI.start();

    this.dealerService.dealerLatestApplication(dealer.vendorId).subscribe(
      response => {
        const data: IDealerLastApplication = response;

        this.latestApplication = data;

        this.blockUI.stop();
      },
      () => {},
      () => {this.initialLoadComplete = true;}
    );
  }
  //#endregion
}
