import { Injectable } from '@angular/core';

@Injectable()
export class AvatarService {

  public getAvatarUrl(user: string): string {

    let imageUrl;

    if (user) {
      imageUrl = '/assets/images/avatars/' + user.split(' ').join('') + '.jpg';
    }

    else {
      imageUrl = '/assets/images/avatars/generic-avatar.jpg';
    }
    return imageUrl;
  }
}

