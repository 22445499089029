import { Injectable } from '@angular/core';
import { DistributionChange, CoveragePackage, ProvinceState, Coverage } from '../models/bonus-bucks.Models';
import { ChangeType } from 'src-private/app/enums/bonus-bucks.enums';
import { BbFormManagementService } from './bb-form-management.service';
import { ProvinceStateService } from 'src-private/app/services/province-states.service';

@Injectable()
export class BBAlterationService {
  changedCoveragePackages: CoveragePackage[];
  provinceStates: ProvinceState[];

  constructor(private formService: BbFormManagementService,
    private provStateService: ProvinceStateService) {
    this.getProvinceStates();
  }

  public getChangedCoverages(): CoveragePackage[] {
    const newCovs = this.formService.currentCoveragePackages;
    const changedPackages =  new Array<CoveragePackage>();
    newCovs.forEach(c => {
      const changed = this.coveragePackageChanged(c);
      if (changed !== null) {
        changedPackages.push(this.coveragePackageChanged(c));
      }
    });
    return changedPackages;
  }

  private coveragePackageChanged(covPack: CoveragePackage): CoveragePackage {
    const changedPackage = new CoveragePackage();
    const oldCovs = this.formService.originalCoveragePackages;
    const originalCovPack = oldCovs.find(covP => covP.CoverageGroupId === covPack.CoverageGroupId);
    if (originalCovPack === undefined) {
      return null;
    } else {
      changedPackage.CoverageGroupId = covPack.CoverageGroupId;
      changedPackage.CoverageGroupLabel = covPack.CoverageGroupLabel;
      changedPackage.CoverageTypeId = covPack.CoverageTypeId;
      changedPackage.Coverages = new Array<Coverage>();

      for (let i = 0; i < covPack.Coverages.length; i++) {
        if (covPack.Coverages[i] !== undefined && originalCovPack.Coverages[i] !== undefined) {
          if (covPack.Coverages[i].IsChecked !== originalCovPack.Coverages[i].IsChecked) {
            changedPackage.Coverages.push(covPack.Coverages[i]);
            continue;
          }
          if (covPack.Coverages[i].IsChecked) {
            if (covPack.Coverages[i].BonusBucksAmount !== originalCovPack.Coverages[i].BonusBucksAmount) {
              changedPackage.Coverages.push(covPack.Coverages[i]);
              continue;
            }
          }
        }
      }
    }
    return changedPackage.Coverages.length > 0 ? changedPackage : null;
  }

  public getDistributionChanges(): DistributionChange[] {
    const distributionChanges = new Array<DistributionChange>();

    const oldDArray = this.formService.originaldistributionForm['distributions'];
    const newDArray = this.formService.distributionForm.getRawValue().distributions;

    for (let i = 0; i < newDArray.length; i++) {
      const newD = newDArray[i];
      const oldD = oldDArray[i];
      if (newD.roleLabel) {
        newD.name = newD.firstName + ' ' + newD.lastName;
        oldD.name = oldD.firstName + ' ' + oldD.lastName;
      }

      if (newD.selected !== oldD.selected) {
        if (newD.selected) {
          distributionChanges.push(new DistributionChange(newD.name, oldD, newD, ChangeType.Added));
        } else {
          distributionChanges.push(new DistributionChange(oldD.name, oldD, newD, ChangeType.Removed));
        }
      } else if (newD.selected) {
        let distributionChange;
        const newUserRegistrationFG = newD.userRegistrationFG;
        if (newUserRegistrationFG) {
          const keys = Object.keys(newUserRegistrationFG);
          for (const key of keys) {
            if (newUserRegistrationFG[key] !== oldD.userRegistrationFG[key]) {
              if (!distributionChange) {
                distributionChange = new DistributionChange(newD.name, oldD, newD, ChangeType.Changed);
              }
              if (key === 'provinceState') {
                distributionChange.modificationMap.set('Province/State', [this.provinceStates.find((ps) => ps.ProvinceStateId === oldD.userRegistrationFG[key]).Label, this.provinceStates.find((ps) => ps.ProvinceStateId === newD.userRegistrationFG[key]).Label]);
              } else if (key === 'eftimage') {
                distributionChange.modificationMap.set('EFT Image', ['old image', 'new image']);
              } else {
                distributionChange.modificationMap.set(key, [oldD.userRegistrationFG[key], newUserRegistrationFG[key]]);
              }
            }
          }
        }
        if (!!distributionChange) {
          distributionChanges.push(distributionChange);
        }
      }
    }
    return distributionChanges;
  }

  private getProvinceStates() {
    this.provStateService.currentStates.subscribe((response: ProvinceState[]) => this.provinceStates = response);
  }
}
