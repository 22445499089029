import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { environment } from '../../../../../environments/environment';

/*-- Enums --*/
import { Country } from '../../../../enums/country.enum';
import { FormState } from '../../../../enums/form-state.enum';

/*-- Interface --*/
import { IDealer } from '../../interfaces/dealer.interface';

/*-- Models --*/
import { Dealer } from '../../models/dealer.model';

/*-- Services --*/
import { BreakpointService } from '../../../../services/breakpoint.service';
import { ComParentChildService } from '../../services/com-parent-child.service';
import { DealersService } from '../../services/dealers.service';
import { DealersSharedService } from '../../services/dealers-shared.service';

/*-- Third Party --*/
import { ToastrService } from 'ngx-toastr';
import {BlockUI, NgBlockUI} from "ng-block-ui";
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { IBatchFile } from '../../interfaces/batch-file';
import { AccountService } from 'src-private/app/areas/account/services/account.service';
import { BatchFileType } from 'src-private/app/enums/bonus-bucks.enums';
import { BonusBucksService } from '../../services/bonus-bucks.service';
import { BatchFile } from '../../models/batch-file.model';
import { BehaviorSubject } from 'rxjs';
import { DealerStatus } from 'src-private/app/enums/dealer-status.enum';

@Component({
  selector: 'app-dealer-info',
  templateUrl: './dealer-info.component.html',
  styleUrls: ['./dealer-info.component.scss']
})
export class DealerInfoComponent implements OnDestroy, OnInit {
  static dealerFormSave: UntypedFormGroup;
  @BlockUI() blockUI: NgBlockUI;

  private component: string;

  public bp: number;
  public dealer: IDealer;
  public dealerForm: UntypedFormGroup;
  public formState: FormState;
  public hideCompetitors = true;
  public hideContact = true;
  public hideSettings = true;
  public hideDealerStat = true;
  public dealerStatusColour = "background-color: blue";
  public dealerStatus = "";
  public isSubmitted: boolean;
  public showCancelButton: boolean;
  public showSaveButton: boolean;
  public faTimes = faTimes;
  public faCheck = faCheck;
  public vendorId$:BehaviorSubject<number>;
  public canUserEdit$: BehaviorSubject<boolean>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private breakpointService: BreakpointService,
    private comParentChildService: ComParentChildService,
    private dealersService: DealersService,
    private dealersSharedService: DealersSharedService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private toastrService: ToastrService,
    private accountService: AccountService
  ) {
    this.vendorId$ = new BehaviorSubject<number>(null)
    this.activatedRoute.parent.queryParams.subscribe(queryParams => {
      if (queryParams['c']) {
        this.component = queryParams['c'];

        switch (this.component) {
          case 'competitors':
            this.hideCompetitors = false;
            this.hideContact = true;
            this.hideSettings = true;
            this.hideDealerStat = true;  

            break;
          case 'contact':
            this.hideCompetitors = true;
            this.hideContact = false;
            this.hideSettings = true;
            this.hideDealerStat = true;  

            break;
          case 'settings':
            this.hideCompetitors = true;
            this.hideContact = true;
            this.hideSettings = false;
            this.hideDealerStat = true;  

            break;
          case 'dealerStat':
            this.hideCompetitors = true;
            this.hideContact = true;
            this.hideSettings = true;
            this.hideDealerStat = false  
        }
      } else {
        const queryParams: Params = { c: 'contact' };

        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: queryParams,
          queryParamsHandling: 'merge'
        });
      }
    });

    breakpointService.bp.subscribe(x => this.bp = x);

    if (DealerInfoComponent.dealerFormSave) {
      this.dealerForm = DealerInfoComponent.dealerFormSave;
    } else {
      this.dealerForm = this.buildForm();
    }

    this.dealersSharedService.dealer.subscribe(dealer => {
      if (!dealer) {
        this.formState = FormState.Static;
        this.populateDealerForm(new Dealer());
      } else {
        this.dealer = dealer;
        let statusString = "";
        switch (this.dealer.vendorStatusId) {
          case 4:
            statusString = "On Hold";
            break;
          case 8:
            statusString = "On Hold Non Payment";
            break;
          default:
            statusString = DealerStatus[this.dealer.vendorStatusId];
        }
        this.dealerStatus = statusString;
        if(this.dealer.vendorStatusId == 1) {
          this.dealerStatusColour = "background-color: green";
        }
        else if (this.dealer.vendorStatusId == 4 || this.dealer.vendorStatusId == 8) {
          this.dealerStatusColour = "background-color: yellow"
        }
        else if (this.dealer.vendorStatusId == 2 || this.dealer.vendorStatusId == 5) {
          this.dealerStatusColour = "background-color: red";
        }
        else {
          this.dealerStatusColour = "background-color: blue";
        }
        this.canUserEdit$.next(this.isAccountingOrClientService)
        this.vendorId$.next(this.dealer.vendorId)
        if (dealer.vendorId) {
          this.formState = FormState.Modify;
          this.showCancelButton = false;
        } else {
          this.formState = FormState.Add;
          this.showCancelButton = true;
          this.canUserEdit$.next(true)
        }

        this.populateDealerForm(dealer);
        this.showSaveButton = this.isAccountingOrClientService || this.formState == FormState.Add;
      }
    });
  }

  //#region - Lifecycle
  ngOnDestroy() {
    DealerInfoComponent.dealerFormSave = this.dealerForm;
  }

  ngOnInit() {
    if (!this.bp) {
      this.bp = this.breakpointService.getBreakpoint();
    }
  
    this.canUserEdit$ = new BehaviorSubject<boolean>(this.isAccountingOrClientService)
  }
  //#endregion

  //#region - Events
  onCancelClick() {
    this.showCancelButton = false;
    this.showSaveButton = false;

    this.dealersSharedService.clearDealer();
  }

  onSubmitDealer() {
    this.isSubmitted = true;

    if (this.dealerForm.valid) {
      const dealer = new Dealer();
      console.log(dealer);
      const competitors: any[] = [];

      let isCompetitorFormGroup: boolean;

      Object.keys(this.dealerForm.controls).forEach((key: string) => {
        const formGroup = (this.dealerForm.get(key) as UntypedFormGroup);

        isCompetitorFormGroup = (key === 'competitors');

        Object.keys(formGroup.controls).forEach((key: string) => {
          const control = formGroup.get(key);

          if (isCompetitorFormGroup) {
            if (control.value) {
              competitors.push(parseInt(key));
            }
          } else {
            if (key in dealer) {
              let value = control.value;
              if (typeof (value) === 'undefined' || value === '') {
                value = null;
              }
              dealer[key] = value;
              console.log(key + ", " + value + ", " + typeof(value));
            }
          }
        });
      });

      dealer.competitors = JSON.stringify(competitors);
      dealer.vendorId = this.dealer.vendorId;

      switch (this.formState) {
        case FormState.Add:
          this.createDealer(dealer).then(
            () => {
              // clear the form and restore to a static state
              //this.dealersSharedService.clearDealer();

              this.showCancelButton = false;
              this.showSaveButton = false;

              this.comParentChildService.publish('dealer-added');
            }
          );

          break;
        case FormState.Modify:
          this.blockUI.start();
          this.updateDealer(dealer).then(() => {
            this.blockUI.stop();
          });
          break;
      }
    }
  }
  //#endregion

  //#region - Private Methods
  private buildForm() {
    return this.formBuilder.group({
      competitors: this.formBuilder.group( // create nested formGroup to pass to child
        []
      ),
      contact: this.formBuilder.group({ // create nested formGroup to pass to child
        friendlyName: [null, Validators.required],
        legalName: [null, Validators.required],
        alias: [null, Validators.required],
        countryId: Country.Canada,
        address: [null, Validators.required],
        city: [null, Validators.required],
        abbreviation: null,
        provinceStateId: [null, Validators.required],
        postalZipCode: [null, Validators.required],
        phone: [null, Validators.required],
        fax: null,
        website: null,  
        pst: null,
        gstHst: null,
        registrationNumber: null
      }),
      settings: this.formBuilder.group({ // create nested formGroup to pass to child
        convenienceFee: [null, Validators.required],
        dealerAccountManagerId: [null, Validators.required],
        enterpriseId: null,
        franchiseId: null,
        maxVehicleKm: null,
        maxVehicleYear: null,
        minVehicleKm: null,
        minVehicleYear: null,
        newVehiclesOnSite: null,
        newVehiclesSoldPerMonth: null,
        repairCentreId: null,
        usedVehiclesOnSite: null,
        usedVehiclesSoldPerMonth: null,
        vendorGroupId: null,
        vendorStatusId: [null, Validators.required],
        vendorTypeId: [null, Validators.required]
      })
    });
  }

  private populateDealerForm(dealer: IDealer) {
    if (!dealer) {
      // console.log('dealer is null');
    } else {
      this.dealerForm.patchValue({
        contact: {
          friendlyName: dealer.friendlyName,
          legalName: dealer.legalName,
          alias: dealer.alias,
          countryId: dealer.countryId,
          address: dealer.address,
          city: dealer.city,
          provinceStateId: dealer.provinceStateId,
          abbreviation: dealer.abbreviation,
          postalZipCode: dealer.postalZipCode,
          phone: dealer.phone,
          fax: dealer.fax,
          pst: dealer.pst,
          gstHst: dealer.gstHst,
          website: dealer.website,
          registrationNumber: dealer.registrationNumber
        },
        settings: {
          convenienceFee: dealer.convenienceFee,
          dealerAccountManagerId: dealer.dealerAccountManagerId,
          enterpriseId: dealer.enterpriseId,
          franchiseId: dealer.franchiseId,
          maxVehicleKm: dealer.maxVehicleKm,
          maxVehicleYear: dealer.maxVehicleYear,
          minVehicleKm: dealer.minVehicleKm,
          minVehicleYear: dealer.minVehicleYear,
          newVehiclesOnSite: dealer.newVehiclesOnSite,
          newVehiclesSoldPerMonth: dealer.newVehiclesSoldPerMonth,
          repairCentreId: dealer.repairCentreId,
          usedVehiclesOnSite: dealer.usedVehiclesOnSite,
          usedVehiclesSoldPerMonth: dealer.usedVehiclesSoldPerMonth,
          vendorGroupId: dealer.vendorGroupId,
          vendorStatusId: dealer.vendorStatusId,
          vendorTypeId: dealer.vendorTypeId
        }
      });
    }
  }
  //#endregion

  //#region - API Methods
  private createDealer(dealer: IDealer) {
    const title = 'Create Dealer';

    const promise = new Promise((resolve, reject) => {
      this.dealersService.dealerCreate(dealer).subscribe(
        (response: number) => {
          // show toast
          this.toastrService.success('Your dealer has been successfully created.', title);

          var batchfileModel = new BatchFile()
          batchfileModel.ItemId = response;
          batchfileModel.CreatedBy = this.accountService.getUserId();
          batchfileModel.BatchFileTypeId = BatchFileType.DealerVendor
          this.dealersService.addBatchFile(batchfileModel).subscribe();
          resolve(true);
          this.dealersSharedService.setDealer(response);
        },
        error => {
          // show error
          this.toastrService.error(environment.messages.apiError, title);

          reject();
        }
      );
    });

    return promise;
  }

  private updateDealer(dealer: IDealer) {
    const title = 'Update Dealer';
    const promise = new Promise((resolve, reject) => {
      this.dealersService.dealerUpdate(dealer).subscribe(
        () => {
          // show toast
          this.toastrService.success('Your dealer has been successfully updated.', title);

          resolve(true);
        },
        error => {
          // show error
          this.toastrService.error(environment.messages.apiError, title);

          reject();
        }
      );
    });

    return promise;
  }

  public get isAccountingOrClientService(): boolean{
    return [this.accountService.isAccountant(),
    this.accountService.isDealerServicesUser(),
    this.accountService.isDealerServicesPowerUser(),
    this.accountService.isDealerServicesManager(),
    this.accountService.isClientServiceConcierge(),
    this.accountService.isClientServiceManager()].some(x => x)
  }
  //#endregion
}
