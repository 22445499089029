
    <mat-dialog-content>
        <block-ui>
        <form [formGroup]="odometerReadingForm">
        <fieldset class="fieldset-form">
            <legend *ngIf="claimId != 0">Odometer Reading - Claim {{ claimId }}</legend>
            <legend *ngIf="claimId == 0">Odometer Reading - Warranty {{ warrantyId }}</legend>
            <div class="container-fluid dialogContent">
            <div class="row">
                <div class="col-sm-6">
                <mat-form-field class="w-100">
                    <input placeholder="KMs" matInput formControlName="odometerKms" type="text" mask="separator.2" thousandSeparator=",">
                    <mat-error *ngIf="odometerReadingForm.get('odometerKms').errors?.required">
                    KMs is required
                    </mat-error>
                </mat-form-field>
                </div>
                <div class="col-sm-6">
                <mat-form-field class="w-100">
                    <input placeholder="Date" [matDatepicker]="OdoDatePicker" matInput formControlName="odometerDate">
                    <mat-datepicker-toggle matSuffix [for]="OdoDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #OdoDatePicker disabled="false"></mat-datepicker>
                    <mat-error *ngIf="odometerReadingForm.get('odometerDate').hasError('required')">
                    Date is required
                    </mat-error>
                </mat-form-field>
                </div>
            </div>
            </div>
        </fieldset>
        </form>
    </block-ui>
    </mat-dialog-content>
    <mat-dialog-actions class="float-right">
    <button mat-button mat-dialog-close class="btn" (click)="cancel()">
        <fa-icon [icon]="faWindowClose" class="pr-2 text-danger"></fa-icon>
        Cancel
    </button>
    <button mat-button class="btn" (click)="confirm()">
        <fa-icon [icon]="faSave" class="pr-2 text-success"></fa-icon>
        Save
    </button>
    </mat-dialog-actions> 

  
