import { ActivatedRoute, Params, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class RouteParamsHelper {

  private currentParamsObj: {};

  constructor(private route: ActivatedRoute, private router: Router) {

  }

  save(route: ActivatedRoute) {
    this.currentParamsObj = Object.assign({}, route.snapshot.params);
  }

  get() {
    return this.currentParamsObj;
  }

  public restore(route: ActivatedRoute) {
    if (!this.currentParamsObj) {
      return;
    }

    if (this.currentParamsObj) {

      const newUrl = this.router.createUrlTree([
        this.currentParamsObj
      ], { relativeTo: route });

      this.router.navigateByUrl(newUrl);
    }
  }
}
