import { HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import FileSaver from 'file-saver';
import moment from 'moment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BehaviorSubject, map, Subject, takeUntil } from 'rxjs';
import { AccountService } from 'src-private/app/areas/account/services/account.service';
import { DialogType } from 'src-private/app/enums/dialog-type';
import { ConfirmDialogComponent } from 'src-private/app/shared/confirm-dialog/confirm-dialog.component';
import { FrameworkComponent } from 'src-private/app/shared/framework/framework.component';
import { CellBuilder } from 'src-private/app/shared/table-adapter/cell-builder/cell-builder';
import { CellType } from 'src-private/app/shared/table-adapter/cell-builder/cell-type';
import { TableAdapterComponent } from 'src-private/app/shared/table-adapter/table-adapter.component';
import { IDocument, DocumentTypes } from '../../interfaces/document';
import { ClaimsDocumentService } from '../../services/claims-document.service';
import { ClaimsService } from '../../services/claims.service';
import { DocumentAddDialogComponent } from '../document-add-dialog/document-add-dialog.component';
import { environment } from "src-private/environments/environment";
import { NotificationSocketService } from 'src-private/app/services/notification-socket.service';
import { ActivatedRoute } from '@angular/router';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { CellActions } from '../../../../shared/table-adapter/cell-builder/cell-type';
import { DocumentConflictDialogComponent } from '../document-conflict-dialog/document-conflict-dialog.component';
import { ToastrService } from "ngx-toastr";
import { DocumentMoveDialogComponent } from '../document-move-dialog/document-move-dialog.component';
import { DocumentService } from '../../services/document.service';

@Component({
  selector: 'app-claim-documents',
  templateUrl: './claim-documents.component.html',
  styleUrls: ['./claim-documents.component.scss']
})
export class ClaimDocumentsComponent extends FrameworkComponent implements OnInit, AfterViewInit{

  public faPlus = faPlus;

  private claimId: number;
  public isAdjuster: boolean;
  public isAuditor: boolean;
  public isDealerServices: boolean;
  public checkLock$: BehaviorSubject<boolean>;

  @BlockUI() blockUI: NgBlockUI;

  private ngUnsubscribe: Subject<any> = new Subject();

  @ViewChild("claimsDocumentsTable")
  claimsDocumentsTable: TableAdapterComponent<IDocument>;
  public claimsDocumentsTableColumns: CellBuilder[] = [
    new CellBuilder(" ", "documentTypeImage", CellType.image, 'w-06'),
    new CellBuilder("Filename", "fileName", CellType.text),
    new CellBuilder("Description", "description", CellType.text),
    new CellBuilder("Created Date", "createdOn", CellType.text, "col-sm-3"),
    new CellBuilder("Uploaded by", "createdBy", CellType.text),
    new CellBuilder("Action", "action", CellType.actions, '', CellActions.Download | CellActions.Remove | CellActions.Move)
  ];

  constructor(
    public dialog: MatDialog,
    private accountService: AccountService,
    private claimsDocumentService: ClaimsDocumentService,
    private claimsService: ClaimsService,
    private route: ActivatedRoute,
    private notificationSocketService: NotificationSocketService,
    private toastr: ToastrService,
    private documentService: DocumentService
  ) { 
    super();
    this.claimId = this.route.snapshot.data['claimId']
  }

  ngOnInit(): void {
    this.checkLock$ = new BehaviorSubject<boolean>(null)
    this.isAdjuster = this.accountService.isAdjuster();
    this.isAuditor = this.accountService.isGeneralAuditor() || this.accountService.isInsurerAuditor();
    this.isDealerServices = this.accountService.isDealerServices();
  }

  ngAfterViewInit(): void {
    this.refreshDocuments();
    super.build("ClaimDocumentsComponent", "claim-documents-component");
  }

  addDocument() {
    let dialogRef = this.dialog.open(DocumentAddDialogComponent, {
      panelClass: "claim-add-dialogs",
      width: "600px",
      data: {
        originId: this.claimId,
      },
    });

    dialogRef.componentInstance.onSubmit
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(dialogRes => {
        if (dialogRes.data) {
          dialogRes.data.originId = this.claimId;
          dialogRes.data.type = "Action";

          this.blockUI.start();
          this.claimsDocumentService
            .add(dialogRes.data)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(res => {
              this.blockUI.stop();
              dialogRef.close();

              if (res.conflicts && res.conflicts.length > 0) {
                this.promptDocumentConflicts(res.conflicts);
              }

              this.toastr.success(
                "Document successfully added. ",
                "Create Document"
              );

              this.refreshDocuments();
            },
            error => {
              this.toastr.error(environment.messages.apiError, "Unable to add document");
            });
        }
      });
  }

  promptDocumentConflicts(documents: IDocument[]) {
    this.dialog.open(DocumentConflictDialogComponent, {
      panelClass: "document-conflicts-dialog",
      autoFocus: true,
      data: documents 
    });
  }

  /**
   * Update Document
   * @param data Passes an element, and an action to be taken
   */
  updateDocument(data) {
    if (data.action == "download") {
      this.downloadDocument(this.claimId, data.element.id);
    } else if (data.action == "remove") {
      this.removeDocument(data.element.id);
    } else if (data.action == "move") {
      this.moveDocument(data.element.id);
    }
  }

  downloadDocument(claimId: number, documentId: number) {
    this.claimsDocumentService
      .download(claimId, documentId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: HttpResponse<Blob>) => {
        let contentDispositionHeader = res.headers.get("content-disposition");
        // the second part of content-disposition contains the file name
        let fileName = contentDispositionHeader
          .split(";")[1]
          .trim()
          .split("=")[1]
          .replace(/"/g, "");
        FileSaver.saveAs(res.body, fileName);
      },
      error => {
        this.toastr.error(environment.messages.apiError, "Unable to download document");
      });
  }

  removeDocument(documentId: number) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'document-dialog',
      width: '400px',
      autoFocus: true,
      data: {
        message: "Are you sure you want to remove document?",
        type: DialogType[0].toString()
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.claimsDocumentService
        .remove(documentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.toastr.success(
            "Document successfully removed. ",
            "Remove Document"
          );
          this.refreshDocuments();
        },
        error => {
          this.toastr.error(environment.messages.apiError, "Unable to remove document");
        });
      }
    });
  }

  moveDocument(documentId: number) {
    this.claimsService.retrieve(this.claimId).subscribe(claim => {
      if (claim) {
        this.dialog.open(DocumentMoveDialogComponent, {
          panelClass: 'document-dialog',
          autoFocus: true,
          data: {
            documentId: documentId,
            documentTypeMap: new Map([
              [[DocumentTypes.Customer], [claim.customerId]],
              [[DocumentTypes.Warranty], [claim.warrantyId]]
            ])
          }
        })
        .afterClosed().subscribe(result => {
          if (result) {
            this.refreshDocuments();
          }
        });
      }
    },
    error => {
      this.toastr.error(environment.messages.apiError, "Unable to fetch claim");
    });
  }

  refreshDocuments() {
    this.claimsDocumentsTable.refresh(
      this.claimsDocumentService.retrieveAll(this.claimId).pipe<IDocument[]>(map(doc => doc.map(x=> {
        x.createdOn = `${moment(x.createdDate).format("YYYY-MM-DD h:mm A (MMMM DD)")}`
        x.documentTypeImage = this.claimsService.getDocumentTypeUrl(x.fileType);
        return x;
      },
      error => {
        this.toastr.error(environment.messages.apiError, "Unable to retrieve documents");
      })))
    );
  }

  viewDocument(response) {
    this.claimsDocumentService
      .download(response.data.claimsId, response.data.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: HttpResponse<Blob>) => {
        this.documentService.openBlob(res, response.data.fileName);
      },
      error => {
        this.toastr.error(environment.messages.apiError, "Unable to download document");
      });
  }

  public get isClaimManager(): boolean{
    return this.accountService.isClaimsManager()
  }

  public get isClaimClosed(): boolean{
    return this.claimsService.getSelectedClaim?.IsClaimClosed
  }

  public get canEditClosedClaim(): boolean{
    return this.isClaimManager || this.accountService.isAssistantClaimsManager() || this.accountService.isAccountant();
  }

  public get canInteract(): boolean {
    return this.isAuditor || this.isAdjuster || this.canEditClosedClaim || this.accountService.isDealerServices();
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    this.notificationSocketService.setClaimLock(null)
  }

  canRenderActions = (actions: CellActions): boolean => {
    if ((actions & CellActions.Remove) === CellActions.Remove
      || (actions & CellActions.Download) === CellActions.Download
      || (actions & CellActions.Move) === CellActions.Move) {
      return this.isClaimClosed ? this.canEditClosedClaim : (this.isAdjuster || this.canEditClosedClaim || this.accountService.isDealerServices());
    }
    return false;
  }
}
