import { HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import FileSaver from 'file-saver';
import moment from 'moment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BehaviorSubject, finalize, forkJoin, map, Subject, takeUntil } from 'rxjs';
import { AccountService } from 'src-private/app/areas/account/services/account.service';
import { DialogType } from 'src-private/app/enums/dialog-type';
import { ConfirmDialogComponent } from 'src-private/app/shared/confirm-dialog/confirm-dialog.component';
import { FrameworkComponent } from 'src-private/app/shared/framework/framework.component';
import { CellBuilder } from 'src-private/app/shared/table-adapter/cell-builder/cell-builder';
import { CellType } from 'src-private/app/shared/table-adapter/cell-builder/cell-type';
import { TableAdapterComponent } from 'src-private/app/shared/table-adapter/table-adapter.component';
import { DocumentTypes, IDocument } from '../../interfaces/document';
import { CustomerDocumentService } from '../../services/customer-document.service';
import { DocumentAddDialogComponent } from '../document-add-dialog/document-add-dialog.component';
import { environment } from "src-private/environments/environment";
import { NotificationSocketService } from 'src-private/app/services/notification-socket.service';
import { ActivatedRoute } from '@angular/router';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { CellActions } from '../../../../shared/table-adapter/cell-builder/cell-type';
import { DocumentConflictDialogComponent } from '../document-conflict-dialog/document-conflict-dialog.component';
import { ToastrService } from "ngx-toastr";
import { ClaimsService } from '../../services/claims.service';
import { DocumentMoveDialogComponent } from '../document-move-dialog/document-move-dialog.component';
import { ClaimsRetrieve } from '../../models/claims-retrieve.model';
import { WarrantyService } from '../../services/warranty.service';
import { WarrantyRetrieve } from '../../models/warranty-retrieve.model';
import { DocumentService } from '../../services/document.service';


@Component({
  selector: 'app-customer-documents',
  templateUrl: './customer-documents.component.html',
  styleUrls: ['./customer-documents.component.scss']
})
export class CustomerDocumentsComponent extends FrameworkComponent implements OnInit, AfterViewInit {

  public faPlus = faPlus;

  private customerId: number;
  public isAdjuster: boolean;
  public isDealerServices: boolean;
  public checkLock$: BehaviorSubject<boolean>;

  @BlockUI() blockUI: NgBlockUI;

  private ngUnsubscribe: Subject<any> = new Subject();

  @ViewChild("customerDocumentsTable")
  customerDocumentsTable: TableAdapterComponent<IDocument>;
  public customerDocumentsTableColumns: CellBuilder[] = [
    new CellBuilder(" ", "documentTypeImage", CellType.image, 'w-06'),
    new CellBuilder("Filename", "fileName", CellType.text),
    new CellBuilder("Description", "description", CellType.text),
    new CellBuilder("Created Date", "createdOn", CellType.text, "col-sm-3"),
    new CellBuilder("Uploaded by", "createdBy", CellType.text),
    new CellBuilder("Action", "action", CellType.actions, '', CellActions.Download | CellActions.Remove | CellActions.Move)
  ];

  constructor(
    public dialog: MatDialog,
    private accountService: AccountService,
    private warrantyService: WarrantyService,
    private customerDocumentService: CustomerDocumentService,
    private claimsService: ClaimsService,
    private route: ActivatedRoute,
    private notificationSocketService: NotificationSocketService,
    private toastr: ToastrService,
    private documentService: DocumentService
  ) { 
    super();
    this.customerId = +this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.checkLock$ = new BehaviorSubject<boolean>(null)
    this.isAdjuster = this.accountService.isAdjuster();
    this.isDealerServices = this.accountService.isDealerServices();
  }

  ngAfterViewInit(): void {
    this.refreshDocuments();
    super.build("CustomerDocumentsComponent", "customer-documents-component");
  }

  addDocument() {
    let dialogRef = this.dialog.open(DocumentAddDialogComponent, {
      panelClass: "customer-add-dialogs",
      width: "600px",
      data: {
        originId: this.customerId,
      },
    });

    dialogRef.componentInstance.onSubmit
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(dialogRes => {
        if (dialogRes.data) {
          dialogRes.data.originId = this.customerId;
          dialogRes.data.type = "Action";

          this.blockUI.start();
          this.customerDocumentService
            .add(dialogRes.data)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(res => {
              this.blockUI.stop();
              dialogRef.close();

              if (res.conflicts && res.conflicts.length > 0) {
                this.promptDocumentConflicts(res.conflicts);
              }

              this.toastr.success(
                "Document successfully added. ",
                "Create Document"
              );

              this.refreshDocuments();
            },
            error => {
              this.toastr.error(environment.messages.apiError, "Unable to add document");
            });
        }
      });
  }

  promptDocumentConflicts(documents: IDocument[]) {
    this.dialog.open(DocumentConflictDialogComponent, {
      panelClass: "document-conflicts-dialog",
      autoFocus: true,
      data: documents 
    });
  }

  /**
   * Update Document
   * @param data Passes an element, and an action to be taken
   */
  updateDocument(data) {
    if (data.action == "download") {
      this.downloadDocument(this.customerId, data.element.id);
    } else if (data.action == "remove") {
      this.removeDocument(data.element.id);
    } else if (data.action == "move") {
      this.moveDocument(data.element.id);
    }
  }

  downloadDocument(customerId: number, documentId: number) {
    this.customerDocumentService
      .download(customerId, documentId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: HttpResponse<Blob>) => {
        let contentDispositionHeader = res.headers.get("content-disposition");
        // the second part of content-disposition contains the file name
        let fileName = contentDispositionHeader
          .split(";")[1]
          .trim()
          .split("=")[1]
          .replace(/"/g, "");
        FileSaver.saveAs(res.body, fileName);
      });
  }

  removeDocument(documentId: number) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'part-dialog',
      width: '400px',
      autoFocus: true,
      data: {
        message: "Are you sure you want to remove document?",
        type: DialogType[0].toString()
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.customerDocumentService
        .remove(documentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.toastr.success(
            "Document successfully removed. ",
            "Remove Document"
          );
          this.refreshDocuments();
        },
        error => {
          this.toastr.error(environment.messages.apiError, "Unable to remove document");
        });
      }
    });
  }

  moveDocument(documentId: number) {
    const claimRet = new ClaimsRetrieve();
    claimRet.customerId = this.customerId;
    const claims$ = this.claimsService.retrieveAll(claimRet);

    const warrantyRet = new WarrantyRetrieve();
    warrantyRet.customerId = this.customerId;
    const warranties$ = this.warrantyService.retrieveAll(warrantyRet);

    this.blockUI.start();

    forkJoin([claims$, warranties$])
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(finalize(() => this.blockUI.stop()))
      .subscribe(results => {
        const claims = results[0];
        const warranties = results[1]

        this.dialog.open(DocumentMoveDialogComponent, {
          panelClass: 'document-dialog',
          autoFocus: true,
          data: {
            documentId: documentId,
            documentTypeMap: new Map([
              [[DocumentTypes.Claim], claims.map(item => item.id)],
              [[DocumentTypes.Warranty], warranties.map(item => item.id)]
            ])
          }
      })
      .afterClosed().subscribe(result => {
        if (result) {
          this.refreshDocuments();
        }
      });
    });
  } 

  refreshDocuments() {
    this.customerDocumentsTable.refresh(
      this.customerDocumentService.retrieveAll(this.customerId).pipe<IDocument[]>(map(doc => doc.map(x=> {
        x.createdOn = `${moment(x.createdDate).format("YYYY-MM-DD h:mm A (MMMM DD)")}`
        x.documentTypeImage = this.claimsService.getDocumentTypeUrl(x.fileType);
        return x;
      })))
    );
  }

  viewDocument(event){
    let response = event.data;
    if (response) {
      this.customerDocumentService
        .download(response.customerId, response.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: HttpResponse<Blob>) => {
          this.documentService.openBlob(res, response.fileName);
        },
        error => {
          this.toastr.error(environment.messages.apiError, "Unable to download document");
        });
    }
  }

  public get isClaimManager(): boolean {
    return this.accountService.isClaimsManager()
  }

  public get isClaimClosed(): boolean {
    return this.claimsService.getSelectedClaim?.IsClaimClosed
  }

  public get canEditClosedClaim(): boolean {
    return this.isClaimManager || this.accountService.isAssistantClaimsManager() || this.accountService.isAccountant();
  }

  public get canInteract(): boolean {
    return this.isAdjuster || this.canEditClosedClaim || this.accountService.isDealerServices();
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    this.notificationSocketService.setClaimLock(null)
  }

  canRenderActions = (actions: CellActions): boolean => {
    if ((actions & CellActions.Remove) === CellActions.Remove
      || (actions & CellActions.Download) === CellActions.Download
      || (actions & CellActions.Move) === CellActions.Move) {
      return this.isClaimClosed ? this.canEditClosedClaim : (this.isAdjuster || this.canEditClosedClaim || this.accountService.isDealerServices());
    }
    return false;
  }
}
