import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { LocaleService } from 'src-private/app/services/locale.service';
import { FrameworkComponent } from 'src-private/app/shared/framework/framework.component';
import { ClaimTransaction } from '../../../models/claim-transaction';

@Component({
  selector: 'part-unapproval-dialog',
  templateUrl: './part-unapproval-dialog.component.html',
  styleUrls: ['./part-unapproval-dialog.component.scss']
})

export class PartUnapprovalDialogComponent extends FrameworkComponent implements OnInit, AfterViewInit, OnDestroy {

  public partData: ClaimTransaction;
  public rowTotal: string;
  public gstAmount: string;
  public rowTotalWithTax: string;
  public invoiceNumber: string;

  public faSave = faSave;
  public faWindowClose = faWindowClose;

  public IsSubmitted: boolean = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private localeService: LocaleService,
    private dialogRef: MatDialogRef<PartUnapprovalDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    super();
    this.partData = data.transaction;
    this.invoiceNumber = data.invoiceNumber;
  }

  ngOnInit() {
    let currencyPipe = new CurrencyPipe(this.localeService.getLanguage());
    this.rowTotal = currencyPipe.transform(this.partData.rowTotal);
    this.gstAmount = currencyPipe.transform(this.partData.gstAmount);
    this.rowTotalWithTax = currencyPipe.transform(this.partData.rowTotalWithTax);

    this.dialogRef.backdropClick().subscribe(() => {
      this.cancel();
    })
  }

  ngAfterViewInit(): void {
    super.build("PartUnapprovalDialogComponent", "parts-unapproval-dialog-component");
  }

  save() {
      this.partData.invoiceApproved = false;
      this.dialogRef.close({ partData: this.partData });
  }

  cancel() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
