<div class="header">

  <form [formGroup]="form">

    <div class="container-fluid">

      <div class="form-row">
        <div [ngClass]="{ 'offset-sm-3': bp === 6 }"></div>

        <div [ngClass]="{ 'col-sm-9': bp === 6, 'col-sm-12': bp !== 6  }">

          <div class="form-row">
            <div class="col-sm-6">

              <div class="form-row">
                <div class="col-sm-6">

                  <div class="header-field-outer">
                    <div class="header-field-inner">
                      <label class="form-field-label">Type</label>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input"
                               id="core"
                               type="radio"
                               value="Core"
                               formControlName="migrationType"
                               (change)="onMigrationTypeChange()">
                        <label class="custom-control-label" for="core">Core</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input"
                               id="tireRim"
                               type="radio"
                               value="TireRim"
                               formControlName="migrationType"
                               (change)="onMigrationTypeChange()">
                        <label class="custom-control-label" for="tireRim">
                          <span *ngIf="bp === 3">T &amp; R</span>
                          <span *ngIf="bp !== 3">Tire and Rim</span>
                        </label>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-sm-6">

                  <div class="header-field-outer">
                    <div class="header-field-inner">
                      <label class="form-field-label">Status</label>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input"
                               id="dealers"
                               type="radio"
                               value="Dealers"
                               formControlName="migrationStatus"
                               (change)="onMigrationStatusChange()">
                        <label class="custom-control-label" for="dealers">Dealers</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input"
                               id="migrated"
                               type="radio"
                               value="Migrated"
                               formControlName="migrationStatus"
                               (change)="onMigrationStatusChange()">
                        <label class="custom-control-label" for="migrated">Migrated</label>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
            <div class="col-sm-6">

              <div class="form-row">
                <div class="col-12">

                  <div class="header-field-outer">
                    <div class="header-field-inner">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="search-addon"><fa-icon [icon]="faSearch"></fa-icon> </span>
                        </div>
                        <input aria-describedby="search-addon"
                               aria-label="Search"
                               class="form-control"
                               placeholder="Search"
                               type="text"
                               formControlName="search"
                               (keydown.esc)="onSearchTextEscKeyDown()"
                               (keyup)="onSearchTextKeyUp()"/>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>


        </div>
      </div>

    </div>

  </form>

</div>

<div class="content">

  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-hover datatable">
    <thead>
    <tr>
      <th>ID</th>
      <th>Dealer</th>
      <th></th>
    </tr>
    </thead>
    <tbody *ngIf="dtLoaded">
    <tr *ngFor="let item of filteredMigrationDealers">
      <td>{{ item.vendorId }}</td>
      <td>{{ item.alias }}</td>
      <td class="data-cell-action">
        <a href="javascript:void(0);"
           (click)="onMigrateClick($event, item)"
           *ngIf="f.migrationStatus.value === migrationStatus.Dealers">
            <span class="icon">
              <fa-icon [icon]="faRedoAlt"></fa-icon>
            </span>
          <span class="label">
              Migrate
            </span>
        </a>
        <a href="javascript:void(0);"
           (click)="onRollbackClick($event, item)"
           *ngIf="f.migrationStatus.value === migrationStatus.Migrated">
            <span class="icon">
              <fa-icon [icon]="faSyncAlt"></fa-icon>
            </span>
          <span class="label">
              Rollback
            </span>
        </a>
      </td>
    </tr>
    </tbody>
  </table>
</div>
