import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src-private/environments/environment';
import { IRepairCentreNote } from '../interfaces/repair-centre-note.interface';

@Injectable({
  providedIn: 'root'
})
export class RepairCentreNotesService {

  constructor(private http:HttpClient) { }

  retrieveAll(repairCentreID:number, claimID:number):Observable<IRepairCentreNote[]>{
    let httpParams = new HttpParams();
    httpParams = httpParams.append('repairCentreID', repairCentreID.toString());
    httpParams = httpParams.append('claimID', claimID == null ? null : claimID.toString());

    return this.http.get<IRepairCentreNote[]>(`${environment.resourceUrl}api/gwc/repaircentre/notes`, {params:httpParams})
  }

  add(note : IRepairCentreNote):Observable<IRepairCentreNote>{
    return this.http.post<IRepairCentreNote>(`${environment.resourceUrl}api/gwc/repaircentre/notes`, note)
  }
}
