import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Params, Router } from '@angular/router';
import { Title } from "@angular/platform-browser";

/*-- Models --*/
import { IEazeeTrakFile } from '../interfaces/eazeetrakfile.interface';

/*-- Pipes --*/
import { ArrayFilterPipe } from 'src-private/app/pipes/array-filter.pipe';

/*-- Services --*/
import { BreakpointService } from '../../../services/breakpoint.service';
import { EazeeTrakService } from '../services/eazeetrak.service';

/*-- Third Party --*/
import * as moment from 'moment';
import * as _  from 'underscore';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import {Subject} from "rxjs";
import { AccountService } from '../../account/services/account.service';

@Component({
  selector: 'app-eazeetrak',
  templateUrl: './eazeetrak.component.html',
  styleUrls: ['./eazeetrak.component.scss']
})
export class EazeetrakComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  private eazeeTrakFiles: IEazeeTrakFile[];
  private flleStatusOpen = true;

  public bp: number;
  public dealerAccountManagers: any;
  public filteredEazeeTrakFiles: IEazeeTrakFile[];
  public form: UntypedFormGroup;
  public faSearch = faSearch;
  public faEye = faEye;
  public dtOptions: DataTables.Settings;
  public isAuditor: Boolean = false;
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private arrayFilterPipe: ArrayFilterPipe,
    private breakpointService: BreakpointService,
    private filesService: EazeeTrakService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private titleService: Title,
    private accountService: AccountService
  ) {
    breakpointService.bp.subscribe(x => this.bp = x);
  }

  //#region - Lifecycle
  ngOnInit(): void {
    this.titleService.setTitle("Eazeetrak");

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      paging: true
    };

    this.dtTrigger.next(true);

    if (!this.bp) {
      this.bp = this.breakpointService.getBreakpoint();
    }

    this.form = this.formBuilder.group({
      dealerAccountManager: ['All'],
      fileStatus: ['Open', Validators.required],
      openedBy: ['All', Validators.required],
      search: ['']
    });

    this.blockUI.start();

    this.retrieveFiles().then(
      () => {
        this.loadDealerAccountManagers();
        this.filterResults();

        this.blockUI.stop();
      }
    );
    this.isAuditor = (this.accountService.isGeneralAuditor() || this.accountService.isInsurerAuditor()) ? true : false;
  }
  //#endregion

  //#region - Events
  onDealerAccountManagerChange(): void {
    this.filterResults();
  }

  onFileStatusChange(): void {
    this.blockUI.start();

    this.retrieveFiles().then(
      () => {
        this.filterResults();

        this.blockUI.stop();
      }
    );
  }

  onOpenedByChange(): void {
    this.filterResults();
  }

  onReviewClick(event, item: IEazeeTrakFile): void {
    event.stopPropagation();

    const dealerId = item.vendorId;
    const eazeeTrakFileId = item.eazeeTrakFileId;
    const param = { dealerId: dealerId };
    const queryParams: Params = { f: eazeeTrakFileId };

    this.router.navigate(['dealers', param], { queryParams: queryParams, skipLocationChange: true, replaceUrl: true });
  }

  onSearchTextEscKeyDown(): void {
    this.form.get('search').setValue('');
  }

  onSearchTextKeyUp(): void {
    this.filterResults();
  }
  //#endregion

  //#region - Private Methods
  private filterResults(): void {
    const dealerAccountManagerId = this.form.value.dealerAccountManager;
    const fileStatus = this.form.value.fileStatus;
    const openedBy = this.form.value.openedBy;
    const search = this.form.value.search;
    const searchFields = (fileStatus === 'Closed') ?
      { 'eazeeTrakId': search, 'alias': search, 'reason': search, 'result': search, 'openedDate': search, 'dueDate': search } :
      { 'eazeeTrakId': search, 'alias': search, 'reason': search, 'result': search, 'openedDate': search, 'closedDate': search };

    let results = this.eazeeTrakFiles;

    this.flleStatusOpen = (fileStatus === 'Open');

    // filter on openedBy
    results = (openedBy === 'Self') ? results.filter(e => e.isCreatedByUser) : results;

    // filter on dealerAccountManagerId
    results = (dealerAccountManagerId === 'All') ? results : results.filter(e => e.dealerAccountManagerId === dealerAccountManagerId);

    // filter on search
    results = (search === '') ? results : this.arrayFilterPipe.transform(results, searchFields);

    this.filteredEazeeTrakFiles = results;
  }

  private getOverdueClass(item: IEazeeTrakFile): string {
    if (!item.isClosed) {
      const currentDate = moment.utc(new Date()).format();

      if (currentDate >= moment.utc(item.dueDate).format()) {
        return 'danger';
      } else if (currentDate >= moment.utc(item.dueDate).day(-1).format()) {
        return 'warning';
      } else {
        return 'success';
      }
    }
  }

  private loadDealerAccountManagers(): void {
    // unique array of dealerAccountManagers
    const eazeeTrakFiles = this.eazeeTrakFiles;

    let arr = [];

    for (let i = 0, len = eazeeTrakFiles.length; i < len; i++) {
      arr.push({
        dealerAccountManagerId: eazeeTrakFiles[i].dealerAccountManagerId,
        dealerAccountManager: eazeeTrakFiles[i].dealerAccountManager
      });
    }

    arr = _.sortBy(_.uniq(arr, function (item) { return item.dealerAccountManager; }), 'dealerAccountManager');

    arr.splice(0, 0, {
      dealerAccountManagerId: 'All',
      dealerAccountManager: 'View All'
    });

    this.dealerAccountManagers = arr;
  }
  //#endregion

  //#region - API Methods
  private retrieveFiles() {
    const fileStatus = this.form.value.fileStatus;
    const isClosed = (fileStatus === 'Closed');

    const promise = new Promise((resolve) => {
      this.filesService.retrieveEazeeTrakFiles(isClosed).subscribe(
        response => {
          const data: IEazeeTrakFile[] = response;

          this.eazeeTrakFiles = data;

          resolve(true);
        }
      );
    });

    return promise;
  }
  //#endregion
}


