<fieldset class="fieldset-form height">
    <legend>
        Parts
        <div *ngIf="funcs['addPart']; else noAddPart" class="float-right">
            <button class="btn custom-button float-right" type="button" (click)="addPart()" [hidden]="!canAddPart">
                <fa-icon [icon]="faPlus"></fa-icon> Add Part
            </button>
        </div>
        <ng-template #noAddPart>
        <framework-hidden [display]="button"></framework-hidden>
        </ng-template>
    </legend>
    <table-adapter #partsTable [columns]="partsTableColumns" [tableLabel]="'parts-table'" (update)="updatePart($event)" (onSelectionChange)="updatePartProcess($event)" [paginationRequired]="false"
    (context)="onAction($event)" [canInteract]="canAddPart" [isClaimClosed]="IsClaimClosed" [canRenderActions]="canRenderActions">
    </table-adapter>
</fieldset>
