<form [formGroup]="eazeeTrakForm"
      (ngSubmit)="onSubmit()"
      *ngIf="eazeeTrakFile"
      novalidate>

  <div class="sidebar-header">

    <h4 class="sidebar-title">Add EazeeTrak File</h4>

  </div>
  <div class="sidebar-actions">

    <button class="btn btn-block btn-icon btn-save"
            type="button"
            (click)="onReOpenClick()"
            *ngIf="eazeeTrakFile.isClosed">
      <fa-icon [icon]="faRedoAlt"></fa-icon> 
      Re-Open
    </button>

    <button class="btn btn-block btn-icon btn-save"
            type="submit"
            *ngIf="!eazeeTrakFile.isClosed">
            <fa-icon [icon]="faCheck" class="icon-left"></fa-icon> 
      Submit
    </button>

    <button class="btn btn-block btn-icon btn-cancel"
            type="button"
            (click)="onCancelClick()">
            <fa-icon [icon]="faTimes" class="text-danger icon-left"></fa-icon> 
      Cancel
    </button>

  </div>
  <div class="sidebar-body">

    <div class="form-group-static">
      <label for="dealer">Dealer</label>
      <input class="w-100"
             id="dealer"
             type="text"
             readonly
             [value]="eazeeTrakFile.alias">
    </div>

    <div class="form-group-static">
      <label for="agent">Agent</label>
      <input class="w-100"
             id="agent"
             type="text"
             readonly
             [value]="eazeeTrakFile.dealerAccountManager">
    </div>

    <div class="form-group-static">
      <label for="contact">Contact</label>
      <input class="w-100"
             id="contact"
             type="text"
             readonly
             [value]="eazeeTrakFile.contactName">
    </div>

    <div class="form-group-static">
      <label for="reason">Reason</label>
      <input class="w-100"
             id="reason"
             type="text"
             readonly
             [value]="eazeeTrakFile.reason">
    </div>

    <div class="form-group-static">
      <label for="result">Result</label>
      <input class="w-100"
             id="result"
             type="text"
             readonly
             [value]="eazeeTrakFile.result">
    </div>

    <div class="form-group-static">
      <label>History</label>
      <dl class="history">
        <ng-container *ngFor="let item of eazeeTrakFileHistory">
          <dt *ngIf="item.eazeeTrakActionId"
              [ngClass]="{ 'file-closed': item.eazeeTrakActionId === 2, 'file-open': item.eazeeTrakActionId !== 2 }">
            <span [ngSwitch]="item.eazeeTrakActionId">
              <span *ngSwitchCase="1">Opened </span>
              <span *ngSwitchCase="2">Closed </span>
              <span *ngSwitchCase="3">Re-Opened </span>
            </span>
            {{item.createdDate | date: 'medium' }}
          </dt>
          <dd *ngIf="item.comment">
            <div class="comment-date">{{item.createdDate | date: 'medium'}}</div>

            <ul class="list-unstyled user">
              <li class="avatar-cell">
                <img class="avatar" [src]="getAvatarImageUrl(item)" avatar />
              </li>
              <li class="name-cell">
                {{item.user}}
              </li>
            </ul>

            <span class="comment w-100">{{item.comment}}</span>
          </dd>
        </ng-container>
      </dl>

    </div>

    <mat-form-field class="w-100"
                    *ngIf="!eazeeTrakFile.isClosed">
      <textarea placeholder="Comment"
                required
                formControlName="comment"
                matInput></textarea>
      <mat-error>
        <mat-error *ngIf="isSubmitted && f.comment.errors?.required">Comment is required</mat-error>
      </mat-error>
    </mat-form-field>

    <div [formGroup]="notificationForm" class="form-group-static"
         *ngIf="!eazeeTrakFile.isClosed">
      <label>Notification</label>
      <ul class="list-unstyled notification-recipients">
        <li *ngFor="let notification of eazeeTrakFileNotifications">

          <mat-checkbox color="primary"
                        formControlName="{{notification.userId}}"
                        [disabled]="notification.isPreSelected === 1">
            {{notification.firstName}}&nbsp;{{notification.lastName}}
          </mat-checkbox>

        </li>
      </ul>
    </div>

    <ul class="list-unstyled pin-container w-100"
        *ngIf="!eazeeTrakFile.isClosed">
      <li class="pin-cell">

        <mat-checkbox color="primary"
                      formControlName="pin">Pin</mat-checkbox>

      </li>
      <li class="pin-days-cell">

        <mat-form-field class="w-100">
          <mat-select formControlName="pinDays"
                      [disabled]="!f.pin.value">
            <mat-option [value]="30">30</mat-option>
            <mat-option [value]="60">60</mat-option>
            <mat-option [value]="90">90</mat-option>
            <mat-option [value]="9999">Indefinitely</mat-option>
          </mat-select>
        </mat-form-field>

      </li>
    </ul>

    <mat-checkbox color="primary"
                  formControlName="closeFile"
                  *ngIf="!eazeeTrakFile.isClosed">Close file</mat-checkbox>

  </div>

</form>
