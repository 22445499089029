import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { FrameworkComponent } from "src-private/app/shared/framework/framework.component";
import { ICustomer } from "../../interfaces/customer.interface";
import { AppValidators } from "src-private/app/validators/app-validators";
import { IWarranty } from "../../interfaces/warranty.interface";
import { ActivatedRoute } from "@angular/router";
import { ClaimsService } from "../../services/claims.service";
import { AccountService } from "src-private/app/areas/account/services/account.service";
import { MaintenanceAdd, MaintenanceAddInput } from "../maintenance/maintenance-add/maintenance-add.component";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ClaimTransaction } from "../../models/claim-transaction";
import { IDealerReal } from "../../interfaces/dealer.interface";
import { ClaimsTransactionService } from "../../services/claims-transaction.service";
import { CustomerService } from "../../services/customer.service";
import { WarrantyService } from "../../services/warranty.service";
import { DealerRealService } from "../../services/dealer-service";
import { Subject, takeUntil } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { environment } from "src-private/environments/environment";
import { BlockUI, NgBlockUI } from "ng-block-ui";

@Component({
  selector: "app-warranty-details",
  templateUrl: "./warranty-details.component.html",
  styleUrls: ["./warranty-details.component.scss"],
})

export class WarrantyDetailsComponent extends FrameworkComponent implements OnInit, AfterViewInit {

  @Input() customerId: number;
  @Input() claimEnterpriseId: number;
  @Input() warrantyId: number;

  public claimId: number;
  public applicationId: number;
  public vehicleId: number;
  public isLoading = false;
  public isAdjuster: boolean;
  public vehicleExtraDetails = "";
  public customer: ICustomer;
  public warranty: IWarranty;
  public claimTotal: ClaimTransaction;
  public dealer: IDealerReal;

  private readonly ngUnsubscribe$: Subject<boolean> = new Subject();

  @BlockUI() blockUI: NgBlockUI;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private claimsService: ClaimsService,
    private accountService: AccountService,
    private claimTransactionService: ClaimsTransactionService,
    private customerService: CustomerService,
    private warrantyService: WarrantyService,
    private dealerService: DealerRealService,
    private toastr: ToastrService,
    ) {
    super();
    this.claimId = this.activatedRoute.snapshot.data['claimId']
  }

  ngOnInit(): void {
    this.isLoading = true;
    if (this.customerId && this.warrantyId) {
      this.warrantyService.retrieve(this.warrantyId)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe({
        next: warranty => {
          this.warranty = warranty;
          this.warrantyId = this.warranty.id;
          this.applicationId = this.warranty.applicationId;
          this.vehicleId = this.warranty.vehicle.id;
          this.buildVehicleExtraDetails();  

          this.dealerService.retrieve(this.warranty.dealerId)
            .pipe(takeUntil(this.ngUnsubscribe$))
              .subscribe({
              next: dealer => {
                if (dealer) {
                  this.dealer = dealer;
                }
              }
          });
          if (!isNaN(this.claimId)){
            this.claimTransactionService.retrieveClaimTotal(this.claimId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
              if (res) {
                this.claimTotal = res[0];
              }
            },
            error => {
              this.toastr.error(environment.messages.apiError, "Unable to retrieve claim totals");
            });
          }
          this.customerService.retrieve(this.customerId)
              .pipe(takeUntil(this.ngUnsubscribe$))
              .subscribe({
              next: customer => {
                if (customer){
                  this.customer = customer;
                }
              }
            });

        }
      });
        
    }

    this.isAdjuster = this.accountService.isAdjuster() || this.accountService.isClaimsManager() || this.accountService.isAssistantClaimsManager();
    this.isLoading = false;

  }

  ngAfterViewInit(): void {
    super.build("WarrantyDetailsComponent", 'app-warranty-details')
  }

  addMaintenance() {
    let canAddMaintenance = this.isClaimClosed ? this.canEditClosedClaim : this.isAdjuster
    if (canAddMaintenance && !isNaN(this.claimId)) {
      var maint = new MaintenanceAddInput();
      maint.warrantyId = this.warrantyId;
      maint.claimId = this.claimId;
      maint.applicationId = this.applicationId;
      maint.vehicleId = this.vehicleId;

      this.dialog.open(MaintenanceAdd, {
        width: "400px",
        data: maint,
        autoFocus: false
      });
    }
  }

  buildVehicleExtraDetails() {
    var extraDetails = [
      this.warranty.vehicle.vehicleType,
      this.warranty.vehicle.wheels,
      this.warranty.vehicle.fuel,
      this.warranty.vehicle.turbo
    ];

    for (let index = 0; index < extraDetails.length; index++) {
      const element = extraDetails[index];
      if (element != "" && element != undefined) {
        this.vehicleExtraDetails += element;
        if (index + 1 != extraDetails.length) {
          this.vehicleExtraDetails += ", ";
        }
      }
    }
    this.vehicleExtraDetails.trim();
  }

  public get isClaimClosed(): boolean {
    return this.claimsService.getSelectedClaim?.IsClaimClosed
  }

  public get canEditClosedClaim(): boolean {
    return this.accountService.isClaimsManager();
  }

  public get hasClaimId(): boolean {
    return !isNaN(this.claimEnterpriseId) && this.claimEnterpriseId != null;
  }

  public get isCustomerContact(): boolean {
    return this.customer != undefined && this.customer.phone != null && (!AppValidators.isEmptyOrSpaces(this.customer.phone));
  }

  public get isDealerPhone(): boolean {
    return this.dealer != undefined && this.dealer.phone != null && (!AppValidators.isEmptyOrSpaces(this.dealer.phone));
  }

  public get isDealerContact(): boolean {
    return this.dealer.contactFirst != null && this.dealer.contactLast != null && (!AppValidators.isEmptyOrSpaces(this.dealer.contactFirst) && !AppValidators.isEmptyOrSpaces(this.dealer.contactLast))
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

}