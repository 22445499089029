import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { TableAdapterComponent } from "src-private/app/shared/table-adapter/table-adapter.component";
import { IDocument, IWarrantyDocument, IClaimDocument } from "../../interfaces/document";
import { CellType } from "src-private/app/shared/table-adapter/cell-builder/cell-type";
import { CellBuilder } from "src-private/app/shared/table-adapter/cell-builder/cell-builder";
import { FrameworkComponent } from "src-private/app/shared/framework/framework.component";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { ClaimsService } from '../../services/claims.service';
import * as moment from 'moment';

@Component({
  selector: 'app-document-conflict-dialog',
  templateUrl: './document-conflict-dialog.component.html',
  styleUrls: ['./document-conflict-dialog.component.scss']
})
export class DocumentConflictDialogComponent extends FrameworkComponent implements OnInit {

  public faCheck = faCheck;

  @ViewChild("conflictsTable")
  table: TableAdapterComponent<IDocument>;

  public columns: CellBuilder[] = [
    new CellBuilder(" ", "documentTypeImage", CellType.image, 'w-06'),
    new CellBuilder("Filename", "fileName", CellType.text),
    new CellBuilder("Description", "description", CellType.text),
    new CellBuilder("Created Date", "createdDate", CellType.text),
    new CellBuilder("Uploaded by", "createdBy", CellType.text, "col-sm-3"),
    new CellBuilder("Association", "association", CellType.text),
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private conflicts: IDocument[],
    private claimsService: ClaimsService) {
    super();
  }

  ngOnInit(): void {
    super.build("DocumentConflictDialogComponent", "documents-conflicts-table");
  }

  ngAfterViewInit() { 
    let dataSource = this.conflicts.map(x => {
      x.createdOn = `${moment(x.createdDate).format("YYYY-MM-DD h:mm A (MMMM DD)")}`
      x.documentTypeImage = this.claimsService.getDocumentTypeUrl(x.fileType)

      var association;

      let warrantyDoc = x as IWarrantyDocument;
      if (warrantyDoc) {
        if (warrantyDoc.warrantyId) {
          association = `Warranty: ${warrantyDoc.warrantyId}`;
        }
      }

      let claimDoc = x as IClaimDocument;
      if (claimDoc) {
        if (claimDoc.claimsId) {
          association = `Claim: ${claimDoc.claimsId}`;
        }
      }

      return {
        ...x,
        association: association
      };
    });

    this.table.refresh(dataSource);
  }
}
