/*-- Interfaces --*/
import { IClaimsRetrieve } from '../interfaces/claims-retrieve.interface';

export class ClaimsRetrieve implements IClaimsRetrieve {
  claimId: number;
  warrantyId: number;
  WarrantyIdFull:string;
  customerId: number;
  customerFirstname: string;
  customerLastname: string;
  vin: string;
  dealer: string;
  repairCenter: string;
  assignedAdjuster: string;
  status: string;
  openedBy: string;
  sortColumn: string;
  sortType: string;
  customerClaims: boolean;

  constructor() {
    this.claimId = null;
    this.warrantyId = null;
    this.WarrantyIdFull = null;
    this.customerId = null;
    this.customerFirstname = null;
    this.customerLastname = null;
    this.vin = null;
    this.dealer = null;
    this.repairCenter = null;
    this.assignedAdjuster = null;
    this.status = 'All';
    this.openedBy = 'All';
    this.sortColumn = null;
    this.sortType = null;
    this.customerClaims = false;
  }
}
