<mat-tab-group mat-stretch-tabs dynamicHeight>
    <!-- <div *ngFor="let coverage of coverageGroups"> -->
        <mat-tab *ngFor="let coverage of coverageGroups" [label]="coverage">
            <ng-template matTabContent>
                <app-selected-coverages [coveragePackageArray]="filterCoverage(coverage)"></app-selected-coverages>
            </ng-template>
        </mat-tab>
    <!-- </div> -->
</mat-tab-group>
<br />
<div [hidden]="isCoverageSelected" class="alert alert-warning" role="alert">
    No Coverage Selected
</div>
<div [hidden]="!isCoverageSelected || (isCoverageSelected && isValid)" class="alert alert-warning" role="alert">
    Selected coverages should have amount greater than 0
</div>
<div>
    <button mat-button matStepperPrevious>Back</button>
    <button mat-button matStepperNext class="float-right" type="button">Next</button>
</div>
