<div style="text-align: center;">
    <p *ngIf="distributionForm.value.distributionTypeId < 0">Please select a form of distribution</p>
    <mat-button-toggle-group style="width: 100%;">
        <mat-button-toggle class="button-toggle" [checked]="distributionForm.value.distributionTypeId === 0"
            (click)="onDistributionTypeChange(0)">Individual Users</mat-button-toggle>
        <mat-button-toggle class="button-toggle" [checked]="distributionForm.value.distributionTypeId === 1"
            (click)="onDistributionTypeChange(1)">Percentage of Bonus Bucks</mat-button-toggle>
    </mat-button-toggle-group>
</div>

<div [hidden]="distributionForm.value.distributionTypeId < 0" [formGroup]="distributionForm">
    <table class="table data-table data-table-dark">
        <thead>
            <tr>
                <th></th>
                <th>Name</th>
                <th>Title</th>
                <th>EazeeApp Role</th>
                <th>Status</th>
                <th class="distribution-width">Distribution</th>
            </tr>
        </thead>
        <tbody>
            <ng-container formArrayName="distributions">
                <ng-container *ngFor="let distro of distributionsForm.controls; let i = index" [formGroupName]="i">
                    <tr [ngClass]="{'hidden': distro.get('selected').disabled}">
                        <td class="rebate-checkbox-cell">
                            <div class="percentage-rebate-container">
                                <mat-checkbox (change)="onRebateCheckboxChange($event.checked, distro)" color="primary" formControlName="selected">
                                </mat-checkbox>
                            </div>
                        </td>
                        <td *ngIf="distro.value.name">{{distro.value.name}}</td>
                        <td *ngIf="!distro.value.name">{{distro.value.firstName}} {{distro.value.lastName}}</td>
                        <td>{{distro.value.title}}</td>
                        <td>{{distro.value.roleLabel}}</td>
                        <td>{{distro.value.status}}</td>
                        <td class="distribution-width"></td>
                    </tr>
                    <tr *ngIf="distro.value.selected && canShowUserType(distro)">
                        <td colspan="6">
                            <mat-radio-group required style="padding-left: 10px;" formControlName="userType">
                                <mat-radio-button [ngClass]="getRadioControlState(distro)" *ngFor="let item of userTypes"  
                                    [value]="item" color="primary" style="padding-right: 10px;" (change)="onUserTypeChange(distro, item)">
                                    {{getUserTypeDesc(item)}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </td>
                    </tr>                    
                    <tr *ngIf="distro.value.selected && distro.value.userType">
                        <td colspan="6">
                            <app-user-registration [distributionForm]="distro" [userType]="distro.value.userType" [distType]="distributionForm.value.distributionTypeId" [dealer]="dealer">
                            </app-user-registration>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
        </tbody>
        <tfoot>
            <tr *ngIf="isPercentage">
                <td colspan="12">
                    <div class="float-right">
                        <fa-icon [ngClass]="{'checked': total === 100, 'nixed': total !== 100}" [icon]="icon"></fa-icon>
                        <mat-form-field class="percentage-field">
                            <input matInput type="text" disabled [value]="total" />
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </div>
                </td>
            </tr>
        </tfoot>
    </table>

    <fieldset>
        <legend>Dealer Principal Authorization</legend>
        <app-dealer-authorization [dealerPrincipal]="dealerPrincipal"></app-dealer-authorization>
    </fieldset>
</div>
<div>
    <div [hidden]="distributionForm.valid" class="alert alert-danger" role="alert">
        {{validationErrors}}
    </div>
    <button mat-button matStepperPrevious>Back</button>
    <button mat-button [hidden]="distributionForm.valid" class="float-right" type="button"
        (click)="distributionsForm.markAllAsTouched()">
        Next
    </button>
    <button mat-button [hidden]="!distributionForm.valid" matStepperNext class="float-right" type="button">
        Next
    </button>
</div>