<fieldset class="fieldset-form">
    <legend>
        Claims
        <div *ngIf="funcs['addClaim'];else noAddClaim" class="float-right">
        <button class="btn custom-button float-right" type="button" (click)="addClaim()" [hidden]="!canAdd || !warrantyId || !customerId || (isClaimClosed ? !canEditClosedClaim : !isAdjuster)">
            <fa-icon [icon]="faPlus"></fa-icon> Add Claim
        </button>
        </div>
        <ng-template #noAddClaim>
        <framework-hidden [display]="button"></framework-hidden>
        </ng-template>
    </legend>
    <table-adapter #claimsTable [columns]="columns" [tableLabel]="'claims-table'" [canInteract]="isAdjuster"></table-adapter>
</fieldset>
