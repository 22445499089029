import { IClaimLock } from "../interfaces/claim-lock.interface";


export class ClaimLockNotification {
    claimLock: IClaimLock;
    userId: string;

    constructor(lock, userId:string){
        this.claimLock = {} as IClaimLock
        this.claimLock.claimId = lock.ClaimId;
        this.claimLock.lock = lock.Lock;
        this.claimLock.lockTime = lock.LockTime;
        this.claimLock.username = lock.Username;
        this.userId = userId;
    }
}
