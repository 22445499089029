/* https://medium.com/front-end-hacking/dynamically-add-components-to-the-dom-with-angular-71b0cb535286 */
import { ComponentFactoryResolver, Injectable, Inject, ViewContainerRef } from '@angular/core';

/*-- Enums --*/
import { Sidebar } from '../enums/sidebar.enum';
import { AppComponent } from '../app.component';

@Injectable()
export class DynamicComponentService {
  factoryResolver: ComponentFactoryResolver;
  rootViewContainer: ViewContainerRef;

  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver
  ) {
    this.factoryResolver = factoryResolver;
  }

  addDynamicComponent(dynamicComponent) {
    const factory = this.factoryResolver.resolveComponentFactory(dynamicComponent);
    const component = factory.create(this.rootViewContainer.parentInjector);

    this.rootViewContainer.clear();
    this.rootViewContainer.insert(component.hostView);

    return component.instance;
  }

  closeAndClear(appComponent: AppComponent, sidebar: Sidebar, clear: boolean = true) {
    const delay = 200;

    switch (sidebar) {
      case Sidebar.Bottom:
        appComponent.sidebarBottom.close();

        if (clear) {
          setTimeout(() => {
            appComponent.dynamicComponentBottom.remove(0);
          }, delay);
        }

        break;
      case Sidebar.Right:
        appComponent.sidebarRight.close();

        if (clear) {
          setTimeout(() => {
            appComponent.dynamicComponentRight.remove(0);
          }, delay);
        }

        break;
      case Sidebar.Top:
        appComponent.sidebarTop.close();

        if (clear) {
          setTimeout(() => {
            appComponent.dynamicComponentTop.remove(0);
          }, delay);
        }

        break;
    }
  }

  open(appComponent: AppComponent, sidebar: Sidebar) {
    switch (sidebar) {
      case Sidebar.Bottom:
        appComponent.sidebarBottom.open();

        break;
      case Sidebar.Right:
        appComponent.sidebarRight.open();

        break;
      case Sidebar.Top:
        appComponent.sidebarTop.open();

        break;
    }
  }

  setRootViewContainerRef(viewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }
}
