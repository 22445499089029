
<mat-dialog-content>
  <form [formGroup]="maintenanceRecordForm">
    <fieldset class="fieldset-form">
      <legend>Maintenance Record</legend>
      <div class="container-fluid" style="background-color: #111111;">
        <span style="font-size: 11px;">Number of KMs at Scheduled Maintenance</span>
        <div class="row">
          <div class="col-sm-6">
            <mat-form-field class="w-100">
              <input placeholder="KMs" matInput formControlName="scheduledKms" type="text" mask="separator.2" thousandSeparator=",">
              <mat-error *ngIf="maintenanceRecordForm.get('scheduledKms').hasError('required')">
                KMs is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-6">

            <mat-form-field class="w-100">
              <mat-label>Maintenance Period</mat-label>
              <mat-select formControlName="scheduledPeriod">
                <mat-option *ngFor="let term of maintenanceTerms" [value]="term.id">{{term.description}}</mat-option>
              </mat-select>
              <mat-error *ngIf="maintenanceRecordForm.get('scheduledPeriod').hasError('required')">
                Scheduled Period is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <span style="font-size: 11px;">KMs When Maintenance Performed</span>
        <div class="row">
          <div class="col-sm-6">
            <mat-form-field class="w-100">
              <input placeholder="KMs" matInput formControlName="performedKms" type="text" mask="separator.2" thousandSeparator=",">
              <mat-error *ngIf="maintenanceRecordForm.get('performedKms').hasError('required')">
                Performed KMs is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field class="w-100">
              <input placeholder="Date" [matDatepicker]="KMsMaintenanceDatePicker" matInput formControlName="performedDate">
              <mat-datepicker-toggle matSuffix [for]="KMsMaintenanceDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #KMsMaintenanceDatePicker disabled="false"></mat-datepicker>
              <mat-error *ngIf="maintenanceRecordForm.get('performedDate').hasError('required')">
                Performed Date is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </fieldset>

  </form>
</mat-dialog-content>
<mat-dialog-actions class="float-right">
  <button mat-button mat-dialog-close class="btn" (click)="cancel()">
    <fa-icon [icon]="faWindowClose" class="pr-2 text-danger"></fa-icon>
    Cancel
  </button>
  <button mat-button class="btn" (click)="confirm()">
    <fa-icon [icon]="faSave" class="pr-2 text-success"></fa-icon>
    Save
  </button>
</mat-dialog-actions>

