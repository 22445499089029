import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src-private/environments/environment';
import { IDealerReal } from '../interfaces/dealer.interface';

@Injectable({
  providedIn: 'root'
})

export class DealerRealService {

  constructor(private http: HttpClient) { }

  retrieve(id: number): Observable<IDealerReal> {
    return this.http.get<IDealerReal>(`${environment.resourceUrl}api/gwc/dealer/${id}`)
  }
}
