import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { faSave, faWindowClose } from '@fortawesome/pro-solid-svg-icons';
import { DealersService } from 'src-private/app/areas/dealers/services/dealers.service';
import { RepairCentreService } from 'src-private/app/areas/claims/services/repair-centre.service'
import { Country } from 'src-private/app/enums/bonus-bucks.enums';
import { AppValidators } from 'src-private/app/validators/app-validators';
import { IRepairCentre } from '../../../interfaces/repair-centre.interface';
import { RepairCentre } from '../../../models/repair-centre.model';
import { catchError, debounceTime } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ConfirmDialogComponent } from 'src-private/app/shared/confirm-dialog/confirm-dialog.component';
import { IRepairCentreStatuses } from '../../../interfaces/repair-centre-statuses.interface';
import { IRepairCentreTypes } from '../../../interfaces/repair-centre-type.interface';
import { IDealer } from 'src-private/app/areas/dealers/interfaces/dealer.interface';
import { D } from '@angular/cdk/keycodes';
import { DialogType } from 'src-private/app/enums/dialog-type';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src-private/environments/environment';
import { min } from 'moment';
import { AccountService } from 'src-private/app/areas/account/services/account.service';
import { PaymentMethod, RepairTimes, TowingService } from 'src-private/app/enums/repair-centre.enums';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ProvinceStateService } from 'src-private/app/services/province-states.service';
import { ProvinceState } from 'src-private/app/areas/dealers/models/bonus-bucks.Models';

@Component({
  selector: 'app-repair-centre-edit',
  templateUrl: './repair-centre-edit.component.html',
  styleUrls: ['./repair-centre-edit.component.scss']
})
export class RepairCentreEditComponent implements OnInit {

  @Input() isEdit: boolean;

  public repairCenterForm: UntypedFormGroup
  public faSave = faSave;
  public faWindowClose = faWindowClose;
  public repairCentreStatuses: IRepairCentreStatuses[];
  public repairCentreTypes: IRepairCentreTypes[];
  public filteredDealers: IDealer[];
  public dealerAutoCompleteText: string;
  public isLoading: boolean = false;
  public isSubmitted: boolean = false;
  public paymentTypes = new Map<string, number>();
  public fieldLabel = new Map<string, string>();
  public towingServices = new Map<string, number>();
  public repairTimes = new Array<string>();

  private provinces: Array<ProvinceState>;
  private states: Array<ProvinceState>;

  postalCodeStateLabel: string;
  postalCodeStateMask: string;
  provinceStateLabel: string;
  headerText: string = "Repair Centre – Add";

  originalRepairCenter: IRepairCentre;

  dealers: any[];
  updateRepairCenter: boolean = true;
  geocoder: google.maps.Geocoder;
  @BlockUI() blockUI: NgBlockUI;

  @ViewChild('repairCentreAutoCompleteRef', { static: true, read: MatAutocompleteTrigger })
  repairCentreAutoCompleteRef: MatAutocompleteTrigger;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: IRepairCentre,
    private dialogRef: MatDialogRef<RepairCentreEditComponent>,
    private dealerService: DealersService,
    private dialog: MatDialog,
    private repairCentreService: RepairCentreService,
    private accountService:AccountService,
    private provStateService: ProvinceStateService,
    private toastr:ToastrService) { }

  ngOnInit() {

    if (this.isEdit) this.headerText = "Repair Centre – Edit";

    this.originalRepairCenter = Object.assign(new RepairCentre, this.data);

    this.repairCenterForm = this.fb.group({
      name: [this.data.name, Validators.required],
      contact: [this.data.contact],
      contactExt: [this.data.contactExt],
      address: [this.data.address],
      city: [this.data.city],
      province: [this.data.province],
      postalCode: [this.data.postalCode],
      phone: [this.data.phone, AppValidators.minlength(10)],
      fax: [this.data.fax, AppValidators.minlength(10)],
      website: [this.data.website],
      email: [this.data.email, AppValidators.email(false)],
      status: [this.data.status],
      type: [this.data.type],
      hourlyRate: [this.data.hourlyRate, Validators.minLength(0)],
      dealerId: [this.data.dealerId],
      dealerName: [this.data.dealerName],
      country: [this.data.country],
      preferred: [this.data.preferred, Validators.required],
      yearsInBusiness:[this.data.yearsInBusiness],
      numberOfLicensedTech:[this.data.numberOfLicensedTech],
      numberOfHoists:[this.data.numberOfHoists],
      numberOfBays:[this.data.numberOfBays],
      retailDoorRate:[this.data.retailDoorRate],
      globalDoorRate:[this.data.globalDoorRate],
      afterMarketPartsDiscount:[this.data.afterMarketPartsDiscount],
      oemPartsDiscount:[this.data.oemPartsDiscount],
      general:[this.data.general],
      diesel:[this.data.diesel],
      airConditioning:[this.data.airConditioning],
      electronicDiagnosis:[this.data.electronicDiagnosis],
      electrical:[this.data.electrical],
      transmissionRebuild:[this.data.transmissionRebuild],
      differentialRebuild:[this.data.differentialRebuild],
      transferCaseRebuild:[this.data.transferCaseRebuild],
      transmissionRnR:[this.data.transmissionRnR],
      differentialRnR:[this.data.differentialRnR],
      transferCaseRnR:[this.data.transferCaseRnR],
      towingService:[this.data.towingService],
      otherWarrantyCompanies:[this.data.otherWarrantyCompanies],
      specialtyRepairs:[this.data.specialtyRepairs],
      specialtyEquipment:[this.data.specialtyEquipment],
      partsTime:[this.data.partsTime],
      labourTime:[this.data.labourTime],
      partDistance:[this.data.partDistance],
      labourDistance:[this.data.labourDistance],
      paymentType:[this.data.paymentType]

    });

    this.updatePostalCodeStateLabelMask()
    this.updateProvinceStateLabel()
    this.setFieldLabels()
    this.setPaymentType()
    this.setTowingService()
    this.setRepairCenterTime()
    this.dialogRef.backdropClick().subscribe(() => {
      this.cancel();
    })
    this.retrieveRepairCentreStatuses();
    this.retrieveRepairCentreTypes();
    this.getProvinceState();
  }

  onCountryChange(): void {
    this.repairCenterForm.get('postalCode').setValue('');
    this.updatePostalCodeStateLabelMask()
    this.updateProvinceStateLabel()
  }

  cancel() {
    this.dialogRef.close()
  }

  checkData() {
    this.isSubmitted = true;
    if(this.repairCenterForm.get('dealerId').invalid){
      this.repairCenterForm.get('dealerName').markAsTouched();
      this.repairCenterForm.get('dealerName').setValue(null)
      return
    }
    if (AppValidators.validateAll(this.repairCenterForm)) {
      if (this.isEdit) {
        this.repairCentreService.retrieve(this.originalRepairCenter.id).subscribe(data => {
          const obj = {
            name: data.name,
            contact: data.contact,
            contactExt: data.contactExt,
            address: data.address,
            city: data.city,
            province: data.province,
            postalCode: data.postalCode,
            phone: data.phone,
            fax: data.fax,
            website: data.website,
            email: data.email,
            status: data.status,
            type: data.type,
            hourlyRate: data.hourlyRate,
            dealerId: data.dealerId,
            dealerName: data.dealerName,
            country: data.country,
            preferred: data.preferred,
            yearsInBusiness: data.yearsInBusiness,
            numberOfLicensedTech: data.numberOfLicensedTech,
            numberOfHoists: data.numberOfHoists,
            numberOfBays: data.numberOfBays,
            retailDoorRate: data.retailDoorRate,
            globalDoorRate: data.globalDoorRate,
            afterMarketPartsDiscount: data.afterMarketPartsDiscount,
            oemPartsDiscount: data.oemPartsDiscount,
            general: data.general,
            diesel: data.diesel,
            airConditioning: data.airConditioning,
            electronicDiagnosis: data.electronicDiagnosis,
            electrical: data.electrical,
            transmissionRebuild: data.transmissionRebuild,
            differentialRebuild: data.differentialRebuild,
            transferCaseRebuild: data.transferCaseRebuild,
            transmissionRnR: data.transmissionRnR,
            differentialRnR: data.differentialRnR,
            transferCaseRnR: data.transferCaseRnR,
            towingService: data.towingService,
            otherWarrantyCompanies: data.otherWarrantyCompanies,
            specialtyRepairs: data.specialtyRepairs,
            specialtyEquipment: data.specialtyEquipment,
            partsTime: data.partsTime,
            labourTime: data.labourTime,
            partDistance: data.partDistance,
            labourDistance: data.labourDistance,
            paymentType: data.paymentType
          };
          if (Object.entries(this.repairCenterForm.value).toString() === Object.entries(obj).toString()) {
            //If data has not been modified don't save
            this.dialogRef.close();
          }
          else if (data.version == this.data.version) {
            this.blockUI.start();
            this.data.version = ++data.version;
            this.save();
          }
          else {
            let dialogRef = this.dialog.open(ConfirmDialogComponent, {
              panelClass: 'part-dialog',
              width: '400px',
              autoFocus: true,
              data: {
                message: "Data you wish to save has been expired. Do you wish to reload?",
                type: DialogType[0].toString()
              }
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                Object.assign(this.repairCenterForm.value, obj);
              }
              else {
                this.data = data;
                this.dialogRef.close({ data: this.data });
              }
            });
          }
        });
        catchError((error) => {
          if (error.error instanceof ErrorEvent) console.log(`Error: ${error.error.message}`)
          else console.log(`Error: ${error.message}`)
          this.toastr.error(environment.messages.apiError, "Unable to retrieve repair centre");
          return of([]);
        });
      }
      else{
        this.save() 
      }
    }
  }

  save() {
    this.isSubmitted = true;
    if (AppValidators.validateAll(this.repairCenterForm)) {    
      this.data = Object.assign(this.data, this.repairCenterForm.value);
      this.createNoteONRepairCenterUpdate(this.data, this.originalRepairCenter).subscribe(()=>{
        if (this.originalRepairCenter.address !== this.data.address ||
          this.originalRepairCenter.city !== this.data.city ||
          this.originalRepairCenter.province !== this.data.province ||
          this.originalRepairCenter.country !== this.data.country) {
  
          this.geocodeAddress(this.data).then(result => { this.addOrEditRepairCentre(result) });
        }
        else {
          this.addOrEditRepairCentre(this.data)
        }
      });
    }
  }

  filterDealers(repaireCentreText: string) {

    debounceTime(750);

    var loadingThis = this;
    let loadingTimer = setTimeout(() => {
      loadingThis.isLoading = true;
    }, 250);

    this.dealerAutoCompleteText = repaireCentreText;

    if (repaireCentreText.length > 0) {
      this.dealerService.dealerLookupByAlias(repaireCentreText)
        .subscribe(dealers => {
          if (dealers) {
            this.loadDealers(loadingTimer, dealers);
          }
        });
    }
    else {
      this.loadDealers(loadingTimer);
      this.ClearDealerValue();
    }
  }

  loadDealers(loadingTimer: any, dealers = []) {
    //if you filter when more than 0 char than a visual bug can occur if the user deletes all char.
    //The lookup will send request on the last char, then on 0 char the list will clear, then the service
    //responds and populates the list even when the input is empty
    //therfore only populate list if adjuster text is still grater than 0
    if (this.dealerAutoCompleteText.length > 0) {
      this.filteredDealers = dealers;
    }
    else {
      this.filteredDealers = [];
    }

    this.isLoading = false;
    clearTimeout(loadingTimer)
  }

  onDealerSelected(selected, event) {
    //ignores deselect trigger of previous selected
    if (event.isUserInput) {
      this.repairCenterForm.get('dealerId').setValue(selected ? selected.vendorId : null)
      this.repairCenterForm.get('dealerName').setValue(selected ? selected.alias : null)
    }
  }

  private updatePostalCodeStateLabelMask(): void {
    this.postalCodeStateLabel = this.isCanada ? 'Postal Code' : 'Zip Code'
    this.postalCodeStateMask = this.isCanada ? 'S0S 0S0' : '00000'
  }

  private updateProvinceStateLabel(): void {
    this.provinceStateLabel = this.isCanada ? 'Province' : 'State'
  }

  get isCanada(): boolean {
    return this.repairCenterForm.get('country').value === Country[Country.Canada]
  }

  geocodeAddress(repairCentre) {
    return new Promise(resolve => {
      this.geocoder = new google.maps.Geocoder();
      this.geocoder.geocode({
        'address': `${repairCentre.address}, ${repairCentre.city}, ${repairCentre.province}. ${repairCentre.country}`
      }, (results, status) => {
        if (status === 'OK') {
          repairCentre.latitude = results[0].geometry.location.lat();
          repairCentre.longitude = results[0].geometry.location.lng();

          resolve(repairCentre);
        };
      });

    });
  }

  addOrEditRepairCentre(repairCentre) {
    if (repairCentre.id) {
      this.repairCentreService.update(repairCentre).subscribe(res => {
          this.dialogRef.close({ data: repairCentre });
          this.blockUI.stop();    
          this.toastr.success("Repair Centre successfully updated. ", 'Create Repair Centre')
        },

        error=>{
          this.blockUI.stop();    
          this.dialogRef.close();
          this.toastr.error(environment.messages.apiError, 'Create Repair Centre')
        }
      )
    }
    else {
      this.repairCentreService.add(repairCentre).subscribe(res => {
          repairCentre.id = res;
          this.dialogRef.close({ data: repairCentre });
          this.toastr.success("Repair Centre successfully added.", 'Create Repair Centre');
        },
        error => {
          this.dialogRef.close();
          this.toastr.error(environment.messages.apiError, 'Create Repair Centre')
        }
      )
    }
  }

  retrieveRepairCentreStatuses() {
    this.repairCentreService.retrieveStatuses().subscribe(data => {
      this.repairCentreStatuses = data;
      this.repairCentreStatuses = this.repairCentreStatuses.filter(x => x.notInUse == false);
    },
    error => {
      this.toastr.error(environment.messages.apiError, "Unable to retrieve repair centre statuses");
    })
  }

  retrieveRepairCentreTypes() {
    this.repairCentreService.retrieveTypes().subscribe(data => {
      this.repairCentreTypes = data;
      this.repairCentreTypes = this.repairCentreTypes.filter(x => x.notInUse == false);
    },
    error => {
      this.toastr.error(environment.messages.apiError, "Unable to retrieve repair centre types");
    })
  }

  closeAutoComplete() {
    this.repairCentreAutoCompleteRef.closePanel();
  }

  ClearDealerValue(){
    this.repairCenterForm.get('dealerId').setValue(null)
    this.repairCenterForm.get('dealerName').setValue(null)
    
  }

  createNoteONRepairCenterUpdate(RepairCenterChanges:IRepairCentre, oldRepairCenter:IRepairCentre) : Observable<IRepairCentre>{
    if(this.originalRepairCenter.id != null){
      var RepairCenterProperty:String[] = Object.getOwnPropertyNames(RepairCenterChanges).filter(x=> {return x != 'version' && x != 'repairCenterNote' && x != 'ClaimID' && x != 'assignedAdjuster' && x != 'id' && x != 'dealerId' && x != 'latitude' && x != 'longitude' })
      var notes:String[] =[]
      RepairCenterProperty.forEach(x=>{
        if(RepairCenterChanges[x.toString()]!= oldRepairCenter[x.toString()]){
          if((RepairCenterChanges[x.toString()] == '' || RepairCenterChanges[x.toString()] == null )&& (oldRepairCenter[x.toString()] != '' || oldRepairCenter[x.toString()] != null)){
            var Removemessage= x == 'towingService' ? TowingService[oldRepairCenter[x.toString()]].toString() : oldRepairCenter[x.toString()];
            notes.push('Removed '+this.getLabelForField(x.toString()).concat(' Data : '+ Removemessage ));
          }
          else{
            var message = x == 'towingService' ? TowingService[oldRepairCenter[x.toString()]] +' to '+TowingService[RepairCenterChanges[x.toString()]] : oldRepairCenter[x.toString()] +' to '+ RepairCenterChanges[x.toString()] ;
            var message2 = x == 'towingService' ? TowingService[RepairCenterChanges[x.toString()]].toString() : RepairCenterChanges[x.toString()];
            notes.push('Changed '+ this.getLabelForField(x.toString()).concat( oldRepairCenter[x.toString()] != null  && oldRepairCenter[x.toString()]!=''? ' from '.concat(message):' to '.concat(message2)))
          }
        }
      })
   
      RepairCenterChanges.repairCenterNote = notes.join(', ') + '.';
    }
    else{
      RepairCenterChanges.assignedAdjuster = this.accountService.getUserId();
    }
    return of(RepairCenterChanges);
  }

  setPaymentType(){
    Object.keys(PaymentMethod).forEach(ele=>{
      if(isNaN(Number(ele)) === false) {
        let val : String =PaymentMethod[ele]
        this.paymentTypes.set(val.split('_').join(" "), Number.parseInt(ele))
      }
     })
  }

  setTowingService(){
    Object.keys(TowingService).forEach(ele=>{
      if(isNaN(Number(ele)) === false) {
        let val : String =TowingService[ele]
        this.towingServices.set(val.split('_').join(" "), Number.parseInt(ele))
      }
     })
  }

  setRepairCenterTime(){
    Object.keys(RepairTimes).forEach(ele=>{
      if(isNaN(Number(ele)) !== false) {
        let val : string = RepairTimes[ele]
        this.repairTimes.push(val);
      }
     })
  }

  setFieldLabels(){
    this.fieldLabel.set('name', 'Name');
    this.fieldLabel.set('contact', 'Contact');
    this.fieldLabel.set('contactExt', 'Contact-Ext');
    this.fieldLabel.set('address', 'Address');
    this.fieldLabel.set('city', 'City');
    this.fieldLabel.set('province', 'Province');
    this.fieldLabel.set('postalCode', 'Postal Code');
    this.fieldLabel.set('phone', 'Phone');
    this.fieldLabel.set('fax', 'Fax');
    this.fieldLabel.set('website', 'Website');
    this.fieldLabel.set('email', 'Email');
    this.fieldLabel.set('status', 'Status');
    this.fieldLabel.set('type', 'Type');
    this.fieldLabel.set('hourlyRate', 'Hourly Rate');
    this.fieldLabel.set('dealerName', 'Dealer');
    this.fieldLabel.set('preferred', 'Preferred');
    this.fieldLabel.set('yearsInBusiness', 'Year in business');
    this.fieldLabel.set('numberOfLicensedTech', 'Number of licensed tech');
    this.fieldLabel.set('numberOfHoists', 'Number of hoists');
    this.fieldLabel.set('numberOfBays', 'Number of bays');
    this.fieldLabel.set('retailDoorRate', 'Retail Door Rate');
    this.fieldLabel.set('globalDoorRate', 'Global Door Rate');
    this.fieldLabel.set('afterMarketPartsDiscount', 'After Market parts');
    this.fieldLabel.set('oemPartsDiscount', 'OEM Parts Discount');
    this.fieldLabel.set('general', 'General');
    this.fieldLabel.set('electrical', 'Electrical');
    this.fieldLabel.set('diesel', 'Diesel');
    this.fieldLabel.set('airConditioning', 'Air Conditioning');
    this.fieldLabel.set('electronicDiagnosis', 'Electronic Diagnosis');
    this.fieldLabel.set('transmissionRebuild', 'Transmission Rebuild');
    this.fieldLabel.set('differentialRebuild', 'Differential Rebuild');
    this.fieldLabel.set('transferCaseRebuild', 'Transfer Case Rebuild');
    this.fieldLabel.set('transmissionRnR', 'Transmission RnR');
    this.fieldLabel.set('differentialRnR', 'Differential RnR');
    this.fieldLabel.set('transferCaseRnR', 'Transfer Case RnR');
    this.fieldLabel.set('towingService', 'Towing Service');
    this.fieldLabel.set('otherWarrantyCompanies', 'Other Warranty Companies');
    this.fieldLabel.set('specialtyRepairs', 'Special Repairs');
    this.fieldLabel.set('specialtyEquipment', 'Special Equipment');
    this.fieldLabel.set('partsTime', 'Parts Time');
    this.fieldLabel.set('labourTime', 'Labour Time');
    this.fieldLabel.set('partDistance', 'Part Distance');
    this.fieldLabel.set('labourDistance', 'Labour Distance');
    this.fieldLabel.set('paymentType', 'Payment Type');

  }

  getLabelForField(field:string):string{
   if(this.fieldLabel.has(field)) return this.fieldLabel.get(field);
   else return field.substr(0,1).toUpperCase() + field.substr(1);
  }

  getProvinceState(): void {
    this.provStateService.currentStates.subscribe((response: ProvinceState[]) => {
      this.states = response.filter(proStat => proStat.CountryId === Country.UnitedStates);
      this.provinces = response.filter(proStat => proStat.CountryId === Country.Canada);
    });
  }

  get provincesOrStates() { return this.isCanada ? this.provinces : this.states; }

}
