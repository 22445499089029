<div class="change">
    <div *ngIf="isCoverage()" [ngSwitch]="alteration?.changeType">
        <div *ngSwitchCase=0>
            <div class="change-title">
                <div style="background-color: green">{{alteration.ChangeString}}</div>
                {{alteration.NewValue.coverageLabel}}
            </div>
            <div class="change-details">
                Distance Band: {{alteration.NewValue.distanceBand}},
                Amount: {{alteration.NewValue.amount | currency}}
            </div>
        </div>
        <div *ngSwitchCase=1>
            <div class="change-title">
                <div style="background-color: rgb(221, 221, 10)">{{alteration.ChangeString}}</div>
                {{alteration.NewValue.coverageLabel}}
            </div>
            <div class="change-details">
                <div>
                    <i class="nixed">&#9746;</i>
                    Distance Band {{alteration.NewValue.distanceBand}},
                    Amount {{alteration.OldValue.amount | currency}}
                </div>
                <div>
                    <i class="checked">&#9745;</i>
                    Distance Band: {{alteration.NewValue.distanceBand}},
                    Amount {{alteration.NewValue.amount | currency}}
                    <span style="color: yellow">
                        ({{alteration.NewValue.amount - alteration.OldValue.amount | currency}})
                    </span>
                </div>
            </div>
        </div>
        <div *ngSwitchCase=2>
            <div class="change-title">
                <div style="background-color: red">{{alteration.ChangeString}}</div>
                {{alteration.NewValue.coverageLabel}}
            </div>
        </div>
    </div>
    <div *ngIf="isDistribution()" [ngSwitch]="alteration?.changeType">
        <div *ngSwitchCase=0>
            <div class="change-title">
                <div style="background-color: green">{{alteration.ChangeString}}</div>
                {{alteration.Name}}
            </div>
            <div class="change-details">
                Address: {{alteration.NewValue.userRegistrationFG.address}}
                <br>
                City: {{alteration.NewValue.userRegistrationFG.city}}
                <br>
                Country: {{isCanada(alteration.NewValue.userRegistrationFG.country)?'Canada':'United States'}}
                <br>
                Postal / Zip Code: {{alteration.NewValue.userRegistrationFG.postalZipCode}}
                <br>
                <div *ngIf="isSin">
                    SIN: {{alteration.NewValue.userRegistrationFG.sin === '' || alteration.NewValue.userRegistrationFG.sin === null ? 'Not Provided': 'XXX XXX XXX'}}
                </div>
                <div *ngIf="!isSin">
                    BN: {{alteration.NewValue.userRegistrationFG.craNum === '' || alteration.NewValue.userRegistrationFG.sin === null ? 'Not Provided': alteration.NewValue.userRegistrationFG.craNum}}
                </div>
                <br>
                Distribution: {{alteration.NewValue.userRegistrationFG.currentDistribution}}%
                <br>
            </div>
        </div>
        <div *ngSwitchCase=1>
            <div class="change-title">
                <div style="background-color: rgb(221, 221, 10)">{{alteration.ChangeString}}</div>
                {{alteration.Name}}
            </div>
            <div *ngFor="let change of alteration.modificationMap | keyvalue" class="change-details">
                <div *ngIf="change.key !== 'id' && change.key !== 'recipientTypeId' && change.value[0] && change.value[1]">
                    <i class="nixed">&#9746;</i>
                    {{ getChangeKey(change, 'old') | titlecase }} {{getChangeValueOld(change)}}{{change.key === 'currentDistribution' ? '%' : ''}}
                </div>
                <div *ngIf="change.key !== 'id' && change.key !== 'recipientTypeId' && change.value[0] && change.value[1]">
                    <i class="checked">&#9745;</i>
                    {{ getChangeKey(change, 'new') | titlecase }} {{getChangeValueNew(change)}}{{change.key === 'currentDistribution' ? '%' : ''}}
                </div>
            </div>
        </div>
        <div *ngSwitchCase=2>
            <div class="change-title">
                <div style="background-color: red">{{alteration.ChangeString}}</div>
                {{alteration.Name}}
            </div>
        </div>
    </div>
</div>