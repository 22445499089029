import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src-private/environments/environment';

/*-- Interfaces --*/
import {
  IEazeeTrakFile, IEazeeTrakFileNotification, IEazeeTrakFileHistory, IEazeeTrakReason, IEazeeTrakResult
} from '../interfaces/eazeetrakfile.interface';
import { IUser } from '../../dealers/interfaces/user.interface';

/*-- Services --*/
import { RequestCacheService } from '../../../helpers/request-cache.service';
import { UtilsService } from '../../../services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class EazeeTrakService {

  constructor(private http: HttpClient) { }

  eazeeTrakFileCreate(eazeeTrakFile: IEazeeTrakFile) {
    const params = {
      eazeeTrakReasonId: eazeeTrakFile.eazeeTrakReasonId,
      eazeeTrakResultId: eazeeTrakFile.eazeeTrakResultId,
      vendorId: eazeeTrakFile.vendorId,
      pinDays: eazeeTrakFile.pinDays,
      contactId: eazeeTrakFile.contactId,
      contactName: eazeeTrakFile.contactName,
      eazeeTrakComment: eazeeTrakFile.eazeeTrakComment,
      notificationJson: eazeeTrakFile.notificationJson
    };

    return this.http.post(environment.resourceUrl + 'api/gwc/eazeetrakfile/create', params);
  }

  eazeeTrakFileRetrieve(eazeeTrakFileId: number, force: boolean = false): Observable<IEazeeTrakFile> {
    const params = {
      eazeeTrakFileId: eazeeTrakFileId
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<IEazeeTrakFile>(environment.resourceUrl + 'api/gwc/eazeetrakfile/retrieve',
      { headers: RequestCacheService.GetCacheHeaders(force), params: queryParams });
  }

  eazeeTrakFileHistoryRetrieve(eazeeTrakFileId: number, force: boolean = false):
    Observable<IEazeeTrakFileHistory[]> {
    const params = {
      eazeeTrakFileId: eazeeTrakFileId
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<IEazeeTrakFileHistory[]>(environment.resourceUrl + 'api/gwc/eazeetrakfilehistory/retrieve',
      { headers: RequestCacheService.GetCacheHeaders(force), params: queryParams });
  }

  eazeeTrakFileNotificationRetrieve(eazeeTrakFileId: number, force: boolean = false):
    Observable<IEazeeTrakFileNotification[]> {
    const params = {
      eazeeTrakFileId: eazeeTrakFileId
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<IEazeeTrakFileNotification[]>(environment.resourceUrl + 'api/gwc/eazeetrakfilenotification/retrieve',
      { headers: RequestCacheService.GetCacheHeaders(force), params: queryParams });
  }

  eazeeTrakFileUpdate(eazeeTrakFile: IEazeeTrakFile) {
    const params = {
      eazeeTrakFileId: eazeeTrakFile.eazeeTrakFileId,
      pinDays: eazeeTrakFile.pinDays,
      eazeeTrakComment: eazeeTrakFile.eazeeTrakComment,
      closeFile: eazeeTrakFile.closeFile,
      notificationJson: eazeeTrakFile.notificationJson
    };

    return this.http.post(environment.resourceUrl + 'api/gwc/eazeetrakfile/update', params);
  }

  eazeeTrakNotificationRetrieveByEazeeTrakResult(dealerId: number, resultId: number, force: boolean = false):
    Observable<IEazeeTrakFileNotification[]> {
    const params = {
      resultId: resultId,
      vendorId: dealerId
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<IEazeeTrakFileNotification[]>(environment.resourceUrl + 'api/gwc/eazeetraknotification/retrievebyeazeetrakresult',
      { headers: RequestCacheService.GetCacheHeaders(force), params: queryParams });
  }

  eazeeTrakReasonLookup(force: boolean = false): Observable<IEazeeTrakReason[]> {
    return this.http.get<IEazeeTrakReason[]>(environment.resourceUrl + 'api/gwc/eazeetrakreason/lookup',
      { headers: RequestCacheService.GetCacheHeaders(force) });
  }

  eazeeTrakResultRetrieve(force: boolean = false): Observable<IEazeeTrakResult[]> {
    return this.http.get<IEazeeTrakResult[]>(environment.resourceUrl + 'api/gwc/eazeetrakresult/retrieve',
      { headers: RequestCacheService.GetCacheHeaders(force) });
  }

  eazeeTrakSendByResponse(fileId: number, sender: string) {
    const params = {
      fileId: fileId,
      sender: sender
    };

    return this.http.post(environment.resourceUrl + 'api/not/service/eazeetraksendbyresponse', params);
  }

  eazeeTrakSendByResult(fileId: number, sendCopyOfMail: boolean, sender: string) {
    const params = {
      fileId: fileId,
      sendCopyOfMail: sendCopyOfMail,
      sender: sender
    };

    return this.http.post(environment.resourceUrl + 'api/not/service/eazeetraksendbyresult', params);
  }

  retrieveEazeeTrakFiles(isClosed: boolean): Observable<IEazeeTrakFile[]> {
    const params = {
      isClosed: isClosed
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<IEazeeTrakFile[]>(environment.resourceUrl + 'api/gwc/eazeetrakfile/retrievebystatus', { params: queryParams });
  }

  userRetrieveByDealer(dealerId: number, force: boolean = false): Observable<IUser[]> {
    const params = {
      vendorId: dealerId
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.get<IUser[]>(environment.resourceUrl + 'api/gwi/aspnetusers/retrievebyvendor',
      { headers: RequestCacheService.GetCacheHeaders(force), params: queryParams });
  }
}
