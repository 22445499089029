import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from '../services/utils.service';
import { environment } from 'src-private/environments/environment';
import { Notification } from '../interfaces/notification.interface';
import { Router } from '@angular/router';
import { ClaimsService } from '../areas/claims/services/claims.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient, private router: Router, private claimService : ClaimsService) { }

  list(isRead: boolean): Observable<Object> {
    const params = {
      isRead: isRead
    };
    const queryParams: HttpParams = UtilsService.buildQueryParams(params);
    return this.http.get<Notification>(environment.resourceUrl + 'api/gwc/Notification/list',
      { params: queryParams });
  }

  update(notification: Notification) {
    const params = {
      id: notification.id,
      userId: notification.userId,
      data: notification.data,
      title: notification.title,
      description: notification.description,
      isRead: notification.isRead,
      typeId: notification.typeId,
      action: notification.action
    }
    const queryParams: HttpParams = UtilsService.buildQueryParams(params);
    return this.http.post(environment.resourceUrl + 'api/gwc/Notification/update', params);
  }

  create(notification: Notification) {
    const params = {
      id: notification.id,
      userId: notification.userId,
      data: notification.data,
      title: notification.title,
      description: notification.description,
      isRead: notification.isRead,
      typeId: notification.typeId,
      action: notification.action
    }
    const queryParams: HttpParams = UtilsService.buildQueryParams(params);
    return this.http.post(environment.resourceUrl + 'api/gwc/Notification/create', params);
  }

  navigateFunction(param: any) {
    this.claimService.setSelectedClaim(null)
    var json = JSON.parse(param.data);
    if (param.action == "claim")
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['claims/' + json.claim.id + '/detail']));
    else if (param.action == "warranty") {
      // Check if the Warranty has a Claim associated with it
      if (json.claim == undefined) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['/warranty/' + json.warranty.id]));
      } else {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['claims/' + json.claim.id + '/warranty/' + json.warranty.id]));
      }
    }
  }
}
