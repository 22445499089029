<div [ngClass]="{content : !claimId, standalone : !claimId}">
  <div class="row">
    <div class="col-xl-7">
      <fieldset class="fieldset-form" *ngIf="warranty != undefined">
        <legend>Warranty <span *ngIf="warranty.deferred" style="margin-left: 2em;" class="badge badge-warning">Deferred</span>
          <span *ngIf="warranty.warrantyStatusLabel" class="badge" [style]="warrantyStatusHeaderColour">{{ warrantyStatusHeader }}</span>
          <span *ngIf="isTireAndRim" class="badge" [style]="warrantyActivationStatusHeaderColour">{{ warrantyActivationStatusHeader }}</span>
          <div *ngIf="CanUnexpireWarranty" class="float-right">
            <button class="btn custom-button float-right" type="button" (click)="warrantyUnExpire()">
              <fa-icon [icon]="faClock"></fa-icon> Unexpire
            </button>
          </div>
        </legend>
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-3">
              <div class="form-group-static">
                <label for="Warranty_ID">Warranty ID</label>
                <input id="Warranty_ID" type="text" readonly class="w-100" [ngModel]="(warranty.prefix) + (warranty.enterpriseId == null || warranty.enterpriseId == 0 ? warranty.id : warranty.enterpriseId)"
                  [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group-static">
                <label for="Warranty_Status">Warranty Status</label>
                <input id="Warranty_Status" type="text" readonly class="w-100" [ngModel]="status"
                  [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <div class="col-sm-7">
              <div class="form-group-static">
                <label for="Warranty_Desc">Description</label>
                <input id="Warranty_Desc" type="text" readonly class="w-100" [ngModel]="warranty.description"
                  [ngModelOptions]="{standalone: true}">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 col-xl-2">
              <div class="form-group-static">
                <label for="Warranty_Odometer">Original Odometer</label>
                <input id="Warranty_Odometer" type="text" readonly class="w-100" [ngModel]="warranty.vehicle !== null ? warranty.vehicle.originalKms: null"
                       [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <div class="col-md-4 col-xl-2">
              <div class="form-group-static">
                <label for="Warranty_Odometer_RD">Odometer Read Date</label>
                <input id="Warranty_Odometer_RD" type="text" readonly class="w-100"
                       [ngModel]="warranty.odometerReadDate | GWDateFormat" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <div class="col-md-4 col-xl-2">
              <div class="form-group-static">
                <label for="Warranty_SoldDate">Sold Date</label>
                <input id="Warranty_SoldDate" type="text" readonly class="w-100"
                       [ngModel]="warranty.vehicleSoldDate | GWDateFormat" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <div class="col-md-4 col-xl-2">
              <div class="form-group-static">
                <label for="Warranty_Expiry">Expiry Date</label>
                <input id="Warranty_Expiry" type="text" readonly class="w-100"
                       [ngModel]="warranty.expiryDate | GWDateFormat" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <div class="col-md-4 col-xl-2">
              <div class="form-group-static">
                <label for="Warranty_Deductible">Deductible</label>
                <input id="Warranty_Deductible" type="text" readonly class="w-100" [ngModel]="warranty.deductible | currency"
                       [ngModelOptions]="{standalone: true}" prefix="$" mask="separator.2" thousandSeparator=",">
              </div>
            </div>
            <div class="col-md-4 col-xl-2">
              <div class="form-group-static">
                <label for="Warranty_Pricing">Pricing</label>
                <input id="Warranty_Pricing" type="text" readonly class="w-100" [ngModel]="warranty.pricing | currency"
                       [ngModelOptions]="{standalone: true}" prefix="$" mask="separator.2" thousandSeparator=",">
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <app-warranty-options *ngIf="warranty" [warrantyId]="warrantyId" [columns]="warrantyOptionsTableColumns"></app-warranty-options>
    </div>

    <div class="col-xl-5">
      <app-warranty-details *ngIf="warrantyId && customerId" [customerId]="customerId" [claimEnterpriseId]="claimEnterpriseId" [warrantyId]="warrantyId"></app-warranty-details>
      <app-claims-table *ngIf="warranty" [warrantyId]="warrantyId" [customerId]="warranty.customerId" [columns]="warrantyClaimsTableColumns" [canAdd]="status == 'Active'"></app-claims-table>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <app-warranty-notes *ngIf="warranty" [warrantyId]="warrantyId" [columns]="warrantyNotesTableColumns"></app-warranty-notes>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <app-warranty-documents *ngIf="warranty" [warrantyId]="warrantyId" [customerId]="warranty.customerId" [columns]="warrantyDocumentsTableColumns"></app-warranty-documents>
    </div>
  </div>
</div>
<style>
  .rounded {
    width:auto;
    height:auto;
    text-justify: center;
    border-radius: 12px;
    display: inline-block;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 15px;
  }
</style>