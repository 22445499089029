import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src-private/environments/environment';
import { IClaimsNotes } from '../interfaces/claims-notes.interface';


@Injectable({
  providedIn: 'root'
})
export class ClaimsNotesService {

  constructor(private http: HttpClient) { }

  retrieveAll(claimId: number): Observable<IClaimsNotes[]> {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('claimId', claimId.toString());

    return this.http.get<IClaimsNotes[]>(`${environment.resourceUrl}api/gwc/claim/notes`, { params: httpParams });
  }

  add(note: IClaimsNotes): Observable<IClaimsNotes> {
    let httpParams = new HttpParams();

    Object.keys(note).forEach(function (key) {
      let value = note[key];
      if (key == "createdDate" && note["id"] == 0) {
        value = note[key].toISOString();
      }
      httpParams = httpParams.append(key, note[key]);
    });

    return this.http.post<IClaimsNotes>(`${environment.resourceUrl}api/gwc/claim/note`, httpParams);
  }
}
