<div class="container-fluid">
  <div class="row">
    <div class="col-md-4 col-xl-6">

      <fieldset class="transparent"
            *ngIf="bp > 2 || showAffiliates">
        <legend>Affiliates</legend>
        <app-affiliates [dealer]="dealer"></app-affiliates>
      </fieldset>

    </div>
    <div class="col-md-8 col-xl-6">

      <fieldset class="transparent"
            *ngIf="bp > 2 || showContacts">
        <legend>Contacts</legend>
        <app-contacts [dealer]="dealer"></app-contacts>
      </fieldset>

      <fieldset class="transparent"
            *ngIf="bp > 2 || showNotes">
        <legend>Notes</legend>
        <app-notes [dealer]="dealer"></app-notes>
      </fieldset>

    </div>
  </div>
</div>
