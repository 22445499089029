import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { ClaimsService } from "../services/claims.service";

@Injectable({
    providedIn: 'root'
})
export class claimIdResloverService implements Resolve<number>{
    constructor(private claimService: ClaimsService, private activeRoutes:ActivatedRoute) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<number> {
        if(route.firstChild){
            return route.firstChild.params.enterpriseId != undefined ? this.claimService.retrieveClaimId(parseInt(route.firstChild.params.enterpriseId)) : of(null)
        }
        else{
            return route.params.enterpriseId != undefined ? this.claimService.retrieveClaimId(parseInt(route.params.enterpriseId)) : of(null)
        }

    }

}