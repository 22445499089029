import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { DealerStats } from 'src-private/app/areas/dealers/models/dealer-stats.model';
import { DealersService } from 'src-private/app/areas/dealers/services/dealers.service';
import { environment } from 'src-private/environments/environment';

@Component({
  selector: 'app-dealer-stats',
  templateUrl: './dealer-stats.component.html',
  styleUrls: ['./dealer-stats.component.scss']
})
export class DealerStatsComponent implements OnInit {
  @Input() vendorId$: BehaviorSubject<number>;
  public dealerStats:DealerStats
  
  constructor(private dealerService: DealersService, private toastr:ToastrService) { }

  ngOnInit(): void {
    this.vendorId$.asObservable().subscribe((vendorId)=>{
      this.dealerStats = new DealerStats();
      if (vendorId){
        this.retrieveDealerStats(vendorId)
      }
    })
  }

  retrieveDealerStats(vendorId: number) {
    this.dealerService.retrieveDealerStats(vendorId).subscribe((dealerStat) => {
      if (dealerStat) {
        this.dealerStats = new DealerStats(dealerStat)
      }
    }, (error) => {
      this.toastr.error(environment.messages.apiError, "Retrieve Dealer Stats")
    })
  }

  update(): void {
    this.vendorId$.asObservable().subscribe((vendorId) => {
      if (vendorId) {
        this.retrieveDealerStats(vendorId)
      }
    });
  }
}
