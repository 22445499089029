import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { faSave, faWindowClose } from '@fortawesome/pro-solid-svg-icons';
import { DocumentTypes } from '../../interfaces/document';
import { finalize, Observable, Subject, takeUntil } from 'rxjs';
import { CustomerService } from '../../services/customer.service';
import { WarrantyService } from '../../services/warranty.service';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs';
import { ClaimsService } from '../../services/claims.service';
import { DocumentService } from '../../services/document.service';
import { ToastrService } from "ngx-toastr";
import { environment } from '../../../../../environments/environment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-document-move-dialog',
  templateUrl: './document-move-dialog.component.html',
  styleUrls: ['./document-move-dialog.component.scss']
})
export class DocumentMoveDialogComponent implements OnInit {

  public faSave = faSave;
  public faWindowClose = faWindowClose;

  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

  @BlockUI() blockUI: NgBlockUI;

  private ngUnsubscribe: Subject<any> = new Subject();
  public documentMoveForm: UntypedFormGroup;
  public items: Map<DocumentTypes, any[]> = new Map<DocumentTypes, any[]>();
  public selection: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private customerService: CustomerService,
    private warrantyService: WarrantyService,
    private documentService: DocumentService,
    private claimsService: ClaimsService,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<DocumentMoveDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: any
  ) {}

  ngOnInit(): void {
    this.documentMoveForm = this.formBuilder.group({
      documentId: ['', Validators.required],
      documentType: ['', Validators.required],
      contextId: [''],
    });

    const observables$: Observable<any>[] = [];

    const items = new Map<DocumentTypes, any[]>();

    this.data.documentTypeMap.forEach((value: Array<number>, key: DocumentTypes) => {
      items.set(key, []);
      value.forEach(id => {
        observables$.push(this.resolver(key, id)
          .pipe(tap(result => {
            if (result) {
              items.get(key).push(result);
            }
        })));
      });
    });

    this.blockUI.start();

    forkJoin(observables$)
      .pipe(finalize(() => this.blockUI.stop()))
      .subscribe(() => {
        this.items = items;
    });
  }

  private resolver(documentType: DocumentTypes, id: number): Observable<any> {
      switch (+documentType) {
        case DocumentTypes.Customer:
          return this.customerService.retrieve(id);
        case DocumentTypes.Warranty:
          return this.warrantyService.retrieve(id);
        case DocumentTypes.Claim:
          return this.claimsService.retrieve(id);
        default:
          return new Observable<any>();
      }
  }

  private contextId(documentType: DocumentTypes, item: any): number {
    switch (+documentType) {
      case DocumentTypes.Customer:
        return item.customerId;
      default:
        return item.id;
    }
  }

  display(documentType: DocumentType, item: any): String {
      switch (+documentType) {
        case DocumentTypes.Customer:
          return `To customer: ${item.firstName} ${item.lastName} (${item.customerId})` 
        case DocumentTypes.Warranty:
          return `To warranty: ${item.id}` 
        case DocumentTypes.Claim:
          return `To claim: ${item.id}` 
        default:
          return "";
      }
  }

  header(documentType: number): String {
    return DocumentTypes[documentType];
  }

  onSelection(documentType: DocumentTypes, item: any): void {
    this.selection = {
      id: this.contextId(documentType, item),
      documentType: documentType
    }
  }

  submit(): void {
    const selection = this.selection;
    if (selection) {

      this.blockUI.start();

      this.documentService
        .move(this.data.documentId, this.selection.documentType, this.selection.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .pipe(finalize(() => this.blockUI.stop()))
        .subscribe(() => {
          this.toastr.success(
            "Document moved successfully. ",
            "Move Document"
          );

          this.dialogRef.close({
            id: selection.id,
            documentType: selection.documentType
          });
        },
        error => {
          this.toastr.error(environment.messages.apiError, "Unable to move document");
        });
    }
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
