import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IClaimAudit } from '../interfaces/claim-audit';

@Injectable({
  providedIn: 'root'
})
export class ClaimsAuditService {
  constructor(private http: HttpClient) { }

  update(claimAudit: IClaimAudit): Observable<IClaimAudit> {
    return this.http.put<IClaimAudit>(environment.resourceUrl + 'api/gwc/claim/audit/update', claimAudit);
  }
}
