import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/*-- Components --*/
import { TableAdapterComponent } from '../shared/table-adapter/table-adapter.component';

/*-- Directives --*/
import { AvatarDirective } from '../directives/avatar.directive';

/*-- Modules --*/
import { MaterialModule } from './material.module';

/*-- Third Party --*/
import { ToastrModule } from 'ngx-toastr';
import { HttpPipe } from '../http.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CustomDatePipe } from '../pipes/custom-date.pipe';
import { FrameworkComponent } from '../shared/framework/framework.component';
import { FrameworkHiddenComponent } from '../shared/framework/framework-hidden/framework-hidden.component';
import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';
import { WarrantyListComponent } from '../shared/warranty-list/warranty-list.component';
import { RouterModule } from '@angular/router';
import { ComponentAuthorizationDirective } from '../directives/component-authorization.directive';
import { DealerStatsComponent } from '../shared/dealer-stats/dealer-stats.component';
import { CustomDateTimePipe } from '../pipes/custom-datetime.pipe';

@NgModule({
    declarations: [
        AvatarDirective,
        HttpPipe,
        TableAdapterComponent,
        WarrantyListComponent,
        CustomDatePipe,
        CustomDateTimePipe,
        FrameworkComponent,
        FrameworkHiddenComponent,
        ConfirmDialogComponent,
        ComponentAuthorizationDirective,
        DealerStatsComponent
    ],
    exports: [
        AvatarDirective,
        HttpPipe,
        TableAdapterComponent,
        WarrantyListComponent,
        CustomDatePipe,
        CustomDateTimePipe,
        FrameworkComponent,
        FrameworkHiddenComponent,
        ComponentAuthorizationDirective,
        DealerStatsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ToastrModule.forRoot(),
        FontAwesomeModule,
        RouterModule
    ]
})
export class SharedModule { }
