import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule, LOCALE_ID} from '@angular/core';
import localeFr from '@angular/common/locales/fr'
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DatePipe, registerLocaleData} from '@angular/common'

import {routing} from './app.routing';

/*-- Components - Dynamic --*/
import {DynamicComponent} from './shared/dynamic/dynamic.component';
import {OmniSearchComponent} from './areas/actionbar/omni-search/omni-search.component';
import {QuickAddComponent} from './areas/actionbar/quick-add/quick-add.component';
import {NotificationComponent} from './areas/actionbar/notification/notification.component';

/*-- Components - Primary --*/
import {AppComponent} from './app.component';

/*-- Helpers --*/

/*-- Modules --*/
import {DealersModule} from './areas/dealers/dealers.module';
import {EazeetrakModule} from './areas/eazeetrak/eazeetrak.module';
import {MigrationModule} from './areas/migration/migration.module';
import {ReportsModule} from './areas/reports/reports.module';
import {SharedModule} from './modules/shared.module';
import {ClaimsModule} from './areas/claims/claims.module';


/*-- Services --*/
import {AvatarService} from './services/avatar.service';
import {DynamicComponentService} from './services/dynamic-component.service';
import {WindowRef} from './services/window.service';

import {BlockUIModule} from 'ng-block-ui';
import {LaddaModule} from 'angular2-ladda';
import {MaterialModule} from './modules/material.module';
import {SidebarModule} from 'ng-sidebar';
import {RequestCacheService} from './helpers/request-cache.service';
import {CacheInterceptor} from './helpers/cache-interceptor';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {APP_CONFIG, APP_DI_CONFIG} from './app.config';
import {LocaleService} from './services/locale.service';
import {APP_DI_FRAMEWORK, APP_FRAMEWORK} from './app.framework';
import {SpinnerComponent} from './shared/spinner/spinner.component';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {claimIdResloverService} from './areas/claims/resolver service/claimId-reslover.service';
import {AbstractSecurityStorage, AuthInterceptor, AuthModule, LogLevel} from "angular-auth-oidc-client";
import {CookieService} from 'ngx-cookie-service';
import {AuthStorage} from './services/auth-storage.service';
import {EventsService} from './services/events.service';
import {LogoutComponent} from "./logout/logout.component";
import {UnauthorizedComponent} from "./unauthorized/unauthorized.component";
import {CallbackComponent} from "./callback/callback.component";
import {AuthConfigModule} from './auth/auth-config.module';


@NgModule({
  declarations: [
    AppComponent,
    DynamicComponent,
    OmniSearchComponent,
    QuickAddComponent,
    NotificationComponent,
    SpinnerComponent,
    LogoutComponent,
    UnauthorizedComponent,
    CallbackComponent
  ],
  imports: [
    BlockUIModule.forRoot({
      template: SpinnerComponent
    }),
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
    DealersModule,
    EazeetrakModule,
    FormsModule,
    HttpClientModule,
    LaddaModule.forRoot({
      style: 'zoom-in'
    }),
    MaterialModule,
    MigrationModule,
    ReactiveFormsModule,
    ReportsModule,
    SidebarModule,
    SharedModule,
    ClaimsModule,
    routing,
    FontAwesomeModule,
    SharedModule,
    MatSlideToggleModule,
    AuthConfigModule
  ],
  providers: [
    AvatarService,
    DynamicComponentService,
    RequestCacheService,
    WindowRef,
    DatePipe,
    claimIdResloverService,
    CookieService,
    EventsService,
    ClaimsModule,
    {provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: APP_CONFIG, useValue: APP_DI_CONFIG},
    {provide: APP_FRAMEWORK, useValue: APP_DI_FRAMEWORK},
    {provide: AbstractSecurityStorage, useClass: AuthStorage},
    {
      provide: LOCALE_ID, useFactory: (localeService: LocaleService) => {
        //console.log('locale ID', localeService.getLanguage());
        return localeService.getLanguage();
      },
      deps: [LocaleService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

registerLocaleData(localeFr, 'fr');
