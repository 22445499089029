import { Component, OnChanges, Input, SimpleChange, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

/*-- Interfaces --*/
import { IDealer, IDealerAffiliates } from '../../../interfaces/dealer.interface';

/*-- Services --*/
import { DealersService } from '../../../services/dealers.service';
import { DealersSharedService } from '../../../services/dealers-shared.service';

/*-- Third Party --*/
import { BlockUI, NgBlockUI } from 'ng-block-ui';


@Component({
  selector: 'app-affiliates',
  templateUrl: './affiliates.component.html',
  styleUrls: ['./affiliates.component.scss']
})
export class AffiliatesComponent implements OnChanges {
  @BlockUI() blockUI: NgBlockUI;

  @Input() dealer: IDealer;

  private dealerId: number;

  public affiliates: IDealerAffiliates[] = [];
  public dealerGroup: string;

  constructor(
    private dealersService: DealersService,
    private dealersSharedService: DealersSharedService,
    private router: Router,
  ) { }

  //#region - Lifecycle
  ngOnChanges(changes: SimpleChanges): void {
    const dealer: SimpleChange = changes.dealer;

    if (dealer && dealer.currentValue) {
      this.retrieveAffiliates(dealer.currentValue);
      this.setDealer(dealer.currentValue);
    }
  }
  //#endregion

  //#region - Private Methods
  private setDealer(dealer: IDealer): void {
    this.dealerGroup = dealer.vendorGroup;
    this.dealerId = dealer.vendorId;
  }
  //#endregion

  //#region - API Methods
  retrieveAffiliates(dealer: IDealer): void {
    if (dealer.vendorGroupId) {
      this.blockUI.start();

      this.dealersService.dealerRetrieveAffiliates(dealer.vendorGroupId, true).subscribe(
        response => {
          const data: IDealerAffiliates[] = response;

          this.affiliates = data;

          this.blockUI.stop();
        }
      );
    } else {
      this.affiliates = [];
    }
  }
  //#endregion

  //#region - Affiliate Hyper Link

  redirectToAffliatedDealer(id){
    this.router.navigateByUrl('/', {
      skipLocationChange:true
    }).then(()=>this.router.navigate(['/dealers', {'dealerId': id}, 'other']));
  }
  //#endregion
}
