import { Component, Input } from '@angular/core';
import { DealerPrincipal } from 'src-private/app/areas/dealers/models/bonus-bucks.Models';

@Component({
  selector: 'app-dealer-authorization',
  templateUrl: './dealer-authorization.component.html',
  styleUrls: ['./dealer-authorization.component.scss']
})
export class DealerAuthorizationComponent {
  @Input() dealerPrincipal: DealerPrincipal;

  constructor() { }
}
