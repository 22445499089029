export enum DealerStatus {
  Active = 1,
  Cancelled = 2,
  New = 3,
  OnHold = 4,
  OOB = 5,
  Potential = 6,
  Registered = 7,
  OnHoldNonPayment = 8
}
