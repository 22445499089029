import { Component, EventEmitter, Output } from '@angular/core';

/*-- Enums --*/
import { FormState } from '../../../../enums/form-state.enum';

/*-- Interfaces --*/
import { IUser } from '../../../dealers/interfaces/user.interface';

@Component({
  selector: 'app-file-form',
  templateUrl: './file-form.component.html',
  styleUrls: ['./file-form.component.scss']
})
export class FileFormComponent {
  @Output() modalResponse: EventEmitter<boolean> = new EventEmitter<boolean>();

  private dealer: string;
  private dealerId: number;

  public contact: IUser;
  public eazeeTrakFileId: number;
  public formState: FormState;
  public selectedTabIndex: number;

  constructor() { }

  onButtonClick(event) {
    this.modalResponse.emit(event);
  }

  onContactCreate(event: IUser): void {
    this.contact = event;
  }

  onSelectedIndexChange(event) {
    this.selectedTabIndex = event;
  }
}
