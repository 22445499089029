import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

/*-- Helpers --*/
import { RouteParamsHelper } from '../../../../helpers/route-params-helper';

/*-- Interfaces --*/
import { IDealer } from '../../interfaces/dealer.interface';

/*-- Services --*/
import { BreakpointService } from '../../../../services/breakpoint.service';
import { DealersSharedService } from '../../services/dealers-shared.service';

@Component({
  selector: 'app-notes-contacts',
  templateUrl: './notes-contacts.component.html',
  styleUrls: ['./notes-contacts.component.scss'],
})
export class NotesContactsComponent implements OnInit, OnDestroy {
  private static routeParamsHelper: RouteParamsHelper;

  private dealer: IDealer;

  public bp: number;
  public showAffiliates: boolean;
  public showContacts: boolean;
  public showNotes: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private breakpointService: BreakpointService,
    private dealersSharedService: DealersSharedService,
    private routeParamsHelper: RouteParamsHelper,
    private router: Router
  ) {
    this.activatedRoute.parent.queryParams.subscribe(queryParams => {
      if (Object.keys(queryParams).length) {
        const component = queryParams['c'];

        switch (component) {
          case 'affiliates':
            this.showAffiliates = true;
            this.showContacts = false;
            this.showNotes = false;

            break;
          case 'contacts':
            this.showAffiliates = false;
            this.showContacts = true;
            this.showNotes = false;

            break;
          case 'notes':
            this.showAffiliates = false;
            this.showContacts = false;
            this.showNotes = true;

            break;
        }
      } else {
        const queryParams: Params = { c: 'affiliates' };

        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: queryParams,
          queryParamsHandling: 'merge'
        });
      }
    });

    breakpointService.bp.subscribe(x => this.bp = x);

    this.dealersSharedService.dealer.subscribe(dealer => {
      if (dealer) {
        this.dealer = dealer;
      }
    });

    if (!NotesContactsComponent.routeParamsHelper) {
      NotesContactsComponent.routeParamsHelper = routeParamsHelper;
    }
  }

  //#region - Lifecycle
  ngOnDestroy(): void {
    NotesContactsComponent.routeParamsHelper.save(this.activatedRoute);
  }

  ngOnInit() {
    if (!this.bp) {
      this.bp = this.breakpointService.getBreakpoint();
    }

    NotesContactsComponent.routeParamsHelper.restore(this.activatedRoute);
  }
  //#endregion
}
