import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { IWarranty } from '../../interfaces/warranty.interface';
import { WarrantyService } from '../../services/warranty.service';
import { ActivatedRoute } from '@angular/router';
import { IClaim } from '../../interfaces/claim';
import { ClaimsService } from '../../services/claims.service';
import { CustomerService } from '../../services/customer.service';
import { ICustomer } from '../../interfaces/customer.interface';
import { Observable, of, Subject } from 'rxjs';
import { CellBuilder } from 'src-private/app/shared/table-adapter/cell-builder/cell-builder';
import { CellActions, CellType } from 'src-private/app/shared/table-adapter/cell-builder/cell-type';
import { Country } from 'src-private/app/enums/bonus-bucks.enums';
import { FrameworkComponent } from 'src-private/app/shared/framework/framework.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MaintenanceAdd, MaintenanceAddInput } from './maintenance-add/maintenance-add.component';
import { AccountService } from 'src-private/app/areas/account/services/account.service';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src-private/environments/environment';
import { MaintenanceScheduleComponent } from '../maintenance-schedule/maintenance-schedule.component';

const UnknownTerm = "unknown";
const ThreeMonthTerm = "3 months";
type MaintenanceTerms = typeof ThreeMonthTerm | typeof UnknownTerm

interface IMaintenanceTerm {
  days: number
  kms: number
}

type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U;
};

const CountryTerms: EnumDictionary<Country, EnumDictionary<MaintenanceTerms, IMaintenanceTerm>> = {
  [Country.Canada]: {

    [ThreeMonthTerm]: {
      days: 120,
      kms: 7000
    } as IMaintenanceTerm,

    [UnknownTerm]: {
      days: 210,
      kms: 11000
    } as IMaintenanceTerm

  },
  [Country.UnitedStates]: {

    [ThreeMonthTerm]: {
      days: 120,
      kms: 3600
    } as IMaintenanceTerm,

    [UnknownTerm]: {
      days: 210,
      kms: 6600
    } as IMaintenanceTerm

  }
};

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent extends FrameworkComponent implements OnInit, AfterViewInit {

  public faPlus = faPlus;

  public claim: IClaim;
  public warranty$: Observable<IWarranty>;
  public customer$: Observable<ICustomer>;
  public warrantyModel: IWarranty;
  public claimId: number;
  public claimDate: Date;
  public enterpriseId: number;
  public isAdjuster: Boolean;
  public currentDate: Date
  @BlockUI() blockUI: NgBlockUI;
private ngUnsubscribe: Subject<any> = new Subject();

  @ViewChild(MaintenanceScheduleComponent) maintScheduleComponent: MaintenanceScheduleComponent;
  public maintScheduleTableColumns: CellBuilder[]

  constructor(
    private route: ActivatedRoute,
    private claimService: ClaimsService,
    private warrantyService: WarrantyService,
    private customerService: CustomerService,
    public dialog: MatDialog,
    private accountService: AccountService,
    private toastr: ToastrService,
    private titleService: Title
  ) {
    super();
    this.claimId = this.route.snapshot.data['claimId']
  }

  ngOnInit() {
    this.titleService.setTitle("Maintenance");
    if (this.claimId) {
      this.claimService.retrieve(this.claimId).subscribe(claim => {
        this.claim = claim;
        this.enterpriseId = claim.enterpriseId
        this.warranty$ = this.warrantyService.retrieve(claim.warrantyId);
        this.customer$ = this.customerService.retrieve(claim.customerId);
        this.claimDate = claim.claimsDateEntered;
        this.currentDate = claim.claimsDateEntered
        this.warranty$.subscribe(warranty => {
          this.warrantyModel = warranty
        })
      },
      error => {
        this.toastr.error(environment.messages.apiError, "Unable to retrieve claim");
      });
    }

    this.isAdjuster = this.accountService.isAdjuster() || this.accountService.isClaimsManager() || this.accountService.isAssistantClaimsManager();
    this.buildMaintScheduleTableColumns();
  }

  buildMaintScheduleTableColumns() {
    this.maintScheduleTableColumns = [
      new CellBuilder("Date of Maintenance", "date", CellType.date),
      new CellBuilder("KMs at this Date", "kms", CellType.number),
      new CellBuilder("Maintenance Term", "term", CellType.text),
      new CellBuilder("Max Term KMs", "maxTermKms", CellType.text),
      new CellBuilder("User Entering Maintenance Schedule", "user", CellType.text),
      new CellBuilder("Due Date", "dueDate", CellType.date),
      new CellBuilder("Max KMs", "maxKms", CellType.number),
      new CellBuilder("Action", "action", CellType.actions, '', CellActions.Remove)
    ];
  }

  addMaintenance() {
    var maint = new MaintenanceAddInput();
    maint.warrantyId = this.warrantyModel.id;
    maint.claimId = this.claimId;
    maint.applicationId = this.warrantyModel.applicationId;
    maint.vehicleId = this.warrantyModel.vehicle.id;

    const addMaintenanceDialogRef = this.dialog.open(MaintenanceAdd, {
      width: "400px",
      data: maint,
      autoFocus: false
    });

    addMaintenanceDialogRef.afterClosed()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(res => {
      this.maintScheduleComponent.refreshMaintenance();
    });
  }

  ngAfterViewInit(){
    super.build("MaintenanceComponent", 'maintenance-component')
  }

  public get IsClaimClosed(): boolean{
    return this.claimService.getSelectedClaim?.IsClaimClosed
  }

  public get CanEditClosedClaim(): boolean{
    return this.accountService.isClaimsManager();
  }

  canRenderActions = (actions: CellActions): boolean => {
    if ((actions & CellActions.Remove) === CellActions.Remove) {
      return this.IsClaimClosed ? this.CanEditClosedClaim : this.isAdjuster.valueOf();
    }
    return false;
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
