import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { IDocument, DocumentTypes } from '../interfaces/document';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient) { }

  move(documentId: number, documentType: DocumentTypes, id: number): Observable<IDocument> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('documentId', documentId);
    httpParams = httpParams.append('documentType', documentType);
    httpParams = httpParams.append('id', id);

    return this.http.post<IDocument>(`${environment.resourceUrl}api/gwc/document/move`, httpParams);
  }

  openBlob(res: HttpResponse<Blob>, filename: string) {
    var contentType = res.headers.get('content-type');
    const file = new File([res.body], filename, { type: contentType })
    let objectUrl = window.URL.createObjectURL(file);

    const a = document.createElement('a');
    a.href = objectUrl;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    setTimeout(() => {
      window.URL.revokeObjectURL(objectUrl);
    }, 0);
  }
}
