import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators, UntypedFormControl } from '@angular/forms';
import { CoveragePackage, BonusBucksValue, BonusBucksAuthorization, DistributionUser, UserDetails, DealerPrincipal } from '../models/bonus-bucks.Models';
import { distributionsValidator } from '../directives/distributions-validator';
import { Dealer } from '../models/dealer.model';
import { BBAmountService } from './bb-selected-amount.service';
import { sinValidator } from '../directives/sin-validator';
import { BonusBucksValueType, CoverageType, DistributionType, RecipientType, UserType, Country, PaymentMethod } from 'src-private/app/enums/bonus-bucks.enums';

@Injectable()
export class BbFormManagementService {
  public originaldistributionForm: Object;
  public distributionForm: UntypedFormGroup;
  public dealerPrincipal: DealerPrincipal;
  public originalCoveragePackages: CoveragePackage[];
  public currentCoveragePackages: CoveragePackage[];
  private hundredBenchmarks = ['Test Drive', '1 Star Powertrain Drive', '2 Star Advantage Drive', 'Titanium 2 Star'];
  private fiftyBenchmarks = ['4 Star Ultimate Drive', '5 Star Ultimate Wrap', 'Ultimate Factory Top Up'];
  private dealer: Dealer;
  private selectedBBAmount: BonusBucksValue;
  private previousBBA: BonusBucksAuthorization;
  private distributionUserForms: Map<String, Map<UserType, UntypedFormGroup>>;


  readonly holdingCompany = {
    LegalName: 'Holding Company',
    UserType: UserType.HoldingCompany
  };
  readonly dealerGroup = {
    LegalName: 'Dealer Group',
    UserType: UserType.DealerGroup
  };

  constructor(private fb: UntypedFormBuilder, private bbAmountService: BBAmountService) {
    this.distributionUserForms = new Map<String, Map<UserType, UntypedFormGroup>>();
    this.distributionForm = this.fb.group({
      distributionTypeId: [DistributionType.Unselected]
    },
    {
      validators: distributionsValidator
    });

    bbAmountService.currentBonusBuckValue.subscribe(newVal => this.onBonusBuckChange(newVal));
  }

  public setDealer(dealer: Dealer) {
    this.dealer = dealer;
    this.distributionForm = this.fb.group({
      distributionTypeId: [DistributionType.Unselected]
    }, { validators: distributionsValidator });
    this.dealerPrincipal = new DealerPrincipal('', '', '');
    this.bbAmountService.setBonusBuckValue(null);
    this.selectedBBAmount = null;
    this.previousBBA = null;
    this.distributionForm.setControl('distributions', new UntypedFormArray(this.createDefaultDistributions()));
  }

  getUserRegistrationForm(id: string, userType: UserType): UntypedFormGroup {
    const userForms = this.distributionUserForms.get(id);
    if (userForms) {
      let userForm = userForms.get(userType);
      if (!userForm) {
        userForm = this.createUserTypeForm(userType);
        if (userForm) {
          userForms.set(userType, userForm);
        }
      }
      return userForm;
    }
  }

  createUserTypeForm(userType: UserType): UntypedFormGroup {
    switch (userType) {
     case UserType.Dealer:
        return this.createDealerDistributionUserForm();
      case UserType.HoldingCompany:
        return this.createHoldingCompanyDistributionUserForm();
      case UserType.DealerGroup:
        return this.createDealerGroupDistributionUserForm();
    }
    return null;
  }

  public addCoverages(coverages: CoveragePackage[]) {
    this.originalCoveragePackages = coverages;
    this.currentCoveragePackages = deepCopy(this.originalCoveragePackages);
  }

  private onBonusBuckChange(selectedBuck: BonusBucksValue) {
    if (selectedBuck && this.currentCoveragePackages && (selectedBuck !== this.selectedBBAmount)) {
      if (selectedBuck.amount !== null && selectedBuck.amount !== BonusBucksValueType.Custom) {
        this.patchAllCoverageValues(selectedBuck.amount);
      } else if (selectedBuck.amount === BonusBucksValueType.Custom) {
        this.patchAllCoverageValues(BonusBucksValueType.Custom);
      } else {
        this.patchAllCoverageValues(BonusBucksValueType.CompanyBenchmark);
      }
    }
    this.selectedBBAmount = selectedBuck;
  }

  public setPreviousBonusBuck(bba: BonusBucksAuthorization) {
    if (bba) {
      this.previousBBA = bba;

      if (this.bbAmountService.allBBVals) {
        const newBBAmount = this.bbAmountService.allBBVals.find(v => bba.BonusBucksAmount === v.amount);
        this.selectedBBAmount = newBBAmount;
        this.bbAmountService.setBonusBuckValue(newBBAmount);
      }
      if ((<UntypedFormArray>this.distributionForm.get('distributions')).length > 3) {
        this.patchDistributions();
      }
    }
  }

  public setDistributionUsers(distUsers: DistributionUser[]) {
    const distributions = this.distributionForm.get('distributions') as UntypedFormArray;
    distUsers.forEach((distroUser) => {
      if (distroUser.Title === 'Dealer Principal') {
        this.dealerPrincipal = new DealerPrincipal(distroUser.FirstName, distroUser.LastName, distroUser.Email);
      }

      const form = this.createDistributionGroup(distroUser);
      distributions.push(form);

      const forms = new Map<UserType, UntypedFormGroup>();
      forms.set(UserType.User, <UntypedFormGroup>form.get('userRegistrationFG'));
      this.distributionUserForms.set(distroUser.Id, forms);
    });

    if (this.previousBBA) {
      this.patchDistributions();
    } else {
      this.originaldistributionForm = this.distributionForm.getRawValue();
    }
  }

  public createDealerDistributionUserForm(disable: boolean = false): UntypedFormGroup {
    const dealerForm = { 
      LegalName: this.dealer.legalName,
      UserType: UserType.Dealer
    }
    return this.createFormGroup(this.toDistUser({ ...dealerForm, ...this.dealer }), disable);
  }

  public createHoldingCompanyDistributionUserForm(disable: boolean = false): UntypedFormGroup {
    return this.createFormGroup(this.toDistUser(this.holdingCompany), disable);
  }

  public createDealerGroupDistributionUserForm(disable: boolean = false): UntypedFormGroup {
    return this.createFormGroup(this.toDistUser(this.dealerGroup), disable);
  }

  private createDefaultDistributions(): UntypedFormGroup[] {
    const defaultDistros = new Array<UntypedFormGroup>();

    const dealer = {
      ...{
        LegalName: this.dealer.legalName,
        UserType: UserType.Dealer
      },
      ...this.dealer
    };

    defaultDistros.push(this.createDistributionGroup(dealer, this.toDistUser(dealer)));
    defaultDistros.push(this.createDistributionGroup(this.holdingCompany, this.toDistUser(this.holdingCompany)));
    defaultDistros.push(this.createDistributionGroup(this.dealerGroup, this.toDistUser(this.dealerGroup)));
 
    return defaultDistros;
  }

  private createDistributionGroup(distro: any, distroUser: DistributionUser = distro): UntypedFormGroup {
    const distroFormGroup = this.fb.group({
      selected: [false],
      name: distro.LegalName,
      firstName: distro.FirstName,
      lastName: distro.LastName,
      title: [distro.Title],
      roleLabel: [distro.RoleLabel],
      status: (distro.IsDisabled === undefined || distro.IsDisabled === null) ? '' : [distro.IsDisabled ? 'Inactive' : 'Active'],
      id: distro.Id || null,
      email: distro.Email,
      userType: (typeof distro.UserType === 'number') ? UserType[distro.UserType] : distro.UserType
    });
    distroFormGroup.setControl('userRegistrationFG', this.createFormGroup(distroUser));
    return distroFormGroup;
  }

  get BonusBucksAuthorizationModel(): BonusBucksAuthorization {
    const bba = new BonusBucksAuthorization();
    if (this.selectedBBAmount) {
      bba.BonusBucksAmount = this.selectedBBAmount.amount;
    }
    bba.DistributionTypeId = this.distributionForm.value.distributionTypeId;
    bba.BonusBucksCoverages = this.currentCoveragePackages;
    bba.BonusBucksDistributions = this.formToBonusBuckDistributions;
    return bba;
  }

  get formToBonusBuckDistributions(): DistributionUser[] {
    return this.distributionForm.getRawValue().distributions.reduce((bbd: DistributionUser[], distribution) => {
      if (distribution.selected) {
        bbd.push(this.formToBonusBuckDistribution(distribution));
      }
      return bbd;
    }, []);
  }

  private formToBonusBuckDistribution(distribution: any): DistributionUser {
    const dist = new DistributionUser();
    dist.Id = distribution.id;
    dist.FirstName = distribution.firstName;
    dist.LastName = distribution.lastName;
    dist.GroupName = distribution.name;
    dist.Email = distribution.userRegistrationFG.email;
    dist.UserType = distribution.userType;
    const userDetails = new UserDetails();
    userDetails.Address = distribution.userRegistrationFG.address;
    userDetails.City = distribution.userRegistrationFG.city;
    userDetails.PostalZipCode = distribution.userRegistrationFG.postalZipCode;
    userDetails.ProvinceStateId = distribution.userRegistrationFG.provinceState;
    userDetails.CountryId = distribution.userRegistrationFG.country;
    userDetails.SocialInsuranceNumber = distribution.userRegistrationFG.sin;
    userDetails.CRABusinessNumber = distribution.userRegistrationFG.craNum;
    userDetails.Phone = distribution.userRegistrationFG.phone;
    userDetails.DistributionPercent = distribution.userRegistrationFG.currentDistribution;
    userDetails.BranchRouting = distribution.userRegistrationFG.branchRouting;
    userDetails.InstitutionCheck = distribution.userRegistrationFG.institutionCheck;
    userDetails.Account = distribution.userRegistrationFG.account;
    userDetails.PaymentTypeId = distribution.userRegistrationFG.paymentType;
    userDetails.EFTImage = distribution.userRegistrationFG.eftimage;
    userDetails.Recipient = distribution.userRegistrationFG.recipient;
    dist.UserRegistrationDetails = userDetails;

    if (distribution.name == this.dealer.legalName)
    {
        dist.UserRegistrationDetails.Recipient = this.dealer.legalName;
    }

    dist.UserRegistrationDetails.RecipientTypeId = this.recipientTypeFromUserType(distribution.userType)

    return dist;
  }

  private patchAllCoverageValues(value: number) {
    const allCovPackages = this.currentCoveragePackages;
    if (value === BonusBucksValueType.CompanyBenchmark) {
      allCovPackages.forEach((coverageGroup) => {
        const cg = coverageGroup.CoverageGroupLabel;
        if (this.hundredBenchmarks.includes(cg)) {
          coverageGroup.Coverages.forEach((coverage) => {
            coverage.IsChecked = true;
            coverage.BonusBucksAmount = 100;
          });
        } else if (this.fiftyBenchmarks.includes(cg)) {
          coverageGroup.Coverages.forEach((coverage) => {
            coverage.IsChecked = true;
            coverage.BonusBucksAmount = 150;
          });
        } else if (coverageGroup.CoverageTypeId === CoverageType.TireAndRim) {
          coverageGroup.Coverages.forEach((coverage) => {
            coverage.IsChecked = true;
            coverage.BonusBucksAmount = 50;
          });
        } else {
          coverageGroup.Coverages.forEach((coverage) => {
            coverage.IsChecked = false;
          });
        }
      });
    } else {
      allCovPackages.forEach((coverageGroup) => {
        coverageGroup.Coverages.forEach((coverage) => {
          coverage.BonusBucksAmount = value;
          coverage.IsChecked = false;
        });
      });
    }
  }

  private patchDistributions() {
    this.distributionForm.patchValue({ distributionTypeId: this.previousBBA.DistributionTypeId });
    const controls = (<UntypedFormArray>this.distributionForm.get('distributions')).controls;

    this.previousBBA.BonusBucksDistributions.forEach((incomingBBD) => {
      const foundControl = <UntypedFormGroup>controls.find(d => d.value.id === incomingBBD.Id);
      if (foundControl !== undefined) {
        foundControl.patchValue({ selected: true, distribution: incomingBBD.UserRegistrationDetails.DistributionPercent, userType: incomingBBD.UserType });
        const userRegistration = this.createFormGroup(incomingBBD, false);
        foundControl.setControl('userRegistrationFG', userRegistration);
        const forms = this.distributionUserForms.get(incomingBBD.Id);
        forms.set(this.resolveUserType(incomingBBD.UserType), userRegistration);
      } else {
        const dealerControl = <UntypedFormGroup>controls.find(d => d.value.name && d.value.name === incomingBBD.GroupName);
        if (dealerControl !== undefined) {
          dealerControl.patchValue({ selected: true, distribution: incomingBBD.UserRegistrationDetails.DistributionPercent, userType: UserType[UserType.Dealer] });
          const userRegistration = this.createFormGroup(incomingBBD, false);
          dealerControl.setControl('userRegistrationFG', userRegistration);

          this.distributionForm.get('distributions') as UntypedFormArray;
          this.createDistributionGroup(dealerControl);
        }
      }

    });
    this.originaldistributionForm = this.distributionForm.getRawValue();
  }

  private toDistUser(distro: any): DistributionUser {
    const d = new DistributionUser();
    d.UserType = distro.UserType;
    d.UserRegistrationDetails = new UserDetails();
    d.FirstName = distro.firstName;
    d.LastName = distro.lastName;
    d.Email = distro.email;
    d.UserRegistrationDetails.Address = distro.address;
    d.UserRegistrationDetails.Phone = distro.phone;
    d.UserRegistrationDetails.CountryId = distro.countryId;
    d.UserRegistrationDetails.PostalZipCode = distro.postalZipCode;
    d.UserRegistrationDetails.City = distro.city;
    d.UserRegistrationDetails.ProvinceStateId = distro.provinceStateId;
    d.UserRegistrationDetails.PaymentTypeId = 0;
    d.UserRegistrationDetails.DistributionPercent = null;
    d.UserRegistrationDetails.BranchRouting = null;
    d.UserRegistrationDetails.InstitutionCheck = null;
    d.UserRegistrationDetails.Account = null;
    d.UserRegistrationDetails.EFTImage = null;
    d.UserRegistrationDetails.Recipient = (UserType.Dealer == distro.UserType) ? distro.LegalName : ''
    return d;
  }

  private resolveUserType(val: any): UserType {
    return (typeof val === 'string') ? UserType[val as string]: val;
  }

  private createFormGroup(user: DistributionUser, disable: boolean = true): UntypedFormGroup {
    let userDetail = user.UserRegistrationDetails;
    if (!userDetail) {
      userDetail = new UserDetails();
    }

    let userType: UserType;
    if (user.UserType) {
      userType = this.resolveUserType(user.UserType);
    } else {
      userType = UserType.User;
    }

    const userRegistrationFG = this.fb.group({
      address: [userDetail.Address, Validators.required],
      phone: [userDetail.Phone, [Validators.required, Validators.minLength(10)]],
      email: [user.Email, [Validators.email, Validators.required]],
      country: [userDetail.CountryId || Country.Canada, [Validators.required]],
      firstName: userDetail.FirstName || '',
      lastName: userDetail.LastName || '',
      postalZipCode: [userDetail.PostalZipCode, Validators.required],
      city: [userDetail.City, Validators.required],
      provinceState: [userDetail.ProvinceStateId, Validators.required],
      paymentType: [userDetail.PaymentTypeId || PaymentMethod.Cheque, Validators.required],
      id: [user.Id],
      previousDistribution: [userDetail.DistributionPercent],
      currentDistribution: [userDetail.DistributionPercent || 0, [Validators.max(100), Validators.required, Validators.min(0 + Number.EPSILON)]],
      branchRouting: userDetail.BranchRouting,
      institutionCheck: userDetail.InstitutionCheck,
      account: userDetail.Account,
      eftimage: userDetail.EFTImage,
      recipient: [userDetail.Recipient || (user.FirstName && user.LastName ? user.FirstName + ' ' + user.LastName : '')],
      recipientTypeId: this.recipientTypeFromUserType(userType)
    });

    if (userType === UserType.User) {
      userRegistrationFG.addControl('sin', new UntypedFormControl(userDetail.SocialInsuranceNumber, [
        Validators.minLength(9),
        Validators.maxLength(9),
        sinValidator(userRegistrationFG.get('country') as UntypedFormControl)
      ]));
    } else {
      userRegistrationFG.addControl('craNum', new UntypedFormControl(userDetail.CRABusinessNumber || '', [
        Validators.minLength(9),
        Validators.maxLength(15)
      ]));
    }

    if (disable) {
      userRegistrationFG.disable();
    }

    return userRegistrationFG;
  }

  private recipientTypeFromUserType(userType: UserType): RecipientType {
    switch (this.resolveUserType(userType)) {
      case UserType.Dealer:
        return RecipientType.Dealer;
      case UserType.HoldingCompany:
        return RecipientType.HoldingCompany;
      case UserType.DealerGroup:
        return RecipientType.DealerGroup;
      default:
        return RecipientType.Individual;
    }
  }

  public isFormValid() {
    var haveAmount :boolean = true;
    this.currentCoveragePackages.forEach(coverage => {
      if(coverage.Coverages.some(cp => cp.IsChecked == true && cp.BonusBucksAmount == 0)) {
        haveAmount = false;
      }
    });
    return haveAmount;
  }
}

export function deepCopy(obj) {
  let copy;

  if (null == obj || 'object' !== typeof obj) { return obj; }

  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCopy(obj[i]);
    }
    return copy;
  }

  if (obj instanceof Object) {
    copy = {};
    for (const attr in obj) {
      if (obj.hasOwnProperty(attr)) { copy[attr] = deepCopy(obj[attr]); }
    }
    return copy;
  }
  throw new Error('Unable to copy obj! Its type isn\'t supported.');
}
