<fieldset class="fieldset-form height">
  <legend>
    Documents
    <div *ngIf="funcs['addDocument'];else noAddDocument" class="float-right">
      <button class="btn custom-button float-right" type="button" (click)="addDocument()" [hidden]="!(isAccountingOrClientService && dealerId)">
        <fa-icon [icon]="faPlus"></fa-icon> Add Document
      </button>
    </div>
    <ng-template #noAddDocument>
      <framework-hidden [display]="button"></framework-hidden>
    </ng-template>
  </legend>
  <table-adapter #dealerDocumentsTable [checkLock]="checkLock$" [columns]="dealerDocumentsTableColumns" [tableLabel]="'dealer-documents-component'" (update)="viewDocument($event)"
    (context)="updateDocument($event)"  [canInteract]="isAccountingOrClientService && dealerId" [canRenderActions]="canRenderActions">
  </table-adapter>
</fieldset> 
