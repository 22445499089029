import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  private listeners: any;
  private eventsSubject: Subject<any>;
  private events: Observable<any>;

  constructor() {
    this.listeners = {};
    this.eventsSubject = new Subject();

    this.events = this.eventsSubject.asObservable();

    this.events.subscribe(({ name, args }) => {
      if (this.listeners[name]) {
        for (let listener of this.listeners[name]) {
          listener(...args);
        }
      }
    });
  }

  on(name, listener) {
    if (!this.listeners[name]) {
      this.listeners[name] = [];
    }

    this.listeners[name].push(listener);
  }

  off(name, listener) {
    this.listeners[name] = this.listeners[name].filter((x) => x != listener);
  }

  broadcast(name, ...args) {
    this.eventsSubject.next({
      name,
      args,
    });
  }
}
