export class ClaimTransaction {
    claimTransactionId: number;
    claimId: number;
    partId: number;
    vendorId: number;
    partName: string;
    warrantyId: number;
    repairCentreId: number;
    customerId: number;
    indicator: string;
    quantity: number;
    ourPrice: number;
    repairCentrePrice: number;
    paidPrice: number;
    shopKeyLabour: number;
    repairCentreLabour: number;
    paidLabour: number;
    other: number;
    process: boolean;
    used: boolean;
    goodwill: number;
    partGroupId: number;
    shipping: number;
    towing: number;
    carRental: number;
    roadAssistance: number;
    accomodation: number;
    machineShop: number;
    inspection: number;
    invoiceId: string;
    invoiceApproved: boolean;
    invoiceNumber: string;
    partDescription: string;
    partGroupName: string;
    repairCentreName: string;
    customerName: string;
    deductible: number;
    description: string;
    transactionKey: number;
    version: number;
    rebuilt:boolean;
    rowTotal : number;
    rowTotalWithTax: number;
    gstAmount: number;
    pstAmount: number;
    hstAmount: number;

    //Variable used for display
    processState:string;
    goodwillState:string;
    totalWithoutTax: number;
    totalWithTax: number;
    gst : number;
    pst : number;
    total: boolean;
    finalTotal: boolean;
    goodwillTotal: boolean;
}
