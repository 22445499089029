import { Component, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

/*-- Interfaces --*/
import { IDealer } from '../../../interfaces/dealer.interface';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class StatisticsComponent implements OnChanges {
  @Input() dealer: IDealer;

  public monthlyEndDate = new UntypedFormControl();
  public monthlyStartDate = new UntypedFormControl();
  public statistics: any = [];
  public totalsEndDate = new UntypedFormControl();
  public totalsStartDate = new UntypedFormControl();

  constructor() { }

  //#region - Lifecycle
  ngOnChanges(changes: SimpleChanges): void {
    const dealer: SimpleChange = changes.dealer;

    if (dealer && dealer.currentValue) {
      this.setFilterDates(dealer.currentValue);
      this.setMonthlyStatistics();
    }
  }
  //#endregion

  //#region - Private Methods
  private setFilterDates(dealer: IDealer): void {
    // totals Start Date - dealer's registration date
    this.totalsStartDate.setValue(moment(dealer.createdDate));

    // totals End Date - current date
    this.totalsEndDate.setValue(moment());

    // monthly Start Date - first day of 6 months prior
    this.monthlyStartDate.setValue(moment().add(-5, 'month').startOf('month'));

    // monthly End Date - current date
    this.monthlyEndDate.setValue(moment());
  }

  private setMonthlyStatistics(): void {
    let inc = -5;

    this.statistics = [];

    while (inc <= 0) {
      this.statistics.push({ date: moment().add(inc, 'month').startOf('month').format() });

      inc++;
    }
  }
  //#endregion
}
