<div *ngIf="control" [formGroup]="control">

    <div class="row">
        <div class="col-10">
            <mat-form-field class="w-100">
                <input formControlName="recipient" matInput placeholder="Recipient" autocomplete="off">
            </mat-form-field>
        </div>
        <div class="col-2" *ngIf="isPercentage">
            <mat-form-field class="distribution-width distribution-form">
                <input matInput type="number"
                    oninput="this.value = Math.abs(this.value) > 100 ? 100 : Math.abs(this.value) "
                    formControlName="currentDistribution"
                    placeholder="{{previousDistributionControl ? previousDistributionControl.value : 0}}" />
                <span matSuffix>&nbsp;%</span>
                <mat-error *ngIf="currentDistributionControl.hasError('required')">Required
                </mat-error>
            </mat-form-field>
            <fa-icon class="arrow-style" [icon]="arrow"></fa-icon>
        </div>
    </div>
    
    <div class="row">
        <div class="col-6">
            <mat-form-field class="w-100">
                <input formControlName="address" matInput placeholder="Address" autocomplete="off" [value]="addressControl.value | addressFormat">
                <mat-error *ngIf="addressControl.hasError('required')">Address is required</mat-error>
            </mat-form-field>
        </div>

        <div class="col-2">
            <mat-form-field class="w-100">
                <input [formControl]="phoneControl" matInput placeholder="Phone" mask="(000) 000-0000" autocomplete="off">
                <mat-error *ngIf="phoneControl.hasError('required')">Phone is required</mat-error>
                <mat-error *ngIf="phoneControl.hasError('minlength')">Please input a valid phone number</mat-error>
            </mat-form-field>
        </div>

        <div class="col-4">
            <mat-form-field class="w-100">
                <input formControlName="email" matInput placeholder="Email" autocomplete="off">
                <mat-error *ngIf="emailControl.hasError('required')">Email is required</mat-error>
                <mat-error *ngIf="emailControl.hasError('email')">Please enter a valid email</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <div class="mat-form-field mat-form-field-static">
                <label class="mat-label">Country</label>
                <mat-radio-group formControlName="country" (change)="onCountryChange()">
                    <mat-radio-button [value]=1 color="primary">CA</mat-radio-button>
                    <mat-radio-button [value]=2 color="primary">US</mat-radio-button>
                    <mat-error *ngIf="countryControl.hasError('required')">Country is required</mat-error>
                </mat-radio-group>
            </div>
        </div>
        <div class="col-3">
            <mat-form-field class="w-100">
                <input [formControl]="postalControl" (change)="capitalize()" matInput [placeholder]="postalZipCodePlaceholder"
                    [mask]="isCanada ? 'S0S 0S0' : '00000'">
                <mat-error *ngIf="postalControl.hasError('required')">{{postalZipCodePlaceholder}} is required
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="w-100">
                <input formControlName="city" (change)="titleCase()" matInput placeholder="City" autocomplete="off">
                <mat-error *ngIf="cityControl.hasError('required')">City is required</mat-error>
            </mat-form-field>
        </div>
        <div class="col-2">
            <mat-form-field class="w-100">
                <mat-select formControlName="provinceState" [placeholder]="isCanada ? 'Province' : 'State'">
                    <mat-option *ngFor="let prov of provincesOrStates" [value]="prov.ProvinceStateId">
                        {{prov.Label}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="provinceStateControl.hasError('required')">
                    {{isCanada ? 'Province is required' : 'State is required'}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-2" *ngIf="craNumControl">
            <mat-form-field class="w-100">
                <input formControlName="craNum" matInput
                    [placeholder]="isCanada ? 'CRA Business Number' : 'Employer Identification Number'"
                    [mask]="isCanada ? '000000000-SS-0000' : '00-0000000'" autocomplete="off">
            </mat-form-field>
        </div>
        <div class="col-2" *ngIf="sinControl">
            <mat-form-field class="w-100">
                <input formControlName="sin" matInput (keydown)="_keyDown($event)"
                    [placeholder]="isCanada ? 'Social Insurance Number' : 'Social Security Number'"
                    [mask]="isCanada ? 'AAA AAA AAA': 'AAA AA AAAA'" autocomplete="off">
                <mat-error *ngIf="sinControl.hasError('validateSin')">Not a valid SIN</mat-error>
            </mat-form-field>
        </div>
        <div class="col-2 mat-form-field mat-form-field-static">
            <label class="mat-label">Method of Payment: </label>
            <mat-radio-group formControlName="paymentType">
                <mat-radio-button [value]=0 color="primary">Cheque</mat-radio-button>
                <mat-radio-button [value]=1 color="primary">EFT</mat-radio-button>
            </mat-radio-group>
            <mat-error *ngIf="paymentMethodControl.hasError('required')">Payment Method Required</mat-error>
        </div>
        <div class="col-auto" *ngIf="paymentMethodControl.value === paymentMethod.EFT">
            <app-eft-selection [userRegistrationForm]="control"></app-eft-selection>
        </div>
    </div>
</div>