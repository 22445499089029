import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { dealersRouting } from './claims.routing';

//Components
import { ClaimsComponent } from './components/claims.component';
import { CustomerComponent } from './components/customer/customer.component';
import { CustomerEditComponent } from './components/customer/customer-edit/customer-edit.component';
import { ClaimsListComponent } from './components/claims-list/claims-list.component';
import { WarrantyComponent } from './components/warranty/warranty.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { MaintenanceAdd } from './components/maintenance/maintenance-add/maintenance-add.component';
import { DetailComponent } from './components/detail/detail.component';
import { ComplaintAddDialogComponent } from './components/complaint-add-dialog/complaint-add-dialog.component';
import { DocumentAddDialogComponent } from './components/document-add-dialog/document-add-dialog.component';
import { NoteAddDialogComponent } from './components/note-add-dialog/note-add-dialog.component';
import { RepairCentreComponent } from './components/repair-centre/repair-centre.component';
import { RepairCentreSearchDialogComponent } from './components/repair-centre/repair-centre-search-dialog/repair-centre-search-dialog.component';
import { RepairCentreInfoComponent } from './components/repair-centre/repair-centre-info/repair-centre-info.component';
import { RepairCentreEditComponent } from './components/repair-centre/repair-centre-edit/repair-centre-edit.component';
import { PartDialogComponent } from './components/part-dialog/part-dialog.component';
import { ClaimEditComponent } from './components/claims-list/claim-edit/claim-edit.component';
import { ClaimLockComponent } from './components/claims-list/claim-lock/claim-lock.component';
import { OdometerAddDialogComponent } from './components/odometer-add-dialog/odometer-add-dialog.component';
import { ApprovalComponent } from './components/approval/approval.component';
import { AuditComponent } from './components/audit/audit.component';
import { CustomerNotesComponent } from './components/customer/customer-notes/customer-notes.component';
import { ClaimNotesComponent } from './components/detail/claim-notes/claim-notes.component';
import { WarrantiesTableComponent } from './components/warranty/warranties-table/warranties-table.component';
import { MaterialModule } from "../../modules/material.module";
import { ClaimInfoComponent } from './components/claim-info/claim-info.component';
import { DocumentConflictDialogComponent } from './components/document-conflict-dialog/document-conflict-dialog.component';
import { PartsListComponent } from './components/parts-list/parts-list.component';
import { PartApprovalDialogComponent } from './components/approval/part-approval-dialog/part-approval-dialog.component';
import { ComplaintInfoComponent } from './components/complaint-info/complaint-info.component';
import { WarrantyDetailsComponent } from './components/warranty-details/warranty-details.component';
import { WarrantyNotesComponent } from './components/warranty-notes/warranty-notes.component'
import { WarrantyDocumentsComponent } from './components/warranty-documents/warranty-documents.component'
import { RepairCentreNotesComponent } from './components/repair-centre-notes/repair-centre-notes.component'
import { MaintenanceScheduleComponent } from './components/maintenance-schedule/maintenance-schedule.component'
import { WarrantyOptionsComponent } from './components/warranty-options/warranty-options.component'

//Third Party
import { BlockUIModule } from 'ng-block-ui';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { SharedModule } from 'src-private/app/modules/shared.module';
import { DataTablesModule } from "angular-datatables";
import { TrimValuePipe } from 'src-private/app/pipes/trim-value.pipe';
import { PhonePipe } from 'src-private/app/pipes/phone.pipe';
import { FileDragNDropDirective } from 'src-private/app/shared/drag-and-drop.directive/file-drag-and-drop';
import { ClaimDocumentsComponent } from './components/claim-documents/claim-documents.component';
import { ClaimsTableComponent } from './components/claims-table/claims-table.component';
import { CustomerInfoComponent } from './components/customer/customer-info/customer-info.component';
import { PartUnapprovalDialogComponent } from './components/approval/part-unapproval-dialog/part-unapproval-dialog.component';
import { CustomerDocumentsComponent } from './components/customer-documents/customer-documents.component';
import { DocumentMoveDialogComponent } from './components/document-move-dialog/document-move-dialog.component';
import { ClaimsService } from './services/claims.service';

@NgModule({
    declarations: [
        ClaimsComponent,
        CustomerComponent,
        CustomerEditComponent,
        CustomerEditComponent,
        WarrantyComponent,
        DetailComponent,
        MaintenanceComponent,
        RepairCentreComponent,
        RepairCentreEditComponent,
        ComplaintAddDialogComponent,
        DocumentAddDialogComponent,
        NoteAddDialogComponent,
        RepairCentreSearchDialogComponent,
        RepairCentreInfoComponent,
        MaintenanceAdd,
        PartDialogComponent,
        ClaimEditComponent,
        ClaimLockComponent,
        TrimValuePipe,
        PhonePipe,
        OdometerAddDialogComponent,
        FileDragNDropDirective,
        ApprovalComponent,
        PartsListComponent,
        PartApprovalDialogComponent,
        CustomerNotesComponent,
        ClaimNotesComponent,
        WarrantiesTableComponent,
        ClaimInfoComponent,
        ClaimDocumentsComponent,
        ClaimsTableComponent,
        DocumentConflictDialogComponent,
        CustomerInfoComponent,
        PartUnapprovalDialogComponent,
        CustomerDocumentsComponent,
        DocumentMoveDialogComponent,
        AuditComponent,
        ComplaintInfoComponent,
        WarrantyDetailsComponent,
        WarrantyNotesComponent,
        WarrantyDocumentsComponent,
        RepairCentreNotesComponent,
        MaintenanceScheduleComponent,
        WarrantyOptionsComponent,
        ClaimsListComponent
    ],
    exports: [
        PartsListComponent,
        ClaimsListComponent
    ],
    imports: [
        dealersRouting,
        SharedModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BlockUIModule,
        FontAwesomeModule,
        RouterModule,
        MaterialModule,
        NgxTrimDirectiveModule,
        NgxMaskModule.forRoot(),
        DataTablesModule
    ],
    providers : [ClaimsComponent]
})
export class ClaimsModule { }
