import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { IClaim } from 'src-private/app/areas/claims/interfaces/claim';
import { IClaimAudit } from '../../interfaces/claim-audit';
import { ClaimsService } from 'src-private/app/areas/claims/services/claims.service';
import { FrameworkComponent } from 'src-private/app/shared/framework/framework.component';
import { ClaimsAuditService } from '../../services/claims-audit.service';
import { ClaimAuditStatus } from 'src-private/app/enums/claim-audit-status.enum';
import { AccountService } from 'src-private/app/areas/account/services/account.service';
import { ToastrService } from "ngx-toastr";
import { environment } from 'src-private/environments/environment';

@Component({
  selector: 'app-claim-info',
  templateUrl: './claim-info.component.html',
  styleUrls: ['./claim-info.component.scss']
})
export class ClaimInfoComponent  extends FrameworkComponent implements OnInit, AfterViewInit {

  private claim$: BehaviorSubject<IClaim>;
  public claimId: number;
  public enterpriseId: number;
  public warrantyId: string;
  public assignedAdjusterName: string;
  public claimsDateEntered: string;
  public claimsDateClosed: string;
  public claimsStatus: string;
  public claimCurrentOdReading: number;
  public showApproveDecline: boolean;
  public claimAuditStatus: ClaimAuditStatus;
  public claimAuditApproved: boolean;
  public claimAuditDenied: boolean;
  public claimAuditApprover: string;
  public claimAuditApprovedDate: string;
  public notifyEmail: string;
  
  public isGeneralAuditor: boolean;
  public isInsurerAuditor: boolean;

  private ngUnsubscribe: Subject<any> = new Subject();
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private claimsService: ClaimsService,
    private claimsAuditService: ClaimsAuditService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private datePipe: DatePipe
    ) {
    super();
    this.claimId = this.activatedRoute.snapshot.data['claimId']
   }

  ngOnInit(): void {
    this.isGeneralAuditor = this.accountService.isGeneralAuditor();
    this.isInsurerAuditor = this.accountService.isInsurerAuditor();

    this.claim$ = new BehaviorSubject<IClaim>({} as IClaim);

    this.claimsService
      .retrieve(this.claimId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((claim) => {
        
        this.claim$.next(claim);
        if (claim) {
          this.enterpriseId = claim.enterpriseId;
          this.warrantyId = claim.warrantyIdFull;
          this.notifyEmail = "mailto:?subject=MBI Claim over $1,500 for your review&body=Greetings, %0D%0AThere is a claim in an MBI province that exceeds $1,500 that should be reviewed. The claim can be reviewed at: https://e2.eazeetrak.com/claims/" + claim.enterpriseId + "/audit.%0D%0A Sincerely,"
          this.claimsDateEntered = this.datePipe.transform(
            claim.claimsDateEntered,
            "yyyy-MM-dd"
          );
          this.claimsDateClosed = this.datePipe.transform(
            claim.claimsDateClosed,
            "yyyy-MM-dd"
          );
          this.claimsStatus = claim.claimsStatus;
          this.assignedAdjusterName = claim.assignedAdjusterName;
          this.claimCurrentOdReading = claim.currentOdReading;

          this.claimAuditStatus = claim.auditApprovalStatus;
          this.claimAuditApproved = claim.auditApprovalStatus === ClaimAuditStatus.Approved ? true : false;
          this.claimAuditDenied = claim.auditApprovalStatus === ClaimAuditStatus.Denied ? true : false;
          this.claimAuditApprover = claim.auditApprover;
          this.claimAuditApprovedDate = this.datePipe.transform(
            claim.auditApprovedDate,
            "yyyy-MM-dd h:mm:ss"
          );

          if ((claim.provinceName === "Alberta" || claim.provinceName === "Saskatchewan") && (claim.claimTotalWithTax > 1500)){
            this.showApproveDecline = true;
          } else {
            this.showApproveDecline = false;
          }
        }
      });
  }

  ngAfterViewInit(): void {
    super.build("ClaimInfoComponent", "claim-info-component");
  }

  approve(): void {
    var claimAudit: IClaimAudit = { claimId: this.claimId, auditApprovalStatus: ClaimAuditStatus.Approved };
    this.claimsAuditService.update(claimAudit).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      this.claimAuditStatus = ClaimAuditStatus.Approved;
      this.claimAuditApproved = true;
      this.claimAuditApprover = this.accountService.getUserName();
      this.claimAuditApprovedDate = this.datePipe.transform(
        new Date(),
        "yyyy-MM-dd h:mm:ss"
      );
    })
  }

  decline(): void {
    var claimAudit: IClaimAudit = { claimId: this.claimId, auditApprovalStatus: ClaimAuditStatus.Denied };
    this.claimsAuditService.update(claimAudit).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      this.claimAuditStatus = ClaimAuditStatus.Denied;
      this.claimAuditDenied = true;
      this.claimAuditApprover = this.accountService.getUserName();
      this.claimAuditApprovedDate = this.datePipe.transform(
        new Date(),
        "yyyy-MM-dd h:mm:ss"
      );
    })
  }

}
