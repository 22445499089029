import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FrameworkComponent } from 'src-private/app/shared/framework/framework.component';
import { CellBuilder } from 'src-private/app/shared/table-adapter/cell-builder/cell-builder';
import { CellType } from 'src-private/app/shared/table-adapter/cell-builder/cell-type';
import { TableAdapterComponent } from 'src-private/app/shared/table-adapter/table-adapter.component';
import { IClaimsNotes } from '../../../interfaces/claims-notes.interface';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ClaimsNotesService } from '../../../services/claims-notes.service';
import { NoteGroupService } from '../../../services/notegroup.service';
import { Subject, takeUntil } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NoteAddDialogComponent } from '../../note-add-dialog/note-add-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClaimsService } from '../../../services/claims.service';
import { AccountService } from 'src-private/app/areas/account/services/account.service';
import { environment } from 'src-private/environments/environment';

@Component({
  selector: 'app-claim-notes',
  templateUrl: './claim-notes.component.html',
  styleUrls: ['./claim-notes.component.scss']
})
export class ClaimNotesComponent extends FrameworkComponent implements OnInit, AfterViewInit {

  @Input() approval: boolean = false;

  public faPlus = faPlus;
  private NoteGroupId: number;
  private claimId: number;
  private claimsNotes: IClaimsNotes[];
  public isAdjuster: boolean;

  private ngUnsubscribe: Subject<any> = new Subject();

  @ViewChild("claimsNotesTable")
  claimsNotesTable: TableAdapterComponent<IClaimsNotes>;
  public claimsNotesTableColumns: CellBuilder[] = [
    new CellBuilder("Created Date", "createdDate", CellType.datetime),
    new CellBuilder("Adjuster", "adjusterName", CellType.text),
    new CellBuilder("Notes", "note", CellType.text),
  ];

  constructor(
    public dialog: MatDialog,
    private claimsService: ClaimsService,
    private accountService: AccountService,
    private claimsNotesService: ClaimsNotesService,
    private noteGroupService: NoteGroupService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) { 
    super();
    this.claimId = this.activatedRoute.snapshot.data['claimId']
  }

  ngOnInit(): void {
    this.noteGroupService
      .retrieveByName("Complaint")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.NoteGroupId = response;
      },
      error => {
        this.toastr.error(environment.messages.apiError, "Unable to retrieve note");
      });
      this.isAdjuster = this.accountService.isAdjuster() || this.accountService.isDealerServices();
    }

  ngAfterViewInit(): void {
    this.refreshNotes();
    super.build("ClaimNotesComponent", "claim-note-component");
  }

  addNote() {
    this.dialog
      .open(NoteAddDialogComponent, {
        panelClass: "claim-add-dialogs",
        width: "600px",
      })
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res.data) {
          res.data.claimId = this.claimId;

          this.claimsNotesService
            .add(res.data)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
              this.toastr.success("Note successfully added.", "Create Note");
              this.refreshNotes();
            },
            error => {
              this.toastr.error(environment.messages.apiError, "Unable to add note");
            });
        }
      });
  }

  refreshNotes() {
    this.claimsNotesTable.refresh(
      this.claimsNotesService.retrieveAll(this.claimId)
    );
  }

  public get IsClaimClosed(): boolean{
    return this.claimsService.getSelectedClaim?.IsClaimClosed
  }
  
  public get isClaimManager(): boolean{
    return this.accountService.isClaimsManager()
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

}
