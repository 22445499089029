<form [formGroup]="claimForm" class="mt-4">
  <div *ngIf="auditApprovalStatus === 2" class="auditApprovalRequiredBanner">
    <div><strong>Claim exceeds $1,500 for MBI Province</strong> This claim exceeds the maximum $1,500 limit, and requires pre-approval with insurer. The following link should be sent to insurer for review: <a class="auditApprovalLink" [href]="auditLink">{{auditLink}}    </a>
      <a [href]="notifyEmail" class="btn btn-secondary" type="button">Notify Insurer</a>
    </div>
  </div>
  <div *ngIf="auditApprovalStatus === 3" class="auditApprovalRequiredBanner">
    <div>This claim in an MBI claim over $1,500 and is under review and not yet approved by the insurer.</div>
  </div>
  <div *ngIf="auditApprovalStatus === 4" class="auditApprovalApprovedBanner">
    <div>This claim in an MBI claim over $1,500 and has been reviewed and approved by the insurer.</div>
  </div>
  <div *ngIf="auditApprovalStatus === 5" class="auditApprovalRequiredBanner">
    <div>This claim in an MBI claim over $1,500 and has been reviewed and denied by the insurer.</div>
  </div>
  
  <fieldset class="fieldset-form">
    <legend>
      Claim <span *ngIf="warranty?.deferred" class="badge badge-warning">Deferred</span>
      <fa-icon *ngIf="funcs['editClaim'];else noEditClaim" [hidden]="isClaimClosed ? !canEditClosedClaim : !(isAdjuster || canEditClosedClaim)" [icon]="faEdit" role="button" (click)="editClaim()">
      </fa-icon>
      <ng-template #noEditClaim>
        <framework-hidden [display]="button"></framework-hidden>
      </ng-template>
      <div class="d-flex flex-row flex-wrap float-lg-right float-right align-items-end">
        <span class="shop-key d-flex flex-column align-middle">
          <a class="btn custom-button grey-background" type="button" [href]="shopLink" target="_blank">{{shopLinkLabel}} <fa-icon class="m-1" [icon]="faExternalLink"></fa-icon></a>
        </span>
        <button *ngIf="funcs['addDetailMaintenance']" class="btn custom-button  grey-background ml-4 mt-3" type="button" (click)="addMaintenance()"
        [hidden]="isClaimClosed ? !canEditClosedClaim : !(isAdjuster || canEditClosedClaim)">
        <fa-icon [icon]="faPlus"></fa-icon> Add Maintenance
        </button>
      </div>
    </legend>
    <div class="container-fluid">
      <div class="form-row">
        <div class="form-row">
          <div class="col-md-2 col-lg-1">
            <div class="form-group-static">
              <label>Claim ID</label>
              <input formControlName="claimId" type="text" readonly class="w-100">
            </div>
          </div>
          <div class="col-md-2 col-xl-1">
            <div class="form-group-static">
              <label>Warranty ID</label>
              <input formControlName="warrantyId" type="text" readonly class="w-100">
            </div>
          </div>
          <div class="col-md-2 col-xl-2">
            <div class="form-group-static">
              <label>VIN</label>
              <input formControlName="vin" type="text" readonly class="w-100">
            </div>
          </div>
          <div class="col-md-4 col-xl-1">
            <div class="form-group-static">
              <label>Assigned Adjuster</label>
              <input formControlName="assignedAdjusterName" type="text" readonly class="w-100">
            </div>
          </div>
          <div class="col-md-4 col-xl-1">
            <div class="form-group-static">
              <label>Claim Source</label>
                <mat-select formControlName="claimSource" (selectionChange)="sourceChange($event.value)">
                  <mat-option *ngFor="let item of claimSources"
                            [value]="item.sourceId">
                    {{item.label}}
                  </mat-option>
                </mat-select>
            </div>
  
          </div>
          <div class="col-md-2 col-xl-1">
            <div class="form-group-static">
              <label>Open Date</label>
              <input formControlName="claimsDateEntered" type="text" readonly class="w-100">
            </div>
          </div>
          <div class="col-md-2 col-xl-1">
            <div class="form-group-static">
              <label>Close Date</label>
              <input formControlName="claimsDateClosed" type="text" readonly class="w-100">
            </div>
          </div>
          <div class="col-md-2 col-xl-1">
            <div class="form-group-static">
              <label>Claim Status</label>
              <input formControlName="claimsStatus" type="text" readonly class="w-100">
            </div>
          </div>
          <div class="col-md-2 col-xl-1">
            <div class="form-group-static">
              <label>Claim Mileage</label>
              <input formControlName="claimCurrentOdReading" type="text" readonly class="w-100">
            </div>
          </div>
          <div class="col-md-4 col-xl-2">
            <div class="form-group-static">
              <label>Repair Centre <span *ngIf="rcStatusIsDoNotUse" class="do-not-use">DO NOT USE</span></label>
              <a *ngIf="funcs['changeRepairCentre']" [hidden]="isClaimClosed ? !canEditClosedClaim : !(isAdjuster || canEditClosedClaim)" class="link label-right" (click)="changeRepairCentre()">Change</a>
              <input formControlName="repairCenterName" type="text" readonly class="w-100" [class.do-not-use]="rcStatusIsDoNotUse">
              <button *ngIf="isClaimManager && showForceClose" class="btn btn-sm custom-button float-right grey-background pb-2" (click)="forceCloseClaim()">Force Close a Claim</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</form>

<fieldset>
  <legend>Dealer Stats - <span *ngIf="warranty"><a class="link" skipLocationChange replaceUrl [routerLink]="['/dealers', { dealerId: warranty.dealerId }, 'dealer-info']">{{warranty.dealerName}}</a></span></legend>
  <app-dealer-stats [vendorId$]="vendorId$"></app-dealer-stats>
</fieldset>

<app-complaint-info></app-complaint-info>
<parts-list [canAddPart]="canAddPart" [warrantyId]="warrantyId" [partsTableColumns]="partsTableColumns" (update)="checkClaim($event)" [canRenderActions]="partsListCanRenderActions"></parts-list>
<app-claim-notes></app-claim-notes>
<app-claim-documents></app-claim-documents>
