<div>
  <fieldset class="fieldset-form">
    <legend>Maintenance Record
      <div class="float-right">
        <button class="btn custom-button float-right" type="button" (click)="addMaintenance()" [hidden]="IsClaimClosed ? !CanEditClosedClaim :!isAdjuster">
          <fa-icon [icon]="faPlus"></fa-icon> Add Maintenance
        </button>
      </div>
    </legend>
    <div class="container-fluid">
      <div class="row" *ngIf="warrantyModel != undefined">

        <div class="col-sm-1 col-lg-1 col-md-1 col-xs-1 maintenance-data">
          <div class="form-group-static">
            <label for="Maint_Clm_Date">Claim Date</label>
            <input id="Maint_Clm_Date" type="text" readonly class="w-100" [ngModel]="claimDate | GWDateFormat"
                   [ngModelOptions]="{standalone: true}">
          </div>
        </div>

        <div class="col-sm-1 col-lg-1 col-md-1 col-xs-1 maintenance-data">
          <div class="form-group-static">
            <label for="Maint_Warr_ID">Warranty ID</label>
            <input id="Maint_Warr_ID" type="text" readonly class="w-100" [ngModel]="(warrantyModel.prefix) + (warrantyModel.enterpriseId == null || warrantyModel.enterpriseId == 0 ? warrantyModel.id : warrantyModel.enterpriseId)"
                   [ngModelOptions]="{standalone: true}">
          </div>
        </div>

        <div class="col-sm-1 col-lg-1 col-md-1 col-xs-1 maintenance-data">
          <div class="form-group-static">
            <label for="Maint_Clm_ID">Claim ID</label>
            <input id="Maint_Clm_ID" type="text" readonly class="w-100" [ngModel]="enterpriseId"
                   [ngModelOptions]="{standalone: true}">
          </div>
        </div>

        <div class="col-sm-1 col-lg-1 col-md-1 col-xs-1 maintenance-data">
          <div class="form-group-static">
            <label for="Maint_Sold_Date">Sold Date</label>
            <input id="Maint_Sold_Date" type="text" readonly class="w-100"
                   [ngModel]="warrantyModel.vehicleSoldDate  | GWDateFormat" [ngModelOptions]="{standalone: true}">
          </div>
        </div>

        <div class="col-sm-1 col-lg-1 col-md-1 col-xs-1 maintenance-data">
          <div class="form-group-static">
            <label for="Maint_OG_KMS">Original Mileage</label>
            <input id="Maint_OG_KMS" type="text" readonly class="w-100" [ngModel]="warrantyModel.vehicle.originalKms"
                   [ngModelOptions]="{standalone: true}">
          </div>
        </div>

        <div class="col-sm-1 col-lg-1 col-md-1 col-xs-1 maintenance-data">
          <div class="form-group-static">
            <label for="Maint_Claim_KMS">Claim Mileage</label>
            <input id="Maint_Claim_KMS" type="text" readonly class="w-100" [ngModel]="claim.currentOdReading"
                   [ngModelOptions]="{standalone: true}">
          </div>
        </div>

        <div class="col-sm-1 col-lg-1 col-md-1 col-xs-1 maintenance-data">
          <div class="form-group-static">
            <label for="Maint_Current_KMS">Current Mileage</label>
            <input id="Maint_Current_KMS" type="text" readonly class="w-100" [ngModel]="warrantyModel.vehicle.currentKms"
                   [ngModelOptions]="{standalone: true}">
          </div>
        </div>

        <div class="col-sm-1 col-lg-1 col-md-1 col-xs-1 maintenance-data">
          <div class="form-group-static">
            <label for="Maint_Current_Date">Current Date</label>
            <input id="Maint_Current_Date" type="text" readonly class="w-100"
                   [ngModel]="currentDate | GWDateFormat" [ngModelOptions]="{standalone: true}">
          </div>
        </div>

      </div>
    </div>
  </fieldset>

  <app-maintenance-schedule [columns]="maintScheduleTableColumns"></app-maintenance-schedule>
</div>
