import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src-private/environments/environment';
import { DistributionUser } from '../models/bonus-bucks.Models';

@Injectable({ providedIn: 'root' })
export class IncompleteRegistrationService {

  constructor(private http: HttpClient) { }

  public getAuthorizedUser(guid: string): Observable<DistributionUser> {
    const actionUrl = 'api/RetrieveIncompleteUser/' + guid;
    return this.http.post<any>(environment.serverUrl + actionUrl, {});
  }

  public submitRegistration(bbd: DistributionUser, guid: string) {
    const actionUrl = 'api/UpdateIncompleteUser';
    return this.http.post<DistributionUser>(environment.serverUrl + actionUrl, { bbd, guid });
  }

}
