import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {
  transform(items: Array<any>, key: string, id: number): Array<any> {
    if (!items || !key || !id) { return null; }

    return items.filter(item => item[key] === id);
  }
}
