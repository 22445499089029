import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AccountService } from '../areas/account/services/account.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
  constructor(private accountService : AccountService, private route: Router){}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
     return this.isPermissionExists(route.data['permission']).pipe((result)=> {
      
        result.subscribe((response)=>{
          if(!response){
            return this.route.navigateByUrl('/unauthorized')
          }
        })
       return result
     })
  }

  private isPermissionExists(Permission : Array<string>) : Observable<boolean>{
   return of(Permission.filter((value)=>{ return this.accountService.hasPermission(value)}).length > 0 )
  }
  
}
