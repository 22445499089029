import { AfterViewInit, Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { IClaim } from 'src-private/app/areas/claims/interfaces/claim';
import { ICustomer } from 'src-private/app/areas/claims/interfaces/customer.interface';
import { CustomerService } from 'src-private/app/areas/claims/services/customer.service';
import { FrameworkComponent } from 'src-private/app/shared/framework/framework.component';
import { Country } from "src-private/app/enums/country.enum";
import { ProvinceStateService } from 'src-private/app/services/province-states.service';

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss']
})
export class CustomerInfoComponent extends FrameworkComponent implements OnInit, AfterViewInit {

  @Input() customerId: number;

  private claim$: BehaviorSubject<IClaim>;
  public customer: ICustomer;
  private provinceStateName: string;
  private countryName: string;

  private ngUnsubscribe: Subject<any> = new Subject();
  
  constructor(
    private customerService: CustomerService,
    private provinceStateService: ProvinceStateService
    ) {
    super();
   }

  ngOnInit(): void {
    this.customerService.retrieve(this.customerId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        this.customer = response
        this.loadCustomerAddress(response);
      });
  }

  loadCustomerAddress(customer: ICustomer) {
    this.provinceStateService.retrieveProvinceById(customer.provinceStateId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        response => {
          this.countryName = Country[customer.countryId];
          this.provinceStateName = response[0] as string;
        });
  }

  ngAfterViewInit(): void {
    super.build("CustomerInfoComponent", "customer-info-component");
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

}
