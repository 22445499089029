import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MaintenanceService } from '../../services/maintenance.service';
import { MaintenanceRetrieve } from '../../models/maintenance-retrieve.model';
import { IMaintenance } from '../../interfaces/maintenance.interface';
import { IWarranty } from '../../interfaces/warranty.interface';
import { WarrantyService } from '../../services/warranty.service';
import { ActivatedRoute } from '@angular/router';
import { IClaim } from '../../interfaces/claim';
import { ClaimsService } from '../../services/claims.service';
import { CustomerService } from '../../services/customer.service';
import { ICustomer } from '../../interfaces/customer.interface';
import * as moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { TableAdapterComponent } from 'src-private/app/shared/table-adapter/table-adapter.component';
import { IClaimsNotes } from '../../interfaces/claims-notes.interface';
import { CellBuilder } from 'src-private/app/shared/table-adapter/cell-builder/cell-builder';
import { CellActions, CellType } from 'src-private/app/shared/table-adapter/cell-builder/cell-type';
import { FrameworkComponent } from 'src-private/app/shared/framework/framework.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AccountService } from 'src-private/app/areas/account/services/account.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src-private/environments/environment';

class MaintenanceRecord {
  maintenanceId: number;
  date: Date;
  kms: number;
  term: string;
  maxTermKms: number;
  user: string;
  dueDate: Date;
  maxKms: number;
  numDays: number;
  numKms: number;
  action: boolean
}

@Component({
  selector: 'app-maintenance-schedule',
  templateUrl: './maintenance-schedule.component.html',
  styleUrls: ['./maintenance-schedule.component.scss']
})
export class MaintenanceScheduleComponent extends FrameworkComponent implements OnInit, AfterViewInit {
  
  @Input() columns: CellBuilder[];

  public claimId: number;
  public claim: IClaim;
  public warranty$: Observable<IWarranty>;
  public customer$: Observable<ICustomer>;
  public records$: Observable<MaintenanceRecord[]>;
  public warrantyModel: IWarranty;
  public currentDate: Date
  public isAdjuster: Boolean;
  
  @BlockUI() blockUI: NgBlockUI;

  @ViewChild("maintScheduleTable") maintScheduleTable: TableAdapterComponent<IClaimsNotes>
  public maintScheduleTableColumns: CellBuilder[]

  constructor(
    private route: ActivatedRoute,
    private maintenanceService: MaintenanceService,
    private claimService: ClaimsService,
    private warrantyService: WarrantyService,
    private customerService: CustomerService,
    public dialog: MatDialog,
    private accountService: AccountService,
    private toastr: ToastrService,
  ) {
    super();
    this.claimId = this.route.snapshot.data['claimId']
  }

  ngOnInit(): void {
    this.blockUI.start();
    if (this.claimId) {
      this.claimService.retrieve(this.claimId).subscribe(claim => {
        this.claim = claim;
        this.warranty$ = this.warrantyService.retrieve(claim.warrantyId);
        this.customer$ = this.customerService.retrieve(claim.customerId);
        this.currentDate = claim.claimsDateEntered
        this.warranty$.subscribe(warranty => {
          this.warrantyModel = warranty
        })

        this.refreshMaintenance();
      },
      error => {
        this.toastr.error(environment.messages.apiError, "Unable to retrieve claim");
      });
    }

    this.isAdjuster = this.accountService.isAdjuster() || this.accountService.isClaimsManager() || this.accountService.isAssistantClaimsManager();
  }
  
  ngAfterViewInit(): void {
    super.build("MaintenanceScheduleComponent", 'app-maintenance-schedule')
  }

  removeMaintenance(data){
    this.maintenanceService.deleteMaintenance((data.element as MaintenanceRecord).maintenanceId).subscribe((res) => {
      if (res) {
        this.blockUI.start()
        this.refreshMaintenance()
        this.toastr.success(
          "Maintenance record successfully removed. ",
          "Remove Maintenance"
        );
      }
    },(error)=>{
      this.toastr.error(environment.messages.apiError, "Remove Maintenance")
    })
  }

  refreshMaintenance(){
    forkJoin([this.warranty$, this.customer$]).subscribe(([warranty, customer]) => {
      this.loadMaintenanceRecords(warranty).subscribe(maintRecords => {
        this.records$ = this.processMaintenanceRecords(warranty, customer, maintRecords);
        this.maintScheduleTable.refresh(this.records$);
        this.blockUI.stop();
      });
    })
  }

  loadMaintenanceRecords(warranty: IWarranty): Observable<IMaintenance[]> {
    const maintenanceRetrieve = new MaintenanceRetrieve();
    maintenanceRetrieve.vehicleId = warranty.vehicle.id

    return this.maintenanceService.retrieveAll(maintenanceRetrieve);
  }

  processMaintenanceRecords(warranty: IWarranty, customer: ICustomer, maintRecords: IMaintenance[])
  : Observable<MaintenanceRecord[]> {

    if(maintRecords.length > 0){
      this.currentDate = new Date(Math.max(...maintRecords.map(x=>{return new Date(x.date).getTime()})))
    }

    let odometerDate = warranty.effectiveDate;

    const records: MaintenanceRecord[] = maintRecords.map((item, idx) => {
      let months = parseInt(item.term);

      const dueDate = moment(item.date).add(months, 'months').toDate();

      const maxKms = item.kms + item.maxKms;

      let record = {
        maintenanceId:item.maintenanceId,
        date: item.date,
        kms: item.kms,
        term: item.term,
        maxTermKms: item.maxKms,
        user: item.userId,
        dueDate: dueDate,
        maxKms: maxKms,
        numDays: Math.abs(moment(item.date).diff(moment(odometerDate), 'days')),
        numKms: item.kms - maxKms,
        action:true
      } as MaintenanceRecord

      odometerDate = item.date;

      return record;
    });

    return of(records);
  }

  public get IsClaimClosed(): boolean{
    return this.claimService.getSelectedClaim?.IsClaimClosed
  }

  public get CanEditClosedClaim(): boolean{
    return this.accountService.isClaimsManager();
  }

  canRenderActions = (actions: CellActions): boolean => {
    if ((actions & CellActions.Remove) === CellActions.Remove) {
      return this.IsClaimClosed ? this.CanEditClosedClaim : this.isAdjuster.valueOf();
    }
    return false;
  }

}