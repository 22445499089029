import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-eft-image',
  templateUrl: './eft-image.component.html',
  styleUrls: ['./eft-image.component.scss']
})
export class EftImageComponent {

  constructor(public dialogRef: MatDialogRef<EftImageComponent>, public sanitizer: DomSanitizer, @Inject(MAT_DIALOG_DATA) public data: {eftimage: string}) { }

  onCloseClick() {
    this.dialogRef.close();
  }

  getResource() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.data.eftimage);
 }
}
