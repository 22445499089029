<div [formGroup]="contactForm" class="container-fluid">
  <div class="row">
    <div class="col-sm-4">

      <div class="form-group-static"
           *ngIf="isStatic">
        <label for="friendlyName">Trade Name</label>
        <input class="w-100"
               id="friendlyName"
               type="text"
               readonly
               formControlName="friendlyName">
      </div>

      <mat-form-field class="w-100"
                      *ngIf="!isStatic">
        <input autocomplete="off"
               placeholder="Trade Name"
               required
               #friendlyName
               formControlName="friendlyName"
               matInput>
        <mat-error>
          <mat-error *ngIf="isSubmitted && f.friendlyName.errors?.required">Trade Name is required</mat-error>
        </mat-error>
      </mat-form-field>

    </div>
    <div class="col-sm-4">

      <div class="form-group-static"
           *ngIf="isStatic">
        <label for="legalName">Legal Entity</label>
        <input class="w-100"
               id="legalName"
               type="text"
               readonly
               formControlName="legalName">
      </div>

      <mat-form-field class="w-100"
                      *ngIf="!isStatic">
        <input autocomplete="off"
               placeholder="Legal Entity"
               required
               formControlName="legalName"
               matInput>
        <mat-error>
          <mat-error *ngIf="isSubmitted && f.legalName.errors?.required">Legal Entity is required</mat-error>
        </mat-error>
      </mat-form-field>

    </div>
    <div class="col-sm-4">

      <div class="form-group-static"
           *ngIf="isStatic">
        <label for="alias">Other Identifier (Internal use only)</label>
        <input class="w-100"
               id="alias"
               type="text"
               readonly
               formControlName="alias">
      </div>

      <mat-form-field class="w-100"
                      *ngIf="!isStatic">
        <input autocomplete="off"
               placeholder="Other Identifier (Internal use only)"
               required
               formControlName="alias"
               matInput>
        <mat-error>
          <mat-error *ngIf="isSubmitted && f.alias.errors?.required">Other Identifier is required</mat-error>
        </mat-error>
      </mat-form-field>

    </div>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <div class="row">
        <div class="col-sm-4">

          <div class="mat-form-field mat-form-field-static">
            <label class="mat-label">Country</label>

            <mat-radio-group (change)="onCustomerCountryChange()"
                             [disabled]="isStatic || disableInput"
                             formControlName="countryId">
              <mat-radio-button color="primary"
                                [value]="country.Canada">CA</mat-radio-button>
              <mat-radio-button color="primary"
                                [value]="country.UnitedStates">US</mat-radio-button>
            </mat-radio-group>

          </div>

        </div>
        <div class="col-sm-8">

          <div class="form-group-static"
               *ngIf="isStatic">
            <label for="address">Address</label>
            <input class="w-100"
                   id="address"
                   type="text"
                   readonly
                   formControlName="address">
          </div>

          <mat-form-field class="w-100"
                          *ngIf="!isStatic">
            <input autocomplete="off"
                   placeholder="Address"
                   required
                   formControlName="address"
                   matInput>
            <mat-error>
              <mat-error *ngIf="isSubmitted && f.address.errors?.required">Address is required</mat-error>
            </mat-error>
          </mat-form-field>

        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="row">
        <div class="col-sm-8">

          <div class="form-group-static"
               *ngIf="isStatic">
            <label for="city">City</label>
            <input class="w-100"
                   id="city"
                   type="text"
                   readonly
                   formControlName="city">
          </div>

          <mat-form-field class="w-100"
                          *ngIf="!isStatic">
            <input autocomplete="off"
                   placeholder="City"
                   required
                   formControlName="city"
                   matInput>
            <mat-error>
              <mat-error *ngIf="isSubmitted && f.city.errors?.required">City is required</mat-error>
            </mat-error>
          </mat-form-field>

        </div>
        <div class="col-sm-2">

          <div class="form-group-static"
               *ngIf="isStatic">
            <label for="abbreviation">{{countryId === country.Canada ? 'Province' : 'State'}}</label>
            <input class="w-100"
                   id="abbreviation"
                   type="text"
                   readonly
                   formControlName="abbreviation">
          </div>

          <mat-form-field class="w-100"
                          *ngIf="!isStatic">
            <mat-select required
                        formControlName="provinceStateId"
                        [placeholder]="countryId === country.Canada ? 'Province' : 'State'">
              <mat-option *ngFor="let item of provinceStates | filterBy : 'countryId' : countryId"
                          [value]="item.provinceStateId">
                {{item.abbreviation}}
              </mat-option>
            </mat-select>
            <mat-error>
              <mat-error *ngIf="isSubmitted && countryId === country.Canada && f.provinceStateId.errors?.required">Province is required</mat-error>
              <mat-error *ngIf="isSubmitted && countryId === country.UnitedStates && f.provinceStateId.errors?.required">State is required</mat-error>
            </mat-error>
          </mat-form-field>

        </div>
        <div class="col-sm-2">

          <div class="form-group-static"
               *ngIf="isStatic">
            <label for="postalZipCode">{{countryId === country.Canada ? bp === 4 ? 'P. Code' : 'Postal Code' : 'Zip Code'}}</label>
            <input class="w-100"
                   id="postalZipCode"
                   type="text"
                   readonly
                   formControlName="postalZipCode"
                   mask="{{postalZipCodeMask}}">
          </div>

          <mat-form-field class="w-100"
                          *ngIf="!isStatic">
            <input autocomplete="off"
                   class="text-uppercase"
                   required
                   formControlName="postalZipCode"
                   [placeholder]="countryId === country.Canada ? 'Postal Code' : 'Zip Code'"
                   matInput
                   mask="{{postalZipCodeMask}}"
                   [clearIfNotMatch]="true">
            <mat-error>
              <mat-error *ngIf="isSubmitted && countryId === country.Canada && f.postalZipCode.errors?.required">Postal Code is required</mat-error>
              <mat-error *ngIf="isSubmitted && countryId === country.UnitedStates && f.postalZipCode.errors?.required">Zip Code is required</mat-error>
            </mat-error>
          </mat-form-field>

        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2">

      <div class="form-group-static"
           *ngIf="isStatic">
        <label for="phone">Phone</label>
        <input class="w-100"
               id="phone"
               type="text"
               readonly
               formControlName="phone"
               mask="(000) 000-0000">
      </div>

      <mat-form-field class="w-100"
                      *ngIf="!isStatic">
        <input autocomplete="off"
               placeholder="Phone"
               required
               formControlName="phone"
               matInput
               mask="(000) 000-0000"
               [clearIfNotMatch]="true">
        <mat-error>
          <mat-error *ngIf="isSubmitted && f.phone.errors?.required">Phone is required</mat-error>
        </mat-error>
      </mat-form-field>

    </div>
    <div class="col-sm-1">

      <div class="form-group-static"
           *ngIf="isStatic">
        <label for="fax">Fax</label>
        <input class="w-100"
               id="fax"
               type="text"
               readonly
               formControlName="fax"
               mask="(000) 000-0000">
      </div>

      <mat-form-field class="w-100"
                      *ngIf="!isStatic">
        <input autocomplete="off"
               placeholder="Fax"
               formControlName="fax"
               matInput
               mask="(000) 000-0000"
               [clearIfNotMatch]="true">
      </mat-form-field>

    </div>

    <div class="col-sm-1">

      <div class="form-group-static"
            *ngIf="isStatic">
        <label for="pst">PST #</label>
        <input class="w-100"
                id="pst"
                type="text"
                readonly
                formControlName="pst" />
      </div>

      <mat-form-field class="w-100"
                      *ngIf="!isStatic">
        <input autocomplete="off"
                placeholder="PST #"
                formControlName="pst"
                type="text"
                matInput />
        </mat-form-field>
    </div>

    <div class="col-sm-2">

      <div class="form-group-static"
           *ngIf="isStatic">
        <label for="pst">GST/HST #</label>
        <input class="w-100"
               id="gstHst"
               readonly
               type="text"
               formControlName="gstHst">
      </div>

      <mat-form-field class="w-100"
                      *ngIf="!isStatic">
        <input autocomplete="off"
               placeholder="GST/HST #"
               formControlName="gstHst"
               type="text"
               matInput>
      </mat-form-field>

    </div>

    <div class="col-sm-3">

      <div class="form-group-static"
           *ngIf="isStatic">
        <label for="website">Website</label>
        <input class="w-100"
               id="website"
               type="text"
               readonly
               formControlName="website">
      </div>

      <mat-form-field class="w-100"
                      *ngIf="!isStatic">
        <input autocomplete="off"
               placeholder="Website"
               formControlName="website"
               matInput>
      </mat-form-field>

    </div>
    <div class="col-sm-2">

      <div class="form-group-static"
           *ngIf="isStatic">
        <label for="registrationNumber">
          <span *ngIf="bp === 3">Reg. Number</span>
          <span *ngIf="bp !== 3">Registration Number</span>
        </label>
        <input class="w-100"
               id="registrationNumber"
               type="text"
               readonly
               formControlName="registrationNumber">
      </div>

      <mat-form-field class="w-100"
                      *ngIf="!isStatic">
        <input autocomplete="off"
               placeholder="Registration Number"
               formControlName="registrationNumber"
               matInput>
      </mat-form-field>

    </div>
  </div>
</div>
