import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "framework-hidden",
  templateUrl: "./framework-hidden.component.html",
  styleUrls: ["./framework-hidden.component.scss"],
})
export class FrameworkHiddenComponent implements OnInit {
  @Input() display: string;

  ngOnInit() {
    if (this.display == undefined) this.display = "";
    this.display = this.display.toLocaleLowerCase();
  }
}
