import {Injectable} from '@angular/core';
import {HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';

const maxAge = 30000;
export const CacheHeader = 'e2-cache';

@Injectable()
export class RequestCacheService {


  cache = new Map();

  public static GetCacheHeaders(force: boolean = false, currentHeaders: HttpHeaders = null): HttpHeaders {
    const httpHeaders = (currentHeaders ? currentHeaders : new HttpHeaders());
    return force ? httpHeaders : httpHeaders
      .append(CacheHeader, 'true');
  }

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const url = req.urlWithParams;
    const cached = this.cache.get(url);

    if (!cached) {
      return undefined;
    }

    const isExpired = cached.lastRead < (Date.now() - maxAge);
    const expired = isExpired ? 'expired ' : '';
    return cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = req.urlWithParams;
    const entry = {url, response, lastRead: Date.now()};
    this.cache.set(url, entry);

    const expired = Date.now() - maxAge;
    this.cache.forEach(expiredEntry => {
      if (expiredEntry.lastRead < expired) {
        this.cache.delete(expiredEntry.url);
      }
    });
  }
}
