import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, FormControl, Validators } from '@angular/forms';
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AppValidators } from 'src-private/app/validators/app-validators';
import { IMaintenance } from '../../../interfaces/maintenance.interface';
import { MaintenanceService } from '../../../services/maintenance.service';
import { IMaintenanceTerm } from '../../../interfaces/maintenance-term.interface';
import { ConfirmDialogComponent } from 'src-private/app/shared/confirm-dialog/confirm-dialog.component';
import { DialogType } from "../../../../../enums/dialog-type";
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src-private/environments/environment';
import { OdometerService } from '../../../services/odometer.service';
import { IOdometer } from '../../../interfaces/odometer.interface';

export class MaintenanceAddInput {
  applicationId: number;
  vehicleId: number;
  claimId: number;
  warrantyId: number;
}

@Component({
  selector: 'app-maintenance-add',
  templateUrl: './maintenance-add.component.html',
  styleUrls: ['./maintenance-add.component.scss']
})
export class MaintenanceAdd implements OnInit {

  public maintenanceRecordForm: UntypedFormGroup;
  public maintenanceRecord: IMaintenance;
  public maintenanceTerms: IMaintenanceTerm[];

  public faSave = faSave;
  public faWindowClose = faWindowClose;

  private vehicleId : number;

  private odometerRecord: IOdometer;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) private input: MaintenanceAddInput,
    private dialogRef: MatDialogRef<MaintenanceAdd>,
    private dialog: MatDialog,
    private maintenanceService: MaintenanceService,
    private toastr: ToastrService,
    private odometerService: OdometerService
    ) {

    this.maintenanceRecord = {
      warrantyId: input.warrantyId,
      claimId: input.claimId,
      date: null,
      kms: null,
      term: null,
      termId: null,
      maxKms: null,
      userId: null,
      maintenanceId: 0
    }

    this.vehicleId = input.vehicleId

    this.odometerRecord = {
      applicationId: input.applicationId,
      date: null,
      id: null,
      reading: null,
      unitType: 1, // KMS
      vehicleId: this.vehicleId
    }
  }

  ngOnInit() {
    this.maintenanceRecordForm = this.formBuilder.group({
      scheduledKms: [null, Validators.required],
      scheduledPeriod: [null, Validators.required],
      performedKms: [null, Validators.required],
      performedDate: [null, Validators.required]
    });

    this.populateTerms();
  }

  saveMaintenance() {
    this.maintenanceService.add(this.maintenanceRecord).toPromise().then((results) => {
      if (results != null) {
        this.odometerService.add(this.odometerRecord).toPromise();

        this.dialogRef.close()
        this.toastr.success("Maintenance successfully added.", 'Maintenance')
      }
    })
      .catch((err) => {
        this.toastr.error(environment.messages.apiError, 'Maintenance')
      });
  }

  confirm() {

    let now = new Date();

    if (this.maintenanceRecordForm.invalid && (!AppValidators.validateAll(this.maintenanceRecordForm))) {
      return;
    }

    if (this.maintenanceRecordForm.dirty) {
      if (AppValidators.validateAll(this.maintenanceRecordForm)) {
        let date = new Date(this.maintenanceRecordForm.controls["performedDate"].value);
        date.setHours(now.getHours());
        date.setMinutes(now.getMinutes());
        this.maintenanceRecord.maxKms = this.maintenanceRecordForm.controls["scheduledKms"].value;
        this.maintenanceRecord.termId = this.maintenanceRecordForm.controls["scheduledPeriod"].value;
        this.maintenanceRecord.date = date;
        this.maintenanceRecord.kms = this.maintenanceRecordForm.controls["performedKms"].value;

        this.odometerRecord.reading = this.maintenanceRecordForm.controls["performedKms"].value;
        this.odometerRecord.date = date;

        this.maintenanceService.retrieveByDate(this.vehicleId, this.datePipe.transform(this.maintenanceRecord.date, 'MM-dd-yyyy')).subscribe(
          data => {
            if (data.length > 0) {
              this.dialog.open(ConfirmDialogComponent, {
                width: '300px',
                autoFocus: true,
                data: {
                  message: "Maintenance record has already been added for this date.",
                  type: DialogType[1].toString()
                }
              });
            }
            else {
              this.saveMaintenance()
            }
          },
          error => {
            this.toastr.error(environment.messages.apiError, "Unable to retrieve maintenance");
          });
      }
    }

  }

  cancel() {
    this.dialogRef.close()
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  populateTerms() {
    this.maintenanceService.retrieveTerms().subscribe(terms => {
      this.maintenanceTerms = terms;
      this.maintenanceTerms = this.maintenanceTerms.filter(x => x.notInUse == false);
    },
    error => {
      this.toastr.error(environment.messages.apiError, "Unable to retrieve terms");
    })
  }
}
