import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src-private/environments/environment';

/*-- Interfaces --*/
import { IMigrationStatus } from '../interfaces/migration.interface';

@Injectable({
  providedIn: 'root'
})
export class MigrationService {

  constructor(private http: HttpClient) { }

  dealerMigrationCoreStatus(): Observable<IMigrationStatus[]> {
    return this.http.get<IMigrationStatus[]>(environment.resourceUrl + 'api/gwc/vendor/migrationstatuscore');
  }

  dealerMigrationTireRimStatus(): Observable<IMigrationStatus[]> {
    return this.http.get<IMigrationStatus[]>(environment.resourceUrl + 'api/gwc/vendor/migrationstatustirerim');
  }

  dealerMigrateCore(dealerId: number) {
    const params = {
      vendorId: dealerId
    };

    return this.http.post(environment.resourceUrl + 'api/gwc/vendor/migratecore', params);
  }

  dealerMigrateTireRim(dealerId: number) {
    const params = {
      vendorId: dealerId
    };

    return this.http.post(environment.resourceUrl + 'api/gwc/vendor/migratetirerim', params);
  }

  dealerRollbackCore(dealerId: number) {
    const params = {
      vendorId: dealerId
    };

    return this.http.post(environment.resourceUrl + 'api/gwc/vendor/rollbackcore', params);
  }

  dealerRollbackTireRim(dealerId: number) {
    const params = {
      vendorId: dealerId
    };

    return this.http.post(environment.resourceUrl + 'api/gwc/vendor/rollbacktirerim', params);
  }
}
