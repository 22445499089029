import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AccountService } from '../areas/account/services/account.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private accountService : AccountService, private route: Router){}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
     return this.isRoleExists(route.data['role']).pipe((result)=> {
      
        result.subscribe((response)=>{
          if(!response){
            return this.route.navigateByUrl('/unauthorized')
          }
        })
       return result
     })
  }

  private isRoleExists(Role : Array<string>) : Observable<boolean>{
   return of(Role.filter((value)=>{ return this.accountService.isInRole(value)}).length > 0 )
  }
  
}
