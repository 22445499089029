<fieldset class="fieldset-form height">
  <legend>
    Customer Notes
    <div *ngIf="funcs['addNote'];else noAddNote" class="float-right">
      <button class="btn custom-button float-right" type="button" (click)="addNote()" [hidden]="IsClaimClosed ? !CanEditClosedClaim : (!isAdjuster)" [ngClass]="{'disableButton': !isAdjuster}">
        <fa-icon [icon]="faPlus"></fa-icon> Add Note
      </button>
    </div>
    <ng-template #noAddNote>
      <framework-hidden [display]="button"></framework-hidden>
    </ng-template>
  </legend>
  <table-adapter #notesTable [tableLabel]="'customer-notes-table'" [columns]="notesTableColumns" [canInteract]="isAdjuster"></table-adapter>
</fieldset>