import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IDocument, IDocumentUploadResponse } from '../interfaces/document';

@Injectable({
  providedIn: 'root'
})
export class CustomerDocumentService {
  constructor(private http: HttpClient) { }

  retrieveAll(customerId: number): Observable<IDocument[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('customerId', customerId.toString());

    return this.http.get<IDocument[]>(`${environment.resourceUrl}api/gwc/customer/documents`, { params: httpParams });
  }

  add(document: IDocument): Observable<IDocumentUploadResponse> {
    let formData = new FormData();
    Object.keys(document).forEach(function (key) {
      formData.append(key, document[key]);
    });
    return this.http.post<IDocumentUploadResponse>(`${environment.resourceUrl}api/gwc/customer/document`, formData);
  }

  remove(documentId: number): Observable<Object> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('documentId', documentId.toString());

    return this.http.delete(`${environment.resourceUrl}api/gwc/customer/document`, { params: httpParams })
  }

  download(customerId: number, documentId: number): Observable<HttpResponse<Blob>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('customerId', customerId.toString());
    httpParams = httpParams.append('documentId', documentId.toString());

    return this.http.get<Blob>(`${environment.resourceUrl}api/gwc/customer/document`, {
      observe: 'response',
      responseType: 'blob' as 'json',
      params: httpParams
    });
  }
}
