<form [formGroup]="contactForm" (ngSubmit)="onFormSubmit()" novalidate>

  <div class="sidebar-header">

    <h4 class="sidebar-title">{{title}}</h4>

  </div>
  <div class="sidebar-actions">

    <button type="submit" class="btn btn-block btn-icon btn-save"><fa-icon [icon]="faCheck" class="text-success"></fa-icon> Submit</button>
    <button type="button" class="btn btn-block btn-icon btn-cancel" (click)="onCancelClick()"><fa-icon [icon]="faTimes" class="text-danger"></fa-icon> Cancel</button>

  </div>
  <div class="sidebar-body">

    <mat-form-field class="w-100">
      <input autocomplete="off"
             placeholder="First Name"
             required
             #firstNameInput
             formControlName="firstName"
             matInput>
      <mat-error>
        <mat-error *ngIf="isSubmitted && f.firstName.errors?.required">First Name is required</mat-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
      <input autocomplete="off"
             placeholder="Last Name"
             required
             formControlName="lastName"
             matInput>
      <mat-error>
        <mat-error *ngIf="isSubmitted && f.lastName.errors?.required">Last Name is required</mat-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
      <input autocomplete="off"
             placeholder="Email"
             required
             formControlName="email"
             matInput>
      <mat-error>
        <mat-error *ngIf="isSubmitted && f.email.errors?.email">Email is invalid</mat-error>
        <mat-error *ngIf="isSubmitted && f.email.errors?.required">Email is required</mat-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
      <mat-select placeholder="EazeeApp Role"
                  required
                  formControlName="roleName">
        <mat-option *ngFor="let role of roles"
                    [value]="role.name">
          {{role.label}}
        </mat-option>
      </mat-select>
      <mat-error>
        <mat-error *ngIf="isSubmitted && f.roleName.errors?.required">EazeeApp Role is required</mat-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
      <mat-select placeholder="Title"
                  required
                  formControlName="title">
        <mat-option *ngFor="let title of titles"
                    [value]="title.name">
          {{title.name}}
        </mat-option>
      </mat-select>
      <mat-error>
        <mat-error *ngIf="isSubmitted && f.title.errors?.required">Title is required</mat-error>
      </mat-error>
    </mat-form-field>
  </div>


</form>
