<div class="container-fluid noPadding">
  <div class="row">
    <div class="col-lg-12">
      <div *ngIf="isLoading;else tableAdapterComponent" class="td-loading-spinner" colspan="1">
        <mat-spinner diameter="25"></mat-spinner>
      </div>
      <ng-template #tableAdapterComponent>
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
          <div *ngFor="let col of columnNames; let i = index">
            <ng-container matColumnDef="{{columns[i].cellName}}">
              <th mat-header-cell *matHeaderCellDef="let element" class="warranty-list" mat-sort-header>
                  <div [ngSwitch]="columns[i].cellType">
                      <div *ngSwitchCase="'text'" class="th-left">
                          {{columns[i].cellName}}
                      </div>
                      <div *ngSwitchDefault class="th-left">
                          <div *ngIf="!readOnly">{{columns[i].cellName}}</div>
                      </div>
                  </div>
              </th>
              <td mat-cell *matCellDef="let element" class="mat-cell td-warranty">
                  <div  [ngSwitch]="columns[i].cellType">
                      <div *ngSwitchCase="'text'" class="warranty-data">

                          <div *ngIf="smallComponent;else regularComponent">
                            <div class="row row-margin">
                              <div class="warranty-link">
                                  <a class="link" [routerLink]="claim ? ['/claims', claim.enterpriseId, 'warranty', element.data.id] : [ '/warranty', element.data.id]">{{ element.data.description }}</a>
                              </div>
                              <div>Warranty Id: <a class="link" [routerLink]="claim ? ['/claims', claim.enterpriseId, 'warranty', element.data.id] : [ '/warranty', element.data.id]"> {{ element.data.prefix }}{{ element.data.enterpriseId == null || element.data.enterpriseId == 0 ? element.data.id : element.data.enterpriseId }}</a></div>
                              <div> {{ element.data.vehicle.year }} {{ element.data.vehicle.make }} {{ element.data.vehicle.model }} &nbsp;  <a class="link" *ngIf="claim" [routerLink]="['/claims', claim.enterpriseId, 'maintenance']">{{ element.data.vehicle.vin }}</a>  </div>
                              <div> Dealer: <a class="link" skipLocationChange replaceUrl [routerLink]="['/dealers', { dealerId: element.data.dealerId }, 'dealer-info']"> {{ element.data.dealerName }} </a></div>
                              <div> Warranty Status: {{ warrantyStatus[element.data.status] }}</div>
                              <div> Start Date: {{ element.data.effectiveDate | GWDateFormat }}</div> 
                              <div> Deferred: {{ element.data.deferred ? 'Yes' : 'No' }}</div>
                              <div>Purchase Kms: {{ element.data.odometer }}</div> 
                            </div>
                          </div>
                          <ng-template #regularComponent>
                            <div class="row row-margin">
                              <div class="warranty-link">
                                  <a class="link" [routerLink]="claim ? ['/claims', claim.enterpriseId, 'warranty', element.data.id] : [ '/warranty', element.data.id]">{{ element.data.description }}</a>
                              </div>
                              <div>Warranty Id: <a class="link" [routerLink]="claim ? ['/claims', claim.enterpriseId, 'warranty', element.data.id] : [ '/warranty', element.data.id]"> {{ element.data.prefix }}{{ element.data.enterpriseId == null || element.data.enterpriseId == 0 ? element.data.id : element.data.enterpriseId }}</a></div>
                              <div> {{ element.data.vehicle.year }} {{ element.data.vehicle.make }} {{ element.data.vehicle.model }} &nbsp; <span *ngIf="!claim">({{ element.data.vehicle.vin }})</span> <a class="link" *ngIf="claim" [routerLink]="['/claims', claim.enterpriseId, 'maintenance']">{{ element.data.vehicle.vin }}</a></div>
                              <div> Dealer: <a class="link" skipLocationChange replaceUrl [routerLink]="['/dealers', { dealerId: element.data.dealerId }, 'dealer-info']"> {{ element.data.dealerName }} </a></div>
                            </div>
                              
                            <div class="warranty-status-data row">
                                <div class="warranty-content"> Warranty Status: {{ warrantyStatus[element.data.status] }}</div>
                                <div class="warranty-content"> Sold Date: {{ element.data.vehicleSoldDate | GWDateFormat }}</div>
                                <div class="warranty-content"> Start Date: {{ element.data.effectiveDate | GWDateFormat }}</div> 
                                <div class="warranty-content"> Deferred: {{ element.data.deferred ? 'Yes' : 'No' }}</div>
                                <div class="warranty-content">Original Odometer: {{ element.data.vehicle.originalKms }}</div> 
                                <div class="warranty-content">Current Kms: {{ element.data.vehicle.currentKms }}</div> 
                            </div>
                          </ng-template>

                      </div>
                      <div *ngSwitchDefault>
                        <div class="th-center" *ngIf="!readOnly">
                          <button class="btn custom-button" type="button" (click)="addClaim(element.data)" [hidden]="!isActive(element.data) || (isClaimClosed ? !canEditClosedClaim : !isAdjuster)">
                            <fa-icon [icon]="faPlus"></fa-icon> Add Claim
                          </button>
                        </div>
                      </div>
                  </div>
              </td>
              
            </ng-container>
          </div>
          <tr mat-header-row *matHeaderRowDef="columnNames; sticky: true"></tr>
          <tr mat-row *matRowDef="let rows; columns: columnNames;" (click)="onRowClick(rows)" [ngClass]="{'rowStyle': this.update.observers.length > 0}"></tr>
        </table>
      </ng-template>
      <mat-paginator [length]=length [pageSize]=pageSize></mat-paginator>
    </div>
    <ng-template #noRefresh>
      <div class="col-lg-12">
        <framework-hidden [display]="'table'">
        </framework-hidden>
      </div>
    </ng-template>
  </div>
</div>
