<fieldset class="fieldset-form height" *ngIf="customer">
    <legend>
      Contact
    </legend>
    <table>
      <tr>
        <td class="row-padding">Customer #: {{customerId}}</td>
      </tr>
      <tr>
        <td class="row-padding"><b>{{customer.firstName}} {{customer.lastName}}</b> </td>
      </tr>
      <tr *ngIf="customer.email">
        <a [href]="'mailto:' + customer.email" class="row-padding">{{customer.email}}</a>
      </tr>
      <tr>
        <td class="row-padding">{{customer.address}}</td>
      </tr>
      <tr>
        <td class="row-padding">{{customer.city}}, {{provinceStateName}} ({{countryName}}) - {{customer.postalZipCode}}</td>
      </tr>
      <tr *ngIf="customer.phone">
        <td class="row-padding">Phone: {{customer.phone | phone: countryName}}</td>
      </tr>
    </table>
  </fieldset>
