import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src-private/environments/environment';
import { IOdometer } from '../interfaces/odometer.interface';

@Injectable({
  providedIn: 'root'
})
export class OdometerService {

  constructor(private http: HttpClient) { }

  add(odometerRecord: IOdometer) {
    return this.http.post<IOdometer>(`${environment.resourceUrl}api/gwc/odometer`, odometerRecord);
  }
}
