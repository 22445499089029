import { ActivatedRoute } from "@angular/router";
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { IClaim } from '../../interfaces/claim';
import { ClaimsTransactionService } from '../../services/claims-transaction.service';
import { ClaimsService } from '../../services/claims.service';
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { ClaimDocumentsComponent } from "../claim-documents/claim-documents.component";
import { Title } from "@angular/platform-browser";
import { CellBuilder } from "src-private/app/shared/table-adapter/cell-builder/cell-builder";
import { CellActions, CellType } from "src-private/app/shared/table-adapter/cell-builder/cell-type";
import { AccountService } from "src-private/app/areas/account/services/account.service";

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss']
})
export class ApprovalComponent implements OnInit, OnDestroy {
  public claim: IClaim;
  public claimId: number;

  public partsTableColumns: CellBuilder[] = [
    new CellBuilder("Type", "indicator", CellType.text),
    new CellBuilder("RC Name", "repairCentreName", CellType.text),
    new CellBuilder("RC ID", "repairCentreId", CellType.text),
    new CellBuilder("Part Group", "partGroupName", CellType.number),
    new CellBuilder("Part Description", "partDescription", CellType.text),
    new CellBuilder("Quantity", "quantity", CellType.number),
    new CellBuilder("GoodWill", "goodwillState", CellType.text),
    new CellBuilder("Total before Tax", "rowTotal", CellType.currency),
    new CellBuilder("GST", "gstAmount", CellType.currency),
    new CellBuilder("PST", "pstAmount", CellType.currency),
    new CellBuilder("HST", "hstAmount", CellType.currency),
    new CellBuilder("Total after Tax", "rowTotalWithTax", CellType.currency),
    new CellBuilder("Action", "action", CellType.actions, '', CellActions.Approve)
  ];

  @ViewChild(ClaimDocumentsComponent) claimDocuments: ClaimDocumentsComponent;

  private readonly ngUnsubscribe$: Subject<boolean> = new Subject();

 @BlockUI() blockUI: NgBlockUI;

  private ngUnsubscribe: Subject<any> = new Subject();
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private claimsService: ClaimsService,
    private accountService: AccountService,
    private titleService: Title,
    public dialog: MatDialog,
  ) { 
  }

  ngOnInit(): void {
  	this.titleService.setTitle("Approval");
    this.claim = this.claimsService.getSelectedClaim;
    if (!this.claim) {
      let claimId = this.activatedRoute.snapshot.data['claimId']
      this.claimsService.retrieve(claimId)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe({
          next: claim => {
            if (claim) {
              this.claim = claim;
            }
          }
        });
    }
  }

  onDocumentAdded(documentAdded: boolean) {
    if(documentAdded) {
      this.claimDocuments.refreshDocuments();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }

  partsListCanRenderActions = (actions: CellActions): boolean => {
    if ((actions & CellActions.Approve) === CellActions.Approve) {
      return this.isClaimClosed();
    }
    else if ((actions & CellActions.Unapprove) === CellActions.Unapprove) {
      return this.isClaimClosed() &&
        (this.accountService.isAccountant() ||
          this.accountService.isClaimsManager() ||
          this.accountService.isAssistantClaimsManager() ||
          this.accountService.isDealerServices() ||
          this.accountService.isClientServiceConcierge());
    }
    return false;
  }

  isClaimClosed(): boolean {
    return this.claim.claimsStatus === 'Closed';
  }
}
