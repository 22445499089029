<div class="container-fluid">
    <div class="row">
        <mat-form-field class="w-100 col-3" appearance="outline">
            <mat-label>First Name</mat-label>
            <input [value]="dealerPrincipal.FirstName" matInput disabled>
        </mat-form-field>
        <mat-form-field class="w-100 col-3" appearance="outline">
            <mat-label>Last Name</mat-label>
            <input [value]="dealerPrincipal.LastName" matInput disabled>
        </mat-form-field>
        <mat-form-field class="w-100 col-6" appearance="outline" >
            <mat-label>Email</mat-label>
            <input [value]="dealerPrincipal.Email" matInput disabled>
        </mat-form-field>
    </div>
</div>