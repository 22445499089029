import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { ReactiveFormsModule } from '@angular/forms';

/*-- Componenents - Dynamic --*/
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';

/*-- Componenents - Static --*/
import { MigrationComponent } from './components/migration.component';

/*-- Modules --*/
import { MaterialModule } from '../../modules/material.module';

/*-- Pipes --*/
// import { ArrayFilterPipe } from '../../pipes/array-filter.pipe';

/*-- Third Party --*/
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {DataTablesModule} from "angular-datatables";

@NgModule({
    declarations: [
        MigrationComponent,
        ConfirmModalComponent,
        /*-- Pipes --*/
        // ArrayFilterPipe
    ],
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        MaterialModule,
        DataTablesModule,
        LayoutModule,
        ReactiveFormsModule,
        FontAwesomeModule
    ],
    // providers: [ArrayFilterPipe]
    providers: []
})
export class MigrationModule { }
