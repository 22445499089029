<div [ngSwitch]="display">
    <div *ngSwitchCase="'table'" class="tableComponent">
        Table Disabled
    </div>
    <div *ngSwitchCase="'button'" class="buttonComponent">
        <button class="btn custom-button buttonComponent" type="button"><fa-icon [icon]="faPlus"></fa-icon> Button Disabled </button>
    </div>
    <div *ngSwitchDefault>
        Component Disabled
    </div>
</div>