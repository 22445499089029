<fieldset class="fieldset-form height">
  <legend>
    Claim Notes
    <div *ngIf="funcs['addNote'];else noAddNote" class="float-right">
      <button class="btn custom-button float-right" type="button" (click)="addNote()" [hidden]="approval">
        <fa-icon [icon]="faPlus"></fa-icon> Add Note
      </button>
    </div>
    <ng-template #noAddNote>
      <framework-hidden [display]="button"></framework-hidden>
    </ng-template>
  </legend>
  <table-adapter #claimsNotesTable [tableLabel]="'notes-table'" [columns]="claimsNotesTableColumns">
  </table-adapter>
</fieldset>
