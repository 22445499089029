import { IRepairCentre } from '../interfaces/repair-centre.interface';
import { Country } from 'src-private/app/enums/bonus-bucks.enums';

export class RepairCentre implements IRepairCentre {
  id: number
  name: string
  contact: string
  contactExt: string
  address: string
  city: string
  province: string
  postalCode: string
  phone: string
  fax: string
  website: string
  email: string
  status: string
  type: string
  hourlyRate: number
  dealerId: string
  dealerName: string
  country: string
  preferred: boolean
  latitude: number
  longitude: number
  version: number
  repairCenterNote:string
  ClaimID:number
  assignedAdjuster:string
  yearsInBusiness:number
  numberOfLicensedTech:number
  numberOfHoists:number
  numberOfBays:number
  retailDoorRate:number
  globalDoorRate:number
  afterMarketPartsDiscount:number
  oemPartsDiscount:number
  general:boolean
  electrical:boolean
  diesel:boolean
  airConditioning:boolean
  electronicDiagnosis:boolean
  transmissionRebuild:boolean
  differentialRebuild:boolean
  transferCaseRebuild:boolean
  transmissionRnR:boolean
  differentialRnR:boolean
  transferCaseRnR:boolean
  towingService:number
  otherWarrantyCompanies:boolean
  specialtyRepairs:string
  specialtyEquipment:string
  partsTime:string
  labourTime:string
  partDistance:string
  labourDistance:string
  paymentType:string


  deserialize(input: any): this {
    return Object.assign(this, input);
  }

  constructor(rc = null) {
    if (rc != null) {
      this.address = rc.address,
        this.city = rc.city,
        this.contact = rc.contact,
        this.country = rc.country,
        this.dealerId = rc.dealerId,
        this.dealerName = rc.dealerName,
        this.email = rc.email,
        this.fax = rc.fax,
        this.hourlyRate = rc.hourlyRate,
        this.id = rc.id,
        this.name = rc.name,
        this.phone = rc.phone,
        this.postalCode = rc.postalCode,
        this.province = rc.province,
        this.status = rc.status,
        this.type = rc.type,
        this.website = rc.website,
        this.latitude = rc.latitude,
        this.longitude = rc.longitude,
        this.yearsInBusiness =rc.yearsInBusiness,
        this.numberOfLicensedTech = rc.numberOfLicensedTech,
        this.numberOfHoists = rc.numberOfHoists,
        this.numberOfBays = rc.numberOfBays,
        this.retailDoorRate = rc.retailDoorRate,
        this.globalDoorRate = rc.globalDoorRate,
        this.afterMarketPartsDiscount = rc.afterMarketPartsDiscount,
        this.oemPartsDiscount = rc.oemPartsDiscount,
        this.general = rc.general,
        this.diesel = rc.diesel,
        this.airConditioning = rc.airConditioning,
        this.electronicDiagnosis = rc.electronicDiagnosis,
        this.transmissionRebuild =rc.transmissionRebuild,
        this.differentialRebuild = rc.differentialRebuild,
        this.transferCaseRebuild = rc.transferCaseRebuild,
        this.transmissionRnR = rc.transmissionRnR,
        this.differentialRnR = rc.differentialRnR,
        this.transferCaseRnR = rc.transferCaseRnR,
        this.towingService = rc.towingService,
        this.otherWarrantyCompanies = rc.otherWarrantyCompanies,
        this.specialtyRepairs = rc.specialtyRepairs,
        this.specialtyEquipment = rc.specialtyEquipment,
        this.partsTime = rc.partsTime,
        this.labourTime = rc.labourTime,
        this.partDistance = rc.partDistance,
        this.labourDistance = rc.labourDistance,
        this.paymentType = rc.paymentType
    } else {
      this.id = null;
      this.name = "";
      this.contact = "";
      this.contactExt = "";
      this.address = "";
      this.city = "";
      this.province = "";
      this.postalCode = "";
      this.phone = "";
      this.fax = "";
      this.website = "";
      this.email = "";
      this.status = "";
      this.type = "";
      this.hourlyRate = null;
      this.dealerId = "";
      this.dealerName = "";
      this.country = Country[Country.Canada];
      this.preferred = false;
      this.yearsInBusiness =null;
      this.numberOfLicensedTech = null;
      this.numberOfHoists = null;
      this.numberOfBays = null;
      this.retailDoorRate = null;
      this.globalDoorRate = null;
      this.afterMarketPartsDiscount = null;
      this.oemPartsDiscount = null;
      this.general = false;
      this.diesel = false;
      this.airConditioning = false;
      this.electronicDiagnosis = false;
      this.transmissionRebuild =false;
      this.differentialRebuild = false;
      this.transferCaseRebuild = false;
      this.transmissionRnR = false;
      this.differentialRnR = false;
      this.transferCaseRnR =false;
      this.towingService = null;
      this.otherWarrantyCompanies = false;
      this.specialtyRepairs = "";
      this.specialtyEquipment = "";
      this.partsTime = "";
      this.labourTime = "";
      this.partDistance = "";
      this.labourDistance = "";
      this.paymentType = ""
    }
  }
}
