import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IOdometer } from '../../interfaces/odometer.interface';
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MaintenanceAddInput } from '../maintenance/maintenance-add/maintenance-add.component';
import { OdometerService } from '../../services/odometer.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { environment } from 'src-private/environments/environment';
import { AppValidators } from 'src-private/app/validators/app-validators';
import { WarrantyService } from '../../services/warranty.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ClaimsService } from '../../services/claims.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { IClaim } from '../../interfaces/claim';

@Component({
  selector: 'app-odometer-add-dialog',
  templateUrl: './odometer-add-dialog.component.html',
  styleUrls: ['./odometer-add-dialog.component.scss']
})
export class OdometerAddDialogComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  @Output() onClaimChanged: EventEmitter<any> = new EventEmitter<any>();

  public odometerReadingForm: UntypedFormGroup;
  public odometerRecord: IOdometer;

  public faSave = faSave;
  public faWindowClose = faWindowClose;
  public claimId : number; 
  
  private claim$: BehaviorSubject<IClaim>;
  private ngUnsubscribe: Subject<any> = new Subject();
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    private claimService: ClaimsService,
    @Inject(MAT_DIALOG_DATA) public warrantyId: number,
    private odometerService: OdometerService,
    private dialogRef: MatDialogRef<OdometerAddDialogComponent>,
    private toastr: ToastrService,
    private warrantyService: WarrantyService
  ) {
    this.retrieveWarrantyDetails(warrantyId);
   }

  ngOnInit(): void {
    this.odometerReadingForm = this.formBuilder.group({
      odometerKms: [null, Validators.required],
      odometerDate: [null, Validators.required]
    })
    this.claimId = this.claimService.getSelectedClaim?.enterpriseId ?? 0;
    if (this.claimId) {
      this.claim$ = new BehaviorSubject<IClaim>({} as IClaim);
      this.claimService
      .retrieve(this.claimService.getSelectedClaim?.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((claim) => {
        this.claim$.next(claim);
      },
      error => {
        this.toastr.error(environment.messages.apiError, "Unable to retrieve claim");
      });
    }
  }

  saveOdometer() {
    this.odometerService.add(this.odometerRecord).toPromise().then((results) => {
      if (results != null) {
        if (this.claimId && this.claim$) {
          this.claim$.value.currentOdReading = this.odometerRecord.reading;
          this.claimService.claimsUpdate(this.claim$.value)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(() => {
            this.onClaimChanged.emit('claim-changed');
          },
          error => {
            this.toastr.error(environment.messages.apiError, "Unable to update claim");
          });
        }

        this.dialogRef.close()
        this.toastr.success("Odometer successfully added.", 'Odometer')
      }
    })
      .catch((err) => {
        this.toastr.error(environment.messages.apiError, 'Odometer')
      });
  }

  confirm() {

    let now = new Date();

    if (this.odometerReadingForm.invalid  &&(!AppValidators.validateAll(this.odometerReadingForm))) {
      return;
    }

    if (this.odometerReadingForm.dirty) {
      if (AppValidators.validateAll(this.odometerReadingForm)) {
        this.odometerRecord.reading = this.odometerReadingForm.controls["odometerKms"].value;
        this.odometerRecord.date = this.odometerReadingForm.controls["odometerDate"].value;
        this.odometerRecord.unitType = 1; // 1 = KMS 2 = Miles. Since we have no way of switching between miles and KMs, its defaulted to KMs.
        this.saveOdometer();
      }
    }
  }

  cancel() {
    this.dialogRef.close()
  }

  retrieveWarrantyDetails(warrantyId){
    this.blockUI.start();
    this.warrantyService.retrieve(warrantyId).subscribe((warranty)=>{
      if(warranty){
        this.odometerRecord = {
          id: null,
          applicationId: null,
          vehicleId: null,
          unitType: null,
          date: null,
          reading: null,
        };

        this.odometerRecord.vehicleId = warranty.vehicle.id
        this.odometerRecord.applicationId = warranty.applicationId
        
      }
      else{
        this.toastr.error("Error loading Warranty details")
        this.cancel();
      }
      this.blockUI.stop()
    },(error)=>{
      this.blockUI.stop();
      this.toastr.error(environment.messages.apiError, "Unable to retrieve warranty");
      this.cancel()
    })
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

}
