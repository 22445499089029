import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import {
  IWarrantyOption,
} from "../../interfaces/warranty.interface";
import { WarrantyService } from "../../services/warranty.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subject, takeUntil } from "rxjs";
import { CellBuilder } from "src-private/app/shared/table-adapter/cell-builder/cell-builder";
import { TableAdapterComponent } from "src-private/app/shared/table-adapter/table-adapter.component";
import { of } from "rxjs/internal/observable/of";
import { AccountService } from "src-private/app/areas/account/services/account.service";
import { FrameworkComponent } from "src-private/app/shared/framework/framework.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";

@Component({
  selector: 'app-warranty-options',
  templateUrl: './warranty-options.component.html',
  styleUrls: ['./warranty-options.component.scss']
})
export class WarrantyOptionsComponent extends FrameworkComponent implements OnInit, AfterViewInit{
  
  @Input() columns: CellBuilder[];
  @Input() warrantyId: number;
  
  private claimId: number;
  public isLoading = false;
  public isAdjuster: boolean;
  public options: Observable<IWarrantyOption[]>;

  private readonly ngUnsubscribe$: Subject<boolean> = new Subject();
  @BlockUI() blockUI: NgBlockUI;

  @ViewChild("warrantyOptionsTable")
  warrantyOptionsTable: TableAdapterComponent<IWarrantyOption>;

  constructor(
    public dialog: MatDialog,
    private accountService: AccountService,
    private warrantyService: WarrantyService,
    private route: ActivatedRoute,
  ) { 
    super();
    this.claimId = this.route.snapshot.data['claimId']
  }

  ngOnInit(): void {
    if (this.warrantyId) {
      this.warrantyService.retrieve(this.warrantyId)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe({
        next: warranty => {
          this.options = of(warranty.options ? warranty.options : []);
          this.refreshOptions();
        }
      });
    }

    this.isAdjuster = this.accountService.isAdjuster() || this.accountService.isClaimsManager() || this.accountService.isAssistantClaimsManager();
  }

  ngAfterViewInit(): void {
    super.build("WarrantyOptionsComponent", 'app-warranty-options')
  }

  refreshOptions() {
    this.options.subscribe((r) => {
      this.warrantyOptionsTable.refresh(this.options);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }

}