import { CellActions, CellType } from "../cell-builder/cell-type";
import { ITableCellAdapter } from "./table-cell-adapter.interface";

export class CellAdapter implements ITableCellAdapter {
    constructor(
        public displayData: any,
        public displayType: CellType,
        public actions: CellActions) { }

    get hasDownload(): boolean {
        return (this.actions & CellActions.Download) === CellActions.Download;
    }

    get hasRemove(): boolean {
        return (this.actions & CellActions.Remove) === CellActions.Remove;
    }

    get hasMove(): boolean {
        return (this.actions & CellActions.Move) === CellActions.Move;
    }

    get hasApprove(): boolean {
        return (this.actions & CellActions.Approve) === CellActions.Approve;
    }
}
