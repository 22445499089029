import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dynamic',
  templateUrl: './dynamic.component.html',
  styleUrls: ['./dynamic.component.css']
})
export class DynamicComponent implements OnInit {
  @Output() notifyParent: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
    this.notifyParent.emit(777);
  }

}
