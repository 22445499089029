<mat-tab-group class="mat-tab-group-headless"
               *ngIf="formState === 'Add'"
               [selectedIndex]="selectedTabIndex">
  <mat-tab label="First">

    <app-add-file [contact]="contact"
                  [dealer]="dealer"
                  [dealerId]="dealerId"
                  (buttonClick)="onButtonClick($event)"
                  (selectedIndexChange)="onSelectedIndexChange($event)"></app-add-file>

  </mat-tab>
  <mat-tab label="Second">

    <app-add-contact (contactCreate)="onContactCreate($event)"
                     (selectedIndexChange)="onSelectedIndexChange($event)"></app-add-contact>

  </mat-tab>
</mat-tab-group>

<app-review-file *ngIf="formState === 'Modify'"
                 [eazeeTrakFileId]="eazeeTrakFileId"
                 (buttonClick)="onButtonClick($event)"></app-review-file>
