import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export class AppValidators {

    static validateAll(form: UntypedFormGroup): boolean {
        Object.keys(form.controls).forEach(field => {
            const control = form.get(field)
            control.markAsTouched({ onlySelf: true })
        });
        return form.valid
    }

    static minlength(min: number): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (!this.isEmptyOrSpaces(c.value)) {
                if (c.value.length < 10) {
                    return { 'minlength': true };
                }
            }
            return null;
        };
    }

    static email(required: boolean): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (!required && this.isEmptyOrSpaces(c.value)) {
                return null;
            }
            return Validators.email(c);
        }
    }

    public static  isEmptyOrSpaces(str) {
        return str === null || str.match(/^ *$/) !== null;
    }
}


