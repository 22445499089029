/*-- Enums --*/
import { Country } from '../../../enums/country.enum';

/*-- Interfaces --*/
import { IDealer } from '../interfaces/dealer.interface';

export class Dealer implements IDealer {
  abbreviation: string;
  address: string;
  alias: string;
  city: string;
  competitors: string;
  convenienceFee: number;
  countryId: number;
  createdDate: Date;
  dealerAccountManagerId: string;
  enterpriseId?: number;
  fax: string;
  franchiseId?: number;
  friendlyName: string;
  legalName: string;
  maxVehicleKm?: number;
  maxVehicleYear?: number;
  minVehicleKm?: number;
  minVehicleYear?: number;
  newVehiclesOnSite?: number;
  newVehiclesSoldPerMonth?: number;
  phone: string;
  postalZipCode: string;
  provinceStateId: number;
  registrationNumber: string;
  repairCentreId?: number;
  usedVehiclesOnSite?: number;
  usedVehiclesSoldPerMonth?: number;
  vendorGroup: string;
  vendorGroupId?: number;
  vendorId: number;
  vendorStatusId: number;
  vendorTypeId: number;
  website: string;
  pst?: string;
  gstHst?: string;

  constructor() {
    this.abbreviation = null;
    this.address = null;
    this.alias = null;
    this.city = null;
    this.competitors = '[]';
    this.convenienceFee = null;
    this.countryId = Country.Canada;
    this.createdDate = null;
    this.dealerAccountManagerId = null;
    this.enterpriseId = null;
    this.fax = null;
    this.franchiseId = null;
    this.friendlyName = null;
    this.legalName = null;
    this.maxVehicleKm = null;
    this.maxVehicleYear = null;
    this.minVehicleKm = null;
    this.minVehicleYear = null;
    this.newVehiclesOnSite = null;
    this.newVehiclesSoldPerMonth = null;
    this.phone = null;
    this.postalZipCode = null;
    this.provinceStateId = null;
    this.registrationNumber = null;
    this.repairCentreId = null;
    this.usedVehiclesOnSite = null;
    this.usedVehiclesSoldPerMonth = null;
    this.vendorGroupId = null;
    this.vendorGroup = null;
    this.vendorId = null;
    this.vendorStatusId = null;
    this.vendorTypeId = null;
    this.website = null;
    this.pst = null;
    this.gstHst = null;
  }
}
