import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[validateCRA]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CRAValidatorDirective, multi: true }]
})
export class CRAValidatorDirective implements Validator {
  @Input() doValidation: string;
  public validate(control: AbstractControl) {
    if (this.doValidation === 'false') {
      return null;
    }

    const CRA = control.value;
    if (CRA === null || CRA.length === 0) {
      return null;
    }


    if (/^[0-9]{9}[A-Za-z]{2}[0-9]{4}$/.test(CRA)) {
      return null;
    }

    if (CRA.length > 9) {
      return { 'validateCRA': true };
    }
    if (/^(\d{9})/.test(CRA)) {
      return null;
    }

    // returning true means invalid
    return { 'validateCRA': true };

  }
}
