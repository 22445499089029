<form [formGroup]="complaintForm">
  <fieldset class="fieldset-form">
    <legend>
      Complaint
      <div *ngIf="funcs['addComplaint'];else noAddComplaint" class="float-right">
        <button  [hidden]="IsClaimClosed ? !CanEditClosedClaim : !(isAdjuster || CanEditClosedClaim)" class="btn custom-button float-right" type="button" (click)="addComplaint()">
          <fa-icon [icon]="faPlus"></fa-icon> Add Complaint
        </button>
      </div>
      <ng-template #noAddComplaint>
        <framework-hidden [display]="button"></framework-hidden>
      </ng-template>
    </legend>
    <table-adapter #complaintsTable [tableLabel]="'complaints-table'" [columns]="complaintsTableColumns">
    </table-adapter>
  </fieldset>
</form>