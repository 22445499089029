import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { ProvinceState } from 'src-private/app/areas/dealers/models/bonus-bucks.Models';
import { environment } from 'src-private/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilsService } from 'src-private/app/services/utils.service';

@Injectable()
export class ProvinceStateService {

  private provStateSource = new BehaviorSubject<ProvinceState[]>([]);
  public currentStates = this.provStateSource.asObservable();

  constructor(private http: HttpClient) {
    this.RetrieveProvinceStates().subscribe((res) => this.provStateSource.next(res));
  }

  private RetrieveProvinceStates(): Observable<ProvinceState[]> {
    return this.http.get<ProvinceState[]>(environment.serverUrl + 'api/retrieveProvinceStates/');
  }

  retrieveProvinceById(id: number): Observable<string> {
    const params = {
      provinceId: id
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);
    return this.http.get<string>(environment.resourceUrl + 'api/gwc/provincestate/provinceRetrieveById',
      { params: queryParams });
  }

  retrieveProvinceByAbbreviation(abbreviation: string): Observable<number> {
    const params = {
      abbreviation: abbreviation
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);
    return this.http.get<number>(environment.resourceUrl + 'api/gwc/provincestate/provinceRetrieveByAbbreviation',
      { params: queryParams });
  }
}
