import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {JwtHelperService} from '@auth0/angular-jwt';
import {OidcSecurityService} from "angular-auth-oidc-client";
import {BehaviorSubject, Subject} from "rxjs";

declare const Promise: any;

@Injectable({
  providedIn: 'root'
})

export class AccountService {
  jwtHelper: any;
  static token: any = null;
  static encodedToken: string = null;
  public static ready: BehaviorSubject<boolean>;

  constructor(private http: HttpClient, public dialog: MatDialog, private oidcSecurityService: OidcSecurityService) {
    AccountService.ready = new BehaviorSubject<boolean>(false);
    this.jwtHelper = new JwtHelperService();
  }

  public static setToken(t: any) {
    AccountService.token = new JwtHelperService().decodeToken(t);
    AccountService.encodedToken = t;
  }

  public static getEncodedToken(): string {
    return AccountService.encodedToken;
  }

  public getToken(): any {
    return AccountService.token;
  }

  public getUserName(): string {
    return this.getToken().FirstName + ' ' + this.getToken().LastName;
  }

  public getUserId(): string {
    return this.getToken().UserId;
  }

  public getEmail(): string {
    return this.getToken().UserName;
  }

  public getUserRoles(): string[] {
    let roles = this.getToken().Roles;
    if (!roles) {
      return [];
    }
    if (typeof roles == "string") {
      return [roles];
    }
    return roles;
  }

  public isInRole(role: string): boolean {
    let userRoles = this.getUserRoles();
    for (let iRole = 0; iRole < userRoles.length; ++iRole) {
      if (userRoles[iRole].toLowerCase() === role.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  public isAdjuster(): boolean {
    return this.isInRole("GlobalWarrantyClaimsAdjuster");
  }

  public isClaimsManager(): boolean {
    return this.isInRole("GlobalWarrantyClaimsManager");
  }

  isAuthenticated(): boolean {
    return true;
  }

  public isAssistantClaimsManager(): boolean {
    return this.isInRole('GlobalWarrantyAssistantClaimsManager')
  }

  public isAccountant(): boolean {
    return this.isInRole('GlobalWarrantyAccountant')
  }

  public isDealerServicesUser(): boolean {
    return this.isInRole('EazeeInfoDealerServicesUser') || this.isInRole('EazeeTrakDealerServicesUser')
  }

  public isDealerServicesPowerUser(): boolean {
    return this.isInRole('EazeeInfoDealerServicesPowerUser') || this.isInRole('XodusDealerServicesPowerUser')
  }

  public isDealerServicesManager(): boolean {
    return this.isInRole('XodusDealerServicesManager')
  }

  public isClientServiceConcierge(): boolean {
    return this.isInRole('GlobalWarrantyClientServicesConcierge')
  }

  public isClientServiceManager(): boolean {
    return this.isInRole('GlobalWarrantyClientServicesManager')
  }

  public isDealerServices(): boolean {
    return this.isInRole('GlobalWarrantyDealerServices-Claims') || this.isInRole('EazeetrakClaims-DealerServices')
  }

  public isGeneralAuditor(): boolean {
    return this.isInRole('GlobalWarrantyGeneralAuditor')
  }

  public isInsurerAuditor(): boolean {
    return this.isInRole('GlobalWarrantyInsurerAuditor')
  }

  public getUserPermissions(): string[] {
    let permissions = this.getToken().Permission;
    if (!permissions) {
      return [];
    }
    if (typeof permissions == "string") {
      return [permissions];
    }
    return permissions;
  }

  public hasPermission(permission: string): boolean {
    let userPermissions = this.getUserPermissions();
    for (let iPermission = 0; iPermission < userPermissions.length; ++iPermission) {
      if (userPermissions[iPermission].localeCompare(permission, undefined, {sensitivity: 'base'}) === 0) {
        return true;
      }
    }
    return false;
  }

  public hasClaimsManagementApprove(): boolean {
    return this.hasPermission('ClaimsManagement_Approve')
  }

  logout() {
    this.oidcSecurityService.logoff();
  }
}
