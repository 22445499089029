import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src-private/environments/environment';
import { ICustomerNote } from '../interfaces/customer-note.interface';

@Injectable({
  providedIn: 'root'
})
export class CustomerNotesService {

  constructor(private http: HttpClient) { }

  retrieveAll(customerId: number): Observable<ICustomerNote[]> {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('customerId', customerId.toString());

    return this.http.get<ICustomerNote[]>(`${environment.resourceUrl}api/gwc/customer/notes`, { params: httpParams });
  }

  add(note: ICustomerNote): Observable<ICustomerNote> {
    let httpParams = new HttpParams();
    Object.keys(note).forEach(function (key) {
      let value = note[key];
      if (key == "createdDate" && note["id"] == 0) {
        value = note[key].toISOString();
      }
      httpParams = httpParams.append(key, value);
    });
    return this.http.post<ICustomerNote>(`${environment.resourceUrl}api/gwc/customer/note`, httpParams);
  }
}
