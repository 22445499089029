<div class="date-range">
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">

        <mat-form-field class="w-100">
          <input placeholder="Start Date"
                 [formControl]="totalsStartDate"
                 [matDatepicker]="totalsStartDatePicker"
                 matInput>
          <mat-datepicker-toggle matSuffix [for]="totalsStartDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #totalsStartDatePicker></mat-datepicker>
        </mat-form-field>

      </div>

      <div class="col-6">

        <mat-form-field class="w-100">
          <input placeholder="End Date"
                 [formControl]="totalsEndDate"
                 [matDatepicker]="totalsEndDatePicker"
                 matInput>
          <mat-datepicker-toggle matSuffix [for]="totalsEndDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #totalsEndDatePicker></mat-datepicker>
        </mat-form-field>

      </div>
    </div>
  </div>
</div>

<table class="table table-striped">
  <thead>
    <tr>
      <th></th>
      <th>Sales</th>
      <th>Claims</th>
      <th>Loss Ratio</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Active</td>
      <td colspan="3" class="text-center">Coming Soon!</td>
    </tr>
    <tr>
      <td>Expired</td>
      <td colspan="3" class="text-center">Coming Soon!</td>
    </tr>
    <tr>
      <td>Totals</td>
      <td colspan="3" class="text-center">Coming Soon!</td>
    </tr>
  </tbody>
</table>

<div class="date-range">
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">

        <mat-form-field class="w-100">
          <input placeholder="Start Date"
                 [formControl]="monthlyStartDate"
                 [matDatepicker]="monthlyStartDatePicker"
                 matInput>
          <mat-datepicker-toggle matSuffix [for]="monthlyStartDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #monthlyStartDatePicker></mat-datepicker>
        </mat-form-field>

      </div>

      <div class="col-6">

        <mat-form-field class="w-100">
          <input placeholder="End Date"
                 [formControl]="monthlyEndDate"
                 [matDatepicker]="monthlyEndDatePicker"
                 matInput>
          <mat-datepicker-toggle matSuffix [for]="monthlyEndDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #monthlyEndDatePicker></mat-datepicker>
        </mat-form-field>

      </div>
    </div>
  </div>
</div>

<table class="table table-striped">
  <thead>
    <tr>
      <th>Period</th>
      <th>Sales</th>
      <th>Claims</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let statistic of statistics" class="ng-scope" style="">
      <td>{{statistic.date | date: 'MMMM y' }}</td>
      <td colspan="2">Coming Soon!</td>
    </tr>
  </tbody>
</table>
