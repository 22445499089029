export enum CellType {
    text = "text",
    number = "number",
    checkbox = "checkbox",
    button = "button",
    currency = "currency",
    date = "date",
    datetime = "datetime",
    contextmenu = "contextmenu",
    dropdown = "dropdown",
    action = "action",
    actions = "actions",
    image ="document-type-image"
}

export enum CellActions {
    None = 0,
    Dropdown = 1,
    Download = 2,
    Remove = 4,
    Approve = 8,
    Unapprove = 16,
    Move = 32
}
