import { Component, OnInit, Inject, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faSave, faWindowClose } from '@fortawesome/pro-solid-svg-icons';
import { AppValidators } from 'src-private/app/validators/app-validators';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ILoginUser } from '../../interfaces/loginuser.interface';
import { AccountService } from "../../../account/services/account.service";
import { LoginUserService } from '../../services/loginuser.service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, share, takeUntil, tap } from 'rxjs/operators';
import { ComplaineeType } from 'src-private/app/enums/complainee-type';
import { PartGroupModel } from '../../models/part-group-model';
import { Part } from '../../models/part-model';
import { PartService } from '../../services/part-service';

@Component({
  selector: 'app-complaint-add-dialog',
  templateUrl: './complaint-add-dialog.component.html',
  styleUrls: ['./complaint-add-dialog.component.scss']
})
export class ComplaintAddDialogComponent implements OnInit {

  warrantyId : number;
  public complaintAddForm: UntypedFormGroup;
  public complainees: ILoginUser[];
  public filteredComplainees: ILoginUser[];
  public adjusterAutoCompleteText: string;
  public complaineeTypes = new Map<string, number>();
  public faSave = faSave;
  public faWindowClose = faWindowClose;
  public complaintBox: string = ''
  public complaineeText: string = ''
  public partText: string = ''
  private prevComplaineeText:string =''
  private prevPartText:string =''
  public partGroupsFiltered: PartGroupModel[] = []
  public partGroups: PartGroupModel[] = []
  public parts: Part[] = []
  public partsFiltered: Part[] = []
  public partGroupNameSelected: boolean
  public partGroupId: number
  public partGroups$: Observable<PartGroupModel[]>

  public isSubmitted: boolean = false;
  public isLoading: boolean = false;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public partService: PartService,
    private formBuilder: UntypedFormBuilder,
    private accountService:AccountService,
    private dialogRef: MatDialogRef<ComplaintAddDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.warrantyId = data;
  }

  ngOnInit() {
    this.complaintAddForm = this.formBuilder.group({
      complainee: ['', Validators.required],
      partGroupName: ['', Validators.required],
      isare: ['', Validators.required],
      description: ['', Validators.required],
      complaint: ['', Validators.required],
      assignedAdjuster:['',Validators.required]
    });
    this.loadPartGroups();
    this.setComplaineeType()
    this.complaintAddForm.patchValue({assignedAdjuster: this.accountService.getUserId()})
  }

  cancel() {
    this.dialogRef.close({ event: 'Cancel' })
  }

  addComplaint() {
    this.isSubmitted = true;
    if (AppValidators.validateAll(this.complaintAddForm)) {

      var complaintData = {
        complainee: this.complaintAddForm.value.complainee,
        partGroupName: this.complaintAddForm.value.partGroupName,
        isare: this.complaintAddForm.value.isare,
        description: this.complaintAddForm.value.description,
        complaint: this.complaintAddForm.value.complaint,
        assignedAdjuster:this.complaintAddForm.value.assignedAdjuster,
      }
      this.dialogRef.close({ data: complaintData })
    }
  }

  fillTextarea() {
    if(this.complaintAddForm.controls['complainee'].valid && this.complaintAddForm.controls['description'].valid && this.complaintAddForm.controls['isare'].valid)
    {
      this.complaintBox = this.complaintAddForm.value.complaint ;
      if(this.complaintAddForm.value.description != "" && this.complaintAddForm.value.description != undefined) {
        this.partText ="part " + ' " '+this.complaintAddForm.value.description + ' " '
      };

      this.complaineeText = ComplaineeType[this.complaintAddForm.value.complainee].split('_').join(" ")+' ';

      if(this.complaintAddForm.value.complaint != '') {
        this.complaintBox += ' + ';
      }
      this.complaintBox += this.complaineeText + this.partText + this.complaintAddForm.value.isare + '.';
      this.complaintAddForm.patchValue({ complaint: this.complaintBox })
    }
    else {
      if(this.partsFiltered.length == 0){
        this.complaintAddForm.controls["description"].markAsTouched();
        if(this.partGroupId == undefined || this.partGroupId == null) {
          this.complaintAddForm.controls["partGroupName"].markAsTouched();
        }
      }
      if(this.complaintAddForm.value.isare == '') {
        this.complaintAddForm.controls["isare"].markAsTouched();
      }
    }
  }

  public loadPartGroups() {
    this.partGroups$ = this.partService.retrieveAllPartGroups(this.warrantyId).pipe(
      share(),
      catchError((error) => {
        if (error.error instanceof ErrorEvent) console.log(`Error: ${error.error.message}`)
        else console.log(`Error: ${error.message}`)
        return of([]);
      }))
    this.partGroups$.subscribe(e => {
      e.forEach(pb => {
        this.partGroups.push(pb)
      })
      this.partGroups = this.partGroups.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      })
      this.partGroupsFiltered = this.partGroups
    })
  }

  public filterPartGroups(pbName: string = null) {
    this.partsFiltered = []
    var filterVal = this.complaintAddForm.value.partGroupName

    if (filterVal != undefined || pbName != null) {
      if (pbName != null) {
        filterVal = pbName.toLocaleLowerCase()
      }
      this.partGroupsFiltered = this.partGroups.filter(e => e.label.toLocaleLowerCase().includes(filterVal))
    }
    else {
      this.partGroupNameSelected = false;
    }
  }

  public selectPartsGroup(partsGroup: PartGroupModel, event) {
    if (event.isUserInput) {
      this.partGroupNameSelected = false;
      this.partGroupId = partsGroup.partGroupId
      this.partGroupsFiltered = [partsGroup];
      this.partGroupNameSelected = true;
      this.complaintAddForm.controls["description"].setValue("");
      this.loadParts()
    }
  }

  public loadParts() {
    this.parts = [];
    this.partService.retrieveAllPartsFromGroup(this.partGroupId, this.warrantyId).pipe(
      share(),
      catchError((error) => {
        if (error.error instanceof ErrorEvent) console.log(`Error: ${error.error.message}`)
        else console.log(`Error: ${error.message}`)
        return of([]);
      }))
    .subscribe(e => {
      e.forEach(p => {
        this.parts.push(p)
      })
      this.partsFiltered = this.parts;
    })

  }

  public filterParts(partName: string = null) {
    var filterVal = this.complaintAddForm.value.description
    if (filterVal != undefined || partName != null) {
      if (partName != null) {
        filterVal = partName
      }
      filterVal = filterVal.toLocaleLowerCase()
      this.partsFiltered = this.parts.filter(e => e.label.toLocaleLowerCase().includes(filterVal))
    }
  }

  setComplaineeType(){
   Object.keys(ComplaineeType).forEach(ele=>{
    if(isNaN(Number(ele)) === false) {
      let val : String =ComplaineeType[ele]
      this.complaineeTypes.set(val.split('_').join(" "), Number.parseInt(ele))
    }
   })
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

}
