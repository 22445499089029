<mat-dialog-content (scroll)="closeAutoComplete()">
  <form [formGroup]="repairCenterForm">
    <fieldset class="fieldset-form">
      <legend>{{headerText}}</legend>
      <div class="container-fluid dialogContent">
        <div class="row">
          <div class="col-12">
            <mat-slide-toggle formControlName="preferred" class="float-right">Preferred</mat-slide-toggle>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <mat-form-field class="w-100">
              <mat-label>Repair Centre Name</mat-label>
              <input formControlName="name" required matInput >
              <mat-error *ngIf="repairCenterForm.get('name').errors?.required">
                Repair Centre Name is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="w-100">
              <mat-label>Contact</mat-label>
              <input formControlName="contact" matInput>
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field class="w-100">
              <mat-label>Contact Ext</mat-label>
              <input formControlName="contactExt" matInput mask="000">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <mat-form-field class="w-100">
              <mat-label>Address</mat-label>
              <input formControlName="address" matInput>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100">
              <mat-label>City</mat-label>
              <input formControlName="city" matInput>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <mat-form-field class="w-100">
              <mat-label>{{ provinceStateLabel }}</mat-label>
              <mat-select formControlName="province" [placeholder]="provinceStateLabel">
                <mat-option *ngFor="let province of provincesOrStates" [value]="province.Abbreviation">
                    {{province.Abbreviation}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="w-100">
              <mat-label>{{ postalCodeStateLabel }}</mat-label>
              <input formControlName="postalCode" matInput
                     [mask]="postalCodeStateMask" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
          </div>
          <div class="col-4">
            <div class="mat-form-field mat-form-field-static">
              <mat-label>Country</mat-label>
              <mat-radio-group formControlName="country" (change)="onCountryChange()">
                <mat-radio-button color="primary" value="Canada">CA</mat-radio-button>
                <mat-radio-button color="primary" value="USA">US</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <mat-form-field class="w-100">
              <mat-label>Phone</mat-label>
              <input formControlName="phone" trim matInput mask="(000) 000-0000"
                     autocomplete="off">
              <mat-error *ngIf="repairCenterForm.get('phone').hasError('minlength')">
                Please input a valid phone number
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="w-100">
              <mat-label>Fax</mat-label>
              <input formControlName="fax" matInput trim mask="(000) 000-0000"
                     autocomplete="off">
              <mat-error *ngIf="repairCenterForm.get('fax').hasError('minlength')">
                Please input a valid phone number
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <mat-form-field class="w-100">
              <mat-label>Website</mat-label>
              <input formControlName="website" trim class="link" matInput
                     autocomplete="off">
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="w-100">
              <mat-label>Email</mat-label>
              <input formControlName="email" class="link" trim matInput
                     autocomplete="off">
              <mat-error *ngIf="repairCenterForm.get('email').hasError('email')">
                Please enter a valid email
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset class="fieldset-form">
      <legend>Service Information</legend>
      <div class="container-fluid">
        <div class="row">
          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>Years in Business</mat-label>
              <input id="yearsInBusiness" formControlName="yearsInBusiness" type="text" matInput 
              mask="separator.2" thousandSeparator=","> 
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>Number of Licensed Techs</mat-label>
              <input id="numberOfLicensedTech" formControlName="numberOfLicensedTech" type="text" matInput 
                      mask="separator.2" thousandSeparator=",">
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>Number of Hoists</mat-label>
              <input id="numberOfHoists" formControlName="numberOfHoists" type="text" matInput 
                      mask="separator.2" thousandSeparator=",">
            </mat-form-field>
          </div>

          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>Number of Bays</mat-label>
              <input id="numberOfBays" formControlName="numberOfBays" type="text" matInput 
                      mask="separator.2" thousandSeparator=",">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <mat-form-field class="w-100">
              <mat-label>Status</mat-label>
              <mat-select #select formControlName="status">
                <mat-option *ngFor="let status of repairCentreStatuses" [value]="status.description">
                  {{status.description}} 
              </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100">
              <input type="text" matInput formControlName="dealerName" placeholder="Affiliated Dealer" [matAutocomplete]="dealer"
                (input)="filterDealers($event.target.value)" #repairCentreAutoCompleteRef>
              <mat-autocomplete  autoActiveFirstOption #dealer="matAutocomplete">
                <mat-option *ngIf="isLoading" class="spinner">
                  <mat-spinner diameter="25"></mat-spinner>
                </mat-option>
                <ng-container *ngIf="!isLoading">
                  <mat-option *ngFor="let dealer of filteredDealers" [value]="dealer.alias"
                    (onSelectionChange)="onDealerSelected(dealer, $event)">
                    {{dealer.alias}}
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <mat-form-field class="w-100">
              <mat-label>Type</mat-label>
              <mat-select #select formControlName="type">
                <mat-option *ngFor="let type of repairCentreTypes" [value]="type.description">
                  {{type.description}} 
              </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>Hourly Rate</mat-label>
              <input id="hourlyRate" formControlName="hourlyRate" type="text" matInput required
                     prefix="$" mask="separator.2" thousandSeparator=",">
              <mat-error *ngIf="repairCenterForm.get('hourlyRate').hasError('minlength')">
                Please enter a valid hourly rate
              </mat-error>
            </mat-form-field>
          </div>
        </div>
     

        <div class="row">
          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>Retail Door rate</mat-label>
              <input id="retailDoorRate" formControlName="retailDoorRate" type="text" matInput 
              prefix="$" mask="separator.2" thousandSeparator=",">
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>Global Door rate</mat-label>
              <input id="globalDoorRate" formControlName="globalDoorRate" type="text" matInput 
              prefix="$"  mask="separator.2" thousandSeparator=",">
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>After Market Parts Discount</mat-label>
              <input id="afterMarketPartsDiscount" formControlName="afterMarketPartsDiscount" type="text" matInput 
              prefix="$"   mask="separator.2" thousandSeparator=",">
            </mat-form-field>
          </div>

          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>OEM Parts Discount</mat-label>
              <input id="OEMPartsDiscount" formControlName="oemPartsDiscount" type="text" matInput 
              prefix="$"   mask="separator.2" thousandSeparator=",">
            </mat-form-field>
          </div>
        </div>
        <div class="row flex-column">
          <div class="contentLabel">Type of repairs</div>
          <div class="typeDiv">
            <div >
            
              <mat-checkbox class="label-top" formControlName="general"  color="primary">General</mat-checkbox>
            </div>
            <div>
            
              <mat-checkbox class="label-top" formControlName="electrical" color="primary">Electrical</mat-checkbox>

            </div>
            <div>
              
              <mat-checkbox class="label-top" formControlName="diesel" color="primary">Diesel</mat-checkbox>

            </div>
            <div>
             
              <mat-checkbox class="label-top" formControlName="airConditioning" color="primary">Air Conditioning</mat-checkbox>

            </div>
            <div>
            
              <mat-checkbox class="label-top" formControlName="electronicDiagnosis"  color="primary">Electronic Diagnosis </mat-checkbox>
            </div>
          </div>
        </div>

        <div class="row flex-column">
          <div class="contentLabel">Driveline</div>
          <div class="driveline">
          <div>
            <mat-label >Transmission</mat-label>
            <mat-checkbox class="label-top" formControlName="transmissionRebuild" color="primary">Rebuild</mat-checkbox>
            <mat-checkbox class="label-top" formControlName="transmissionRnR" color="primary">RnR</mat-checkbox>

          </div>
          <div>
            <mat-label>Differential</mat-label>
              <mat-checkbox class="label-top" formControlName="differentialRebuild" color="primary">Rebuild</mat-checkbox>
              <mat-checkbox class="label-top" formControlName="differentialRnR" color="primary">RnR</mat-checkbox>

          </div>
          <div >
            <mat-label >Transfer Case</mat-label>
            <mat-checkbox class="label-top" formControlName="transferCaseRebuild" color="primary">Rebuild</mat-checkbox>
            <mat-checkbox class="label-top" formControlName="transferCaseRnR" color="primary">RnR</mat-checkbox>
          </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <mat-checkbox class="label-top" formControlName="otherWarrantyCompanies" color="primary">Other Warranty Companies</mat-checkbox>
          </div>
          <div class="col-4">
            <mat-form-field class="w-100">
              <mat-label>Towing Service</mat-label>
                <mat-select  formControlName="towingService"  >
                  <mat-option>--</mat-option>
                  <mat-option *ngFor="let towingService of towingServices | keyvalue" [value]="towingService.value">
                    {{towingService.key}}
                   </mat-option>
                  </mat-select> 
             
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="w-100">
              <mat-label>Type of payment</mat-label>
              <mat-select  formControlName="paymentType"  >
                <mat-option>--</mat-option>
                <mat-option *ngFor="let paymentType of paymentTypes | keyvalue" [value]="paymentType.key">
                  {{paymentType.key}}
                </mat-option>
              </mat-select>         
            </mat-form-field>
          </div>
          
        </div>
        <div class="row">
          <div class="col-6">
            <mat-form-field class="w-100">
              <mat-label>Types of specialty equipments</mat-label>
              <input  formControlName="specialtyEquipment" matInput 
                      >
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100">
              <mat-label>Types of special repairs</mat-label>
              <input  formControlName="specialtyRepairs"  matInput 
                      >

            </mat-form-field>
          </div>
        </div>
        <div class="row">
          
          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>Labour Distance</mat-label>
              <input id="labourDistance" formControlName="labourDistance" type="text" matInput 
                      mask="separator.2" thousandSeparator="," suffix=" Km">
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>Parts Distance</mat-label>
              <input id="partDistance" formControlName="partDistance" type="text" matInput 
                      mask="separator.2" thousandSeparator="," suffix=" Km">
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>Parts Time</mat-label>
                <mat-select  formControlName="partsTime"  >
                  <mat-option>--</mat-option>
                    <mat-option *ngFor="let PartRepairTime of repairTimes " [value]="PartRepairTime">
                          {{PartRepairTime}}
                      </mat-option>
                    </mat-select>   
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>Labour Time</mat-label>  
                <mat-select  formControlName="labourTime"  >
                  <mat-option>--</mat-option>
                    <mat-option *ngFor="let LabourRepairTime of repairTimes " [value]="LabourRepairTime">
                        {{LabourRepairTime}}
                      </mat-option>
                     </mat-select>   
            </mat-form-field>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="float-right">
  <button mat-button mat-dialog-close class="btn" type="button" (click)="cancel()">
    <fa-icon [icon]="faWindowClose" class="pr-2 text-danger"></fa-icon> Cancel
  </button>
  <button mat-button class="btn" type="button" (click)="checkData()">
    <fa-icon [icon]="faSave" class="pr-2 text-success"></fa-icon> Save
  </button>
  
</mat-dialog-actions>
