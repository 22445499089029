import {Component, OnInit, ViewChild} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {forkJoin, Subject} from 'rxjs';
import { Title } from "@angular/platform-browser";

import { environment } from '../../../../environments/environment';

/*-- Components - Dynamic --*/
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';

/*-- Components - Static --*/
import { AppComponent } from '../../../app.component';

/*-- Enums --*/
import { MigrationType, MigrationStatus } from '../enums/migration.enum';
import { Sidebar } from '../../../enums/sidebar.enum';

/*-- Interfaces --*/
import { IMigrationStatus } from '../interfaces/migration.interface';

/*-- Pipes --*/
import { ArrayFilterPipe } from 'src-private/app/pipes/array-filter.pipe';

/*-- Services --*/
import { BreakpointService } from '../../../services/breakpoint.service';
import { DynamicComponentService } from '../../../services/dynamic-component.service';
import { MigrationService } from '../services/migration.service';

/*-- Third Party --*/
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { faRedoAlt, faSearch, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import {DataTableDirective} from "angular-datatables";

@Component({
  selector: 'app-migration',
  templateUrl: './migration.component.html',
  styleUrls: ['./migration.component.scss']
})
export class MigrationComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;

  private migrationCoreDealers: IMigrationStatus[];
  private migrationTireRimDealers: IMigrationStatus[];
  private migrationType = MigrationType;
  private migrationStatus = MigrationStatus;

  public bp: number;
  public filteredMigrationDealers: IMigrationStatus[] = [];
  public form: UntypedFormGroup;
  public faRedoAlt = faRedoAlt;
  public faSyncAlt = faSyncAlt;
  public faSearch = faSearch;

  public dtOptions: DataTables.Settings;
  dtTrigger: Subject<any> = new Subject<any>();
  public dtLoaded: boolean = false;

  constructor(
    private appComponent: AppComponent,
    private arrayFilterPipe: ArrayFilterPipe,
    private breakpointService: BreakpointService,
    private dynamicComponentService: DynamicComponentService,
    private formBuilder: UntypedFormBuilder,
    private migrationService: MigrationService,
    private toastrService: ToastrService,
    private titleService: Title
  ) {
    breakpointService.bp.subscribe(x => this.bp = x);
  }

  //#region - Lifecycle
  ngOnInit(): void {
    this.titleService.setTitle("Migration");
    if (!this.bp) {
      this.bp = this.breakpointService.getBreakpoint();
    }

    this.form = this.formBuilder.group({
      migrationStatus: ['Dealers'],
      migrationType: ['Core'],
      search: ['']
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      paging: true
    };

    this.blockUI.start();

    forkJoin(
      this.retrieveMigrationCoreDealers(),
      this.retrieveMigrationTireRimDealers())
      .subscribe(
        () => {
          this.filterResults();

          this.blockUI.stop();
        }
      );
  }
  //#endregion

  //#region - Getters
  get f() { return this.form.controls; }
  //#endregion

  //#region - Events
  onMigrateClick(event, dealer: IMigrationStatus): void {
    event.stopPropagation();

    this.migrateRollbackDealer(dealer);
  }

  onMigrationStatusChange(): void {
    this.filterResults();
  }

  onMigrationTypeChange(): void {
    this.filterResults();
  }

  onRollbackClick(event, dealer: IMigrationStatus): void {
    event.stopPropagation();

    this.migrateRollbackDealer(dealer);
  }

  onSearchTextEscKeyDown(): void {
    this.form.get('search').setValue('');
  }

  onSearchTextKeyUp(): void {
    this.filterResults();
  }

  //#endregion

  //#region - Private Methods
  private filterResults(): void {
    const migrationStatus = this.form.value.migrationStatus;
    const migrationType = this.form.value.migrationType;
    const search = this.form.value.search;
    const searchFields = {'vendorId': search, 'alias': search};

    let results: IMigrationStatus[];

    // filter on type
    switch (migrationType) {
      case 'Core':
        results = this.migrationCoreDealers;

        break;
      case 'TireRim':
        results = this.migrationTireRimDealers;

        break;
    }

    // filter on status
    results = (migrationStatus === 'Dealers') ? results.filter(e => !e.migratedDate) : results.filter(e => e.migratedDate);

    // filter on search
    results = (search === '') ? results : this.arrayFilterPipe.transform(results, searchFields);

    if (this.dtLoaded) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.table(0).destroy());
    }

    this.filteredMigrationDealers.splice(0, this.filteredMigrationDealers.length);
    this.filteredMigrationDealers.push(...results);
    this.dtTrigger.next(true);
    this.dtLoaded = true;
  }

  private migrateRollbackDealer(dealer: IMigrationStatus): void {
    const migrationStatus = this.form.value.migrationStatus;
    const migrationType = this.form.value.migrationType;
    const sidebar = Sidebar.Top;
    let instance: any;

    this.dynamicComponentService.setRootViewContainerRef(this.appComponent.dynamicComponentTop);

    instance = this.dynamicComponentService.addDynamicComponent(ConfirmModalComponent);

    instance.action = migrationStatus === 'Dealers' ? 'migrate' : 'rollback';
    instance.dealer = dealer.alias;
    instance.sidebar = sidebar;
    instance.title = migrationStatus === 'Dealers' ? 'Migrate Dealer Confirmation' : 'Rollback Dealer Confirmation';

    instance.modalResponse.subscribe(
      response => {
        if (response) {
          this.blockUI.start();

          switch (migrationType) {
            case MigrationType.Core:

              switch (migrationStatus) {
                case MigrationStatus.Dealers:
                  this.migrateCore(dealer.vendorId).then(
                    () => {
                      this.retrieveMigrationCoreDealers().then(
                        () => {
                          this.filterResults();

                          this.blockUI.stop();

                          this.dynamicComponentService.closeAndClear(this.appComponent, sidebar);
                        }
                      );
                    }
                  );

                  break;
                case MigrationStatus.Migrated:
                  this.rollbackCore(dealer.vendorId).then(
                    () => {
                      this.retrieveMigrationCoreDealers().then(
                        () => {
                          this.filterResults();

                          this.blockUI.stop();

                          this.dynamicComponentService.closeAndClear(this.appComponent, sidebar);
                        }
                      );
                    }
                  );

                  break;
              }

              break;
            case MigrationType.TireRim:

              switch (migrationStatus) {
                case MigrationStatus.Dealers:
                  this.migrateTireRim(dealer.vendorId).then(
                    () => {
                      this.retrieveMigrationTireRimDealers().then(
                        () => {
                          this.filterResults();

                          this.blockUI.stop();

                          this.dynamicComponentService.closeAndClear(this.appComponent, sidebar);
                        }
                      );
                    }
                  );

                  break;
                case MigrationStatus.Migrated:
                  this.rollbackTireRim(dealer.vendorId).then(
                    () => {
                      this.retrieveMigrationTireRimDealers().then(
                        () => {
                          this.filterResults();

                          this.blockUI.stop();

                          this.dynamicComponentService.closeAndClear(this.appComponent, sidebar);
                        }
                      );
                    }
                  );

                  break;
              }

              break;
          }
        } else {
          this.dynamicComponentService.closeAndClear(this.appComponent, sidebar);
        }
      });

    this.appComponent.sidebarTopOpened = true;
  }
  //#endregion

  //#region - API Methods
  private migrateCore(dealerId: number): Promise<{}> {
    const title = 'Migrate Dealer (Core)';

    const promise = new Promise((resolve, reject) => {
      this.migrationService.dealerMigrateCore(dealerId).subscribe(
        () => {
          // show toast
          this.toastrService.success('Your dealer has been successfully migrated.', title);

          resolve(true);
        },
        () => {
          // show error
          this.toastrService.error(environment.messages.apiError, title);

          reject();
        }
      );
    });

    return promise;
  }

  private migrateTireRim(dealerId: number): Promise<{}> {
    const title = 'Migrate Dealer (Core)';

    const promise = new Promise((resolve, reject) => {
      this.migrationService.dealerMigrateTireRim(dealerId).subscribe(
        () => {
          // show toast
          this.toastrService.success('Your dealer has been successfully migrated.', title);

          resolve(true);
        },
        () => {
          // show error
          this.toastrService.error(environment.messages.apiError, title);

          reject();
        }
      );
    });

    return promise;
  }

  private retrieveMigrationCoreDealers(): Promise<{}> {
    const promise = new Promise((resolve) => {
      this.migrationService.dealerMigrationCoreStatus().subscribe(
        response => {
          const data: IMigrationStatus[] = response;

          this.migrationCoreDealers = data;

          resolve(true);
        }
      );
    });

    return promise;
  }

  private retrieveMigrationTireRimDealers(): Promise<{}> {
    const promise = new Promise((resolve) => {
      this.migrationService.dealerMigrationTireRimStatus().subscribe(
        response => {
          const data: IMigrationStatus[] = response;

          this.migrationTireRimDealers = data;

          resolve(true);
        }
      );
    });

    return promise;
  }

  private rollbackCore(dealerId: number): Promise<{}> {
    const title = 'Rollback Dealer (Core)';

    const promise = new Promise((resolve, reject) => {
      this.migrationService.dealerRollbackCore(dealerId).subscribe(
        () => {
          // show toast
          this.toastrService.success('Your dealer has been successfully rolled back.', title);

          resolve(true);
        },
        () => {
          // show error
          this.toastrService.error(environment.messages.apiError, title);

          reject();
        }
      );
    });

    return promise;
  }

  private rollbackTireRim(dealerId: number): Promise<{}> {
    const title = 'Rollback Dealer (Core)';

    const promise = new Promise((resolve, reject) => {
      this.migrationService.dealerRollbackTireRim(dealerId).subscribe(
        () => {
          // show toast
          this.toastrService.success('Your dealer has been successfully rolled back.', title);

          resolve(true);
        },
        () => {
          // show error
          this.toastrService.error(environment.messages.apiError, title);

          reject();
        }
      );
    });

    return promise;
  }
  //#endregion
}
