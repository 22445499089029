<form [formGroup]="eazeeTrakForm" (ngSubmit)="onEazeeTrakFileSubmit()" novalidate>

  <div class="sidebar-header">

    <h4 class="sidebar-title">Add EazeeTrak File</h4>

  </div>
  <div class="sidebar-actions">

    <button class="btn btn-block btn-icon btn-save"
            type="submit">
      <fa-icon [icon]="faCheck" class="text-success"></fa-icon> 
      Submit
    </button>

    <button class="btn btn-block btn-icon btn-cancel"
            type="button"
            (click)="onEazeeTrakFileCancelClick()">
      <fa-icon [icon]="faTimes" class="text-danger"></fa-icon> 
      Cancel
    </button>

  </div>
  <div class="sidebar-body">

    <div class="form-group-static">
      <label for="dealer">Dealer</label>
      <input class="w-100"
             id="dealer"
             type="text"
             readonly
             formControlName="dealer">
    </div>

    <ul class="list-unstyled contact-container w-100">
      <li class="contact-cell">

        <mat-form-field class="w-100">
          <mat-select placeholder="Contact"
                      required
                      formControlName="contact">
            <mat-option *ngFor="let contact of contacts"
                        [value]="contact.id">
              <span>{{contact.firstName}} {{contact.lastName}}</span>
              <span *ngIf="contact.title"> ({{contact.title}})</span>
            </mat-option>
          </mat-select>
          <mat-error>
            <mat-error *ngIf="isSubmitted && f.contact.errors?.required">Contact is required</mat-error>
          </mat-error>
        </mat-form-field>

      </li>
      <li class="contact-days-cell">

        <button class="btn btn-sm btn-dark"
                (click)="onAddContactClick($event)">
          <fa-icon [icon]="faPlus"></fa-icon> 
        </button>

      </li>
    </ul>

    <mat-form-field class="w-100">
      <mat-select placeholder="Reason Group"
                  required
                  formControlName="reasonGroup"
                  (selectionChange)="onReasonGroupSelectionChange()">
        <mat-option *ngFor="let reasonGroup of reasonGroups"
                    [value]="reasonGroup.eazeeTrakReasonGroupId">
          {{reasonGroup.eazeeTrakReasonGroup}}
        </mat-option>
      </mat-select>
      <mat-error>
        <mat-error *ngIf="isSubmitted && f.reasonGroup.errors?.required">Reason Group is required</mat-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
      <mat-select placeholder="Reason"
                  required
                  formControlName="reason">
        <mat-option *ngFor="let reason of reasons"
                    [value]="reason.eazeeTrakReasonId">
          {{reason.eazeeTrakReason}}
        </mat-option>
      </mat-select>
      <mat-error>
        <mat-error *ngIf="isSubmitted && f.reason.errors?.required">Reason is required</mat-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
      <textarea placeholder="Comment"
                required
                formControlName="comment"
                matInput></textarea>
      <mat-error>
        <mat-error *ngIf="isSubmitted && f.comment.errors?.required">Comment is required</mat-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
      <mat-select placeholder="Result"
                  required
                  formControlName="result"
                  (selectionChange)="onResultSelectionChange()">
        <mat-option *ngFor="let result of eazeeTrakResults"
                    [value]="result.eazeeTrakResultId">
          {{result.label}}
        </mat-option>
      </mat-select>
      <mat-error>
        <mat-error *ngIf="isSubmitted && f.result.errors?.required">Result is required</mat-error>
      </mat-error>
    </mat-form-field>

    <div [formGroup]="notificationForm" class="form-group-static"
         *ngIf="eazeeTrakFileNotifications">
      <label>Notification</label>
      <ul class="list-unstyled notification-recipients">
        <li *ngFor="let notification of eazeeTrakFileNotifications">

          <mat-checkbox color="primary"
                        formControlName="{{notification.userId}}"
                        [disabled]="notification.isPreSelected === 1">{{notification.firstName}}&nbsp;{{notification.lastName}}</mat-checkbox>

        </li>
      </ul>
    </div>

    <ul class="list-unstyled pin-container w-100">
      <li class="pin-cell">

        <mat-checkbox color="primary"
                      formControlName="pin">Pin</mat-checkbox>

      </li>
      <li class="pin-days-cell">

        <mat-form-field class="w-100">
          <mat-select formControlName="pinDays"
                      [disabled]="!f.pin.value">
            <mat-option [value]="30">30</mat-option>
            <mat-option [value]="60">60</mat-option>
            <mat-option [value]="90">90</mat-option>
            <mat-option [value]="9999">Indefinitely</mat-option>
          </mat-select>
        </mat-form-field>

      </li>
    </ul>

    <mat-checkbox color="primary"
                  formControlName="sendCopyOfMail">Send me a copy of email</mat-checkbox>

  </div>
</form>
