<fieldset class="fieldset-form height">
  <legend>
    Claim
  </legend>
  <table>
    <tr>
      <td class="row-padding">Claim ID:</td>
      <td class="row-padding">{{enterpriseId}}</td>
    </tr>
    <tr>
      <td class="row-padding">Warranty ID:</td>
      <td class="row-padding">{{warrantyId}}</td>
    </tr>
    <tr>
      <td class="row-padding">Adjuster:</td>
      <td class="row-padding">{{assignedAdjusterName}}</td>
    </tr>
    <tr>
      <td class="row-padding">Open Date:</td>
      <td class="row-padding">{{claimsDateEntered}}</td>
    </tr>
    <tr>
      <td class="row-padding">Close Date:</td>
      <td class="row-padding">{{claimsDateClosed}}</td>
    </tr>
    <tr>
      <td class="row-padding">Claim Status:</td>
      <td class="row-padding">{{claimsStatus}}</td>
    </tr>
    <tr>
      <td class="row-padding">Current Mileage:</td>
      <td class="row-padding">{{claimCurrentOdReading}}</td>
    </tr>
    <tr *ngIf="claimAuditStatus > 0">
      <td class="row-padding">Audit Status:</td>
      <td *ngIf="claimAuditStatus == 1" class="row-padding">Not Applicable</td>
      <td *ngIf="claimAuditStatus == 2" class="row-padding">Not Approved</td>
      <td *ngIf="claimAuditStatus == 3" class="row-padding">In Review</td>
      <td *ngIf="claimAuditStatus == 4" class="row-padding">Approved</td>
      <td *ngIf="claimAuditStatus == 5" class="row-padding">Denied</td>
    </tr>
    <tr *ngIf="claimAuditApproved">
      <td class="row-padding">Insurer Approved By:</td>
      <td class="row-padding">{{claimAuditApprover}}</td>
    </tr>
    <tr *ngIf="claimAuditDenied">
      <td class="row-padding">Insurer Denied By:</td>
      <td class="row-padding">{{claimAuditApprover}}</td>
    </tr>
    <tr *ngIf="claimAuditApproved || claimAuditDenied">
      <td *ngIf="claimAuditApproved" class="row-padding">Insurer Approved On:</td>
      <td *ngIf="claimAuditDenied" class="row-padding">Insurer Denied On:</td>
      <td class="row-padding">{{claimAuditApprovedDate}}</td>
    </tr>
  </table>
  <div class="center mt-2" *ngIf="!claimAuditApproved && !claimAuditDenied && showApproveDecline">
    <label class="approveText">Claim amount exceeds $1,500 for MBI. Approval required.</label>
    <br />
    <div *ngIf="!(isGeneralAuditor || isInsurerAuditor) && !claimAuditApproved && !claimAuditDenied && showApproveDecline">
      <a [href]="notifyEmail" class="btn btn-success mx-3" type="button">Notify Insurer</a>
    </div>
    <div *ngIf="(isGeneralAuditor || isInsurerAuditor) && !claimAuditApproved && !claimAuditDenied && showApproveDecline">
      <button class="btn btn-success mx-3" type="button" (click)="approve()">Approve</button>
      <button class="btn btn-danger mx-3" type="button" (click)="decline()">Decline</button>
    </div>
  </div>
</fieldset>