import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { FrameworkComponent } from "src-private/app/shared/framework/framework.component";
import { ActivatedRoute } from "@angular/router";
import { ClaimsService } from "../../services/claims.service";
import { AccountService } from "src-private/app/areas/account/services/account.service";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ToastrService } from "ngx-toastr";
import { environment } from "src-private/environments/environment";
import { NoteAddDialogComponent } from "../note-add-dialog/note-add-dialog.component";
import { WarrantyNotesService } from "../../services/warranty-notes.service";
import { TableAdapterComponent } from "src-private/app/shared/table-adapter/table-adapter.component";
import { IWarrantyNote } from "../../interfaces/warranty-note.interface";
import { CellBuilder } from "src-private/app/shared/table-adapter/cell-builder/cell-builder";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-warranty-notes",
  templateUrl: "./warranty-notes.component.html",
  styleUrls: ["./warranty-notes.component.scss"],
})

export class WarrantyNotesComponent extends FrameworkComponent implements OnInit, AfterViewInit {

  @Input() columns: CellBuilder[];
  @Input() warrantyId: number;

  public faPlus = faPlus;
  public claimId: number;
  public isLoading = false;

  public canAddNotes: boolean;

  @ViewChild("warrantyNotesTable")
  warrantyNotesTable: TableAdapterComponent<IWarrantyNote>;

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private claimsService: ClaimsService,
    private accountService: AccountService,
    private noteService: WarrantyNotesService,
    private toastr: ToastrService,
    ) {
    super();
    this.claimId = this.activatedRoute.snapshot.data['claimId']
  }

  ngOnInit(): void {
    this.isLoading = true;
    super.build("WarrantyNotesComponent", 'app-warranty-notes')
    this.canAddNotes = this.accountService.isAdjuster() || this.accountService.isClaimsManager() || this.accountService.isAssistantClaimsManager() || this.accountService.isDealerServices();
    this.isLoading = false;
  }

  ngAfterViewInit(): void {
    this.refreshNotes();
  }

  addNote(): void {
    const addNoteDialogRef = this.dialog.open(NoteAddDialogComponent, {
      width: "600px",
    });

    addNoteDialogRef.afterClosed().subscribe((res) => {
      if (res.data) {
        res.data.warrantyId = this.warrantyId;
        res.data.claimId = this.claimId;
        this.noteService.add(res.data).subscribe(() => {
          this.toastr.success("Note successfully added. ", "Note Centre");
          this.refreshNotes();
        },
        error => {
          this.toastr.error(environment.messages.apiError, "Unable to add note");
        });
      }
    });
  }

  refreshNotes() {
    this.warrantyNotesTable.refresh(
      this.noteService.retrieveAll(this.warrantyId)
    );
  }

  public get isClaimClosed(): boolean {
    return this.claimsService.getSelectedClaim?.IsClaimClosed
  }

  public get canEditClosedClaim(): boolean {
    return this.accountService.isClaimsManager();
  }


}