import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faSave, faWindowClose, faCarBuilding } from '@fortawesome/pro-solid-svg-icons';
import { AppConfig, APP_CONFIG } from 'src-private/app/app.config';
import { IRepairCentre } from '../../../interfaces/repair-centre.interface';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SelectionModel } from '@angular/cdk/collections';
import { RepairCentreService } from '../../../services/repair-centre.service'
import { ActivatedRoute } from '@angular/router';
import { resolve } from '@angular/compiler-cli/src/ngtsc/file_system';
import { AppValidators } from 'src-private/app/validators/app-validators';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { debounceTime, expand, fromEvent, iif, map, Observable, observable, of, skipWhile, Subscription, take } from 'rxjs';
import { BlockUI, BlockUIService, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { environment } from "src-private/environments/environment";

@Component({
  selector: 'app-repair-centre-search-dialog',
  templateUrl: './repair-centre-search-dialog.component.html',
  styleUrls: ['./repair-centre-search-dialog.component.scss']
})
export class RepairCentreSearchDialogComponent implements OnInit {
  @BlockUI('repair') blockUI: NgBlockUI;

  private readonly globalWarrantyLatLng : google.maps.LatLng  = new google.maps.LatLng(42.98778779203776, -81.24397592146798) //Global Warranty Geo-location

  public faSave = faSave;
  public faWindowClose = faWindowClose;
  public faCarBuilding = faCarBuilding;

  public repairCentreId: number;
  public predictedCities: string[];
  public repairCentres: IRepairCentre[] = [];

  public filteredRepairCentres: IRepairCentre[] = [];
  public predictedRepairCentres: IRepairCentre[] = [];
  public selectedFilter: string;
  private isRepairCentreSelected: boolean = false;

  public optionsMap = new Map();
  selection: SelectionModel<any>;
  public repairCentreRows: MatTableDataSource<any>;
  public repairCentreColumns = [
    { friendly: 'Name', raw: 'name' },
    { friendly: 'Address', raw: 'address' },
    { friendly: 'Postal Code', raw: 'postalCode' },
    { friendly: 'Phone', raw: 'phone' },
    { friendly: "Status", raw: 'status' }
  ];
  public repairCentreColumnsNames = this.repairCentreColumns.map(column => column.raw);
  private mouseOverInfoWindow: boolean = false;

  public selectedRepairCentre: IRepairCentre;
  private markers: google.maps.Marker[] = [];
  selectedCity: string = '';
  userSelectedCity: google.maps.LatLng;
  isMapInitializing: boolean = true;
  resultRadius: number;
  fromEventCall: boolean = false;
  dragEvent: boolean = false
  functionCalled: boolean = false;
  isZoomCalled: boolean = false;
  searchRadius: number;
  mapDragEvent: google.maps.MapsEventListener;
  mapZoomEvent: google.maps.MapsEventListener;
  isNoResult: boolean = false;
  apiSubscription: Subscription[] = []
  retryLimit: number = 10;
  retryCount: number = 1;
  userSelectedProvince: string;
  userSelectedCountry: string;
  userSelectedProvincelongName: string
  isSearchByCountryCalled: boolean = false;
  isLookup: boolean = false;
  keyUpSubscription: Subscription;
  public statusForm: UntypedFormGroup;

  @ViewChild('mapContainer') gmap: ElementRef;
  @ViewChildren('mapWidget') gmapWidget: QueryList<ElementRef>;
  map: google.maps.Map;
  service: google.maps.places.PlacesService;
  geocoder: google.maps.Geocoder;
  infoWindow: google.maps.InfoWindow;
  autoCompleteService: any;
  autoCompleteSessionToken: any;

  @ViewChild('citiesAutoCompleteRef', { static: true, read: MatAutocompleteTrigger })
  citiesAutoCompleteRef: MatAutocompleteTrigger;
  @ViewChild('repairCentreInput', { static: true, read: MatAutocompleteTrigger })
  repairCentreInput: MatAutocompleteTrigger;

  constructor(@Inject(APP_CONFIG) private config: AppConfig,
    private fb: UntypedFormBuilder,
    private repairCentreService: RepairCentreService,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<RepairCentreSearchDialogComponent>,
    private blockUIService: BlockUIService,
    private toastrService: ToastrService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) data) {
    this.selection = new SelectionModel<any>(false, []);
    this.repairCentreId = data;
  }

  ngOnInit() {
    this.resultRadius = this.config.mapCityRadius;
    this.searchRadius = this.config.mapCityRadius
    this.selectedFilter = 'All'
    this.getDefaultCoordinates().then(res => {
      this.mapInitializer(res);

      if (this.repairCentreId) {
        this.repairCentreService.retrieve(this.repairCentreId).subscribe(res => {
          this.selectedRepairCentre = res;

          var currentRepairCentreDisplay = {
            name: this.selectedRepairCentre.name,
            address: this.selectedRepairCentre.address,
            postalCode: this.selectedRepairCentre.postalCode,
            phone: this.selectedRepairCentre.phone,
          };

          (<HTMLInputElement>document.getElementById('repairCentreInput')).value = this.getRepairCentreDisplay(currentRepairCentreDisplay);

          let fullAddress = `${this.selectedRepairCentre.city}, ${this.selectedRepairCentre.province}, ${this.selectedRepairCentre.country}`;

          this.geocodeCity(fullAddress).then(res => {
            this.getRepairCentresByCity(res.geometry.location as google.maps.LatLng)
          })
        });
      }
      else {
        this.setKeyUpEvent()
        this.getRepairCentresByCity(res as google.maps.LatLng);
      }
    }).finally(() => {
      this.isMapInitializing = false;
    });

    this.statusForm = this.fb.group({
      status: ['All'],
    });

    this.dialogRef.backdropClick().subscribe(() => {
      this.unSubscribeAPI();
      this.cancel();
    })
    this.dialogRef.beforeClosed().subscribe(() => {
      this.unSubscribeAPI();
    })
  }

  getDefaultCoordinates() {
    return new Promise((resolve) => {
      if (this.repairCentreId) {
        this.repairCentreService.retrieve(this.repairCentreId).subscribe(res => {
          this.userSelectedCity = new google.maps.LatLng(res.latitude, res.longitude)
          resolve(new google.maps.LatLng(res.latitude, res.longitude));
        });
      }
      else {
        resolve(this.globalWarrantyLatLng);
      }
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          resolve(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
        });
      }
    });
  }

  mapInitializer(defaultCoordinates) {
    const mapOptions: google.maps.MapOptions = {
      center: defaultCoordinates,
      zoom: 8,
      minZoom: 5,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DEFAULT,
        position: google.maps.ControlPosition.TOP_RIGHT
      }
    };
    this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);
    this.geocoder = new google.maps.Geocoder();
    this.infoWindow = new google.maps.InfoWindow({
      content: ''
    });
    this.autoCompleteService = new google.maps.places.AutocompleteService();
    this.autoCompleteSessionToken = new google.maps.places.AutocompleteSessionToken();

    this.setMapEvent();
    google.maps.event.addListener(this.map, 'idle', () => {
      if (this.isZoomCalled) {
        if(!this.blockUIService.isActive('repair')){
          this.blockUIService.start('repair')
        }
        this.resultRadius = this.getVisibleRadius() < this.config.mapCityRadius ? this.config.mapCityRadius : this.getVisibleRadius();
        this.fromEventCall = true;
        this.userSelectedCity = this.map.getCenter()
        setTimeout(() => {
          if (!this.functionCalled) {
            this.getRepairCentresByCity(this.userSelectedCity)
          }
        }, 2000);
        this.isZoomCalled = false
      }

    })
  }

  sliderBox(controlDiv, map, place, selectedRepairCentreData) {

    if (document.contains(document.getElementById("sliderbox"))) {
      document.getElementById("map_canvas").innerHTML = null;
    }
    var box = document.createElement('div');
    box.id = 'sliderbox';
    box.style.height = '100%';
    box.style.width = '100%';
    box.style.backgroundColor = 'white';
    box.style.position = "relative";
    box.style.transition = '0.5s';
    box.style.zIndex = "1";
    controlDiv.appendChild(box);

    var toggleBtn = document.createElement('button');
    toggleBtn.id = 'toggleBtn';
    toggleBtn.type = 'button';
    toggleBtn.innerHTML = '<span>&#8249;</span>';
    toggleBtn.style.width = "17px";
    toggleBtn.style.fontSize = "20px";
    toggleBtn.style.height = '30px';
    toggleBtn.style.position = "absolute";
    toggleBtn.style.right = "-17px";
    toggleBtn.style.border = "none";
    toggleBtn.onclick = function () {
      var container = document.getElementById('sliderbox');
      container.style.width = '0px';
      let children: HTMLCollectionOf<HTMLElement> = container.children as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < children.length; i++) {
        if (children[i].id != 'imgLoc')
          children[i].style.display = "none";
      }
      document.getElementById('toggleBtnClose').style.display = "block";
    };
    toggleBtn.style.borderLeft = "1px solid #D4D4D4";

    box.appendChild(toggleBtn);

    toggleBtn = document.createElement('button');
    toggleBtn.id = 'toggleBtnClose';
    toggleBtn.type = 'button';
    toggleBtn.innerHTML = '<span>&#8250;</span>';
    toggleBtn.style.width = "17px";
    toggleBtn.style.fontSize = "20px";
    toggleBtn.style.height = '30px';
    toggleBtn.style.position = "absolute";
    toggleBtn.style.right = "-17px";
    toggleBtn.style.border = "none";
    toggleBtn.style.display = "none";
    toggleBtn.onclick = function () {
      var container = document.getElementById('sliderbox');
      container.style.width = '100%';
      let children: HTMLCollectionOf<HTMLElement> = container.children as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < children.length; i++) {
        if (children[i].id != 'imgLoc')
          children[i].style.display = "block";
      }
      document.getElementById('toggleBtnClose').style.display = "none";
    };
    toggleBtn.style.borderLeft = "1px solid #D4D4D4";

    box.appendChild(toggleBtn);

    if (place.photos != undefined && place.photos.length > 0) {
      var img = document.createElement('img');
      img.id = 'imgLoc';
      img.src = place.photos[0].getUrl();
      img.style.height = "35%";
      img.style.maxHeight = "150px";
      img.style.width = "100%";
      box.appendChild(img);
    }
    else {
      var rating = document.createElement("div");
      rating.innerHTML = document.getElementById("buildingIcon").innerHTML;
      rating.style.width = "100%";
      rating.style.textAlign = "center";
      box.appendChild(rating);
    }

    if (selectedRepairCentreData != undefined && (selectedRepairCentreData.name != undefined && !AppValidators.isEmptyOrSpaces(selectedRepairCentreData.name))) {
      var name = document.createElement('h5');
      name.id = 'locName';
      name.innerText = selectedRepairCentreData.name;
      name.style.width = "100%";
      name.style.padding = "10px";
      box.appendChild(name);
    }

    if (place.rating != undefined) {
      var rating = document.createElement("h6");
      rating.className = 'rating';
      rating.innerHTML = '<b style="font-weight:500;">Rating:</b> ' + place.rating + '/5';
      rating.style.width = "100%";
      rating.style.padding = "10px";
      rating.style.paddingTop = "0px";
      rating.style.fontWeight = '300';
      box.appendChild(rating);
    }

    if (selectedRepairCentreData != undefined) {
      if (selectedRepairCentreData.website != undefined && !AppValidators.isEmptyOrSpaces(selectedRepairCentreData.website)) {
        var rating = document.createElement("h6");
        rating.innerHTML = '<b style="font-weight:500;">Website:</b> <a target="_blank" href="//' + selectedRepairCentreData.website + '"> ' + selectedRepairCentreData.website + ' </a>';
        rating.style.width = "100%";
        rating.style.padding = "10px";
        rating.style.paddingTop = "0px";
        rating.style.fontWeight = '300';
        box.appendChild(rating);
      }

      if (selectedRepairCentreData.email != undefined && !AppValidators.isEmptyOrSpaces(selectedRepairCentreData.email)) {
        var rating = document.createElement("h6");
        rating.innerHTML = '<b style="font-weight:500;">E-mail:</b> <a href="mailto:' + selectedRepairCentreData.email + '"> ' + selectedRepairCentreData.email + ' </a>';
        rating.style.width = "100%";
        rating.style.padding = "10px";
        rating.style.paddingTop = "0px";
        rating.style.fontWeight = '300';
        box.appendChild(rating);
      }

      if (selectedRepairCentreData.phone != undefined && !AppValidators.isEmptyOrSpaces(selectedRepairCentreData.phone)) {
        var rating = document.createElement("h6");
        rating.innerHTML = '<b style="font-weight:500;">Phone:</b> ' + selectedRepairCentreData.phone;
        rating.style.width = "100%";
        rating.style.padding = "10px";
        rating.style.paddingTop = "0px";
        rating.style.fontWeight = '300';
        box.appendChild(rating);
      }
    }

    var address = document.createElement('h6');
    address.innerHTML = '<b style="font-weight:500;">Address:</b> ' + place.formatted_address;
    address.style.width = "100%";
    address.style.padding = "10px";
    address.style.paddingTop = "0px";
    address.style.fontWeight = '300';
    box.appendChild(address);

    map.appendChild(controlDiv);
  }

  clear() {
    this.setKeyUpEvent();
    this.setMapMarkers(null);
    this.markers = [];
    this.selectedCity = '';
    this.predictedCities = [];
    this.predictedRepairCentres = []
    this.selectedRepairCentre = null;
    (document.getElementById('repairCentreInput') as HTMLInputElement).value = '';
    this.repairCentres = [];
    this.isRepairCentreSelected = false
    this.resetMap();
    this.repairCentreRows = new MatTableDataSource(null);
    this.filteredRepairCentres = []
    this.userSelectedCity = null;
    this.isMapInitializing = true;
    this.fromEventCall = false;
    this.resultRadius = this.config.mapCityRadius
    this.searchRadius = this.config.mapCityRadius;
    this.userSelectedCountry = null
    this.userSelectedProvince = null
    this.retryCount = 1
    this.isSearchByCountryCalled = false;
    this.unSubscribeAPI();
    this.setCityLocation(this.globalWarrantyLatLng, 12)
  }

  citiesAutoComplete(cityPartialText) {
    if (cityPartialText.length >= 3) {
      const predictedCitiesCallBack = (predictions, status) => {

        if (status === google.maps.places.PlacesServiceStatus.OK || predictions) {
          this.predictedCities = [];

          predictions.forEach((prediction) => {
            this.predictedCities.push(prediction.description);
          });
        }
      };

      let predictionParameters = {
        input: cityPartialText,
        types: ['locality', 'sublocality', 'administrative_area_level_3'],
        componentRestrictions: { country: ['ca', 'us'] },
        sessionToken: this.autoCompleteSessionToken
      };
      this.autoCompleteService.getPlacePredictions(predictionParameters, predictedCitiesCallBack);
    }

    else if (cityPartialText === "") {
      this.predictedCities = [];
      this.clear();
    }
  }

  repairCentreAutoComplete(repairCentrePartialText) {
    if (repairCentrePartialText !== '') {
      this.predictedRepairCentres = this.repairCentres.filter(r =>
        r.name.toLocaleLowerCase().startsWith(repairCentrePartialText.toLowerCase())
      )
    }
    else {
      this.predictedRepairCentres = this.repairCentres
      this.showLocation();
    }
    this.formatTable(this.predictedRepairCentres.filter(r => (this.selectedFilter == 'All' || r.preferred)))
  }

  geocodeCity(city, isForInfo: boolean = false) {
    return new Promise<google.maps.GeocoderResult>(resolve => {
      if (!isForInfo) {
        if (city.split(',').length == 4) {
          this.selectedCity = city.split(',').slice(1).join();
        }
        else {
          this.selectedCity = city;
        }
      }

      this.geocoder.geocode({
        'address': `${city}`
      }, (results, status) => {
        if (status === 'OK') {
          resolve(results[0]);
        }
      });
    })
  }

  getRepairCentresByCity(cityCoords: google.maps.LatLng, isCitySelection: boolean = false) {
    if (cityCoords == null) { return; }
    this.isMapInitializing = true
    this.functionCalled = true;
    let repairObservable = new Observable<IRepairCentre[]>();

    let backrepairCentreProvinceLookup = this.repairCenterProvinceLookup(this.userSelectedProvince, this.userSelectedCountry).pipe(expand(repairCenter => {

      if (repairCenter.length == 0 && this.isSearchByCountryCalled) {
        if(!this.repairCentreId){
          this.setCityLocation(this.globalWarrantyLatLng, 12);
        }
        this.blockUIService.stop('repair')
        return new Observable<IRepairCentre[]>()
      }
      else if (repairCenter.length == 0) {
        this.isSearchByCountryCalled = true;
        return this.repairCenterProvinceLookup('', this.userSelectedCountry)
      }
      else {
        this.geocodeCity(`${this.userSelectedProvincelongName} ${this.userSelectedCountry}`, true).then((res) => {
          if (this.isSearchByCountryCalled) {
            this.setMapZoom(5)
            this.removeMapEvent();
            this.map.setCenter(res.geometry.location)
          }
          else {
            this.removeMapEvent()
            this.setMapBounds(res.geometry.location, 300)
            this.setMapEvent()
          }
          this.fromEventCall = true
          this.isSearchByCountryCalled = false;
        })
        return new Observable<IRepairCentre[]>()
      }

    })).pipe(skipWhile(repairCenter => repairCenter.length == 0))

    if (this.fromEventCall || this.selectedRepairCentre) {
      repairObservable = this.repairCentreService.retrieveRepairCentresInCity(cityCoords.lat(), cityCoords.lng(), this.resultRadius)
    }
    else {
      repairObservable = this.repairCentreService.retrieveRepairCentresInCity(cityCoords.lat(), cityCoords.lng(), this.resultRadius).pipe(expand((repairCenter) => {

        if (repairCenter.length == 0) {
          this.retryCount++
          if (this.retryCount > this.retryLimit) {
            this.unSubscribeAPI();
            repairObservable = backrepairCentreProvinceLookup
            this.subscribeRepairCenterApi(repairObservable);
          }
          else {
            this.isNoResult = true;
            return this.repairCentreService.retrieveRepairCentresInCity(cityCoords.lat(), cityCoords.lng(), this.searchRadius += 50)
          }
        }

        else {
          if (this.isNoResult) {
            this.configMapBounds(cityCoords)
            this.isNoResult = false;
          }
          //It will break the loop
          return new Observable<IRepairCentre[]>()
        }
      })).pipe(skipWhile(repairCenter => repairCenter.length == 0))
    }

    this.subscribeRepairCenterApi(repairObservable, false, this.dragEvent, isCitySelection)
  }

  resetSelectedRepairCentre() {
    this.selectedRepairCentre = null;
    (<HTMLInputElement>document.getElementById('repairCentreInput')).value = "";
  }

  selectCity(city, event) {
    //ignores deselect trigger of previous selected
    if (event.isUserInput) {
      if(this.keyUpSubscription){
        this.keyUpSubscription.unsubscribe();
      }
      this.resultRadius = this.config.mapCityRadius;
      this.fromEventCall = false;
      if (!this.isLookup) {
        this.resetSelectedRepairCentre();
      }
      this.geocodeCity(city).then(res => {
        this.userSelectedProvince = res.address_components.filter(x => x.types.filter(y => y == "administrative_area_level_1").length > 0)[0].short_name
        this.userSelectedCountry = res.address_components.filter(x => x.types.filter(y => y == "country").length > 0)[0].short_name == 'US' ? 'USA' : res.address_components.filter(x => x.types.filter(y => y == "country").length > 0)[0].long_name
        this.userSelectedProvincelongName = res.address_components.filter(x => x.types.filter(y => y == "administrative_area_level_1").length > 0)[0].long_name
        this.userSelectedCity = res.geometry.location as google.maps.LatLng;
        this.getRepairCentresByCity(res.geometry.location as google.maps.LatLng, true)
      })
    }
  }

  showLocation(isCitySelection: boolean = false) {
    this.setMapMarkers(null);
    this.markers = [];
    let repairCentres: IRepairCentre[] = [];

    if (this.selectedRepairCentre) {
      this.isRepairCentreSelected = true;
      repairCentres.push(this.selectedRepairCentre);
    } else {
      this.isRepairCentreSelected = false;
      repairCentres = this.filteredRepairCentres;
    }

    if (isCitySelection || repairCentres.length == 0) {
      if (!this.fromEventCall) {
        if (this.userSelectedCity !== undefined && this.userSelectedCity !== null && this.userSelectedCity.lat() != 0 && this.userSelectedCity.lng() != 0) {
          this.setCityLocation(this.userSelectedCity, 11)
        }
        else{
          this.setCityLocation(this.globalWarrantyLatLng, 12)
        }
      }
    }

    repairCentres.forEach(repairCentre => {
      if ((repairCentre.latitude != null && repairCentre.longitude != null) && (repairCentre.latitude != 0 && (isFinite(repairCentre.latitude) && Math.abs(repairCentre.latitude) <= 90)) &&
        (repairCentre.longitude != 0 && (isFinite(repairCentre.longitude) && Math.abs(repairCentre.longitude) <= 180))) {
        console.log('Using Lat Long');
        this.createMarker(repairCentre, new google.maps.LatLng(repairCentre.latitude, repairCentre.longitude), !isCitySelection);
      }
      else { //if lat/long aren't defined, try geolocating the repair centre address
        console.log('Geolocating');
        this.geocodeCity(`${repairCentre.address}, ${repairCentre.city}, ${repairCentre.province}, ${repairCentre.country}`).then(res => {
          this.createMarker(repairCentre, res.geometry.location as google.maps.LatLng, !isCitySelection);
        })
      }
    });

    //  Repair center info widget will only shown to selected Repair Centre
    if (this.selectedRepairCentre) {
      var request = {
        query: this.selectedRepairCentre.address + ', ' + this.selectedRepairCentre.postalCode + ', ' + this.selectedRepairCentre.country,
        fields: ['photos', 'formatted_address', 'icon', 'name', 'rating', 'opening_hours', 'geometry', 'business_status', 'geometry.viewport', 'geometry.location', 'photos', 'place_id', 'plus_code', 'type']
      }
      this.service = new google.maps.places.PlacesService(this.map)
      this.service.findPlaceFromQuery(request, this.callback.bind(this));
    }

    this.setMapMarkers(this.map);
  }

  callback(results, status) {
    if (status == google.maps.places.PlacesServiceStatus.OK) {
      if (results) {
        var place = results[0];
        var sliderBoxDiv = document.createElement('div');
        this.sliderBox(sliderBoxDiv, this.gmapWidget.first.nativeElement, place, this.selectedRepairCentre);
        this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.gmapWidget.first.nativeElement);
      }
    }
    else {
      console.log('Address do not exist');
      // It will remove sliderBox of previously selected Repair Center. if address do not exist for selected Repair center
      if (document.contains(document.getElementById("sliderbox"))) {
        document.getElementById("map_canvas").innerHTML = null;
      }
    }
  }

  createMarker(repairCentre: IRepairCentre, location: google.maps.LatLng, center: boolean = true) {
    this.isMapInitializing = true;

    if (!this.fromEventCall && center) {
      this.map.setCenter(location);
      this.setMapZoom(this.isRepairCentreSelected ? 19 : 15)
    }
    var marker = new google.maps.Marker({
      map: this.map,
      position: location,
    });
    marker.addListener('mouseover', () => {
      this.infoWindow.setContent(
        `<p id="infoWindowContent"><strong>${repairCentre.name}</strong></p>
              <p>${repairCentre.address}, ${repairCentre.city}, ${repairCentre.province} ${repairCentre.postalCode}</p>`
      );
      this.infoWindow.setZIndex(-1);
      this.infoWindow.open(this.map, marker);
      this.addOpenInfoWindowListeners();
    });
    marker.addListener('mouseout', () => {
      setTimeout(() => {
        if (!this.mouseOverInfoWindow) {
          this.infoWindow.close();
        }
      }, 400);
    });
    this.markers.push(marker);
    this.isMapInitializing = false;
  }

  setMapMarkers(map: google.maps.Map | null) {
    this.markers.forEach(marker => {
      marker.setMap(map);
    });
  }

  addOpenInfoWindowListeners() {
    let infoWindowElement = ((document.getElementById('infoWindowContent') === null || document.getElementById('infoWindowContent') === undefined) ||
      (document.getElementById('infoWindowContent').parentNode === null || document.getElementById('infoWindowContent').parentNode === undefined) ||
      (document.getElementById('infoWindowContent').parentNode.parentNode === null || document.getElementById('infoWindowContent').parentNode.parentNode === undefined)) ?
      undefined : document.getElementById('infoWindowContent').parentNode.parentNode.parentNode;

    if (infoWindowElement) {
      infoWindowElement.addEventListener('mouseleave', () => {
        this.infoWindow.close();
        this.mouseOverInfoWindow = false;
      });

      infoWindowElement.addEventListener('mouseenter', () => {
        this.mouseOverInfoWindow = true;
      });
    }
  }

  formatTable(repairCentresToFormat: IRepairCentre[]) {
    let formattedRepairCenter = [];

    repairCentresToFormat.forEach(repairCentre => {
      let obj = {};
      this.repairCentreColumns.forEach(repairCentreColumns => {
          obj[repairCentreColumns.raw] = repairCentre[repairCentreColumns.raw];
      })
      this.optionsMap.set(obj, repairCentre);
      formattedRepairCenter.push(obj);
    })

    formattedRepairCenter.map(row => {
      if (row.selected) {
        this.selection.select(row);
      }
    });
    this.repairCentreRows = new MatTableDataSource(formattedRepairCenter);
  }

  getRepairCentreDisplay(repairCentre): string {
    let display = '';

    const numSectionWidth = Math.floor((document.getElementById('repairCentreInput') as HTMLInputElement).offsetWidth / Object.keys(repairCentre).length);

    let elementSpan = <HTMLSpanElement>document.getElementById('spanRuler');
    elementSpan.hidden = false;
    const charWidth = elementSpan.offsetWidth + 2;
    elementSpan.hidden = true;

    var postalCode = repairCentre['postalCode'];
    repairCentre['postalCode'] = postalCode.substring(0, 3) + " " + postalCode.substring(3);

    for (const key in repairCentre) {
      const extraSpaces = Math.floor((numSectionWidth / charWidth) - (repairCentre[key].length));
      if (extraSpaces > 0) {
        if (key !== Object.keys(repairCentre)[Object.keys(repairCentre).length - 1]) {
          display += (repairCentre[key] + Array(extraSpaces).fill('\xa0').join(''));
        } else {
          display += repairCentre[key];
        }
      } else {
        display += (repairCentre[key] + ' ');
      }
    }
    return display;
  }

  resetRepairCentreCursor() {
    (<HTMLInputElement>document.getElementById('repairCentreInput')).focus();
    (<HTMLInputElement>document.getElementById('repairCentreInput')).setSelectionRange(0, 0);
    (<HTMLInputElement>document.getElementById('repairCentreInput')).blur();
    (<HTMLInputElement>document.getElementById('repairCentreInput')).focus();
    this.closeAutoComplete()
  }

  setRepairCentre(value) {
    if(this.keyUpSubscription){
      this.keyUpSubscription.unsubscribe()
    }
    this.fromEventCall = false;
    this.selectedRepairCentre = value
    if (this.isLookup) {
      let fullAddress = `${this.selectedRepairCentre.city}, ${this.selectedRepairCentre.province}, ${this.selectedRepairCentre.country}`;
      this.selectCity(fullAddress, { isUserInput: true })
      this.isLookup = false;
    }
    else {
      this.showLocation()
    }
    this.resetRepairCentreCursor();
  }

  cancel() {
    this.dialogRef.close({ event: 'Cancel' })
  }

  confirm() {
    if (this.selectedRepairCentre) {

      this.dialogRef.close({ data: this.selectedRepairCentre });
    }
  }

  setFilter(value) {
    this.fromEventCall = false;
    this.selectedRepairCentre = null;
    this.isRepairCentreSelected = false;
    (document.getElementById('repairCentreInput') as HTMLInputElement).value = '';
    this.repairCentreAutoComplete('');
    this.selectedFilter = value;
    this.filterRepairCenters().then(() => {
      this.showLocation()
      this.formatTable(this.filteredRepairCentres);
    });
  }

  filterRepairCenters() {
    return new Promise(resolve => {
      if (this.selectedFilter === "Preferred") {
        this.filteredRepairCentres = this.predictedRepairCentres.filter(repairCenter =>
          repairCenter.preferred
        );
      }
      else {
        this.filteredRepairCentres = this.predictedRepairCentres;
      }
      resolve(null);
    })
  }

  closeAutoComplete() {
    this.citiesAutoCompleteRef.closePanel();
    this.repairCentreInput.closePanel();
  }

  openAutocomplete(trigger) {
    trigger.openPanel();
  }

  formatPostalCode(postalCode) {
    return postalCode.substring(0, 3) + " " + postalCode.substring(3);
  }

  resetMap() {
    this.setMapZoom(4);
    this.setMapMarkers(this.map);
    this.setMapMarkers(null); //This will remove marker from map
  }

  getVisibleRadius(): number {
    var bounds = this.map.getBounds()
    var center = this.map.getCenter()
    if (bounds && center) {
      var northEast = bounds.getNorthEast();
      return Math.round((google.maps.geometry.spherical.computeDistanceBetween(center, northEast)) / 1000);
    }

    return this.config.mapCityRadius;
  }

  setMapEvent() {
    this.mapDragEvent = google.maps.event.addListener(this.map, 'dragend', () => {
      if (!this.isMapInitializing) {
        this.resultRadius = this.getVisibleRadius() < this.config.mapCityRadius ? this.config.mapCityRadius : this.getVisibleRadius();
        this.fromEventCall = true;
        this.userSelectedCity = this.map.getCenter();
        this.dragEvent = true
        setTimeout(() => {
          if (!this.functionCalled) {
            this.getRepairCentresByCity(this.userSelectedCity)
          }
        }, 1000);
      }
    })

    this.mapZoomEvent = google.maps.event.addListener(this.map, 'zoom_changed', () => {
      if (!this.isMapInitializing) {
        this.isZoomCalled = true;
      }
    })

  }

  removeMapEvent() {
    if(this.mapDragEvent != undefined) {
      this.mapDragEvent.remove();
    }

    if(this.mapZoomEvent != undefined) {
      this.mapZoomEvent.remove();
    }
  }

  setMapZoom(zoomLevel: number) {
    this.removeMapEvent();
    this.map.setZoom(zoomLevel);
    this.setMapEvent();
  }

  setMapBounds(cityCoords: google.maps.LatLng, radius: number) {
    var circ = new google.maps.Circle()
    circ.setRadius((radius * 1000))
    circ.setCenter(cityCoords)
    this.map.setCenter(cityCoords)
    this.map.fitBounds(circ.getBounds(), 0)
  }

  repairCenterProvinceLookup(province: string, country: string): Observable<IRepairCentre[]> {
    return this.repairCentreService.repairCentreLookupByProvinceAndCountry(province, country);
  }

  unSubscribeAPI() {
    this.apiSubscription.forEach((sub) => {
      sub.unsubscribe();
    })
    this.apiSubscription = []
  }

  subscribeRepairCenterApi(repairObservable: Observable<IRepairCentre[]>, islookUp: boolean = false, dragEvent: boolean = false, isCitySelection: boolean = false) {
    if(!this.blockUIService.isActive('repair') && !dragEvent){
      this.blockUIService.start('repair')
    }
    let repairCenterSub = repairObservable.subscribe(res => {
      this.repairCentres = res;
      this.predictedRepairCentres = res;
      if (this.predictedRepairCentres.length > 0) {
        this.predictedRepairCentres = this.predictedRepairCentres.filter(r => (this.selectedFilter == 'All' || r.preferred))
      }
      this.filterRepairCenters().then(() => {
        if (!islookUp) {
          this.showLocation(isCitySelection)
        }
        this.formatTable(this.filteredRepairCentres);
      });;
      this.isMapInitializing = false;
      this.functionCalled = false;
      this.dragEvent = false;
      this.blockUIService.stop('repair')
    },
    error => {
      this.toastrService.error(environment.messages.apiError, 'Error');
      this.blockUIService.stop('repair')
    })

    this.apiSubscription.push(repairCenterSub)
  }

  configMapBounds(cityCoords: google.maps.LatLng) {
    this.removeMapEvent();
    this.fromEventCall = true
    this.setMapBounds(cityCoords, this.searchRadius);
    this.setMapEvent();
  }

  setKeyUpEvent() {
    const keyUp = fromEvent(document.getElementById('repairCentreInput') as HTMLInputElement, 'keyup')

    this.keyUpSubscription = keyUp.pipe(map((ev) => ((ev.currentTarget) as HTMLInputElement).value),
      debounceTime(1000)).subscribe((input) => {
        if (this.selectedCity == (null || '')) {
          this.repairCenterDBLookupByName(input)
        }
      })

  }

  repairCenterDBLookupByName(repairCenterName: string) {
    this.isLookup = true;
    this.subscribeRepairCenterApi(this.repairCentreService.repairCentreLookupByName(repairCenterName), true)
  }

  isInUse(row: object) {
    return row["status"] === "Do Not Use";
  }

  setCityLocation(latLng: google.maps.LatLng, zoom: number) {
    this.map.setCenter(latLng)
    this.setMapZoom(zoom)
    var marker = new google.maps.Marker({
      map: this.map,
      position: latLng,

    });
    this.markers.push(marker)
  }
}
