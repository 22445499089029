<fieldset class="fieldset-form">
  <legend>
    Repair Centre Notes
    <div *ngIf="funcs['addNote'];else noAddNote" class="float-right">
      <button class="btn custom-button float-right" type="button" (click)="addNote()" [hidden]="!CanAddNotes" [ngClass]="{'disableButton': !CanAddNotes}" >
          <fa-icon [icon]="faPlus"></fa-icon> Add Note
      </button>
    </div>
    <ng-template #noAddNote>
      <framework-hidden [display]="button"></framework-hidden>
    </ng-template>
  </legend>
  <table-adapter #repairCentreNotesTable [tableLabel]="'repair-centre-notes-table'" [columns]="columns"></table-adapter>
</fieldset>