import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src-private/environments/environment';
import { IDocument, IDocumentUploadResponse } from '../interfaces/document';
import { ClaimTransaction } from '../models/claim-transaction';
import { PartGroupModel } from '../models/part-group-model';
import { Part } from '../models/part-model';

@Injectable({
  providedIn: 'root'
})

export class PartService {
  constructor(private http: HttpClient) { }

  retrieveAllPartsFromGroup(partGroupId: number, warrantyId: number): Observable<Part[]> {
    return this.http.get<Part[]>(environment.resourceUrl + `api/gwc/parts/${partGroupId}/${warrantyId}`); 
  }

  retrieveAllPartGroups(warrantyId: number):Observable<PartGroupModel[]>{
    return this.http.get<PartGroupModel[]>(`${environment.resourceUrl}api/gwc/partgroups/get/${warrantyId}`)
  }

  approve(ct: ClaimTransaction) {
    let httpParams = new HttpParams();
    Object.keys(ct).forEach(function (key) {
        httpParams = httpParams.append(key, ct[key]);
    });
    return this.http.put<ClaimTransaction>(`${environment.resourceUrl}api/gwc/parts/approve`, httpParams)
  }

  unapprove(ct: ClaimTransaction) {
    let httpParams = new HttpParams();
    Object.keys(ct).forEach(function (key) {
      httpParams = httpParams.append(key, ct[key]);
    });
    return this.http.put<ClaimTransaction>(`${environment.resourceUrl}api/gwc/parts/unapprove`, httpParams)
  }
}
