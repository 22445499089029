import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

/*-- Enums --*/
import { Country } from '../../../../../enums/country.enum';

/*-- Interfaces --*/
import { IDealer, IDealerDealsRebates } from '../../../interfaces/dealer.interface';
import { DealsStatsViewModel } from '../deals-stats-view-model';

/*-- Services --*/
import { BreakpointService } from '../../../../../services/breakpoint.service';
import { DealersService } from '../../../services/dealers.service';

/*-- Third Party --*/
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-deals-and-rebates',
  templateUrl: './deals-and-rebates.component.html',
  styleUrls: ['./deals-and-rebates.component.scss']
})
export class DealsAndRebatesComponent implements OnChanges, OnInit {
  @BlockUI() blockUI: NgBlockUI;

  @Input() dealer: IDealer;

  private static _groupedDealsRebates: Array<DealsStatsViewModel> = Array<DealsStatsViewModel>();
  public _isGroupsDealsRebatesLoaded = false;

  private bp: number;
  private country = Country;
  private coverageGroupId: number;

  public groupedDealsRebates: Array<DealsStatsViewModel> = Array<DealsStatsViewModel>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private breakpointService: BreakpointService,
    private dealersService: DealersService
  ) {
    breakpointService.bp.subscribe(x => this.bp = x);

    activatedRoute.paramMap.subscribe(params => {
      this.coverageGroupId = Number(params.get('coverageGroupId'));

      if (!this.coverageGroupId) {
        this.coverageGroupId = 0; // all closed
      }
    });
  }

  //#region - Lifecycle
  ngOnChanges(changes: SimpleChanges): void {
    const dealer: SimpleChange = changes.dealer;

    if (dealer && dealer.currentValue) {
      this.retrieveDealsAndRebates(dealer.currentValue);
    }
  }

  ngOnInit(): void {
    if (!this.bp) {
      this.bp = this.breakpointService.getBreakpoint();
    }
  }
  //#endregion

  //#region - Private Methods
  private groupResults(dealerDealsRebates: IDealerDealsRebates[]): void {
    DealsAndRebatesComponent._groupedDealsRebates = [];

    let coverageGroupIndex = 0;

    let currentCoverageGroup = '';

    for (let rebateA of dealerDealsRebates) {
      if (rebateA.coverageGroup === currentCoverageGroup) {
        continue;
      }

      const coverageGroup = {
        coverages: [],
        coverageGroup: rebateA.coverageGroup,
        coverageGroupId: ++coverageGroupIndex,
        isOpen: false
      };

      currentCoverageGroup = rebateA.coverageGroup;
      let coverageIndex = 0;

      let currentCoverage = '';

      for (let rebateB of dealerDealsRebates) {
        if (!(rebateB.coverageGroup === currentCoverageGroup &&
          rebateB.coverage !== currentCoverage)) {
          continue;
        }

        const coverage = {
          coverage: rebateB.coverage,
          deals: [],  // deals contain one or more deal combinations as well as a total
          deductibles: [],
          isOpen: (coverageIndex === 0),
          partGroups: []
        };
        currentCoverage = rebateB.coverage;
        coverageIndex++;

        let currentAgeBand = '';
        let currentDistanceBand = '';

        // get the deals
        for (let rebateC of dealerDealsRebates) {
          if (!(rebateC.coverageGroup === currentCoverageGroup &&
            rebateC.coverage === currentCoverage &&
            rebateC.coverageAgeBand !== currentAgeBand &&
            rebateC.coverageDistanceBand !== currentDistanceBand)) {

            currentAgeBand = undefined;
            currentDistanceBand = undefined;
            continue;
          }

          const deal = {
            records: [],
            total: 0
          };

          // does deal already exist
          let exists = false;

          for (let deal of coverage.deals) {
            for (let record of deal.records) {
              if (record.coverageGroup === currentCoverageGroup &&
                record.coverage === currentCoverage &&
                (record.coverageAgeBand === currentAgeBand || record.coverageAgeBand === 'N/A') &&
                (record.coverageDistanceBand === currentDistanceBand || record.coverageDistanceBand === 'N/A') &&
                record.deductible === null &&
                record.partGroup === null) {
                exists = true;
              }
            }
          }

          if (exists) {
            continue;
          }

          currentAgeBand = rebateC.coverageAgeBand;
          currentDistanceBand = rebateC.coverageDistanceBand;

          for (let rebateD of dealerDealsRebates) {
            if (!(rebateD.coverageGroup === currentCoverageGroup &&
              rebateD.coverage === currentCoverage &&
              (rebateD.coverageAgeBand === currentAgeBand || rebateD.coverageAgeBand === 'N/A') &&
              (rebateD.coverageDistanceBand === currentDistanceBand || rebateD.coverageDistanceBand === 'N/A') &&
              rebateD.deductible === null &&
              rebateD.partGroup === null)) {
              continue;
            }
            if (rebateD.coverageAgeBand === 'N/A') {
              rebateD.coverageAgeBand = null;
            }

            if (rebateD.coverageDistanceBand === 'N/A') {
              rebateD.coverageDistanceBand = null;
            }

            deal.records.push(rebateD);

            deal.total += rebateD.basePrice || 0;
            deal.total += rebateD.adjustment || 0;
            deal.total += rebateD.surcharge || 0;
          }
          coverage.deals.push(deal);
        }

        // get the deductibles
        for (let rebateE of dealerDealsRebates) {
          if (!(rebateE.coverageGroup === currentCoverageGroup && rebateE.coverage === currentCoverage && rebateE.deductible !== null)) {
            continue;
          }

          if (rebateE.coverageAgeBand === 'N/A') {
            rebateE.coverageAgeBand = null;
          }

          if (rebateE.coverageDistanceBand === 'N/A') {
            rebateE.coverageDistanceBand = null;
          }

          coverage.deductibles.push(rebateE);
        }

        // get the part groups
        for (let rebateF of dealerDealsRebates) {
          if (!(rebateF.coverageGroup === currentCoverageGroup && rebateF.coverage === currentCoverage && rebateF.partGroup !== null)) {
            continue;
          }

          if (rebateF.coverageAgeBand === 'N/A') {
            rebateF.coverageAgeBand = null;
          }

          if (rebateF.coverageDistanceBand === 'N/A') {
            rebateF.coverageDistanceBand = null;
          }

          coverage.partGroups.push(rebateF);
        }
        coverageGroup.coverages.push(coverage);
      }

      DealsAndRebatesComponent._groupedDealsRebates.push(coverageGroup);
    }

    this.groupedDealsRebates = DealsAndRebatesComponent._groupedDealsRebates;

    this._isGroupsDealsRebatesLoaded = true;
  }
  //#endregion

  //#region - API Methods
  private retrieveDealsAndRebates(dealer: IDealer): void {
    this.blockUI.start();

    this.dealersService.dealerRetrieveDealsRebates(dealer.vendorId).subscribe(
      response => {
        const data: IDealerDealsRebates[] = response;

        this.groupResults(data);

          this.blockUI.stop();
      }
    );
  }
  //#endregion
}
