import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src-private/environments/environment';
import { IClaimLock } from '../interfaces/claim-lock.interface';

@Injectable({
    providedIn: 'root'
})
export class ClaimsLockService {

    constructor(private http: HttpClient) { }

    retrieve(claimId: number): Observable<IClaimLock> {
        return this.http.get<IClaimLock>(environment.resourceUrl + 'api/gwc/claimlock', {
            params: new HttpParams().append('claimId', claimId.toString())
        });
    }

    lockUnlock(claimLockUnlock: IClaimLock): Observable<number> {
        let httpParams = new HttpParams();

        Object.keys(claimLockUnlock).forEach(function (key) {
            httpParams = httpParams.append(key, claimLockUnlock[key]);
        });

        return this.http.put<number>(environment.resourceUrl + 'api/gwc/claimlock', httpParams);
    }

    add(claimId: number): Observable<number> {
        let claimLock = {} as IClaimLock;
        claimLock.claimId = claimId;

        let httpParams = new HttpParams();

        Object.keys(claimLock).forEach(function (key) {
            httpParams = httpParams.append(key, claimLock[key]);
        });

        return this.http.post<number>(`${environment.resourceUrl}api/gwc/claimlock`, httpParams)
    }
}
