import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ClaimsService } from "../../services/claims.service";
import { ComplaintAddDialogComponent } from "../complaint-add-dialog/complaint-add-dialog.component";
import { IClaim } from "../../interfaces/claim";
import { CurrencyPipe, DatePipe } from "@angular/common";
import { ILoginUser } from "../../interfaces/loginuser.interface";
import { IClaimsNotes } from "../../interfaces/claims-notes.interface";
import { RepairCentreSearchDialogComponent } from "../repair-centre/repair-centre-search-dialog/repair-centre-search-dialog.component";
import { faEdit, faExternalLinkAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { LoginUserService } from "../../services/loginuser.service";
import { ClaimsNotesService } from "../../services/claims-notes.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TableAdapterComponent } from "src-private/app/shared/table-adapter/table-adapter.component";
import { CellType, CellActions } from "src-private/app/shared/table-adapter/cell-builder/cell-type";
import { CellBuilder } from "src-private/app/shared/table-adapter/cell-builder/cell-builder";
import { BehaviorSubject, Subject } from "rxjs";
import { ComplaintsService } from "../../services/complaints.service";
import { IComplaint } from "../../interfaces/complaint.interface";
import { AccountService } from "src-private/app/areas/account/services/account.service";
import { takeUntil } from "rxjs/operators";
import { ClaimEditComponent } from "../claims-list/claim-edit/claim-edit.component";
import { NotificationService } from "src-private/app/services/notification.service";
import { Notification } from "src-private/app/interfaces/notification.interface";
import { ClaimTransaction } from "../../models/claim-transaction";
import { FrameworkComponent } from "src-private/app/shared/framework/framework.component";
import { ClaimsLockService } from "../../services/claims-lock.service";
import { IClaimLock } from "../../interfaces/claim-lock.interface";
import { ClaimLockComponent } from "../claims-list/claim-lock/claim-lock.component";
import { NotificationType } from "src-private/app/enums/notification-type.enum";
import { ToastrService } from "ngx-toastr";
import {
  MaintenanceAdd,
  MaintenanceAddInput,
} from "../maintenance/maintenance-add/maintenance-add.component";
import { WarrantyService } from "../../services/warranty.service";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { environment } from "src-private/environments/environment";
import { ConfirmDialogComponent } from "src-private/app/shared/confirm-dialog/confirm-dialog.component";
import { DealerStatsComponent } from "src-private/app/shared/dealer-stats/dealer-stats.component";
import { DialogType } from "src-private/app/enums/dialog-type";
import { LocaleService } from "src-private/app/services/locale.service";
import { NotificationSocketService } from "src-private/app/services/notification-socket.service";
import { ClaimStatus } from "src-private/app/enums/claims.enums";
import { EventsService } from "src-private/app/services/events.service";
import { IWarranty } from "../../interfaces/warranty.interface";
import { RepairCentreService } from "../../services/repair-centre.service";
import { IRepairCentre } from "../../interfaces/repair-centre.interface";
import { ClaimAuditStatus } from "src-private/app/enums/claim-audit-status.enum";
import { ClaimsAuditService } from "../../services/claims-audit.service";
import { IClaimSource } from "../../interfaces/claim-source";
import { P } from "@angular/cdk/keycodes";
import { CoverageType } from "src-private/app/enums/bonus-bucks.enums";

@Component({
  selector: "app-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.scss"],
})

export class DetailComponent
  extends FrameworkComponent
  implements OnInit, AfterViewInit {
  private claim$: BehaviorSubject<IClaim>;
  private claimsId: number;
  private complaint = {} as IComplaint;
  private updatedClaim = {} as IClaim;
  private repairCentre: IRepairCentre;
  public claimForm: UntypedFormGroup;
  public complaintForm: UntypedFormGroup;
  public faEdit = faEdit;
  public faPlus = faPlus;
  public faExternalLink = faExternalLinkAlt
  public users: ILoginUser[];
  public partData: ClaimTransaction[];
  public isAdjuster: boolean;
  public warrantyId: number;
  public vendorId$: BehaviorSubject<number>;
  public checkLock$: BehaviorSubject<boolean>;
  private claimEnterpriseId: number;
  public showForceClose: boolean;
  public shopLink = environment.shopLink
  public shopLinkLabel = environment.shopLinkLabel
  public warranty: IWarranty;
  public auditApprovalStatus: ClaimAuditStatus;
  public auditLink: string;
  public claimSources: IClaimSource[];
  public notifyEmail: string;
  @BlockUI() blockUI: NgBlockUI;

  private ngUnsubscribe: Subject<any> = new Subject();

  @ViewChild(DealerStatsComponent, { static: false }) dealerStats: DealerStatsComponent;

  public partsTableColumns: CellBuilder[] = [
    new CellBuilder("Type", "indicator", CellType.text),
    new CellBuilder("Repair Center Number", "repairCentreId", CellType.number),
    new CellBuilder("RC Name", "repairCentreName", CellType.text),
    new CellBuilder("Part Group", "partGroupName", CellType.number),
    new CellBuilder("Part Description", "partDescription", CellType.text),
    new CellBuilder("Quantity", "quantity", CellType.number),
    new CellBuilder("Our $", "ourPrice", CellType.currency),
    new CellBuilder("RC $", "repairCentrePrice", CellType.currency),
    new CellBuilder("Paid $", "paidPrice", CellType.currency),
    new CellBuilder("SK Labour", "shopKeyLabour", CellType.number),
    new CellBuilder("RC Labour", "repairCentreLabour", CellType.currency),
    new CellBuilder("Paid Labour", "paidLabour", CellType.currency),
    new CellBuilder("Other", "other", CellType.currency),
    new CellBuilder("Deductible", "deductible", CellType.currency),
    new CellBuilder("Process", "process", CellType.dropdown,'', CellActions.Dropdown, [{label:'Yes', value:true}, {label:'No', value:false}]),
    new CellBuilder("GoodWill", "goodwill", CellType.currency, '', CellActions.None, [{ custom: { whole: '1.0-0', decimal: '1.2-2' } }]),
    new CellBuilder("Total before Tax", "rowTotal", CellType.currency),
    new CellBuilder("GST", "gstAmount", CellType.currency),
    new CellBuilder("PST", "pstAmount", CellType.currency),
    new CellBuilder("HST", "hstAmount", CellType.currency),
    new CellBuilder("Total after Tax", "rowTotalWithTax", CellType.currency, '', CellActions.None, [{ custom: { whole: '1.0-0', decimal: '1.2-2'}, totalColumn: {} }]),
    new CellBuilder("Used Part", "used", CellType.checkbox),
    new CellBuilder("Rebuilt Part", "rebuilt", CellType.checkbox),
    new CellBuilder("Action", "action", CellType.actions, '', CellActions.Remove)
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private claimsService: ClaimsService,
    private datePipe: DatePipe,
    private loginUserService: LoginUserService,
    private claimsNotesService: ClaimsNotesService,
    private complaintsService: ComplaintsService,
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private notificationService: NotificationService,
    private claimsLockService: ClaimsLockService,
    private warrantyService: WarrantyService,
    private localeService: LocaleService,
    private notificationSocketService: NotificationSocketService,
    private titleService: Title,
    private eventsService: EventsService,
    private repairCentreService: RepairCentreService,
    private claimsAuditService: ClaimsAuditService,
    private router: Router
  ) {
    super();
    this.claimsId = this.activatedRoute.snapshot.data['claimId']
  }

  ngOnInit() {
    this.claimEnterpriseId = parseInt(this.activatedRoute.snapshot.paramMap.get("enterpriseId"))
    this.claim$ = new BehaviorSubject<IClaim>({} as IClaim);
    this.vendorId$ = new BehaviorSubject<number>(null);
    this.checkLock$ = new BehaviorSubject<boolean>(null)

    this.setClaimLockNotification();
    this.checkIfClaimLocked();

    this.notifyEmail = "mailto:?subject=MBI Claim over $1,500 for your review&body=Greetings, %0D%0AThere is a claim in an MBI province that exceeds $1,500 that should be reviewed. The claim can be reviewed at: https://e2.eazeetrak.com/claims/" + this.claimEnterpriseId + "/audit.%0D%0A Sincerely,"

    this.claimForm = this.formBuilder.group({
      claimId: [null],
      warrantyId: [null],
      vin: [null],
      claimsDateEntered: [""],
      claimsDateClosed: [""],
      claimsStatus: [""],
      claimsOwner: [{ value: "", disabled: true }],
      repairCenterName: [""],
      assignedAdjusterName: [""],
      claimCurrentOdReading:[""],
      claimsSource: [],
      claimSource: [""]
    });

    this.complaintForm = this.formBuilder.group({
      comments: [""],
    });

    this.claimsService
      .retrieve(this.claimsId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((claim) => {
        if ((claim.coverageTypeId == CoverageType.TireAndRim || claim.coverageTypeId == CoverageType.TireAndRimNew) && this.accountService.isInsurerAuditor()) {
          let route =  '/unauthorized';
          this.router.navigateByUrl('/', {
            skipLocationChange: false 
          }).then(() => this.router.navigate([route])); 
        }
        if(claim.claimTotalWithTax > claim.claimMaxLimit) {
          this.showWarningDialog(claim.claimMaxLimit)
          this.claimsService.setDialogState = true
          this.showForceClose = ClaimStatus[claim.claimsStatus] == (ClaimStatus.Open || ClaimStatus.Pending);
        }
        this.claim$.next(claim);
        this.populateClaimForm(this.claim$.value);
        this.warrantyId = claim.warrantyId;
        this.vendorId$.next(claim.vendorId);
        this.titleService.setTitle("Claims - " + claim.enterpriseId);
        this.auditApprovalStatus = claim.auditApprovalStatus;
        this.auditLink = "https://e2.eazeetrak.com/claims/" + this.claimEnterpriseId + "/audit";

        if (this.warrantyId) {
          this.warrantyService.retrieve(this.warrantyId).subscribe((warranty) => {
            this.warranty = warranty;
          },
          error => {
            this.toastr.error(environment.messages.apiError, "Unable to retrieve warranty");
          });
        }
      },
      error => {
        this.toastr.error(environment.messages.apiError, "Unable to retrieve claim");
      });

    this.isAdjuster = this.accountService.isAdjuster();

    this.eventsService.on('claim-changed', () => {
      this.refreshClaims();
    });

    this.claim$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(claim => {
        this.refreshRepairCentre(claim?.claimsRepairCenterId);
      });
    
    let data = { adjusterId: this.accountService.getUserId(), claimId: this.claimsId, createdDate: new Date(), note: 'Viewed Claim', id: 0, adjusterName: null, type: null}; 
    this.claimsNotesService.add(data)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(() => {});
  }

  ngAfterViewInit() {
    this.refreshClaims(true);
    super.build("DetailComponent", "detail-component");
  }

  refreshClaims(initial: boolean = false) {
    this.claimsService
      .retrieve(this.claimsId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((claim) => {
        this.claim$.next(claim);
        this.populateClaimForm(this.claim$.value);
        this.auditApprovalStatus = claim.auditApprovalStatus;
        if(claim.claimTotalWithTax > claim.claimMaxLimit){
          this.showForceClose = ClaimStatus[claim.claimsStatus] == (ClaimStatus.Open || ClaimStatus.Pending);
        }
        else{
          this.showForceClose = false;
        }
      },
      error => {
        this.toastr.error(environment.messages.apiError, "Unable to retrieve claim");
      });

      if (!initial) {
        this.dealerStats.update();
      }
  }

  refreshRepairCentre(id: number) {
    if (!id) return;
    this.repairCentreService
      .retrieve(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(rc => {
        this.repairCentre = rc;
      },
      error => {
        this.toastr.error(environment.messages.apiError, "Unable to retrieve claim repair centre");
      });
  }

  lockAndEditClaim(unlockAfter, manuallyUnlock: boolean=false) {
    let claimLock = {} as IClaimLock;
    claimLock.claimId = this.claimsId;
    claimLock.lock = true;
    claimLock.userId = this.accountService.getUserId();
    let param = {};
    Object.assign(param, this.claim$.value);
    this.claimsLockService
      .lockUnlock(claimLock)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        //claim has been locked
        if(manuallyUnlock){
          let userInfo = {} as ILoginUser;
          userInfo.id = this.accountService.getUserId();
          this.notifyUserManualLock(userInfo)
        }

        this.blockUI.stop();
        let dialogRef = this.dialog.open(ClaimEditComponent, {
          width: "300px",
          data: param,
        });

        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res) => {
            if (res.data == "Refresh") {
              this.refreshClaims();
            } else if (res.data !== "Cancel" && res.data !== undefined) {
              this.updatedClaim = this.claim$.value;
              let isStatusChanged = false;
              let isAdjusterChanged = false;
              let oldAdjuster = "";
              let newAdjuster = "";
              if(this.updatedClaim.claimsStatus != res.data.claimsStatus){
                isStatusChanged = true;
              }
              if(this.updatedClaim.assignedAdjuster != res.data.assignedAdjuster){
                isAdjusterChanged = true;
                oldAdjuster = this.updatedClaim.assignedAdjusterName;
                newAdjuster = res.data.assignedAdjusterName;
              }
              this.updatedClaim.assignedAdjuster = res.data.assignedAdjuster;
              this.updatedClaim.claimsStatus = res.data.claimsStatus;
              this.updateClaim(this.updatedClaim, isStatusChanged, isAdjusterChanged, oldAdjuster, newAdjuster)
            }

            let claimLock = {} as IClaimLock;
            claimLock.claimId = this.claimsId;
            claimLock.lock = false;
            claimLock.userId = this.accountService.getUserId();

            if (unlockAfter) {
              this.claimsLockService.lockUnlock(claimLock).subscribe(()=>{
                if(manuallyUnlock){
                  this.setButton(true, true)
                  this.checkLock$.next(true)
                }
              });
            }
          });
      });
  }

  //#region Claim edit

  //Claim Update Dialog

  editClaim() {
    if(this.isAdjuster || this.canEditClosedClaim){
      this.blockUI.start();
      this.claimsLockService
      .retrieve(this.claimsId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        //if lock doesn't exist create lock
        if (!res) {
          this.claimsLockService
            .add(this.claimsId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
              this.lockAndEditClaim(true);
            });
        }

        //if currently locked
        else if (res.lock) {
          res.claimEnterpriseId = this.claim$.getValue().enterpriseId
          //can't check expiry time before lock result is determined to be not null
          let expireInterval = 15;
          let expiryTime = new Date(res.lockTime);
          expiryTime.setMinutes(expiryTime.getMinutes() + expireInterval);
          let currentTime = new Date();
          this.blockUI.stop();
          if (expiryTime > currentTime) { //claim is currently locked
            this.dialog
              .open(ClaimLockComponent, {
                panelClass: "claim-add-dialogs",
                width: "600px",
                data: res,
              })
              .afterClosed()
              .subscribe((data) => {
                if (data.manuallyUnlock) {
                  this.lockAndEditClaim(true, data.manuallyUnlock);
                }
              });
          }
        } else { //claim is currently unlocked
          this.lockAndEditClaim(true);
        }
      },
      error => {
        this.toastr.error(environment.messages.apiError, "Unable to retrieve lock for claim");
      });
    }
  }

  checkClaim(event) {
    this.refreshClaims(false);
    if(event > this.claim$.getValue().claimMaxLimit){
      this.showWarningDialog(this.claim$.getValue().claimMaxLimit, true);
      this.showForceClose = ClaimStatus[this.claim$.getValue().claimsStatus] == (ClaimStatus.Open || ClaimStatus.Pending);
    }
    else{
      this.showForceClose = false;
    }
  }

  //#endregion

  //#region Claim Form

  populateClaimForm(claim: IClaim) {
    if (claim) {
      this.claimForm.patchValue({
        claimId: claim.enterpriseId,
        warrantyId: claim.warrantyIdFull,
        vin: claim.vin,
        claimsDateEntered: this.datePipe.transform(
          claim.claimsDateEntered,
          "yyyy-MM-dd"
        ),
        claimsDateClosed: this.datePipe.transform(
          claim.claimsDateClosed,
          "yyyy-MM-dd"
        ),
        claimsStatus: claim.claimsStatus,
        claimsOwner: claim.claimsOwner,
        repairCenterName: claim.repairCenterName,
        assignedAdjusterName: claim.assignedAdjusterName,
        claimCurrentOdReading: claim.currentOdReading,
        claimSource: claim.sourceId
      });
      this.getClaimSources(claim.sourceId ?? 0);
    }
  }

  populateComplaintsForm(notes: IClaimsNotes[]) {
    let result = notes.filter((n) => n.type == "Complaint").join("\r\n\r\n");
    this.complaintForm.patchValue({
      comments: result,
    });
  }

  changeRepairCentre() {
    this.dialog
      .open(RepairCentreSearchDialogComponent, {
        width: "75vw",
        data: this.claim$.value.claimsRepairCenterId,
      })
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res.data) {
          this.claim$.value.repairCenterName = res.data.name;
          this.claim$.value.claimsRepairCenterId = res.data.id;

          this.claimsService
            .claimsUpdate(this.claim$.value)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
              this.toastr.success(
                "Repair Centre successfully changed. ",
                "Change Repair Centre"
              );
              this.populateClaimForm(this.claim$.value);
              this.refreshRepairCentre(res.data.id);
            },
            error => {
              this.toastr.error(environment.messages.apiError, "Unable to update claim");
            });
        }
      });
  }

  addMaintenance() {
    this.warrantyService.retrieve(this.warrantyId).subscribe((warranty) => {
      var maint = new MaintenanceAddInput();
      maint.warrantyId = warranty.id;
      maint.claimId = this.claimsId;
      maint.applicationId = warranty.applicationId;
      maint.vehicleId = warranty.vehicle.id;

      this.dialog.open(MaintenanceAdd, {
        width: "400px",
        data: maint,
        autoFocus: false
      });
    },
    error => {
      this.toastr.error(environment.messages.apiError, "Unable to retrieve warranty");
    });
  }

  public get isClaimClosed(): boolean {
    return this.claimsService.getSelectedClaim?.IsClaimClosed;
  }

  public get canEditClosedClaim(): boolean {
    return this.isClaimManager || this.accountService.isAssistantClaimsManager() || this.accountService.isAccountant();
  }

  public get canAddPart(): boolean {
    return this.isClaimClosed ? this.canEditClosedClaim : (this.isAdjuster || this.canEditClosedClaim);
  }

  //#endregion

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    this.notificationSocketService.setClaimLock(null)
  }

  @HostListener("window:beforeunload")
  unlockClaim() {
    let claimLock = {} as IClaimLock;
    claimLock.claimId = this.claimsId;
    claimLock.lock = false;
    claimLock.userId = this.accountService.getUserId();
    this.claimsLockService.lockUnlock(claimLock).subscribe(() => {
      return false;
    });
  }


  showWarningDialog(claimMaxLimit: number, notFromClaim: boolean= false) {
    if(!notFromClaim && this.claimsService.getDialogShownState) {return;}
    let currencyPipe = new CurrencyPipe(this.localeService.getLanguage())
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'part-dialog',
      width: '400px',
      autoFocus: true,
      data: {
        message: `You have exceeded Claim Maximum Limit of ${currencyPipe.transform(claimMaxLimit).split('.')[0]}. Please correct details before Proceeding`,
        type: DialogType[1].toString()
      }
    });
  }

  setClaimLockNotification() {
    this.notificationSocketService.ClaimLockObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe((resp) => {
      if (resp && this.claim$) {
        this.setButton(!resp.claimLock.lock)
        this.checkLock$.next(!resp.claimLock.lock)
      }
    })
  }

  setButton(value: boolean, manuallyChange: boolean = false) {
    let keys =  Object.keys(this.funcs).filter(x=>x != "editClaim")
    keys.forEach(x=>{
      this.funcs[x] = value
    })

    if(!value){
      this.dialog.closeAll(); // Close all dialog 
    }
    if(!manuallyChange){
      this.showClaimLockAlert(!value ? `Claim ${this.claim$?.getValue()?.enterpriseId ?? this.claimEnterpriseId} Locked From Editing` : `Claim Unlocked. Please refresh page before doing any changes`)
    }
  }

  showClaimLockAlert(message:string){
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'part-dialog',
      width: '400px',
      autoFocus: true,
      data: {
        message: message,
        type: DialogType[1].toString()
      }
    });
  }

  checkIfClaimLocked(){
    this.claimsLockService.retrieve(this.claimsId).subscribe((res)=>{
      if(res){
        if(res.lock){
          this.setButton(!res.lock)
          this.checkLock$.next(!res.lock)
        }
      }
    },
    error => {
      this.toastr.error(environment.messages.apiError, "Unable to retrieve lock for claim");
    })
  }

  notifyUserManualLock(userInfo){
    this.loginUserService.search(userInfo).subscribe((res) => {
      if (res) {
        let alertCurrentEditor = {} as Notification;

        alertCurrentEditor.data = JSON.stringify({
          claim: { id: this.claim$.value.enterpriseId },
        });
        alertCurrentEditor.title = `MANUALLY UNLOCKED - Claims : ${this.claim$.value.enterpriseId}`
        alertCurrentEditor.description = `Claim ID: ${this.claim$.value.enterpriseId} - lock manually overridden by ${this.accountService.getUserName()}`
        alertCurrentEditor.typeId = NotificationType.InApp;
        alertCurrentEditor.userId = res.id;
        alertCurrentEditor.action = "claim";

        this.notificationService.create(alertCurrentEditor).subscribe();
      }
    },
    error => {
      this.toastr.error(environment.messages.apiError, "Unable to find user");
    });
  }

  updateClaim(claim: IClaim, isStatusChanged: boolean = false, isAdjusterChanged: boolean = false, oldAdjusterName: string = "", newAdjusterName: string = "") {
    this.blockUI.start();
    this.claimsService
      .claimsUpdate(claim)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.toastr.success(
          "Claim successfully updated. ",
          "Update Claim"
        );
        if (isStatusChanged) {
          this.noteClaimStatusChange(this.updatedClaim)
        }
        if (isAdjusterChanged) {
          this.noteClaimAdjusterChange(this.updatedClaim, oldAdjusterName, newAdjusterName);
        }
        this.refreshClaims();
        this.blockUI.stop();
      },
      error => {
        this.toastr.error(environment.messages.apiError, "Unable to update claim");
      });
  }

  noteClaimStatusChange(claim: IClaim) {
    let data: IClaimsNotes = {
      claimId: this.claim$.value.enterpriseId,
      note: this.accountService.getUserName() + "*" + this.datePipe.transform(
        new Date(),
        "MM/dd/yyyy h:mm:ss"
      ) + "*" + "Claim status changed to " + claim.claimsStatus,
      adjusterId: this.accountService.getUserId(),
      type: '',
      id: 0,
      adjusterName: '',
      createdDate: new Date()
    }

    this.claimsNotesService
      .add(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.toastr.success("Note successfully added.", "Create Note");
      },
      error => {
        this.toastr.error(environment.messages.apiError, "Unable to add note");
      });
  }

  noteClaimAdjusterChange(claim: IClaim, oldAdjusterName: string, newAdjusterName) {
    let data: IClaimsNotes = {
      claimId: this.claim$.value.id,
      note: this.accountService.getUserName() + " * " + this.datePipe.transform(
        new Date(),
        "MM/dd/yyyy h:mm:ss"
      ) + " * " + "Adjuster changed from " + oldAdjusterName + " to " + newAdjusterName,
      adjusterId: this.accountService.getUserId(),
      type: '',
      id: 0,
      adjusterName: '',
      createdDate: new Date()
    }

    this.claimsNotesService
      .add(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.toastr.success("Note successfully added.", "Create Note");
      },
      error => {
        this.toastr.error(environment.messages.apiError, "Unable to add note");
      });
  }

  forceCloseClaim() {
    if(ClaimStatus[this.claim$.getValue().claimsStatus] == (ClaimStatus.Open || ClaimStatus.Pending)){
      let claim = this.claim$.getValue();
      claim.claimsStatus = ClaimStatus[ClaimStatus.Closed].toString()
      this.updateClaim(claim, true);
    }
  }

  public get isClaimManager(): boolean {
    return this.accountService.isClaimsManager() || this.accountService.isAssistantClaimsManager();
  }

  viewDocument(event){
    let response = event.data;
    if (response) {
      const a = document.createElement('a');
      a.href = `${environment.serverUrl}claim/${response.claimsId}/document/${response.id}`;
      a.target = "_blank"
      a.title = response.fileName;
      a.click();
      a.rel = "noopener"
      a.remove();
    }
  }

  partsListCanRenderActions = (actions: CellActions): boolean => {
    if ((actions & CellActions.Remove) === CellActions.Remove)
      return this.canAddPart;
    return false
  }

  public get rcStatusIsDoNotUse(): boolean {
    return this.repairCentre?.status === 'Do Not Use' ?? false;
  }

  sourceChange(value) {
    this.claimsService.claimSourceUpdate(this.claimsId,  value).subscribe(
      response => {
        if (response) {
          this.toastr.success("Claim source updated sucessfully.", "Update Source");
        }
        else {
          this.toastr.error("Error updating claim source.", "Update Source")
        }
      }  
    )
  }

  private getClaimSources(sourceId) {
    this.claimsService.getClaimSources(sourceId).subscribe(
      response => {
        const data: IClaimSource[] = response;
        this.claimSources = data;
        this.claimSources = this.claimSources.filter(x => x.disabled == false);
      }
    );
  }
}
