import { Component, OnInit } from '@angular/core';
import { BonusBucksValue } from '../../../models/bonus-bucks.Models';
import { BBAmountService } from '../../../services/bb-selected-amount.service';
import { BonusBucksValueType } from 'src-private/app/enums/bonus-bucks.enums';
import { BbFormManagementService } from '../../../services/bb-form-management.service';

@Component({
  selector: 'app-front-page',
  templateUrl: './front-page.component.html',
  styleUrls: ['./front-page.component.scss']
})
export class FrontPageComponent implements OnInit {

  selectedBonusBuck: BonusBucksValue;
  bonusBuckValues: BonusBucksValue[];
  oldValue : BonusBucksValue = new BonusBucksValue(0 , false);
  buttonDisabled : boolean = false;

  constructor(private bbAmountService: BBAmountService, private formManager: BbFormManagementService) { }

  ngOnInit() {
    this.bonusBuckValues = this.bbAmountService.allBBVals;
    this.bbAmountService.currentBonusBuckValue.subscribe(newVal => { 
      if (newVal == null || newVal == undefined) {
        this.oldValue = null;

        if (this.hasExistingCoverages()) {
          // default to "No Change to BB Setup" and mark active
          newVal = this.bbAmountService.allBBVals.find(bb => bb.amount === BonusBucksValueType.NoChange);
          if (newVal) {
            newVal.isActive = true;
          }
        }
      }

      this.selectedBonusBuck = newVal;
      this.buttonDisabled = (newVal == null || newVal == undefined);
    });
  }

  hasExistingCoverages(): boolean {
    if (this.formManager.currentCoveragePackages) {
      for (let pkg of this.formManager.currentCoveragePackages) {
        for (let cov of pkg.Coverages) {
          if (cov.IsChecked) return true;
        }
      }
    }
    return false;
  }

  onValueClick(newBonusBuck: BonusBucksValue) {
    if (newBonusBuck.amount === -1) {
      newBonusBuck.isActive = true;
      this.buttonDisabled = false;
      if (this.selectedBonusBuck) {
        this.selectedBonusBuck.isActive = false;
      }
    } else {
      this.buttonDisabled = this.oldValue == null && newBonusBuck.amount === -1;
      if (this.selectedBonusBuck) {
        this.selectedBonusBuck.isActive = false;
      }
      this.bbAmountService.setBonusBuckValue(newBonusBuck);
    }
  }

  getbonusBucksDisplay(buck: BonusBucksValue) {
    const formatter = new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
      minimumFractionDigits: 2
    });

    if (buck.amount === BonusBucksValueType.NoChange) {
      return 'No Change to BB Setup';
    } else if (buck.amount === BonusBucksValueType.Custom) {
      return 'Custom';
    } else if (buck.amount === BonusBucksValueType.CompanyBenchmark) {
      return 'Company\xa0Benchmarks';
    } else {
      return formatter.format(buck.amount);
    }
  }

}
