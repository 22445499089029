<button class="btn btn-dark btn-sm btn-fieldset margintop"
        type="button"
        *ngIf="!isAddingCompetitor"
        (click)="onAddCompetitorClick()"
        [disabled]="isStatic || disableInput">
        <fa-icon [icon]="faPlus"></fa-icon> Add Competitor
</button>

<div id="add-competitor" class="margintop"
     *ngIf="isAddingCompetitor">
  <ul>
    <li>

      <input class="form-control-sm"
             type="text"
             #addCompetitorInput
             [(ngModel)]="competitor"
             (keydown.enter)="onSaveCompetitorClick()"
             (keydown.esc)="onCancelCompetitorClick()"
             trim="blur" />

    </li>
    <li>

      <button class="submit-btn btn btn-sm btn-dark btn-icon btn-save" (click)="onSaveCompetitorClick()"
                type="button">
                <fa-icon [icon]="faCheck" class="text-success"></fa-icon> 
        </button>

    </li>
    <li>

      <button class="btn btn-sm btn-dark btn-icon btn-cancel cancel-btn"
                type="button"
                (click)="onCancelCompetitorClick()">
                <fa-icon [icon]="faTimes" class="text-danger"></fa-icon> 
        </button>
    </li>
  </ul>
</div>

<div [formGroup]="competitorsForm" class="container-fluid">
  <div class="row">
    <div class="col-sm-12">

      <ul class="competitors list-unstyled">
        <li class="form-check form-check-inline" *ngFor="let competitor of competitors;">

          <mat-checkbox color="primary" [disabled]="disableInput"
                        formControlName="{{competitor.competitorId}}">{{competitor.label}}</mat-checkbox>

        </li>
      </ul>

    </div>
  </div>
</div>
