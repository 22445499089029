import { Component, Input } from '@angular/core';
import { CoveragePackage } from 'src-private/app/areas/dealers/models/bonus-bucks.Models';
import { MatExpansionPanelHeader } from '@angular/material/expansion';

@Component({
  selector: 'app-selected-coverages',
  templateUrl: './selected-coverages.component.html',
  styleUrls: ['./selected-coverages.component.scss']
})
export class SelectedCoveragesComponent {
  @Input() coveragePackageArray: CoveragePackage[];
  @Input() expand: boolean;

  isExpanded(cp: CoveragePackage): boolean {
    return this.expand || cp.Coverages.some(cov => cov.IsChecked);
  }

  expandPanel(header: MatExpansionPanelHeader, event: Event): void {
    event.stopPropagation(); // Preventing event bubbling
    if (!this._isExpansionIndicator(<HTMLElement>event.target)) {
      header._toggle();
    }
  }
  
  private _isExpansionIndicator(target: Element): boolean {
    const expansionIndicatorClass = 'mat-expansion-indicator';
    return (target.classList && target.classList.contains(expansionIndicatorClass));
  }

  covPackAllSelected(cp: CoveragePackage): boolean {
    return cp.Coverages.filter(cov => cov.IsChecked).length > 0;
  }

  packageChecked(event: any, cp: CoveragePackage) {
    cp.Coverages.forEach(cov => cov.IsChecked = event.checked);
  }

  selectAll(event: any) {
    this.coveragePackageArray.forEach(pack => pack.Coverages.forEach(cov => cov.IsChecked = event.checked));
  }

  get allSelected() {
    return this.coveragePackageArray.every(cp => cp.Coverages.every(cov => cov.IsChecked));
  }

  getSelectionStatus() {
    return this.allSelected ? 'Deselect All' : 'Select All';
  }

  onChangeBucks(cp: CoveragePackage) {
    this.coveragePackageArray.forEach(pack => {
      if (pack === cp) {
        pack.Coverages.forEach(cov => {
          cov.BonusBucksAmount = cov.IsChecked ? pack.Amount : 0;
        });
      }
    });
  }

  isChecked(cp: CoveragePackage){
    return !(cp.Coverages.filter(cov => cov.IsChecked).length > 0)
  }
}
