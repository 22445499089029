import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Title } from "@angular/platform-browser";
import { RepairCentreService } from '../../services/repair-centre.service'
import { IRepairCentre } from '../../interfaces/repair-centre.interface';
import { ActivatedRoute } from '@angular/router';
import { faEdit, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Country } from 'src-private/app/enums/bonus-bucks.enums';
import { CellBuilder } from 'src-private/app/shared/table-adapter/cell-builder/cell-builder';
import { CellType } from 'src-private/app/shared/table-adapter/cell-builder/cell-type';
import { FrameworkComponent } from 'src-private/app/shared/framework/framework.component';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src-private/app/areas/account/services/account.service';
import { TowingService } from 'src-private/app/enums/repair-centre.enums';
import { ClaimsService } from '../../services/claims.service';
import { environment } from 'src-private/environments/environment';

@Component({
  selector: 'app-repair-centre',
  templateUrl: './repair-centre.component.html',
  styleUrls: ['./repair-centre.component.scss']
})
export class RepairCentreComponent extends FrameworkComponent implements OnInit, AfterViewInit {

  public faEdit = faEdit
  public faPlus = faPlus

  public repairCentre: IRepairCentre
  public claimId: number
  public repairCentreID : number
  public towingservice : string =''

  postalCodeStateLabel: string
  postalCodeStateMask: string
  provinceStateLabel: string

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private formBuilder: UntypedFormBuilder,
    private repairCentreService: RepairCentreService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private accountService :AccountService,
    private toastr: ToastrService,
    private claimService: ClaimsService,
    private titleService: Title) {
      super();
      this.claimId = this.route.snapshot.data['claimId']
    }

  public repairCentreNotesTableColumns :CellBuilder[] = [
    new CellBuilder("Created Date", "createdDate", CellType.date, 'col-3 col-sm-3'),
    new CellBuilder("Adjuster", "adjusterName", CellType.text, 'col-2 col-sm-2'),
    new CellBuilder("Note", "note", CellType.text)
  ]

  ngOnInit() {
    this.repairCentreID = +this.route.snapshot.paramMap.get('id');
    this.titleService.setTitle("Repair Centre - " + this.repairCentreID);
    if (this.repairCentreID) {
      this.repairCentreService.retrieve(this.repairCentreID).subscribe(res => {
        this.reload(res)
      },
      error => {
        this.toastr.error(environment.messages.apiError, "Unable to retrieve repair centre");
      })
    }
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit(){
    super.build("RepairCentreComponent", 'repaircentre-component')
  }

  onEdit(data: IRepairCentre): void {
    this.reload(data)
  }

  reload(data: IRepairCentre): void {
    this.repairCentre = data
    this.towingservice = TowingService[data.towingService]
    this.updatePostalCodeStateLabel()
    this.updateProvinceStateLabel()
  }

  updatePostalCodeStateLabel(): void {
    this.postalCodeStateLabel = this.isCanada ? 'Postal Code' : 'Zip Code'
    this.postalCodeStateMask = this.isCanada ? 'S0S 0S0' : '00000'
  }

  updateProvinceStateLabel(): void {
    this.provinceStateLabel = this.isCanada ? 'Province' : 'State'
  }

  get isCanada(): boolean {
    return this.repairCentre.country === Country[Country.Canada]
  }

  public get isDoNotUse():boolean{
    return this.repairCentre?.status === 'Do Not Use' ?? false;
  }

  public get IsAdjuster(): boolean {
    return this.accountService.isAdjuster() || this.accountService.isClaimsManager() || this.accountService.isAssistantClaimsManager();
  }

  public get CanAddNotes(): boolean {
    return this.accountService.isAdjuster() || this.accountService.isClaimsManager() || this.accountService.isAssistantClaimsManager() || this.accountService.isDealerServices();
  }
}
