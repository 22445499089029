import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from './utils.service';
import { environment } from 'src-private/environments/environment';
import { ISearchResult } from '../interfaces/search-result.interface';
import { IRecentResult } from '../interfaces/recent-result.interface';

@Injectable({
  providedIn: 'root'
})
export class SearchResultService {

  constructor(private http: HttpClient) { }

  search(searchTerm: string, userID: string, searchType: number): Observable<Object> {
    const params = {
      searchTerm: searchTerm,
      userID: userID,
      searchType: searchType
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);
    return this.http.get<ISearchResult>(environment.serverUrl + 'api/Search/search',
      { params: queryParams });
  }

  listRecent(userId: string): Observable<Object> {
    const params = {
      userId: userId
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);
    return this.http.get<IRecentResult[]>(environment.serverUrl + 'api/Search/listRecent',
      { params: queryParams });
  }

  deleteRecent(recent: IRecentResult) {
    const params = {
      ID: recent.ID,
      description: recent.description
    }

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);
    return this.http.post(environment.serverUrl + 'api/Search/deleteRecent', params);
  }
}
