<mat-dialog-content>
  <form [formGroup]="noteAddForm">
    <div class="container-fluid dialogContent container-Padding">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Note</mat-label>
            <textarea matInput formControlName="note" rows="6"></textarea>
            <mat-error *ngIf="noteAddForm.get('note').errors?.required">
              Note is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="float-right">
  <button mat-button mat-dialog-close class="btn" type="button" (click)="cancel()">
    <fa-icon [icon]="faWindowClose" class="pr-2 text-danger"></fa-icon> Cancel
  </button>
  <button mat-button class="btn" type="button" (click)="addNote()">
    <fa-icon [icon]="faSave" class="pr-2 text-success"></fa-icon> Save
  </button>
</mat-dialog-actions>