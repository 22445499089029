import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { faSave, faWindowClose } from '@fortawesome/pro-solid-svg-icons';
import { ICustomer } from '../../../interfaces/customer.interface';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AppValidators } from 'src-private/app/validators/app-validators';
import { Country } from 'src-private/app/enums/bonus-bucks.enums';
import { CustomerService } from '../../../services/customer.service';
import { ProvinceStateService } from 'src-private/app/services/province-states.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ConfirmDialogComponent } from 'src-private/app/shared/confirm-dialog/confirm-dialog.component';
import { DialogType } from 'src-private/app/enums/dialog-type';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss']
})
export class CustomerEditComponent implements OnInit {
  public customerEditForm: UntypedFormGroup;

  public faSave = faSave;
  public faWindowClose = faWindowClose
  postalCodeStateLabel: string
  postalCodeStateMask: string;
  provinceStateLabel: string
  @BlockUI() blockUI: NgBlockUI;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private formBuilder: UntypedFormBuilder,
    private customerService: CustomerService,
    private provinceStateService: ProvinceStateService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: ICustomer,
    private dialogRef: MatDialogRef<CustomerEditComponent>) { }

  ngOnInit() {
    this.customerEditForm = this.formBuilder.group({
      firstName: [this.data.firstName, Validators.required],
      lastName: [this.data.lastName, Validators.required],
      phone: [this.data.phone, Validators.required],
      email: [this.data.email, AppValidators.email(false)],
      address: [this.data.address, Validators.required],
      city: [this.data.city, Validators.required],
      province: [this.data.provinceName, Validators.required],
      postalZipCode: [this.data.postalZipCode, Validators.required],
      country: [this.data.countryName, Validators.required],
      driversLicense: [this.data.driversLicense],
    });

    this.updatePostalCodeStateLabelMask()
    this.updateProvinceStateLabel()

    this.dialogRef.backdropClick().subscribe(() => {
      this.cancel();
    })
  }

  confirm() {
    if (AppValidators.validateAll(this.customerEditForm)) {
      this.data = Object.assign(this.data, this.customerEditForm.value);
      this.customerService.retrieve(this.data.customerId).subscribe(res => {
        this.loadProvinceName(res);
      })
    }
  }

  loadProvinceName(data : ICustomer) {
    this.provinceStateService.retrieveProvinceById(data.provinceStateId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        response => {
          data.provinceName = response[0] as string;
          let localData : object = new Object();

          for (var key of Object.keys(data)) {
            localData[key] = this.data[key];
          }
          if(Object.entries(localData).toString() === Object.entries(data).toString()) {
            //If data has not been modified don't save
            this.dialogRef.close({ data: "Cancel" });
          }
          else if(data.version == this.data.version) {
            this.blockUI.start();
            this.data.version = ++this.data.version ;
            this.dialogRef.close({ data: this.data });
          }else {
            let dialogRef = this.dialog.open(ConfirmDialogComponent, {
              panelClass: 'part-dialog',
              width: '400px',
              autoFocus: true,
              data: {
                message : "Data you wish to save has been expired. Do you wish to reload?",
                type: DialogType[0].toString()
              }
            });
            dialogRef.afterClosed().subscribe(result => {
              if(result) {
                for (var key of Object.keys(data)) {
                  this.data[key] = data[key];
                }

                this.customerEditForm = this.formBuilder.group({
                  firstName: [this.data.firstName, Validators.required],
                  lastName: [this.data.lastName, Validators.required],
                  phone: [this.data.phone, Validators.required],
                  email: [this.data.email, AppValidators.email(false)],
                  address: [this.data.address, Validators.required],
                  city: [this.data.city, Validators.required],
                  province: [this.data.provinceName, Validators.required],
                  postalZipCode: [this.data.postalZipCode, Validators.required],
                  country: [this.data.countryName, Validators.required],
                  driversLicense: [this.data.driversLicense]
                });
              }
              else {
                this.data['status'] = 'refresh';
                this.dialogRef.close({ data: this.data });
              }
            });
          }
        }
      );
  }

  cancel() {
    this.dialogRef.close({ event: 'Cancel' })
  }

  onCountryChange(): void {
    this.customerEditForm.get('postalZipCode').setValue('');
    this.updatePostalCodeStateLabelMask()
    this.updateProvinceStateLabel()

  }

  updatePostalCodeStateLabelMask(): void {
    this.postalCodeStateLabel = this.isCanada ? 'Postal Code' : 'Zip Code'
    this.postalCodeStateMask = this.isCanada ? 'S0S 0S0' : '00000'
  }

  updateProvinceStateLabel(): void {
    this.provinceStateLabel = this.isCanada ? 'Province' : 'State'
  }

  get isCanada(): boolean {
    return this.customerEditForm.get('country').value === Country[Country.Canada]
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
