import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class BreakpointService {
  @Output() bp = new EventEmitter<number>();

  private breakpoint: number;

  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {
    this.breakpointObserver
      .observe([
        '(min-width: 375px)',
        '(min-width: 480px)',
        '(min-width: 768px)',
        '(min-width: 1024px)',
        '(min-width: 1440px)',
        '(min-width: 1600px)',
      ])
      .subscribe((state: BreakpointState) => {
        this.handleViewportChange(state.breakpoints);
      });
  }

  public getBreakpoint() {
    return this.breakpoint;
  }

  private handleViewportChange(breakpoints: object) {
    if (breakpoints['(min-width: 1600px)']) {
      this.bp.emit(6);
      this.breakpoint = 6;
    } else if (breakpoints['(min-width: 1440px)']) {
      this.bp.emit(5);
      this.breakpoint = 5;
    } else if (breakpoints['(min-width: 1024px)']) {
      this.bp.emit(4);
      this.breakpoint = 4;
    } else if (breakpoints['(min-width: 768px)']) {
      this.bp.emit(3);
      this.breakpoint = 3;
    } else if (breakpoints['(min-width: 480px)']) {
      this.bp.emit(2);
      this.breakpoint = 2;
    } else {
      this.bp.emit(1);
      this.breakpoint = 1;
    }
  }
}
