import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { environment } from '../../../../../../environments/environment';

/*-- Enums --*/
import { FormState } from '../../../../../enums/form-state.enum';

/*-- Interfaces --*/
import { ICompetitor } from '../../../interfaces/competitor.interface';
import { IDealer } from '../../../interfaces/dealer.interface';

/*-- Services --*/
import { DealersService } from '../../../services/dealers.service';
import { DealersSharedService } from '../../../services/dealers-shared.service';

/*-- Third Party --*/
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { faCheck, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-competitors',
  templateUrl: './competitors.component.html',
  styleUrls: ['./competitors.component.scss']
})
export class CompetitorsComponent implements OnChanges, OnInit {
  @BlockUI() blockUI: NgBlockUI;

  @Input() competitorsForm: UntypedFormGroup;
  @Input() dealer: IDealer;
  @Input() formState: FormState;
  @Input() submitted: boolean;
  @Input() canAllowEditing$: BehaviorSubject<boolean>;

  @ViewChild('addCompetitorInput') addCompetitorInput: ElementRef;

  private competitor: string;
  private currentDealer: IDealer;
  private isStatic: boolean;

  public competitors: ICompetitor[];
  public isAddingCompetitor: boolean;
  public faPlus = faPlus;
  public faTimes = faTimes;
  public faCheck = faCheck;
  public disableInput: boolean

  constructor(
    private dealersService: DealersService,
    private dealersSharedService: DealersSharedService,
    private toastr: ToastrService
  ) { }

  //#region - Lifecycle
  ngOnChanges(changes: SimpleChanges) {
    const dealer: SimpleChange = changes.dealer;
    const formState: SimpleChange = changes.formState;

    if(this.canAllowEditing$){
      this.canAllowEditing$.asObservable().subscribe((resp)=>{
        if(!resp){
          this.competitorsForm.disable();
          this.disableInput = true
        }
        else{
          this.competitorsForm.enable();
          this.disableInput = false;
        }
      })
    }

    if (dealer && dealer.currentValue) {
      this.currentDealer = dealer.currentValue;

      this.setCompetitors();
    }

    if (formState && formState.currentValue) {
      switch (formState.currentValue) {
        case FormState.Add:
        case FormState.Modify:
          this.isStatic = false;

          if(!this.disableInput){
            Object.keys(this.competitorsForm.controls).forEach(key => {
              this.competitorsForm.get(key).enable();
            });  
          }
        
          break;
        case FormState.Static:
          this.isStatic = true;

          Object.keys(this.competitorsForm.controls).forEach(key => {
            this.competitorsForm.get(key).patchValue(false);
            this.competitorsForm.get(key).disable();
          });

          break;
      }
    }
  }

  ngOnInit() {
    this.lookupCompetitors();
  }
  //#endregion

  //#region - Events
  onAddCompetitorClick() {
    this.isAddingCompetitor = true;

    setTimeout(() => this.addCompetitorInput.nativeElement.focus(), 0);
  }

  onCancelCompetitorClick() {
    this.isAddingCompetitor = false;

    this.competitor = null;
  }

  onSaveCompetitorClick() {
    if (this.competitor) {
      this.createCompetitor();
    } else {
      setTimeout(() => this.addCompetitorInput.nativeElement.focus(), 0);
    }
  }
  //#endregion

  //#region - Private Methods
  private setCompetitors() {
    let competitors;
    if (this.currentDealer.competitors) {
        competitors = JSON.parse(this.currentDealer.competitors);
    }


    let control;

    // clear the checkboxes
    Object.keys(this.competitorsForm.controls).forEach(key => {
      this.competitorsForm.get(key).patchValue(false);
    });

    // set the checkboxes
    if (competitors && competitors.length) {
      competitors.forEach(competitor => {
        control = this.competitorsForm.get(competitor.toString());

        if (control) {
          control.patchValue(true);
        }
      });
    }
  }
  //#endregion

  //#region - API Methods
  private createCompetitor(): void {
    const title = 'Create Competitor';

    this.blockUI.start();

    this.dealersService.competitorCreate(this.competitor).subscribe(
      () => {
        // hide the form
        this.onCancelCompetitorClick();

        // reload the competitors
        this.lookupCompetitors();

        // show toast
        this.toastr.success('Your competitor has been successfully created.', title);

        this.blockUI.stop();
      },
      () => {
        this.toastr.error(environment.messages.apiError, title);

        this.blockUI.stop();
      }
    );
  }

  private lookupCompetitors(): void {
    this.dealersService.competitorLookup().subscribe(response => {
      const data: ICompetitor[] = response;

      this.competitors = data;

      // load the 'competitorForm' object
      this.competitors.forEach(item => {
        this.competitorsForm.addControl(item.competitorId.toString(), new UntypedFormControl(false));
      });

      // disable the controls if the form state is 'Static'
      if (this.formState === FormState.Static) {
        Object.keys(this.competitorsForm.controls).forEach(key => {
          this.competitorsForm.get(key).disable();
        });
      }

      // handle page refresh
      if (this.currentDealer) {
        this.setCompetitors();
      }
    });
  }
  //#endregion
}
