<mat-dialog-content>
    <fieldset class="fieldset-form">
        <legend>Claim {{claimLock.claimEnterpriseId}} Locked From Editing</legend>
        <div class="lockInfo">
            <p>
                Claim {{claimLock.claimEnterpriseId}} is currently being edited by {{claimLock.username}} since
                {{claimLock.lockTime | date:'short'}}
            </p>
        </div>
        <fa-icon [icon]="faLock" size="10x" class="lockIcon"></fa-icon>
    </fieldset>
</mat-dialog-content>
<mat-dialog-actions class="float-right">
    <button mat-button mat-dialog-close class="btn" type="button" (click)="cancel()">
        <fa-icon [icon]="faWindowClose" class="pr-2 text-danger"></fa-icon> Cancel
    </button>
    <button mat-button class="btn" type="button" (click)="manuallyUnlock()">
        <fa-icon [icon]="faLockOpen" class="pr-2 text-success"></fa-icon> Manually Unlock (Dangerous)
      </button>
</mat-dialog-actions>