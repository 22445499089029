import { Directive, ElementRef, HostListener } from '@angular/core';
import { exists } from 'fs';

@Directive({
  selector: '[avatar]'
})
export class AvatarDirective {
  constructor(private el: ElementRef) {}
  
  @HostListener('error') onError() {
    this.setGenericAvatarImg(this.el);
  }

  private setGenericAvatarImg(element) {

    var defaultURL = '/assets/images/avatars/generic-avatar.jpg';

    var img = new Image();
    img.src = defaultURL;

    img.onload = function(){
      element.nativeElement.src = defaultURL;
    }

    img.onerror = function(){
      element.nativeElement.hidden = true;
    }
  }
}