<div>
    <div class="sidebar-header">
        <h4 class="sidebar-title">Notifications</h4>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6" (click)="markAllAsRead()">
                <div class="notif-btn left no-pad">
                    <fa-icon [icon]="faCheck" class="markReadIcon"></fa-icon> Mark All as Read
                </div>
            </div>
            <div class="col-lg-6" (click)="closeSideBar()">
                <div class="notif-btn right no-pad">
                    <fa-icon [icon]="faTimes" class="closeIcon"></fa-icon> Close
                </div>
            </div>
        </div>
    </div>
    <div class="sidebar-body">
        <div class="container">
            <div *ngIf="!hasNotifications;else notificationList" class="row notif-list-row noNotifications">
                <div class="col-lg-12 notif-col-center">
                    No notifications found.
                </div>
            </div>
            <ng-template #notificationList>
                <div class="row notif-list-row" *ngFor="let not of notifications">
                    <div class="col-sm-10">
                        <div (click)="navigateFunction(not)">
                            {{not.description}}
                        </div>
                    </div>
                    <div class="notif-btn read col-sm-2">
                        <div (click)="markAsRead(not)">
                            <fa-icon [icon]="faTimes"></fa-icon>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>