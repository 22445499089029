<div *ngIf="loading; else reviewChanges">
    <mat-spinner></mat-spinner>
</div>
<ng-template #reviewChanges>
    <app-selected-coverages [expand]="true" [coveragePackageArray]="changedCoveragePackages"></app-selected-coverages>
    
    <mat-card class="col-12">
        <mat-card-title>Distribution Changes</mat-card-title>
        <mat-card-content>
            <div *ngFor="let change of distributionChanges">
                <app-change [alteration]="change"></app-change>
            </div>
            <div *ngIf="distributionChanges.length === 0">No distribution changes</div>
        </mat-card-content>
    </mat-card>

    <form [formGroup]="submitForm">
        <a href="../../../../../../../../Assets/files/termsAndConditions.pdf" target="_blank">Terms and Conditions</a>
        <section>
            <mat-checkbox formControlName="isTermsAgreed" color="primary">I Agree to and accept the terms and conditions
                in the agreement and wish to proceed.
            </mat-checkbox>
        </section>
        <div [hidden]="!isCoveragesInvalid" class="alert alert-warning" role="alert">
            There are selected Coverages with an amount of 0.
        </div>
        <button mat-button class="float-right" type="button" [disabled]="!submitForm.valid"
            (click)="onSubmit()">Submit</button>
    </form>

</ng-template>