import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AccountService } from '../areas/account/services/account.service';

@Directive({
  selector: '[appComponentAuthorization]'
})
export class ComponentAuthorizationDirective implements OnInit{
  @Input('appComponentAuthorization') roles : Array<string>;

  constructor(private accountService : AccountService, private el : ElementRef) { }

  ngOnInit(){
    if(!this.isRoleExists(this.roles)){
      this.el.nativeElement.style.display = 'none'
    }
  }

   isRoleExists(Role : Array<string>) : boolean{
    return Role.filter((value)=>{ return this.accountService.isInRole(value)}).length > 0 ;
   }
}
