import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src-private/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class NoteGroupService {

  constructor(private http: HttpClient) { }

  retrieveByName(GroupName: string): Observable<number> {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('label', GroupName);

    return this.http.get<number>(`${environment.resourceUrl}api/gwc/notegroup/`, { params: httpParams });
  }
}
