<fieldset class="fieldset-form height" *ngIf="repairCentre">
    <legend>
        <div class="row">
            <div class="col-6">
                Repair Centre
                <fa-icon [icon]="faEdit" [hidden]="!canEdit" class="pr-2" (click)="isAdjuster ? edit() : false" role="button"></fa-icon>
            </div>
            <div class="col-6 text-right" *ngIf="repairCentre.preferred">
                <label class="preferred">Preferred</label>
            </div>
        </div>
    </legend>

    <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 font-weight-bold text-uppercase">
            <a [routerLink]="['/repair-centre', repairCentre.id]">
              {{repairCentre.name}} ({{repairCentre.id}})
            </a>
          </div>
        </div>

        <div class="row" *ngIf="repairCentre.contact | trimValue as contact">
            <div class="col-md-12">
                Contact: {{contact}}
            </div>
        </div>

        <div class="row" *ngIf="repairCentre.address | trimValue as address">
            <div class="col-md-12">
                {{address}}
            </div>
        </div>

        <div class="row" *ngIf="formatAddress(repairCentre) as address">
            <div class="col-md-12">
                {{address}}
            </div>
        </div>

        <div class="row">
            <div class="col-md-12" *ngIf="repairCentre.phone as phone">
                Phone: {{phone | phone: repairCentre.country}} <span *ngIf="repairCentre.contactExt | trimValue as ext">ext. {{ext}}</span>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12" *ngIf="repairCentre.fax | trimValue as fax">
                Fax: {{fax | phone: repairCentre.country}}
            </div>
        </div>

        <div class="row">
            <div class="col-md-12" *ngIf="repairCentre.website | trimValue as website">
                Website: <a [href]="website | http" [target]="_blank" [ngStyle]="{'pointer-events': !website ? 'none' : ''}">{{website}}</a>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12" *ngIf="repairCentre.email | trimValue as email">
                Email: <a href="mailto:{{email}}">{{email}}</a>
            </div>
        </div>
    </div>
</fieldset>
