import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

/*-- Interfaces --*/
import { IDealer } from '../interfaces/dealer.interface';

/*-- Models --*/
import { Dealer } from '../models/dealer.model';

/*-- Services --*/
import { DealersService } from './dealers.service';
import { BlockUI, NgBlockUI } from "ng-block-ui";

@Injectable({
  providedIn: 'root'
})
export class DealersSharedService {
  @BlockUI() blockUI: NgBlockUI;

  private dealerSource = new BehaviorSubject<Dealer>(null);

  dealer = this.dealerSource.asObservable();

  public params: BehaviorSubject<Params>;

  constructor(
    private dealersService: DealersService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.params = new BehaviorSubject(this.getDealersComponentRoute().snapshot.params);
    this.route.children[0].params.subscribe(params => {
      this.params.next(params);
    });
  }

  // getParam(key: string): string {
  //   return this.params.getValue()[key];
  // }

  clearDealer() {
    this.dealerSource.next(null);

    // this.updateUrlParam('dealerId', null);
  }

  // setDefaultParamValue(key: string, value: string) {
  //   if (this.route.children[0].snapshot.params[key] != null) {
  //     return;
  //   }
  //   this.updateUrlParam(key, value);
  // }

  getDealersComponentRoute() {
    const currentRoute = this.route;

    while (currentRoute) {
      if (typeof currentRoute.component === 'function' && currentRoute.component.name === 'DealersComponent') {
        return currentRoute;
      }

      return currentRoute.children[0];
    }
  }

  resetUrl() {
    this.router.navigate(['/dealers', {}], {});
    this.params.next({});
  }

  updateUrlParam(key: string, value: string) {
    // TODO: handle route paramter for page refresh
    const currentParams = this.params.getValue();
    if (currentParams[key] === value) {
      return;
    }

    let deepestChildRoute = this.route;
    while (deepestChildRoute.children[0]) {
      deepestChildRoute = deepestChildRoute.children[0];
    }

    let newParams: Params = Object.assign({}, currentParams);

    const newParam = {};
    newParam[key] = value;
    newParams = Object.assign(newParams, newParam);

    if (!value) {
      delete newParams[key];
    }

    this.router.navigate(deepestChildRoute.snapshot ?
      ['/dealers', newParams, deepestChildRoute.snapshot.url[0].path] :
      ['/dealers', newParams],
      {});

    this.params.next(newParams);
  }

  setDealer(dealerId: number): void {
    if (!dealerId) {
      this.resetUrl();
      this.dealerSource.next(new Dealer());
    } else {
      if (this.dealerSource.getValue() && this.dealerSource.getValue().vendorId === dealerId) {
        return;
      }

      this.blockUI.start();

      this.dealersService.dealerRetrieve(dealerId, true).subscribe(response => {
        const dealer: IDealer = response;
        this.dealerSource.next(dealer);

        // TODO: handle route paramter for page refresh
        if (this.route.children[0].snapshot.params['dealerId'] !== dealer.vendorId) {
          // // DealersComponent will be the first route child
          // // This is a hack to normalize the optional param
          // const currentParamsObj: Params = Object.assign({}, this.route.children[0].snapshot.params);
          // const newParam = {};

          // newParam['dealerId'] = dealer.vendorId;

          // const newUrl = this.router.createUrlTree([
          //   Object.assign(currentParamsObj, newParam)
          // ], { relativeTo: this.route.children[0] });

          // this.router.navigateByUrl(newUrl);
          // this.router.navigate(['/dealers'], new class implements NavigationExtras {skipLocationChange = true});
          this.updateUrlParam('dealerId', dealer.vendorId.toString());
          this.blockUI.stop();
        }
      });
    }
  }
}
