<form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>

  <div class="sidebar-header">

    <h4 class="sidebar-title">Report Filter</h4>

  </div>
  <div class="sidebar-actions">

    <button class="btn btn-block btn-icon btn-save"
            type="submit">
      <fa-icon [icon]="faCheck" class="pr-2 text-success icon-left"></fa-icon> Run
    </button>

    <button class="btn btn-block btn-icon btn-cancel"
            type="button"
            (click)="onCloseClick()">
            <fa-icon [icon]="faTimes" class="pr-2 text-danger icon-left"></fa-icon>
      Close
    </button>

  </div>
  <div class="sidebar-body">

    <mat-form-field class="w-100"
                    *ngIf="showFirstDate">
      <input disabled
             required
             formControlName="firstDate"
             [matDatepicker]="firstDatePicker"
             [placeholder]="firstDateLabel"
             matInput>
      <mat-datepicker-toggle matSuffix [for]="firstDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #firstDatePicker disabled="false"></mat-datepicker>
      <mat-error>
        <mat-error *ngIf="isSubmitted && f.firstDate.errors?.required">Field is required</mat-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100"
                    *ngIf="showLastDate">
      <input [placeholder]="lastDateLabel"
             required
             formControlName="lastDate"
             [matDatepicker]="lastDatePicker"
             matInput>
      <mat-datepicker-toggle matSuffix [for]="lastDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #lastDatePicker></mat-datepicker>
      <mat-error>
        <mat-error *ngIf="isSubmitted && f.lastDate.errors?.required">Field is required</mat-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100"
                    *ngIf="showFirstDate2">
      <input [placeholder]="firstDate2Label"
             required
             formControlName="firstDate2"
             [matDatepicker]="firstDatePicker2"
             matInput>
      <mat-datepicker-toggle matSuffix [for]="firstDatePicker2"></mat-datepicker-toggle>
      <mat-datepicker #firstDatePicker2></mat-datepicker>
      <mat-error>
        <mat-error *ngIf="isSubmitted && f.firstDate2.errors?.required">Field is required</mat-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100"
                    *ngIf="showLastDate2">
      <input [placeholder]="lastDate2Label"
             required
             formControlName="lastDate2"
             [matDatepicker]="lastDatePicker2"
             matInput>
      <mat-datepicker-toggle matSuffix [for]="lastDatePicker2"></mat-datepicker-toggle>
      <mat-datepicker #lastDatePicker2></mat-datepicker>
      <mat-error>
        <mat-error *ngIf="isSubmitted && f.lastDate2.errors?.required">Field is required</mat-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100"
                    *ngIf="showUsers && users.length">
      <mat-label>{{userIdLabel}}</mat-label>
      <mat-select #select formControlName="user">
        <mat-option *ngFor="let item of users" [value]="item.id">
          {{item.name}}
        </mat-option>
      </mat-select>
      <mat-error>
        <mat-error *ngIf="isSubmitted && f.user.errors?.required">Field is required</mat-error>
      </mat-error>
    </mat-form-field>

    <ul class="list-unstyled select-all-options"
        *ngIf="showAgents && agents.length">
      <li class="select-cell">

        <mat-form-field class="w-100">
          <mat-label>{{agentsLabel}}</mat-label>
          <mat-select #select formControlName="agent" multiple>
            <mat-option *ngFor="let item of agents" [value]="item.id">
              {{item.label}}
            </mat-option>
          </mat-select>
          <mat-error>
            <mat-error *ngIf="isSubmitted && f.agent.errors?.required">Field is required</mat-error>
          </mat-error>
        </mat-form-field>

      </li>
      <li class="checkbox-cell">

        <mat-checkbox color="primary"
                      [formControl]="allAgentsSelected"
                      (change)="onToggleAllAgents()">Select All</mat-checkbox>

      </li>
    </ul>

    <ul class="list-unstyled select-all-options"
        *ngIf="showReasons && reasonGroups.length">
      <li class="select-cell">

        <mat-form-field class="w-100">
          <mat-label>{{reasonsLabel}}</mat-label>
          <mat-select #select formControlName="reasonGroup" multiple>
            <mat-option *ngFor="let item of reasonGroups" [value]="item.id">
              {{item.label}}
            </mat-option>
          </mat-select>
          <mat-error>
            <mat-error *ngIf="isSubmitted && f.reasonGroup.errors?.required">Field is required</mat-error>
          </mat-error>
        </mat-form-field>

      </li>
      <li class="checkbox-cell">

        <mat-checkbox color="primary"
                      [formControl]="allReasonGroupsSelected"
                      (change)="onToggleAllReasonGroups()">Select All</mat-checkbox>

      </li>
    </ul>

    <ul class="list-unstyled select-all-options"
        *ngIf="showRegions && regions.length">
      <li class="select-cell">

        <mat-form-field class="w-100">
          <mat-label>{{regionsLabel}}</mat-label>
          <mat-select formControlName="region" multiple>
            <mat-option *ngFor="let item of regions" [value]="item.id">
              {{item.label}}
            </mat-option>
          </mat-select>
          <mat-error>
            <mat-error *ngIf="isSubmitted && f.region.errors?.required">Field is required</mat-error>
          </mat-error>
        </mat-form-field>

      </li>
      <li class="checkbox-cell">

        <mat-checkbox color="primary"
                      [formControl]="allRegionsSelected"
                      (change)="onToggleAllRegions()">Select All</mat-checkbox>

      </li>
    </ul>

    <ul class="list-unstyled select-all-options"
        *ngIf="showFileStatus && fileStatus.length">
      <li class="select-cell">

        <mat-form-field class="w-100">
          <mat-label>{{fileStatusLabel}}</mat-label>
          <mat-select formControlName="fileStatus" multiple>
            <mat-option *ngFor="let item of fileStatus" [value]="item.id">
              {{item.label}}
            </mat-option>
          </mat-select>
          <mat-error>
            <mat-error *ngIf="isSubmitted && f.fileStatus.errors?.required">Field is required</mat-error>
          </mat-error>
        </mat-form-field>

      </li>
      <li class="checkbox-cell">

        <mat-checkbox color="primary"
                      [formControl]="allFileStatusSelected"
                      (change)="onToggleAllFileStatus()">Select All</mat-checkbox>

      </li>
    </ul>

    <mat-form-field class="w-100"
                    *ngIf="showSalesClaims && salesClaims.length">
      <mat-label>{{salesClaimsLabel}}</mat-label>
      <mat-select formControlName="salesClaims">
        <mat-option *ngFor="let item of salesClaims" [value]="item.id">
          {{item.label}}
        </mat-option>
      </mat-select>
      <mat-error>
        <mat-error *ngIf="isSubmitted && f.salesClaims.errors?.required">Field is required</mat-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100"
                    *ngIf="showVehicleCategories && vehicleCategories.length">
      <mat-label>{{vehicleCategoriesLabel}}</mat-label>
      <mat-select formControlName="vehicleCategory" multiple>
        <mat-option *ngFor="let item of vehicleCategories" [value]="item.id">
          {{item.label}}
        </mat-option>
      </mat-select>
      <mat-error>
        <mat-error *ngIf="isSubmitted && f.vehicleCategory.errors?.required">Field is required</mat-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100" *ngIf="showDealer">
      <input type="text" matInput placeholder="Dealer Search" [matAutocomplete]="dealer"
        (input)="filterDealers($event.target.value)">
      <mat-autocomplete autoActiveFirstOption #dealer="matAutocomplete" [displayWith]="dealerAutoCompleteDisplay">
        <mat-option *ngIf="isLoading" class="spinner">
          <mat-spinner diameter="25"></mat-spinner>
        </mat-option>
        <ng-container *ngIf="!isLoading">
          <mat-option *ngFor="let dealer of filteredDealers" [value]="dealer"
            (onSelectionChange)="onDealerSelected(dealer, $event)">
            [{{dealer.vendorId}}] {{dealer.alias}}
          </mat-option>
        </ng-container>
      </mat-autocomplete>
      <mat-error>
        <mat-error *ngIf="isSubmitted && f.selectedDealer.errors?.required">Field is required</mat-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100"
                    *ngIf="showCompany && companies.length">
      <mat-label>{{companyLabel}}</mat-label>
      <mat-select formControlName="selectedCompany">
        <mat-option *ngFor="let company of companies" [value]="company.id">
          {{company.label}}
        </mat-option>
      </mat-select>
      <mat-error>
        <mat-error *ngIf="isSubmitted && f.company.errors?.required">Field is required</mat-error>
      </mat-error>
   </mat-form-field>

   <mat-form-field class="w-100"
                    *ngIf="ShowInfoComplete && infoComplete.length">
      <mat-label>{{infoCompleteLabel}}</mat-label>
      <mat-select formControlName="infoComplete">
        <mat-option *ngFor="let item of infoComplete" [value]="item.id">
          {{item.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>
</form>
