import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { faSave, faWindowClose } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  public message : string;
  public type : string;
  public faSave = faSave;
  public faWindowClose = faWindowClose;

  constructor(@Inject(MAT_DIALOG_DATA) data) { 
    this.message = data.message; 
    this.type = data.type;
  }

  ngOnInit() {
  }

}
