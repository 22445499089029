<div class="header">

  <div class="container-fluid">

    <div class="form-row">
      <div [ngClass]="{ 'offset-sm-2': bp >= 5 }"></div>

      <div class="col-sm-1">

        <div class="header-button-outer"
             [ngClass]="{ 'disabled': filterDisabled }"
             (click)="onFilterClick()">
          <div class="header-button-inner">

            <fa-icon [icon]="faFilter"></fa-icon>
            <span class="header-button-label"
                  *ngIf="bp < 3 || bp >= 4">Filter</span>

          </div>
        </div>

      </div>

      <div [ngClass]="{ 'col-sm-3': bp >= 5, 'col-sm-5': bp < 5 }">

        <div class="header-field-outer">
          <div class="header-field-inner">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text form-field-label" id="category-addon">Category</span>
              </div>
              <mat-select #select [formControl]="category" aria-describedby="category-addon"
              aria-label="category" class="custom-select report-select">
                <mat-option *ngFor="let category of categories" [value]="category.reportGroupId" (click)="onReportCategoryChange()">
                  {{category.reportGroup}}
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>

      </div>
      <div [ngClass]="{ 'col-sm-4': bp >= 5, 'col-sm-6': bp < 5 }">
        <div class="header-field-outer">
          <div class="header-field-inner">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text form-field-label" id="report-addon">Report</span>
              </div>
              <mat-select #select [formControl]="report" aria-describedby="report-addon"
              aria-label="report" class="custom-select report-select">
                <mat-option [disabled]="report.reportStatusId === reportStatus.ComingSoon" *ngFor="let report of reports" [value]="report.reportId" (click)="onReportChange()">
                  {{report.label}} <ng-container *ngIf="report.reportStatusId === reportStatus.ComingSoon"> [ Coming Soon ]</ng-container>
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>
      </div>

    <div *ngIf="isBonusBucksReport">
      <button mat-button (click)="authClick(1)">Tier 1</button>
      <button mat-button (click)="authClick(2)">Tier 2</button>
      <button mat-button (click)="authClick(3)">Tier 3</button>
      <button mat-button (click)="authClick(4)">Tier 4</button>
    </div>

    <div *ngIf="isBonusBucksReport" [ngClass]="{ 'col-sm-4': bp >= 5, 'col-sm-6': bp < 5 }">
      <div>
        <div class="header-field-outer">
          <div class="header-field-inner">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text form-field-label">Dealer</span>
              </div>
              <mat-select model="dealers" #select [formControl]="dealers" class="custom-select report-select">
                <mat-option value="">Choose a Dealer</mat-option>
                <mat-option *ngFor="let dealer of dealers" [value]="dealer.alias" (click)="updateDealer(dealer.alias)">
                  {{dealer.alias}}
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="selectedDealer">
        <mat-form-field style="padding-right:20px;">
          <input matInput required [matDatepicker]="picker1" placeholder="Choose a start date" (dateChange)="onDateChange()" [formControl]="date1">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <input matInput required [matDatepicker]="picker2" placeholder="Choose an end date" (dateChange)="onDateChange()" [formControl]="date2">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
      
      
      <div [hidden]="bbData" class="alert alert-warning" role="alert">
        There are no reports to display for this period.
      </div>

      <ng-container *ngIf="bbData">      
        <table style="width: 100%" id="t1" class="table data-table data-table-dark report-table-align">
          <thead>         
            <tr>
              <th>Region</th>
              <th>Agent</th>
              <th>Coverage</th>
              <th>Total</th>
              <th>Distribution %</th>
              <th>Recipient</th>
              <th>Address</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Branch/Routing</th>
              <th>Institution/Check</th>
              <th>Account</th>
              <th>SIN</th>
            </tr> 
          </thead>
          <ng-container *ngFor="let distro of bbData">
            <tbody *ngFor="let dist of distro.Distributions">
              <td>{{distro.Region}}</td>
              <td>{{dist.SalesPersonName}}</td>
              <td>{{dist.CoverageName}}</td>
              <td>{{distro.BonusBucksAmount}}</td>
              <td>{{dist.DistributionPercent}}</td>
              <td>{{dist.Recipient}}</td>
              <td>{{dist.Address}}</td>
              <td>{{dist.Phone}}</td>
              <td>{{dist.Email}}</td>
              <td>{{dist.BranchRouting}}</td>
              <td>{{dist.InstitutionCheck}}</td>
              <td>{{dist.Account}}</td>
              <td>{{dist.SocialInsuranceNumber}}</td>
            </tbody>
          </ng-container>
        </table>

        <!-- <table id="t2" class="table data-table data-table-dark">
          <thead>
            <tr>
              <th>Agent</th>
              <th>Dealer</th>
              <th>Total</th>
            </tr>
          </thead>
          
          <ng-container *ngFor="let distro of bbData">
            <tbody>
                <td>{{distro.PrincipalAuth}}</td>
                <td>{{distro.Dealer}}</td>
                <td>{{distro.BonusBucksAmount}}</td>
              </tbody>
          </ng-container>
        </table>

        <table id="t3" class="table data-table data-table-dark">
          <thead>
            <tr>
              <th>Dealer</th>
              <th>Recipient</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{bbData.Dealer}}</td>
              <td>{{principalAuth.agent}}</td>
              <td>{{principalAuth.amount}}</td>
            </tr>
            <ng-container *ngFor="let data of bbData">
              <tr *ngFor="let distro of data.Distributions">
                <td>{{data.Dealer}}</td>
                <td>{{distro.SalesPersonName}}</td>
                <td>{{distro.DistributionPercent}}%</td>
              </tr>
            </ng-container>
          </tbody>
        </table>

        <table id="t4" class="table data-table data-table-dark">
          <thead>
            <tr>
              <th>Sales Person</th>
              <th>Coverage</th>
              <th>Bonus Bucks Amount</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let data of bbData">
              <tr *ngFor="let distro of data.Distributions">
                <td>{{distro.SalesPersonName}}</td>
                <td>{{distro.CoverageName}}</td>
                <td>{{((distro.DistributionPercent | number: '.2') / 100) * (data.BonusBucksAmount | number: '.2')}}</td>
              </tr>
            </ng-container>
          </tbody>
        </table> -->
    </ng-container>
    </div>

    <div *ngIf="is_BB_MissingRecipInfoReport" [ngClass]="{ 'col-sm-4': bp >= 5, 'col-sm-6': bp < 5 }">

      <div>
        <mat-form-field style="padding-right:20px;">
          <input matInput required [matDatepicker]="picker1" placeholder="Choose a start date" (dateChange)="getMissingRecipientInfo()" [formControl]="date1">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <input matInput required [matDatepicker]="picker2" placeholder="Choose an end date" (dateChange)="getMissingRecipientInfo()" [formControl]="date2">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>

      <div [hidden]="MissRecipInfoData" class="alert alert-warning" role="alert">
        There are no reports to display for this period.
      </div>

      <ng-container *ngIf="MissRecipInfoData">
        <table style="width: 100%" id="t1" class="table data-table data-table-dark">
          <thead>
            <tr>
              <th>Sales Person Name</th>
              <th>Distribution Percent</th>
              <th>Recipient</th>
              <th>Address</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Social Insurance Number</th>
              <th>CRA Business Number</th>
              <th>Branch/Routing</th>
              <th>Institution Check</th>
              <th>EFT Image</th>
              <th>Account</th>
              <th>Coverage Name</th>
            </tr>
          </thead>

          <tbody>
            <ng-container *ngFor="let recip of MissRecipInfoData">
              <tr>
                <td>{{recip.SalesPersonName}}</td>
                <td>{{recip.DistributionPercent}}</td>
                <td>{{recip.Recipient}}</td>
                <td *ngIf="recip.Address; else MissingInfo">{{recip.Address}}</td>
                <td *ngIf="recip.Phone; else MissingInfo">{{recip.Phone}}</td>
                <td *ngIf="recip.Email; else MissingInfo">{{recip.Email}}</td>
                <td *ngIf="recip.SocialInsuranceNumber; else MissingInfo">{{recip.SocialInsuranceNumber}}</td>
                <td *ngIf="recip.CRABusinessNumber; else MissingInfo">{{recip.CRABusinessNumber}}</td>
                <td *ngIf="recip.BranchRouting; else MissingInfo">{{recip.BranchRouting}}</td>
                <td *ngIf="recip.InstitutionCheck; else MissingInfo">{{recip.InstitutionCheck}}</td>
                <td>{{recip.EFTImage}}</td>
                <td *ngIf="recip.Account; else MissingInfo">{{recip.Account}}</td>
                <td>{{recip.CoverageName}}</td>
              </tr>
            </ng-container>
          </tbody>

        </table>
        <ng-template #MissingInfo><td align="center"><fa-icon [icon]="faFlag" style="color: red;"></fa-icon></td></ng-template>
      </ng-container>

    </div>


    <div [ngClass]="{ 'col-sm-4': bp >= 5, 'col-sm-6': bp < 5 }" *ngIf="isBatchReport && !isNewPayeeVendor">
      
      <p>Please select a Company to download batch file:</p>

      <div>
        <div class="header-field-outer">
          <div class="header-field-inner">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text form-field-label">Company</span>
              </div>
              <select (change)="updateCompany($event.target.value)" class="custom-select custom-select">
                <option disabled="true" value="">Select a Company</option>
                <option value="1">Global Warranty Corp.</option>
                <option value="2">Global Warranty (Ontario) Corp.</option>
                <option value="3">Global Warranty (Mid-West) Corp.</option>
                <option value="4">Global Warranty (MI) Corp.</option>
                <option value="5">Global Warranty (West Coast) Corp.</option>
                <option value="6">Global Warranty Management</option>
                <option value="7">Global Warranty Tire and Rim Corp.</option>
                <option value="8">Global Warranty Gap Corp.</option>
              </select>
            </div>
          </div>
        </div>
      </div>

    </div>    
    <div *ngIf="isBatchReport && (isNewPayeeVendor || selectedCompany)">
      <button mat-button type="button" (click)="downloadCSV()">Download CSV</button>
    </div>
  </div>
</div>

<div class="content">
  <div class="viewer-frame-wrapper">
    <iframe id="ifrmReport" class="viewer-frame" [src]="reportViewerUrl | safe" style="width: 100%;"></iframe>
  </div>
</div>
