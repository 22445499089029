import { CellType } from "./cell-type";
import { CellActions } from "./cell-type";

export class CellBuilder {
    constructor(
        public cellName: string,
        public modelAttrName: string,
        public cellType: CellType,
        public cssClass: string = '',
        public actions: CellActions = CellActions.None,
        public attributes: any[] = [{}],
        ){ }
}
