import { InjectionToken } from "@angular/core";
import { environment } from "src-private/environments/environment";

export class AppConfig {
  mapApiKey: string;
  mapCityRadius: number;
  claimsListHighlightRedDaysRangeStart: number;
  claimsListHighlightRedDaysRangeEnd: number;
  claimsListHighlightYellowDaysRangeStart: number;
  claimsListHighlightYellowDaysRangeEnd: number;
  GWDateFormat: string;
  GWDateTimeFormat: string;
}

export const APP_DI_CONFIG: AppConfig = {
  mapApiKey: environment.mapApiKey,
  mapCityRadius: environment.mapCityRadius,
  claimsListHighlightRedDaysRangeStart: environment.claimsListHighlightRedDaysRangeStart,
  claimsListHighlightRedDaysRangeEnd: environment.claimsListHighlightRedDaysRangeEnd,
  claimsListHighlightYellowDaysRangeStart: environment.claimsListHighlightYellowDaysRangeStart,
  claimsListHighlightYellowDaysRangeEnd: environment.claimsListHighlightYellowDaysRangeEnd,
  GWDateFormat: "y-MM-dd",
  GWDateTimeFormat: "y-MM-dd hh:mm a"
};

export const APP_CONFIG = new InjectionToken<AppConfig>('app.config');
