import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src-private/environments/environment';

/*-- Interfaces --*/
import { IUser } from '../areas/dealers/interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class IdentityService {

  constructor(private http: HttpClient) { }

  archive(user: IUser): Observable<Object> {
    const params = {
      isEmployee: user.isEmployee,
      userId: user.id
    };

    return this.http.post(environment.serverUrl + 'api/identity/archive', params);
  }

  create(user: IUser): Observable<Object> {
    const params = {
      email: user.email,
      firstName: user.firstName,
      isEmployee: user.isEmployee,
      lastName: user.lastName,
      permissions: user.permissions,
      roles: user.roles,
      title: user.title,
      vendorId: user.vendorId
    };

    return this.http.post(environment.serverUrl + 'api/identity/create', params);
  }

  update(user: IUser): Observable<Object> {
    const params = {
      email: user.email,
      firstName: user.firstName,
      userId: user.id,
      isEmployee: user.isEmployee,
      lastName: user.lastName,
      permissions: user.permissions,
      roles: user.roles,
      title: user.title,
      vendorId: user.vendorId
    };

    return this.http.post(environment.serverUrl + 'api/identity/update', params);
  }


  // exists(email: string): Observable<boolean> {
  //  const params = {
  //    email: email
  //  };

  //  const queryParams: HttpParams = UtilsService.buildQueryParams(params);

  //  return this.http.get<boolean>(environment.serverUrl + 'api/identity/exists', { params: queryParams });
  // }
}
