<mat-dialog-content>
  <form [formGroup]="noteForm" (ngSubmit)="onFormSubmit()" novalidate>
    <div class="container-fluid dialogContent">
      <div class="sidebar-body p-0">
        <ul class="list-unstyled note-group-pin w-100 p-0 m-0">
          <li class="note-group w-75">
            <mat-form-field class="w-100" appearance="fill">
              <mat-label class="labelPadding">Note Group</mat-label>
              <mat-select matSelect required formControlName="noteGroup">
                <mat-option *ngFor="let noteGroup of noteGroups"
                            [value]="noteGroup.noteGroupId">
                  {{noteGroup.label}}
                </mat-option>
              </mat-select>
              <mat-error>
                <mat-error *ngIf="isSubmitted && f.noteGroup.errors?.required">Note Group is required</mat-error>
              </mat-error>
            </mat-form-field>
          </li>
          <li class="pin pr-3">
            <button type="button" class="btn btn-lg w-25 align-baseline" (click)="onPinClicked()">
              <fa-icon [ngClass]="{'icon-thumbtack-gold': isPinned, 'icon-thumbtack-outline': !isPinned}"
                       [icon]="isPinned ? faThumbtackSolid : faThumbtackRegular"></fa-icon>
            </button>
            <mat-label class="pin-label">PIN</mat-label>
          </li>
        </ul>
        <mat-form-field class="w-100 " appearance="fill">
          <mat-label>Notes</mat-label>
          <textarea matInput formControlName="noteText" rows="6"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="float-right">
  <button mat-button mat-dialog-close class="btn" type="button" (click)="onCancelClick()">
    <fa-icon [icon]="faWindowClose" class="pr-2 text-danger"></fa-icon> Cancel
  </button>
  <button mat-button class="btn" (click)="onFormSubmit()">
    <fa-icon [icon]="faSave" class="pr-2 text-success"></fa-icon> Save
  </button>
</mat-dialog-actions>
