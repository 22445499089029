import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addressFormat'
})
export class AddressFormatPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (!value) return value; // safeguard
    return value.replace(/\b(?<![-.])[^\d\W]+\b/g, (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase());
  }
}
