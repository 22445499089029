import { IBatchFile } from '../interfaces/batch-file'

export class BatchFile implements IBatchFile {
    BatchFileId: number;
    BatchFileTypeId: number;
    ItemId: number;
    CreatedBy: string;
    CreatedDate: Date;
    ProcessedBy: string;
    ProcessedDate: Date;

    constructor() {
        this.BatchFileId = null;
        this.BatchFileTypeId = null;
        this.ItemId = null;
        this.CreatedBy = null;
        this.CreatedDate = null;
        this.ProcessedBy = null;
        this.ProcessedDate = null;
    }
    
}

