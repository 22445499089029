<div>
    <div *ngIf="isAuthenticated" [formGroup]="userRegistrationForm" class="col">
        <mat-spinner *ngIf="submitting"></mat-spinner>
        <div *ngIf="!submitting">
            <div class="row">
                <div class="col-6">
                    <mat-form-field>
                        <input formControlName="firstName" matInput placeholder="First Name">
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field>
                        <input formControlName="lastName" matInput placeholder="Last Name">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <mat-form-field class="w-100">
                        <input formControlName="address" matInput placeholder="Address">
                    </mat-form-field>
                </div>

                <div class="col-2">
                    <mat-form-field class="w-100">
                        <input formControlName="phone" matInput placeholder="Phone" mask="(000) 000-0000"
                            autocomplete="off">
                    </mat-form-field>
                </div>

                <div class="col-4">
                    <mat-form-field class="w-100">
                        <input formControlName="email" matInput placeholder="Email">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <div class="mat-form-field mat-form-field-static">
                        <label class="mat-label">Country</label>
                        <mat-radio-group formControlName="country">
                            <mat-radio-button [value]=1 color="primary">CA</mat-radio-button>
                            <mat-radio-button [value]=2 color="primary">US</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="col-3">
                    <mat-form-field class="w-100">
                        <input formControlName="postalZipCode" matInput [placeholder]="postalZipCodePlaceholder">
                    </mat-form-field>
                </div>
                <div class="col-2">
                    <mat-form-field class="w-100">
                        <input formControlName="city" matInput placeholder="City">
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field class="w-100">
                        <input formControlName="provinceState" matInput
                            [placeholder]="isCanada() ? 'Province' : 'State'">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-2" *ngIf="nameControl">
                    <mat-form-field class="w-100">
                        <input formControlName="name" matInput placeholder="Name">
                    </mat-form-field>
                </div>

                <div class="col-2" *ngIf="craNumControl">
                    <mat-form-field class="w-100">
                        <input formControlName="craNum" matInput
                            [placeholder]="isCanada() ? 'CRA Business Number' : 'Employer Identification Number'">
                    </mat-form-field>
                </div>

                <div class="col-2" *ngIf="sinControl">
                    <mat-form-field class="w-100">
                        <input formControlName="sin" matInput (keydown)="_keyDown($event)"
                            [mask]="isCanada() ? 'AAA AAA AAA': 'AAA AA AAAA'"
                            [placeholder]="isCanada() ? 'Social Insurance Number' : 'Social Security Number'">
                        <mat-error *ngIf="sinControl.hasError('validateSin')">Not a valid SIN</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-2 mat-form-field mat-form-field-static">
                    <label class="mat-label">Method of Payment: </label>
                    <mat-radio-group formControlName="paymentType">
                        <mat-radio-button [value]=0 color="primary">Cheque</mat-radio-button>
                        <mat-radio-button [value]=1 color="primary">EFT</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="paymentMethodControl.hasError('required')">Payment Method Required</mat-error>
                </div>

                <ng-container *ngIf="paymentMethodControl.value === paymentMethod.EFT">
                    <app-eft-selection [userRegistrationForm]="userRegistrationForm"></app-eft-selection>
                </ng-container>
            </div>
        <div>
            <button mat-button class="col-12" color="primary" (click)="onSubmit()" [disabled]="getValidation()">
                Submit
            </button>
        </div>
    </div>
    <div *ngIf="!isAuthenticated">
        Not authenticated!
    </div>
</div>