import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js/min';
import { Country } from 'src-private/app/enums/country.enum';


@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(phoneValue: string | string, country: string): any {
    try {
        if (phoneValue) {
          var countryCode: CountryCode = 'US';
          if (country.localeCompare(Country[Country.Canada], undefined, { sensitivity: 'accent' }) === 0) {
            countryCode = 'CA'; 
          }
          return parsePhoneNumber(phoneValue, countryCode).formatNational();
        }
    } catch (error) {
        // fall through and return original }
    }
    return phoneValue;
  }
}