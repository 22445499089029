import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { Subject, takeUntil } from 'rxjs';
import { LocaleService } from 'src-private/app/services/locale.service';
import { FrameworkComponent } from 'src-private/app/shared/framework/framework.component';
import { ClaimTransaction } from '../../../models/claim-transaction';
import { DocumentAddDialogComponent } from '../../document-add-dialog/document-add-dialog.component';

@Component({
    selector: 'part-approval-dialog',
    templateUrl: './part-approval-dialog.component.html',
    styleUrls: ['./part-approval-dialog.component.scss']
})

export class PartApprovalDialogComponent extends FrameworkComponent implements OnInit, AfterViewInit, OnDestroy {

  public invoiceNumber : string;
  public documentData : any;
  public partData : ClaimTransaction;
  public rowTotal : string;
  public gstAmount : string;
  public rowTotalWithTax : string;

  public faSave = faSave;
  public faWindowClose = faWindowClose;

  public IsSubmitted: boolean = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private localeService: LocaleService,
    private dialogRef: MatDialogRef<PartApprovalDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data
    ) {
      super();
      this.partData = data.transaction;
      this.invoiceNumber = data.invoiceNumber;
      this.documentData = data.documentData;
    } 

  ngOnInit() {
    let currencyPipe = new CurrencyPipe(this.localeService.getLanguage());
    this.rowTotal = currencyPipe.transform(this.partData.rowTotal);
    this.gstAmount = currencyPipe.transform(this.partData.gstAmount);
    this.rowTotalWithTax = currencyPipe.transform(this.partData.rowTotalWithTax);

    this.dialogRef.backdropClick().subscribe(() => {
      this.cancel();
    })
  }

  ngAfterViewInit(): void {
    super.build("PartApprovalDialogComponent", "parts-approval-dialog-component");
  }

  save() {
    if(this.invoiceNumber) {
      this.partData.invoiceId = this.invoiceNumber;
      this.partData.invoiceNumber = this.invoiceNumber;
      this.partData.invoiceApproved = true;
      
      this.dialogRef.close({ partData: this.partData, documentData: this.documentData });
    }  
  }

  cancel() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  addDocument() {
    let dialogRef = this.dialog.open(DocumentAddDialogComponent, {
      panelClass: "warranty-add-dialogs",
      width: "600px",
      data: {
        originId: this.partData.claimId,
      },
    });

    dialogRef.componentInstance.onSubmit
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(dialogRes => {
        if (dialogRes.data) {
          dialogRes.data.originId = this.partData.claimId;
          this.documentData = dialogRes.data;
          dialogRef.close();
        }
      });
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
