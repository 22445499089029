import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { FrameworkComponent } from "src-private/app/shared/framework/framework.component";
import { IClaimsNotes } from "../../interfaces/claims-notes.interface";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ComplaintAddDialogComponent } from "../complaint-add-dialog/complaint-add-dialog.component";
import { ClaimsService } from "../../services/claims.service";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";
import { IClaim } from "../../interfaces/claim";
import { IComplaint } from "../../interfaces/complaint.interface";
import { TableAdapterComponent } from "src-private/app/shared/table-adapter/table-adapter.component";
import { CellBuilder } from "src-private/app/shared/table-adapter/cell-builder/cell-builder";
import { CellType } from "src-private/app/shared/table-adapter/cell-builder/cell-type";
import { ComplaintsService } from "../../services/complaints.service";
import { ToastrService } from "ngx-toastr";
import { environment } from "src-private/environments/environment";
import { AccountService } from "src-private/app/areas/account/services/account.service";
import { faEdit, faExternalLinkAlt, faPlus } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-complaint-info",
  templateUrl: "./complaint-info.component.html",
  styleUrls: ["./complaint-info.component.scss"],
})

export class ComplaintInfoComponent
  extends FrameworkComponent
  implements OnInit, AfterViewInit 
{
  private claim$: BehaviorSubject<IClaim>;
  private complaint = {} as IComplaint;
  private claimsId: number;
  
  public complaintForm: UntypedFormGroup;
  public warrantyId: number;
  public isAdjuster: boolean;
  public faPlus = faPlus;

  private ngUnsubscribe: Subject<any> = new Subject();

  @ViewChild("complaintsTable")
  complaintsTable: TableAdapterComponent<IComplaint>;
  public complaintsTableColumns: CellBuilder[] = [
    new CellBuilder("Adjuster", "adjusterName", CellType.text),
    new CellBuilder("Created Date", "createdDate", CellType.datetime),
    new CellBuilder("Complaint", "comment", CellType.text),
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private claimsService: ClaimsService,
    private complaintsService: ComplaintsService,
    private accountService: AccountService,
    private toastr: ToastrService,
    ) {
    super();
    this.claimsId = this.activatedRoute.snapshot.data['claimId']
  }

  ngOnInit(): void {
    this.complaintForm = this.formBuilder.group({
      comments: [""],
    });

    this.claim$ = new BehaviorSubject<IClaim>({} as IClaim);

    this.claimsService
    .retrieve(this.claimsId)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((claim) => {

      this.claim$.next(claim);
      if (claim) {
        this.warrantyId = claim.warrantyId;
      }
    });

    this.isAdjuster = this.accountService.isAdjuster();
  }

  ngAfterViewInit(): void {
    super.build("ComplaintInfoComponent", "complaint-info-component");
    this.refreshComplaints();
  }

  populateComplaintsForm(notes: IClaimsNotes[]) {
    let result = notes.filter((n) => n.type == "Complaint").join("\r\n\r\n");
    this.complaintForm.patchValue({
      comments: result,
    });
  }

  addComplaint() {
    let complaintAddDialogRef = this.dialog.open(ComplaintAddDialogComponent, {
      panelClass: "claim-add-dialogs",
      width: "630px",
      data: this.warrantyId,
    });

    complaintAddDialogRef
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res !== undefined) {
          if (res.data) {
            this.complaint.ClaimId = this.claimsId;
            this.complaint.Comment = res.data.complaint;
            this.complaint.AdjusterId = res.data.assignedAdjuster;
            this.complaint.PartName = res.data.description;
            this.complaint.CustomerId = this.claim$.value.customerId;
            this.complaint.ComplaineeType = res.data.complainee;

            this.complaintsService
              .add(this.complaint)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe(() => {
                this.toastr.success(
                  "Complaint successfully added. ",
                  "Create Complaint"
                );
                this.refreshComplaints();
              },
              error => {
                this.toastr.error(environment.messages.apiError, "Unable to add complaint");
              });
          }
        }
      });
  }

  refreshComplaints() {
    this.complaintsTable.refresh(
      this.complaintsService.retrieve(this.claimsId)
    );
  }

  public get IsClaimClosed(): boolean {
    return this.claimsService.getSelectedClaim?.IsClaimClosed
  }

  public get CanEditClosedClaim(): boolean {
    return this.isClaimManager || this.accountService.isAssistantClaimsManager() || this.accountService.isAccountant();
  }

  public get isClaimManager(): boolean {
    return this.accountService.isClaimsManager()
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}