import { AfterViewInit, Component, ElementRef, Input, ViewChild,  } from '@angular/core';

@Component({
  selector: 'app-note-comment',
  templateUrl: './note-comment.component.html',
  styleUrls: ['./note-comment.component.css']
})
export class NoteCommentComponent implements AfterViewInit {
  @Input() htmlContent: any;

  @ViewChild('commentRef', { static: true }) commentRef: ElementRef;

  constructor() { }

  //#region - Lifecycle
  ngAfterViewInit(): void {
    const elementRef = this.commentRef;

    elementRef.nativeElement.innerHTML = this.htmlContent;
  }
  //#endregion
}
