export class DealerStats {
    activeSales: number;
    expiredSales: number;
    expiredSalesAuto: number;
    expiredSalesTire: number;
    prev6MonthsSales: number;
    activeClaims: number;
    expiredClaims: number;
    expiredClaimsAuto: number;
    expiredClaimsTire: number;
    ultimateLossRatio: number;
    ultimateLossRatioAuto: number;
    ultimateLossRatioTire: number;
    activeLossRatio: number;
    expiredLossRatio: number;
    expiredLossRatioTire: number;
    expiredLossRatioAuto: number;
    budRate: number;
    remainingBudgetExpired: number;
    remainingBudgetActive:number;

    constructor(ds = null) {
        if(ds == null){
            this.activeSales = null
            this.expiredSales = null
            this.prev6MonthsSales = null
            this.activeClaims = null
            this.expiredClaims = null
            this.ultimateLossRatio = null
            this.activeLossRatio = null
            this.expiredLossRatio = null
            this.budRate = null
            this.remainingBudgetExpired = null
            this.remainingBudgetActive = null
            this.expiredSalesAuto = null
            this.expiredSalesTire = null
            this.ultimateLossRatioAuto = null
            this.ultimateLossRatioTire = null
            this.expiredClaimsAuto = null
            this.expiredClaimsTire = null
            this.expiredLossRatioAuto = null
            this.expiredLossRatioTire = null
        }
        else{
            this.activeSales = ds.activeSales
            this.expiredSales = ds.expiredSales
            this.prev6MonthsSales = ds.prev6MonthsSales
            this.activeClaims = ds.activeClaims
            this.expiredClaims = ds.expiredClaims
            this.activeLossRatio = ds.activeLossRatio
            this.ultimateLossRatio = ds.ultimateLossRatio
            this.expiredLossRatio = ds.expiredLossRatio
            this.remainingBudgetActive = ds.remainingBudgetActive
            this.remainingBudgetExpired = ds.remainingBudgetExpired
            this.budRate = ds.budRate
            this.expiredSalesAuto = ds.expiredSalesAuto
            this.expiredSalesTire = ds.expiredSalesAuto
            this.ultimateLossRatioAuto = ds.ultimateLossRatioAuto
            this.ultimateLossRatioTire = ds.ultimateLossRatioTire
            this.expiredClaimsAuto = ds.expiredClaimsAuto
            this.expiredClaimsTire = ds.expiredClaimsTire
            this.expiredLossRatioAuto = ds.expiredLossRatioAuto
            this.expiredLossRatioTire = ds.expiredLossRatioTire
        }
    }
}
