import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { environment } from '../../../../../environments/environment';

/*-- Enums --*/
import { RoleGroup } from '../../../../enums/role-group.enum';
import { TitleGroup } from '../../../../enums/title-group.enum';

/*-- Interfaces --*/
import { IRole } from '../../../dealers/interfaces/role.interface';
import { ITitle } from '../../../dealers/interfaces/title.interface';
import { IUser } from '../../../dealers/interfaces/user.interface';

/*-- Services --*/
import { DealersService } from '../../../dealers/services/dealers.service';
import { IdentityService } from '../../../../services/identity.service';

/*-- Third Party --*/
import { ToastrService } from 'ngx-toastr';
import {DealersSharedService} from "../../../dealers/services/dealers-shared.service";
import {Dealer} from "../../../dealers/models/dealer.model";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent implements AfterViewInit, OnInit {
  @Output() selectedIndexChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() contactCreate: EventEmitter<IUser> = new EventEmitter<IUser>();
  @BlockUI() blockUI: NgBlockUI;

  @ViewChild('firstNameInput') firstNameInput: ElementRef;

  private contact: IUser;
  private dealer: Dealer;

  public contactForm: UntypedFormGroup;
  public isSubmitted: boolean;
  public roles = [] as IRole[];
  public title: string;
  public titles = [] as ITitle[];
  public faCheck = faCheck;
  public faTimes = faTimes;

  constructor(
    private dealersService: DealersService,
    private formBuilder: UntypedFormBuilder,
    private identityService: IdentityService,
    private toastrService: ToastrService,
    private dealersSharedService: DealersSharedService
  ) {
    this.contactForm = this.buildForm();

    this.dealersSharedService.dealer.subscribe(dealer => {
      if (dealer) {
        this.dealer = dealer;
      }
    });
  }

  //#region - Lifecycle
  ngOnInit(): void {
    this.retrieveRoles();
    this.retrieveTitles();
  }

  ngAfterViewInit(): void {
    // TODO: remove timeout 500ms hack to set focus after 'Submit' button receives focus
    setTimeout(() => this.firstNameInput.nativeElement.focus(), 500);
  }
  //#endregion

  //#region - Getters
  get f() { return this.contactForm.controls; }
  //#endregion

  //#region - Events
  onCancelClick(): void {
    // reset the form
    this.contactForm.reset();

    // return to add file
    this.selectedIndexChange.emit(0);
  }

  onFormSubmit(): void {
    this.isSubmitted = true;
    this.blockUI.start();

    if (this.contactForm.valid) {
      this.createContact().then(
        () => {
          // reset the form
          this.contactForm.reset();

          // return to add file
          this.selectedIndexChange.emit(0);
          this.blockUI.stop();
        });
    }
  }
  //#endregion

  //#region - Private Methods
  private buildForm(): UntypedFormGroup {
    return this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      roleName: [null, Validators.required],
      title: [null, Validators.required]
    });
  }
  //#endregion

  //#region - API Methods
  private createContact(): Promise<{}> {
    const contact: IUser = this.contactForm.value;
    const title = 'Add Contact';

    contact.isEmployee = false;
    contact.permissions = JSON.stringify([]);
    contact.roles = this.contactForm.get('roleName').value !== 'N/A' ?
      JSON.stringify([{ name: this.contactForm.get('roleName').value }]) :
      JSON.stringify([]);
    contact.title = this.contactForm.get('title').value === 'N/A' ?
      null :
      this.contactForm.get('title').value;
    contact.vendorId = this.dealer.vendorId;

    const promise = new Promise((resolve, reject) => {
      this.identityService.create(contact).subscribe(
        response => {
          contact.id = response.toString();

          this.contactCreate.emit(contact);
          this.toastrService.success('Your contact has been successfully added.', title);

          resolve(true);
        },
        () => {
          this.toastrService.error(environment.messages.apiError, title);

          reject();
        }
      );
    });

    return promise;
  }

  private retrieveRoles(): void {
    const roleGroup = RoleGroup.EazeeApp;

    this.dealersService.roleRetrieveByRoleGroup(roleGroup, true).subscribe(
      response => {
        const data: IRole[] = response;

        data.splice(0, 0, {
          id: null,
          name: 'N/A',
          label: 'Additional Contact',
          ordinal: null,
          roleGroupId: null
        });

        this.roles = data;
      }
    );
  }

  private retrieveTitles(): void {
    const titleGroup = TitleGroup.EazeeApp;

    this.dealersService.titleRetrieve(titleGroup, true).subscribe(
      response => {
        const data: ITitle[] = response;

        data.splice(0, 0, {
          id: null,
          name: 'N/A',
          title: null,
          titleGroupId: null
        });

        this.titles = data;
      }
    );
  }
  //#endregion
}
