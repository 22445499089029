import { IMaintenanceRetrieve } from '../interfaces/maintenance-retrieve.interface';

export class MaintenanceRetrieve implements IMaintenanceRetrieve {
  warrantyId: number;
  claimId: number;
  vehicleId: number;
  constructor() {
    this.warrantyId = null;
    this.claimId = null;
    this.vehicleId = null
  }
}
