import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { FrameworkComponent } from "src-private/app/shared/framework/framework.component";
import { ActivatedRoute } from "@angular/router";
import { ClaimsService } from "../../services/claims.service";
import { AccountService } from "src-private/app/areas/account/services/account.service";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ToastrService } from "ngx-toastr";
import { environment } from "src-private/environments/environment";
import { NoteAddDialogComponent } from "../note-add-dialog/note-add-dialog.component";
import { TableAdapterComponent } from "src-private/app/shared/table-adapter/table-adapter.component";
import { CellBuilder } from "src-private/app/shared/table-adapter/cell-builder/cell-builder";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Subject, takeUntil } from "rxjs";
import { RepairCentreNotesService } from "../../services/repair-centre-notes.service";
import { IRepairCentreNote } from "../../interfaces/repair-centre-note.interface";

@Component({
  selector: "app-repair-centre-notes",
  templateUrl: "./repair-centre-notes.component.html",
  styleUrls: ["./repair-centre-notes.component.scss"],
})

export class RepairCentreNotesComponent extends FrameworkComponent implements OnInit, AfterViewInit {

  @Input() columns: CellBuilder[];
  @Input() repairCentreId: number;

  public faPlus = faPlus;
  public claimId: number;
  public isLoading = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  @ViewChild("repairCentreNotesTable") repairCentreNotesTable : TableAdapterComponent<IRepairCentreNote>

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private claimsService: ClaimsService,
    private accountService: AccountService,
    private repairCentreNotesService : RepairCentreNotesService,
    private toastr: ToastrService,
    ) {
    super();
    this.claimId = this.activatedRoute.snapshot.data['claimId']
  }

  ngOnInit(): void {
    this.isLoading = true;
    super.build("RepairCentreNotesComponent", 'app-repair-centre-notes')
    this.isLoading = false;
  }

  ngAfterViewInit(): void {
    this.loadNotes()
  }

  addNote(){
    const addNoteDialogRef = this.dialog.open(NoteAddDialogComponent, { width:'600px'});
    addNoteDialogRef.afterClosed()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(res=>{
      if(res.data){
        res.data.repairCentreId = this.repairCentreId;
        res.data.claimId = this.claimId;
        this.repairCentreNotesService.add(res.data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(()=>{
          this.toastr.success("Note successfully created. ", 'Create Note');
          this.loadNotes();
        },
        error => {
          this.toastr.error(environment.messages.apiError, "Unable to add note");
        });
      }
    });
  }
  
  loadNotes(){
    this.repairCentreNotesTable.refresh(this.repairCentreNotesService.retrieveAll(this.repairCentreId, this.claimId));
  }

  public get isClaimClosed(): boolean {
    return this.claimsService.getSelectedClaim?.IsClaimClosed
  }

  public get canEditClosedClaim(): boolean {
    return this.accountService.isClaimsManager();
  }

  public get CanAddNotes(): boolean {
    return this.accountService.isAdjuster() || this.accountService.isClaimsManager() || this.accountService.isAssistantClaimsManager() || this.accountService.isDealerServices();
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

}
