import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src-private/environments/environment';
import { IComplaint } from '../interfaces/complaint.interface';


@Injectable({
  providedIn: 'root'
})
export class ComplaintsService {

  constructor(private http: HttpClient) { }

  retrieve(claimId: number): Observable<IComplaint[]> {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('claimId', claimId.toString());

    return this.http.get<IComplaint[]>(`${environment.resourceUrl}api/gwc/complaint`, { params: httpParams });
  }

  add(complaint: IComplaint): Observable<IComplaint> {
    return this.http.post<IComplaint>(`${environment.resourceUrl}api/gwc/complaint/`, complaint);
  }
}
