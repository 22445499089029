import { Injectable } from "@angular/core";
import { IClaim } from "../interfaces/claim";

@Injectable({
    providedIn: 'root'
  })
export class Claim implements IClaim {
  id: number;
  claimsDateEntered: Date;
  claimsDateClosed: Date;
  claimsRepairCenterId: number;
  warrantyId: number;
  warrantyIdFull: string;
  coverageTypeId: number;
  customerId: number;
  customerFirstname: string;
  customerLastname: string;
  vin: string;
  vendorId: number;
  vendorName: string;
  assignedAdjuster: string;
  assignedAdjusterName: string;
  claimsStatus: string;
  warrantyStatus: string;
  claimsCorrection: string;
  claimsOwner: string;
  claimsTransProc: boolean;
  claimTotal: number;
  warrantyVIN: string;
  repairCenterName: string;
  version: number;
  totalRecords: number;
  enterpriseId: number;
  currentOdReading: number;
  claimMaxLimit: number;
  claimTotalWithTax: number;
  auditApproverId: string;
  auditApprover: string;
  auditApprovalStatus: number;
  auditApprovedDate: Date;
  provinceStateId: number;
  provinceName: string;
  provinceAbbr: string;
  sourceId: number;

  //Claim Functions
  public get IsClaimClosed(): boolean{
    return this.claimsStatus == "Closed"
  }
}
