<form [formGroup]="dealerForm" (ngSubmit)="onSubmitDealer()" novalidate>
  
  <fieldset [hidden]="bp < 3 && hideDealerStat">
    <legend>Dealer Stats - {{dealer?.friendlyName}}
      <h6 *ngIf="true" class="rounded" [style]="dealerStatusColour">{{ dealerStatus }}</h6>
    </legend>
    <app-dealer-stats [vendorId$]="vendorId$"></app-dealer-stats>
  </fieldset>

  <fieldset class="fieldset-form"
            [hidden]="bp < 3 && hideContact">
    <legend>Contact</legend>

    <app-contact [contactForm]="dealerForm.controls.contact"
                 [formState]="formState"
                 [submitted]="isSubmitted"
                 [canAllowEditing$]="canUserEdit$"></app-contact>

  </fieldset>

  <fieldset class="fieldset-form"
            [hidden]="bp < 3 && hideSettings">
    <legend>Settings</legend>

    <app-settings [settingsForm]="dealerForm.controls.settings"
                  [dealer]="dealer"
                  [formState]="formState"
                  [submitted]="isSubmitted"
                  [canAllowEditing$]="canUserEdit$"></app-settings>

  </fieldset>

  <fieldset class="fieldset-form"
            [hidden]="bp < 3 && hideCompetitors">
    <legend>Competitors</legend>

    <app-competitors [competitorsForm]="dealerForm.controls.competitors"
                     [dealer]="dealer"
                     [formState]="formState"
                     [submitted]="isSubmitted"
                     [canAllowEditing$]="canUserEdit$"></app-competitors>

  </fieldset>

  <app-dealer-documents></app-dealer-documents>

  <footer>

    <ul class="list-unstyled button-row">
      <li class="button-cell"
          *ngIf="showCancelButton">

        <button class="btn btn-lg btn-dark btn-icon btn-cancel"
                type="button"
                (click)="onCancelClick()">
                <fa-icon [icon]="faTimes" class="text-danger"></fa-icon> Cancel
        </button>

      </li>
      <li class="button-cell"
          *ngIf="showSaveButton">

        <button class="btn btn-lg btn-dark btn-icon btn-save"
                type="submit">
                <fa-icon [icon]="faCheck" class="text-success"></fa-icon> Save
        </button>

      </li>
    </ul>

  </footer>

</form>
<style>
  .rounded {
    width:auto;
    height:auto;
    text-justify: center;
    border-radius: 12px;
    display: inline-block;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 15px;
  }
</style>
