import { Component, EventEmitter, Output } from '@angular/core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

/*-- Enums --*/
import { Sidebar } from '../../../../../../enums/sidebar.enum';

/*-- Services --*/
import { DynamicComponentService } from '../../../../../../services/dynamic-component.service';

@Component({
  selector: 'app-remove-contact',
  templateUrl: './remove-contact.component.html',
  styleUrls: ['./remove-contact.component.css']
})
export class RemoveContactComponent {
  @Output() modalResponse: EventEmitter<boolean> = new EventEmitter<boolean>();

  private sidebar: Sidebar;

  public contact: string;
  public title: string;
  public faTimes = faTimes;
  public faCheck = faCheck;

  constructor(private dynamicComponentService: DynamicComponentService) { }

  //#region - Events
  onNoClick(): void {
    this.modalResponse.emit(false);
  }

  onYesClick(): void {
    this.modalResponse.emit(true);
  }
  //#endregion
}
