import { ValidatorFn, UntypedFormGroup, ValidationErrors, UntypedFormArray } from '@angular/forms';
import { DistributionType } from 'src-private/app/enums/bonus-bucks.enums';

export const distributionsValidator: ValidatorFn = (distributions: UntypedFormGroup): ValidationErrors | null => {
  const d = distributions.get('distributions') as UntypedFormArray;
  let dis;
  if (d) {
    dis = d.value;
  } else {
    return null;
  }

  if (distributions.value.distributionTypeId === DistributionType.Percentage) {
    const total = dis.reduce((accumulator, currentValue) => {
      return (currentValue.selected && currentValue.userRegistrationFG) ? accumulator + currentValue.userRegistrationFG.currentDistribution : accumulator;
    }, 0);
    return total !== 100 ? { 'invalidTotal': true } : null;
  }
  else if (distributions.value.distributionTypeId === DistributionType.Individual) {
    let res = null;

    res = dis.some(dist => dist.selected) ? null : { 'noDistribution': true };

    dis.forEach(dist => {
      if (dist.selected && dist.userRegistrationFG) {
        if (dist.userRegistrationFG.recipient === null || dist.userRegistrationFG.recipient == '') {
          res = { 'noRecipient': true };
        }
      }
    });

    return res;
  }
  else {
    return { 'invalidDistributionType': true };
  }
};
