import { InjectionToken } from "@angular/core";

export class AppFramework {
  modules: FrameworkItem[];
}

export class FrameworkItem {
  constructor(
    public componentType: string,
    public displayType: string,
    public componentLabel: string,
    public functions: string[]
  ) { }
}

export const APP_DI_FRAMEWORK: AppFramework = {
  modules: [
    //#region Claims

    //#region Detail Component
    // Buttons
    new FrameworkItem("DetailComponent", "button", "detail-component", [
      "addComplaint",
      "addDocument",
      "addNote",
      "editClaim",
      "addDetailMaintenance",
      "changeRepairCentre"
    ]),
    new FrameworkItem("PartsListComponent", "button", "parts-component", [
      "addPart"
    ]),
    // Tables
    // Tables
    new FrameworkItem("TableAdapterComponent", "table", "documents-conflicts-table", [
      "refresh",
    ]),
    new FrameworkItem("TableAdapterComponent", "table", "complaints-table", [
      "refresh",
    ]),
    new FrameworkItem("TableAdapterComponent", "table", "parts-table", [
      "refresh",
      "dropDown",
      "action",
      "rowClick",
      "remove"
    ]),
    new FrameworkItem("TableAdapterComponent", "table", "documents-table", [
      "refresh",
      "download",
      "remove",
      "move",
      "rowClick"
    ]),
    new FrameworkItem("TableAdapterComponent", "table", "notes-table", [
      "refresh",
    ]),
    //#endregion

    //#region Customer
    // Buttons
    new FrameworkItem("CustomerComponent", "button", "customer-component", [
      "addNote",
    ]),
    // Tables
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "customer-warranties-table",
      ["refresh"]
    ),
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "customer-notes-table",
      ["refresh"]
    ),
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "customer-claims-table",
      ["refresh", "rowClick"]
    ),
    //#endregion

    //#region Warranty Component
    // Buttons
    new FrameworkItem("WarrantyComponent",
      "button",
      "warranty-component", [
      "addDocument",
      "addNote",
    ]),
    // Tables
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "warranty-options-table",
      ["refresh", "rowClick"]
    ),
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "warranty-details-table",
      ["refresh"]
    ),
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "claims-table",
      ["refresh", "rowClick"]
    ),
    new FrameworkItem(
      "ClaimsTableComponent",
      "button",
      "claim-table-component",
      ["addClaim"]
    ),
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "warranty-documents-table",
      ["refresh", "download", "remove", "move", "rowClick"]
    ),
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "warranty-notes-table",
      ["refresh"]
    ),
    //#endregion

    //#region Maintenance
    // Tables
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "maintenance-schedule-table",
      ["refresh", "action", "remove"]
    ),
    //#endregion
      
    //#region Repair Centre Component
    new FrameworkItem("RepairCentreComponent", "button", "repaircentre-component",[
      "addNote"
    ]),

    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "repair-centre-notes-table",
      ["refresh"]
    ),

    //#endregion 

    //#region Approval
    // Buttons
    new FrameworkItem("CustomerNotesComponent", "button", "customer-notes-component", [
      "addNote",
    ]),
    new FrameworkItem("ClaimNotesComponent", "button", "claim-note-component", [
      "addNote",
    ]),
    new FrameworkItem("WarrantiesTableComponent", "button", "warranties-table-component", [
      "",
    ]),
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "warranties-table-component",
      ["refresh"]
    ),
    new FrameworkItem("ClaimInfoComponent", "button", "claim-info-component", [
    ]),
    new FrameworkItem("ClaimDocumentsComponent", "button", "claim-documents-component", [
      "addDocument",
    ]),
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "claim-documents-component",
      ["refresh", "download", "remove", "move", "rowClick"]
    ),
    new FrameworkItem("CustomerDocumentsComponent", "button", "customer-documents-component", [
      "addDocument",
    ]),
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "customer-documents-component",
      ["refresh", "download", "remove", "move", "rowClick"]
    ),
    new FrameworkItem("DealerDocumentsComponent", "button", "dealer-documents-component", [
      "addDocument",
    ]),
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "dealer-documents-component",
      ["refresh", "download", "remove", "move", "rowClick"]
    ),
    new FrameworkItem("PartApprovalDialogComponent", "button", "parts-approval-dialog-component", [
      "addDocument",
    ]),
    new FrameworkItem("CustomerInfoComponent", "button", "customer-info-component", [
    ]),
    new FrameworkItem("ComplaintInfoComponent", "button", "complaint-info-component", [
      "addComplaint",
    ]),
    new FrameworkItem("WarrantyDetailsComponent", "button", "app-warranty-details", [
      "",
    ]),
    new FrameworkItem("WarrantyNotesComponent", "button", "app-warranty-notes", [
      "addNote",
    ]),
    new FrameworkItem("WarrantyDocumentsComponent", "button", "warranty-documents-component", [
      "addDocument",
    ]),
    new FrameworkItem("RepairCentreNotesComponent", "button", "app-repair-centre-notes", [
      "addNote",
    ]),
    new FrameworkItem("MaintenanceScheduleComponent", "button", "app-maintenance-schedule", [
    ]),
    new FrameworkItem("WarrantyOptionsComponent", "button", "app-warranty-options", [
    ]),
    //#endregion 

    //#endregion Claims
  ],
};

export const APP_FRAMEWORK = new InjectionToken<AppFramework>("app.framework");
