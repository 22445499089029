import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src-private/environments/environment';
import { ILoginUser } from '../interfaces/loginuser.interface';

@Injectable({
  providedIn: 'root'
})
export class LoginUserService {

  constructor(private http: HttpClient) { }

  // loginUsersGet(): Observable<ILoginUser[]> {
  //   return this.http.get<ILoginUser[]>(environment.resourceUrl + 'api/gwc/loginuser');
  // }

  assignedAdjustersGet(): Observable<ILoginUser[]> {
    return this.http.get<ILoginUser[]>(environment.resourceUrl + 'api/gwc/assignedadjusters');
  }

  //search a single user by identifying info, id, username or email
  search(loginUser: ILoginUser): Observable<ILoginUser> {
    let httpParams = new HttpParams();

    Object.keys(loginUser).forEach(function (key) {
      httpParams = httpParams.append(key, loginUser[key]);
    });

    return this.http.post<ILoginUser>(environment.resourceUrl + 'api/gwc/user/search', httpParams);
  }

  //lookup all users that match partial user name for autocomplete filtering
  lookup(lookupText: string): Observable<ILoginUser[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('lookupText', lookupText);

    return this.http.get<ILoginUser[]>(environment.resourceUrl + 'api/gwc/user/lookup', { params: httpParams });
  }
}
