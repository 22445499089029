<block-ui>

  <ng-sidebar-container class="sideNavHeight100">
    <ng-sidebar [closeOnClickBackdrop]="true"
                [closeOnClickOutside]="false"
                [keyClose]="true"
                [position]="'top'"
                [showBackdrop]="true"
                [(opened)]="sidebarTopOpened"
                #sidebarTop>
      <ng-template #dynamicContainerTop></ng-template>
    </ng-sidebar>

    <ng-sidebar [closeOnClickBackdrop]="true"
                [closeOnClickOutside]="false"
                [keyClose]="true"
                [position]="'right'"
                [showBackdrop]="true"
                [(opened)]="sidebarRightOpened"
                (onClosed)="onNotificationPanelClose()"
                #sidebarRight>
      <ng-template #dynamicContainerRight></ng-template>
    </ng-sidebar>

    <ng-sidebar [closeOnClickBackdrop]="true"
                [closeOnClickOutside]="false"
                [keyClose]="true"
                [position]="'bottom'"
                [showBackdrop]="true"
                [(opened)]="sidebarBottomOpened"
                #sidebarBottom>
      <ng-template #dynamicContainerBottom></ng-template>
    </ng-sidebar>

    <!--<ng-sidebar [closeOnClickBackdrop]="false"
                [closeOnClickOutside]="false"
                [keyClose]="false"
                [mode]="sideBarNavigationMode"
                [position]="'left'"
                [showBackdrop]="false"
                [sidebarClass]="'navigation'"
                [(opened)]="sidebarNavigationOpened"
                #sidebarMenu>
      <ng-template #dynamicContainerLeft></ng-template>
      <app-navigation></app-navigation>
    </ng-sidebar>-->
    <!-- page content -->
    <div ng-sidebar-content>
      <div class="demo-mode" [hidden]="!isDemo">
        THIS IS THE TESTING ENVIRONMENT ONLY. DO NOT USE EXCEPT FOR TESTING PURPOSES.
      </div>
      <header *ngIf="isAuthenticated">
        <img id="logo" src="/assets/images/navbar-logo@2x.png" />        
        <div class="visual-indicator" id="visual-indicator-fullscreen" *ngIf="getVisualIndicatorInfo">
          {{visualIndicatorText}} 
        </div>
        <ul id="header-outer">
          <li class="d-md-none">
           
            <button mat-icon-button [matMenuTriggerFor]="menumobile" aria-label="Mobile Menu">
                <mat-icon>menu</mat-icon>
            </button>
            <mat-menu #menumobile="matMenu">
            <div class="nav mobile-view" *ngIf="!isAuditor">
              <a routerLink="eazeetrak"
                 routerLinkActive="active-route">EazeeTrak</a>
              <a routerLink="claims"
                 routerLinkActive="active-route">Claims</a>
              <a routerLink="dealers"
                skipLocationChange replaceUrl routerLinkActive="active-route">Dealers</a>
              <a routerLink="reports"
                 routerLinkActive="active-route">Reports</a>
              <a routerLink="migration"
                 routerLinkActive="active-route">Migration</a>
            </div>
            </mat-menu>
            
          </li>
          
          <li class="d-none d-sm-block">
            <div class="nav" *ngIf="!isAuditor">
              <a routerLink="eazeetrak"
                 routerLinkActive="active-route">EazeeTrak</a>
              <a routerLink="claims"
                 routerLinkActive="active-route">Claims</a>
              <a routerLink="dealers"
                skipLocationChange replaceUrl routerLinkActive="active-route">Dealers</a>
              <a routerLink="reports"
                 routerLinkActive="active-route">Reports</a>
              <a routerLink="migration"
                 routerLinkActive="active-route">Migration</a>
            </div>
            <div class="nav" *ngIf="isAuditor">
              <a routerLink="eazeetrak"
                 routerLinkActive="active-route">Claims</a>
            </div>
          </li>

          <li>
            <button mat-icon-button  class="nav-menu"  id="changeTheme" (click)="setTheme()">
              <fa-icon [icon]="faSun" size="lg" class="nav-icon"></fa-icon>
            </button>
          </li>
          <li>
            <button mat-icon-button [hidden]="!isAdjuster" class="nav-menu" id="quickAdd" (click)="openQuickAdd()">
              <fa-icon [icon]="faPlus" size="lg" class="nav-icon"></fa-icon>
            </button>
          </li>

          <li>
            <button mat-icon-button class="nav-menu" id="omnisearch" (click)="openOmniSearch()">
              <fa-icon [icon]="faSearch" size="lg" class="nav-icon"></fa-icon>
            </button>
          </li>

          <li>
            <button mat-icon-button class="nav-menu" id="notification" (click)="openNotification()">
              <fa-icon [icon]="faBell" size="lg" class="nav-icon" [ngClass]="{'yellow-bell': notificationsExist}"></fa-icon>
            </button>
          </li>
          

          <li class="avatar-outer">
            <div class="avatar-inner">
              <img class="avatar" [src]="avatarImageUrl" avatar />
              <span class="user">{{userFullName}}</span>
            </div>
            <ul class="sub-menu"
                (click)="logout()">
              <li>Logout</li>
            </ul>
          </li>
        </ul>
      </header>
      <div class="visual-indicator" id="visual-indicator-small" *ngIf="getVisualIndicatorInfo">
        {{visualIndicatorText}}
      </div>
      <router-outlet></router-outlet>
    </div>
  </ng-sidebar-container>
  <div class="appVersion">
    Version {{appVersion}}
  </div>
</block-ui>
