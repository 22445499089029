<mat-dialog-content>
  <form [formGroup]="customerEditForm">
    <div class="container-fluid dialogContent">
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field class="w-100">
            <input formControlName="firstName" placeholder="First Name" required matInput>
            <mat-error>
              <mat-error *ngIf="customerEditForm.get('firstName').hasError('required')">First Name is required</mat-error>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="w-100">
            <input formControlName="lastName" placeholder="Last Name" required matInput>
            <mat-error>
              <mat-error *ngIf="customerEditForm.get('lastName').hasError('required')">Last Name is required</mat-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <mat-form-field class="w-100">
            <input formControlName="phone" placeholder="Phone" required matInput trim mask="(000) 000-0000">
            <mat-error>
              <mat-error *ngIf="customerEditForm.get('phone').hasError('required')">Phone is required</mat-error>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-7">
          <mat-form-field class="w-100">
            <input formControlName="email" placeholder="Email" required matInput trim>
            <mat-error>
              <mat-error *ngIf="customerEditForm.get('email').hasError('email')">Email is required</mat-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-7">
          <mat-form-field class="w-100">
            <input formControlName="driversLicense" placeholder="Drivers License" matInput trim>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <mat-form-field class="w-100">
            <input formControlName="address" placeholder="Address" required matInput>
            <mat-error>
              <mat-error *ngIf="customerEditForm.get('address').hasError('required')">Address is required</mat-error>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-1">
        </div>
        <div class="col-sm-5">
          <mat-form-field class="w-100">
            <input formControlName="city" placeholder="City" required matInput>
            <mat-error>
              <mat-error *ngIf="customerEditForm.get('city').hasError('required')">City is required</mat-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3">
          <mat-form-field class="w-100">
            <input formControlName="province" [placeholder]="provinceStateLabel" required matInput>
            <mat-error>
              <mat-error *ngIf="customerEditForm.get('province').hasError('required')">{{provinceStateLabel}} is required</mat-error>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-3">
          <mat-form-field class="w-100">
            <input formControlName="postalZipCode" [placeholder]="postalCodeStateLabel" required matInput trim
                   [mask]="postalCodeStateMask" oninput="this.value = this.value.toUpperCase()">
            <mat-error>
              <mat-error *ngIf="customerEditForm.get('postalZipCode').hasError('required')">{{postalCodeStateLabel}} is required</mat-error>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-1">
        </div>
        <div class="col-sm-4">
          <div class="mat-form-field mat-form-field-static">
            <label class="mat-label">Country</label>
            <mat-radio-group formControlName="country" (change)="onCountryChange()">
              <mat-radio-button color="primary" value="Canada">CA</mat-radio-button>
              <mat-radio-button color="primary" value="USA">US</mat-radio-button>
            </mat-radio-group>
          </div>

        </div>

      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="float-right">
  <button mat-button mat-dialog-close class="btn" (click)="cancel()">
    <fa-icon [icon]="faWindowClose" class="pr-2 text-danger"></fa-icon>
    Cancel
  </button>
  <button mat-button class="btn" (click)="confirm()">
    <fa-icon [icon]="faSave" class="pr-2 text-success"></fa-icon>
    Save
  </button>
</mat-dialog-actions>
