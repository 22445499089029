<div class="container-fluid noPadding">
  <div class="row">
    <div *ngIf="funcs['refresh'];else noRefresh" class="col-lg-12">
      <div *ngIf="isLoading;else tableAdapterComponent" class="td-loading-spinner" colspan="1">
        <mat-spinner diameter="25"></mat-spinner>
      </div>
      <ng-template #tableAdapterComponent>
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
          <div *ngFor="let col of columnNames; let i = index">
            <ng-container matColumnDef="{{columns[i].cellName}}">
              <th mat-header-cell *matHeaderCellDef="let element" mat-sort-header [ngClass]="columns[i].cssClass">
                <div [ngSwitch]="columns[i].cellType" class="full-width">
                  <div *ngSwitchCase="'currency'" class="th-left">
                    {{columns[i].cellName}}
                  </div>
                  <div *ngSwitchCase="'number'" class="th-center">
                    {{columns[i].cellName}}
                  </div>
                  <div *ngSwitchCase="'checkbox'" class="th-center">
                    {{columns[i].cellName}}
                  </div>
                  <div *ngSwitchCase="'dropDown'" class="th-center">
                    {{columns[i].cellName}}
                  </div>
                  <div *ngSwitchCase="'actions'" [hidden]="!hasActions()" class="th-right">
                    {{columns[i].cellName}}
                  </div>
                  <div *ngSwitchDefault class="th-left">
                    {{columns[i].cellName}}
                  </div>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" class="mat-cell td-{{element.display[i].displayType}} {{columns[i].cssClass}}">
                <div [ngSwitch]="element.display[i].displayType">
                  <div *ngSwitchCase="'currency'" class="td-left">
                    <div *ngIf="element.data.goodwillTotal">
                      <div *ngIf="element.display[i].displayData != 0">
                        <ng-container *ngIf="columns[i].attributes[0].custom; else default">
                          {{ element.display[i].displayData | currency:'USD':'symbol':(element.display[i].displayData % 1 == 0 ? (columns[i].attributes[0].custom.whole ?? '1.0-0') : (columns[i].attributes[0].custom.decimal ?? '1.2-2')) }}
                        </ng-container>
                        <ng-template #default>{{element.display[i].displayData==0 ? '-': element.display[i].displayData | currency:'USD':'symbol':'1.2-2' }}</ng-template>
                      </div>
                      <div *ngIf="element.display[i].displayData == 0">
                        <div *ngIf="columns[i].attributes[0].totalColumn">
                          <ng-container *ngIf="columns[i].attributes[0].custom; else default">
                            {{ element.display[i].displayData | currency:'USD':'symbol':(element.display[i].displayData % 1 == 0 ? (columns[i].attributes[0].custom.whole ?? '1.0-0') : (columns[i].attributes[0].custom.decimal ?? '1.2-2')) }}
                          </ng-container>
                          <ng-template #default>{{element.display[i].displayData==0 ? '-': element.display[i].displayData | currency:'USD':'symbol':'1.2-2' }}</ng-template>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!element.data.goodwillTotal">
                      <ng-container *ngIf="columns[i].attributes[0].custom; else default">
                        {{ element.display[i].displayData | currency:'USD':'symbol':(element.display[i].displayData % 1 == 0 ? (columns[i].attributes[0].custom.whole ?? '1.0-0') : (columns[i].attributes[0].custom.decimal ?? '1.2-2')) }}
                      </ng-container>
                      <ng-template #default>{{element.display[i].displayData==0 ? '-': element.display[i].displayData | currency:'USD':'symbol':'1.2-2' }}</ng-template>
                    </div>
                  </div>
                  <div *ngSwitchCase="'number'" class="td-center">
                    <div *ngIf="!element.data.goodwillTotal">
                      <div *ngIf="!isTotalRow(element.data)">
                        {{element.display[i].displayData==null ? '-': element.display[i].displayData}}
                      </div>
                      <div *ngElse>
                        {{element.display[i].displayData}}
                      </div>
                    </div>
                  </div>
                  <div *ngSwitchCase="'checkbox'" class="td-center">
                    <mat-checkbox disabled [checked]=element.display[i].displayData></mat-checkbox>
                  </div>
                  <div *ngSwitchCase="'date'" class="td-left">
                    {{element.display[i].displayData | GWDateFormat}}
                  </div>
                  <div *ngSwitchCase="'datetime'" class="td-left">
                    {{element.display[i].displayData | GWDateTimeFormat}}
                  </div>
                  <div *ngSwitchCase="'contextmenu'" class="td-center">
                    <button mat-icon-button [matMenuTriggerFor]="menu" [hidden]="!canInteract" (click)="$event.stopPropagation()">
                      <fa-icon [icon]="faEllipsisV"></fa-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button *ngIf="element.display[i].hasDownload" mat-menu-item (click)="contextMenuUpdate($event, element.data, 'download')" [hidden]="!canInteract || !funcs['download']">
                        <fa-icon [icon]="faDownload"></fa-icon>
                        <span> Download</span>
                      </button>
                      <button mat-menu-item (click)="contextMenuUpdate($event, element.data, 'remove')" [hidden]="!canInteract || !funcs['remove']">
                        <fa-icon [icon]="faTrash"></fa-icon>
                        <span> Remove</span>
                      </button>
                    </mat-menu>
                  </div>
                  <div *ngSwitchCase="'dropdown'" class="w-75">
                      <mat-select *ngIf="!isTotalRow(element.data)" [(value)]="element.display[i].displayData" (click)="$event.stopPropagation()"
                        (selectionChange)="triggerFunction(element.data, element.display[i].displayData)" [disabled]="!canInteract || !funcs['dropDown']">
                        <mat-option *ngFor="let option of columns[i].attributes" [value]="option.value">{{option.label}}</mat-option>
                      </mat-select>
                  </div>
                  <div *ngSwitchCase="'action'" class="td-center">
                      <button *ngIf="element.display[i].displayData" mat-menu-item (click)="actionButtonClick($event, element.data, 'remove')" [hidden]="!canInteract || !funcs['action']">
                        <fa-icon [icon]="faTrash"></fa-icon>
                      </button>
                  </div>
                  <div *ngSwitchCase="'actions'" class="td-right">
                    <button *ngIf="element.display[i].hasDownload && canRender(CellActions.Download)" mat-menu-item class="action" (click)="contextMenuUpdate($event, element.data, 'download')" [hidden]="!canInteract || !funcs['download']">
                      <fa-icon [icon]="faDownload"></fa-icon>
                    </button>
                    <button *ngIf="element.display[i].hasRemove && canRender(CellActions.Remove)" mat-menu-item class="action" (click)="contextMenuUpdate($event, element.data, 'remove')" [hidden]="!canInteract || !funcs['remove']">
                      <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                    <button *ngIf="element.display[i].hasMove && canRender(CellActions.Move)" mat-menu-item class="action" (click)="contextMenuUpdate($event, element.data, 'move')" [hidden]="!canInteract || !funcs['move']">
                      <fa-icon [icon]="faShareSquare"></fa-icon>
                    </button>
                    <button mat-menu-item *ngIf="element.display[i].hasApprove && element.display[i].displayData && !element.data.invoiceApproved && canRender(CellActions.Approve)" class="approve" (click)="contextMenuUpdate($event, element.data, 'approve')">
                      Approve
                    </button>
                    <ng-container *ngIf="element.display[i].hasApprove && element.display[i].displayData && element.data.invoiceApproved">
                      <button mat-menu-item *ngIf="canRender(CellActions.Unapprove);else approvedCheck" class="unapprove" (click)="contextMenuUpdate($event, element.data, 'unapprove')">
                        Unapprove
                      </button>
                      <ng-template #approvedCheck>
                        <fa-icon [icon]="faCheck" class="approvedCheck"></fa-icon>
                      </ng-template>
                    </ng-container>
                  </div>
                  <div *ngSwitchCase="'downloadandaction'" class="td-right">
                    <button mat-menu-item class="action" (click)="contextMenuUpdate($event, element.data, 'download')" [hidden]="!canInteract || !funcs['download']">
                      <fa-icon [icon]="faDownload"></fa-icon>
                    </button>
                  </div>
                  <div *ngSwitchCase="'document-type-image'">
                    <img [src]="element.display[i].displayData" class="border-radius-9px ml-3 mr-3" width="30" height="30">
                  </div>
                  <div *ngSwitchDefault class="td-left">
                    <a class="link" *ngIf="element.display[i].displayData === element.data.enterpriseId; else defaultBlock" [routerLink]="['/claims', element.data.enterpriseId, 'detail']">{{element.display[i].displayData}}</a>
                    <ng-template #defaultBlock>{{element.display[i].displayData}}</ng-template>
                  </div>
                </div>
              </td>
            </ng-container>
          </div>
          <tr mat-header-row *matHeaderRowDef="columnNames; sticky: true;"></tr>
          <tr mat-row *matRowDef="let rows; columns: columnNames;" 
              (auxclick)="canInteract && funcs['rowClick'] ? onRowClick(rows, $event, true) : false" 
              (click)="canInteract && funcs['rowClick'] ? onRowClick(rows, $event, false) : false" [ngClass]="getRowCSS(rows)"></tr>
        </table>
      </ng-template>
      <mat-paginator *ngIf="paginationRequired" [length]=length [pageSize]=pageSize></mat-paginator>
    </div>
    <ng-template #noRefresh>
      <div class="col-lg-12">
        <framework-hidden [display]="'table'">
        </framework-hidden>
      </div>
    </ng-template>
  </div>
</div>
