import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NoteAddDialogComponent } from '../../note-add-dialog/note-add-dialog.component';
import { CustomerNotesService } from '../../../services/customer-notes.service';
import { ICustomerNote } from '../../../interfaces/customer-note.interface';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { TableAdapterComponent } from 'src-private/app/shared/table-adapter/table-adapter.component';
import { CellBuilder } from 'src-private/app/shared/table-adapter/cell-builder/cell-builder';
import { CellType } from 'src-private/app/shared/table-adapter/cell-builder/cell-type';
import { ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { FrameworkComponent } from 'src-private/app/shared/framework/framework.component';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { ClaimsService } from '../../../services/claims.service';
import { AccountService } from 'src-private/app/areas/account/services/account.service';
import { environment } from 'src-private/environments/environment';

@Component({
  selector: 'app-customer-notes',
  templateUrl: './customer-notes.component.html',
  styleUrls: ['./customer-notes.component.scss']
})
export class CustomerNotesComponent extends FrameworkComponent implements OnInit, AfterViewInit {

  @Input() customerId: number;

  public faPlus = faPlus;

  public claimsId: number;
  public notes$: BehaviorSubject<ICustomerNote[]>;
  public isAdjuster: boolean;

  private ngUnsubscribe: Subject<any> = new Subject();
  
  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private claimsService: ClaimsService,
    private accountService: AccountService,
    private noteService: CustomerNotesService,
    private toastr: ToastrService
    ) { 
    super();
    this.claimsId = this.route.snapshot.data['claimId']
  }

  @ViewChild("notesTable") notesTable: TableAdapterComponent<ICustomerNote>

  public notesTableColumns: CellBuilder[] = [
    new CellBuilder("Created Date", "createdDate", CellType.datetime, 'col-4 col-sm-4'),
    new CellBuilder("Adjuster", "adjusterName", CellType.text),
    new CellBuilder("Note", "note", CellType.text),
  ];

  ngOnInit(): void {
    this.notes$ = new BehaviorSubject<ICustomerNote[]>([] as ICustomerNote[]);
    this.isAdjuster = this.accountService.isAdjuster() || this.accountService.isClaimsManager() || this.accountService.isAssistantClaimsManager() || this.accountService.isDealerServices();
  }

  ngAfterViewInit() {
    this.loadNotes();
    super.build("CustomerNotesComponent", 'customer-notes-component')
  }

  loadNotes() {
    this.notesTable.refresh(this.noteService.retrieveAll(this.customerId));
  }

  addNote() {
    const addNoteDialogRef = this.dialog.open(NoteAddDialogComponent,
      {
        width: '600px'
      });

    addNoteDialogRef.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (res.data) {
          res.data.customerId = this.customerId;
          res.data.claimId = this.claimsId;
          this.noteService.add(res.data)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
              this.toastr.success("Note successfully created. ", 'Create Note');
              this.loadNotes();
            },
            error => {
              this.toastr.error(environment.messages.apiError, "Unable to add note");
            });
        }
      });
  }

  public get IsClaimClosed(): boolean{
    return this.claimsService.getSelectedClaim?.IsClaimClosed
  }

  public get CanEditClosedClaim(): boolean{
    return this.accountService.isClaimsManager();
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
