import { Component, OnInit, Inject, Output, AfterViewInit, ViewChild, Input, EventEmitter, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { FrameworkComponent } from 'src-private/app/shared/framework/framework.component';
import { CellBuilder } from 'src-private/app/shared/table-adapter/cell-builder/cell-builder';
import { CellType, CellActions } from 'src-private/app/shared/table-adapter/cell-builder/cell-type';
import { TableAdapterComponent } from 'src-private/app/shared/table-adapter/table-adapter.component';
import { environment } from 'src-private/environments/environment';
import { IClaim } from '../../interfaces/claim';
import { ClaimTransaction } from '../../models/claim-transaction';
import { Part } from '../../models/part-model';
import { ClaimsTransactionService } from '../../services/claims-transaction.service';
import { ClaimsService } from '../../services/claims.service';
import { PartApprovalDialogComponent } from '../approval/part-approval-dialog/part-approval-dialog.component';
import { PartDialogComponent } from '../part-dialog/part-dialog.component';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { ClaimsDocumentService } from '../../services/claims-document.service';
import { PartService } from '../../services/part-service';
import { ClaimStatus } from 'src-private/app/enums/claims.enums';
import { PartUnapprovalDialogComponent } from '../approval/part-unapproval-dialog/part-unapproval-dialog.component';


@Component({
    selector: 'parts-list',
    templateUrl: './parts-list.component.html',
    styleUrls: ['./parts-list.component.scss']
})

export class PartsListComponent extends FrameworkComponent implements OnInit, AfterViewInit, OnDestroy {
    
  @Output() documentAdded = new EventEmitter<boolean>();

  @Input() canAddPart: boolean;
  @Input() warrantyId : number;
  @Input() partsTableColumns: CellBuilder[];
  @Input() canRenderActions: Function;
  @Output() update = new EventEmitter();
  
  public faPlus = faPlus;
  
  private claimsId: number;
  public partData: ClaimTransaction[];

  public invoiceNumber : string = null;
  public documentData : any = null;

  @BlockUI() blockUI: NgBlockUI;

  private ngUnsubscribe: Subject<any> = new Subject();

  @ViewChild("partsTable")
  partsTable: TableAdapterComponent<Part>;

  constructor(private toastr: ToastrService,
      private claimsDocumentService: ClaimsDocumentService,
      private claimTransactionService: ClaimsTransactionService,
      private partService: PartService,
      private activatedRoute: ActivatedRoute,
      public dialog: MatDialog,
      private claimsService: ClaimsService,) {
      super();
          this.claimsId = this.activatedRoute.snapshot.data['claimId']
      }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
      this.refreshParts();
      super.build("PartsListComponent", "parts-component");
  }

  public get IsClaimClosed(): boolean{
    return this.claimsService.getSelectedClaim?.IsClaimClosed
  }

  public get IsClaimApproved(): boolean {
    if(this.partData)
      return this.partData.find(claim => claim.invoiceApproved) !== undefined;
    else
      return false;
  }

  addPart() {
    var transaction = new ClaimTransaction();
    transaction.claimId = this.claimsId;
    transaction.warrantyId = this.warrantyId;
    transaction.goodwill = 0;
    transaction.used = false;
    transaction.rebuilt = false;
    transaction.quantity = 1;
    let dialogRef = this.dialog.open(PartDialogComponent, {
      panelClass: "part-dialog",
      width: "900px",
      autoFocus: false,
      data: {
        transaction: transaction,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      var transaction: ClaimTransaction = dialogRef.componentInstance.transaction;
      if (dialogRef.componentInstance.updateStatus == "save") {
        this.blockUI.start();
        this.claimTransactionService.add(transaction).subscribe((d) => {
          this.blockUI.stop();
          this.toastr.success("Part successfully added. ", "Create Part");
          if(d['alert']) {
            this.toastr.warning("Part has been flagged due to price higher than average. ", "Create Part");
          }
          this.refreshParts(true);
        },
        error => {
          this.toastr.error(environment.messages.apiError, "Unable to add part");
        });
      } 
    });
  }

  updatePart(transaction) {

    var cleanedTransaction = transaction.data
    if (!(cleanedTransaction.finalTotal || cleanedTransaction.total || cleanedTransaction.goodwillTotal)) {
      cleanedTransaction.warrantyId = this.warrantyId;
      let dialogRef = this.dialog.open(PartDialogComponent, {
          panelClass: "part-dialog",
          width: "900px",
          autoFocus: false,
          data: {
            transaction: cleanedTransaction,
            readOnly: cleanedTransaction.invoiceApproved
          },
      });

      dialogRef.afterClosed().subscribe(() => {
          if (dialogRef.componentInstance.updateStatus == "save") {
          this.claimTransactionService
              .update(dialogRef.componentInstance.transaction)
              .subscribe(() => {
              this.toastr.success("Parts successfully updated. ", "Update Parts");
              this.refreshParts(true);
              },
              error => {
                this.toastr.error(environment.messages.apiError, "Unable to update part");
              });
          }
      });
    }
  }

  updatePartProcess(transactionData) {
    transactionData.rowData.process = transactionData.selectedValue
    this.claimTransactionService
    .update(transactionData.rowData)
    .subscribe(() => {
        this.refreshParts(true);
        this.toastr.success("Part Process successfully updated. ", "Update Parts Process");     
    },
    (error)=>{
        this.toastr.error(environment.messages.apiError, "Update Parts Process")
    });
  }

  refreshParts(isFromUpdate: boolean = false) {
    this.partsTable.refresh(
          this.claimTransactionService.retrieve(this.claimsId)
      );

      this.claimTransactionService
          .retrieve(this.claimsId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((data) => {
          this.partData = data;

          if (this.partData.filter(x => x.hstAmount > 0)) {
            this.partsTableColumns.find(x => x.cellName === "GST").cssClass = "hidden";
            this.partsTableColumns.find(x => x.cellName === "PST").cssClass = "hidden";
            this.partsTableColumns.find(x => x.cellName === "HST").cssClass = "";
          } else {
            this.partsTableColumns.find(x => x.cellName === "GST").cssClass = "";
            this.partsTableColumns.find(x => x.cellName === "PST").cssClass = "";
            this.partsTableColumns.find(x => x.cellName === "HST").cssClass = "hidden";
          }

          if(isFromUpdate){
              this.update.emit(this.partData.filter(x=>x.finalTotal)[0].rowTotalWithTax);
          }
      },
      error => {
        this.toastr.error(environment.messages.apiError, "Unable to retrieve parts");
      });
  }

  onAction(data) {
    if (data.action == 'approve') {
      let dialogRef = this.dialog.open(PartApprovalDialogComponent, {
        panelClass: "part-dialog",
        width: "500px",
        autoFocus: false,
        data: {
          transaction: data.element,
          documentData: this.documentData,
          invoiceNumber: this.invoiceNumber
        },
      });

      dialogRef.afterClosed().subscribe((sd) => {
        if (sd.event === "Cancel") return;

        sd.partData.warrantyId = this.warrantyId;

        this.documentData = sd.documentData;
        this.invoiceNumber = sd.partData.invoiceNumber;

        this.blockUI.start();

        this.partService.approve(sd.partData).subscribe((d) => {
          if (sd.documentData) {
            this.claimsDocumentService.add(sd.documentData, sd.partData.partId).subscribe((d) => {
              this.documentAdded.emit(true);
            },
            error => {
              this.toastr.error(environment.messages.apiError, "Unable to add document");
            });
          }

          this.blockUI.stop();
          this.toastr.success("Part successfully approved. ", "Approve Part");
          this.refreshParts();
        },
        (error) => {
          this.toastr.error(environment.messages.apiError, "Approve Part Process");
          this.blockUI.stop();
        });
      });
    }
    else if (data.action == 'unapprove') {
      let dialogRef = this.dialog.open(PartUnapprovalDialogComponent, {
        panelClass: "part-dialog",
        width: "500px",
        autoFocus: false,
        data: {
          transaction: data.element,
          invoiceNumber: this.invoiceNumber
        },
      });

      dialogRef.afterClosed().subscribe((sd) => {
        if (sd.event === "Cancel") return;

        this.blockUI.start();

        sd.partData.warrantyId = this.warrantyId;

        this.partService.unapprove(sd.partData).subscribe((d) => {
          this.blockUI.stop();
          this.toastr.success("Part successfully unapproved. ", "Unapprove Part");
          this.refreshParts();
        },
        (error) => {
          this.toastr.error(environment.messages.apiError, "Unapprove Part Process");
          this.blockUI.stop();
        });
      });
    }
    else if (data.action =='remove') {
        this.claimTransactionService.delete(data.element.claimTransactionId).subscribe(
            data => {
              if(data){
                this.refreshParts(true);
                this.toastr.success(
                  "Part successfully removed. ",
                  "Remove Part"
                );
              }
            },
            error => {
              this.toastr.error(environment.messages.apiError, "Unable to remove part");
            }
        )
    }
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
