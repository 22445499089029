<header class="header">
  <div class="container-fluid mb-0 mb-md-3">
    <div class="row justify-content-between">
      <div class="col-lg-6 col-md-12 d-none d-sm-block">
        <div class="form-row" *ngIf="selectedClaim && !isClaimsListPage && !isAuditor">
            <div class="col-12">
              <div class="btn-group btn-groupalign sub-menu" role="group">
                <a class="btn btn-align text-nowrap" type="button" [routerLink]="[selectedClaim.enterpriseId, 'detail']" routerLinkActive="active">
                  Detail
                </a>
                <a class="btn btn-align text-nowrap" type="button" [routerLink]="[selectedClaim.enterpriseId, 'customer', selectedClaim.customerId]" routerLinkActive="active">
                  Customer
                </a>
                <a class="btn btn-align text-nowrap" type="button" [routerLink]="[selectedClaim.enterpriseId, 'warranty', selectedClaim.warrantyId]" routerLinkActive="active">
                  Warranty
                </a>
                <a class="btn btn-align text-nowrap" type="button" [routerLink]="[this.selectedClaim.enterpriseId, 'repair-centre', this.selectedClaim.claimsRepairCenterId ? this.selectedClaim.claimsRepairCenterId : {}]"
                  routerLinkActive="active" [class.disabled]="!this.selectedClaim.claimsRepairCenterId" [ngClass]="{'disableButton': !this.selectedClaim.claimsRepairCenterId}">
                  Repair Centre
              </a>
                <a class="btn btn-align text-nowrap" type="button" [routerLink]="[selectedClaim.enterpriseId, 'maintenance']" routerLinkActive="active">
                  Maintenance
                </a>
                <a class="btn btn-align text-nowrap" type="button" [routerLink]="[selectedClaim.enterpriseId, 'approval']" routerLinkActive="active" *ngIf="hasApprovalPermission">
                  Approval
                </a>
              </div>
            </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-12 my-0 my-md-2 pr-lg-3 pr-0 d-flex justify-content-end" *ngIf="!selectedClaim || isClaimsListPage">
        <form [formGroup]="statusForm">
          <div class="form-row">
              <div class="d-flex justify-content-end">
                <div class="header-field-outer text-nowrap px-3">
                  <div class="header-field-inner">
                    <label class="form-field-label">Opened By</label>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" id="openedByAll" type="radio" value="All"
                        formControlName="openedBy">
                      <label class="custom-control-label" for="openedByAll">All</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" id="openedBySelf" type="radio" value="Self"
                        formControlName="openedBy">
                      <label class="custom-control-label" for="openedBySelf">Self</label>
                    </div>
                  </div>
                </div>
              <div class="header-field-outer ml-3">
                <div class="header-field-inner text-nowrap px-3">
                  <label class="form-field-label">Status</label>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="statusAll" formControlName="status" class="custom-control-input"
                      value="All">
                    <label class="custom-control-label" for="statusAll">All</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="statusOpen" formControlName="status" class="custom-control-input"
                      value="Open">
                    <label class="custom-control-label" for="statusOpen">Open</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="statusClosed" formControlName="status" class="custom-control-input"
                      value="Closed">
                    <label class="custom-control-label" for="statusClosed">Closed</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="d-md-none" *ngIf="selectedClaim && !isAuditor">
    <button mat-icon-button [matMenuTriggerFor]="menumobile" aria-label="Mobile Menu">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menumobile="matMenu">
        <a mat-menu-item [routerLink]="[selectedClaim.enterpriseId, 'detail']">Detail</a>
        <a mat-menu-item [routerLink]="[selectedClaim.enterpriseId, 'customer', selectedClaim.customerId]">Customer</a>
        <a mat-menu-item [routerLink]="[selectedClaim.enterpriseId, 'warranty', selectedClaim.warrantyId]">Warranty</a>
        <a mat-menu-item [routerLink]="[selectedClaim.enterpriseId, 'repair-centre', selectedClaim.claimsRepairCenterId ? selectedClaim.claimsRepairCenterId : {}]"
        [disabled]="!this.selectedClaim.claimsRepairCenterId" [ngClass]="{'disableButton': !this.selectedClaim.claimsRepairCenterId}">
        Repair Centre</a>
        <a mat-menu-item [routerLink]="[selectedClaim.enterpriseId, 'maintenance']">Maintenance</a>
        <a mat-menu-item [routerLink]="[selectedClaim.enterpriseId, 'approval']">Approval</a>
    </mat-menu>
  </div>
</header>

<section class="content">
  <router-outlet></router-outlet>
</section>
