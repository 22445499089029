<h1 mat-dialog-title>Document Duplicates</h1>
<mat-dialog-content>
    <div class="container-fluid dialogContent">
        <div class="row">
            <div class="col-md-12">
                <table-adapter #conflictsTable [columns]="columns" [tableLabel]="'documents-conflicts-table'"></table-adapter>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <p class="font-weight-light">One or more of the documents you uploaded may already exist.</p>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="float-right">
    <button mat-button mat-dialog-close class="btn" type="button"  cdkFocusInitial>
        <fa-icon [icon]="faCheck" class="pr-2 text-success"></fa-icon> Ok
    </button>
</mat-dialog-actions>