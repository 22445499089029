import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { APP_CONFIG, AppConfig } from '../app.config';

@Pipe({
  name: 'GWDateTimeFormat'
})
export class CustomDateTimePipe extends DatePipe implements PipeTransform {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    @Inject(LOCALE_ID) public sLocale: string) {
    super(sLocale);
  }

  transform(value: any, args?: any): any {

    var dateformat = this.config.GWDateTimeFormat;

    if (dateformat)
      return super.transform(value, dateformat);
    else
      return super.transform(value);
  }
}
