<div class="btn-group w-100"
     role="group">
  <button class="btn"
          type="button"
          [ngClass]="{active: notesSection === 'notes'}"
          (click)="onButtonGroupClick('notes')">
    General
  </button>
  <button class="btn"
          type="button"
          [ngClass]="{active: notesSection === 'files'}"
          (click)="onButtonGroupClick('files')">
    EazeeTrak
  </button>
</div>

<div *ngIf="notesSection === 'notes'">
  <div class="date-range">
    <div class="container-fluid">
      <div class="form-row">
        <div class="col-6">

          <mat-form-field class="w-100">
            <input placeholder="Start Date"
                   [formControl]="notesStartDate"
                   [matDatepicker]="notesStartDatePicker"
                   (dateChange)="onNotesStartDateChange()"
                   matInput>
            <mat-datepicker-toggle matSuffix [for]="notesStartDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #notesStartDatePicker></mat-datepicker>
          </mat-form-field>

        </div>

        <div class="col-6">

          <mat-form-field class="w-100">
            <input placeholder="End Date"
                   [formControl]="notesEndDate"
                   [matDatepicker]="notesEndDatePicker"
                   (dateChange)="onNotesEndDateChange()"
                   matInput>
            <mat-datepicker-toggle matSuffix [for]="notesEndDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #notesEndDatePicker></mat-datepicker>
          </mat-form-field>

        </div>
      </div>
      <div class="form-row">
        <div class="col-sm-6">

          <mat-form-field class="w-100">
            <mat-select placeholder="Note Group"
                        [formControl]="noteGroupId"
                        (selectionChange)="onNoteGroupChange()">
              <mat-option [value]="0">All</mat-option>
              <mat-option *ngFor="let noteGroup of noteGroups"
                          [value]="noteGroup.noteGroupId">
                {{noteGroup.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <div class="col-sm-6">

          <ul class="list-unstyled filter-action w-100">
            <li class="filter-cell">

              <mat-form-field class="w-100">
                <input placeholder="Filter"
                       [formControl]="notesFilter"
                       (input)="onNotesFilterChange()"
                       (keydown.esc)="onNotesFilterKeydown()"
                       matInput>
                  <fa-icon [icon]="faFilter" matSuffix></fa-icon>
              </mat-form-field>

            </li>
            <li *ngIf="IsDealerLoaded" class="action-cell">

              <button class="btn btn-sm btn-dark"
                      (click)="onAddNoteClick()">
                <fa-icon [icon]="faPlus" matSuffix></fa-icon>
              </button>

            </li>
          </ul>

        </div>
      </div>

    </div>
  </div>

  <h5 class="no-records"
      *ngIf="!filteredPinnedNotes.length && !filteredStandardNotes.length">
    No notes for this dealer at this time
  </h5>

  <div class="accordion-wrapper">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let note of filteredPinnedNotes | orderBy: '-createdDate'">
        <mat-expansion-panel-header>

          <h5 class="accordion-header">
            <fa-icon [icon]="faThumbtack" class="icon-thumbtack"></fa-icon>
            {{note.firstName}} {{note.lastName}} - {{note.createdDate | date: 'mediumDate'}}
          </h5>

        </mat-expansion-panel-header>

        <app-note-comment [htmlContent]="note.comment"></app-note-comment>

      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="accordion-wrapper">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let note of filteredStandardNotes | orderBy: '-createdDate'">
        <mat-expansion-panel-header>

          <h5 class="accordion-header">
            {{note.firstName}} {{note.lastName}} - {{note.createdDate | date: 'mediumDate'}}
          </h5>

        </mat-expansion-panel-header>

        <app-note-comment [htmlContent]="note.comment"></app-note-comment>

      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div *ngIf="notesSection === 'files'">
  <div class="date-range">
    <div class="container-fluid">
      <div class="form-row">
        <div class="col-6">

          <mat-form-field class="w-100">
            <input placeholder="Start Date"
                   [formControl]="filesStartDate"
                   [matDatepicker]="filesStartDatePicker"
                   (dateChange)="onFilesStartDateChange()"
                   matInput>
            <mat-datepicker-toggle matSuffix [for]="filesStartDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #filesStartDatePicker></mat-datepicker>
          </mat-form-field>

        </div>

        <div class="col-6">

          <mat-form-field class="w-100">
            <input placeholder="End Date"
                   [formControl]="filesEndDate"
                   [matDatepicker]="filesEndDatePicker"
                   (dateChange)="onFilesEndDateChange()"
                   matInput>
            <mat-datepicker-toggle matSuffix [for]="filesEndDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #filesEndDatePicker></mat-datepicker>
          </mat-form-field>

        </div>
      </div>
      <div class="form-row">
        <div class="col-sm-6">

          <mat-form-field class="w-100">
            <mat-select placeholder="Reason Group"
                        [formControl]="reasonGroupId"
                        (selectionChange)="onReasonGroupChange()">
              <mat-option [value]="0">All</mat-option>
              <mat-option *ngFor="let reasonGroup of reasonGroups"
                          [value]="reasonGroup.eazeeTrakReasonGroupId">
                {{reasonGroup.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <div class="col-sm-6">

          <mat-form-field class="w-100">
            <input placeholder="Filter"
                   [formControl]="filesFilter"
                   (input)="onFilesFilterChange()"
                   (keydown.esc)="onFilesFilterKeydown()"
                   matInput>
            <fa-icon [icon]="faFilter" matSuffix></fa-icon>
          </mat-form-field>

        </div>
      </div>
    </div>
  </div>

  <h5 class="no-records"
      *ngIf="!filteredPinnedFiles.length && !filteredStandardFiles.length">
    No notes for this dealer at this time
  </h5>

  <table class="table table-hover datatable files">
    <tbody>
      <tr *ngFor="let file of filteredPinnedFiles"
          (click)="onEazeeTrakFileClick(file.eazeeTrakFileId)">
        <td>
          <fa-icon [icon]="faThumbtack" class="icon-thumbtack"></fa-icon>
        </td>
        <td>#{{file.eazeeTrakFileId}}</td>
        <td>{{file.reason}}</td>
        <td>{{file.isClosed ? 'Closed' : 'Open'}}</td>
      </tr>
    </tbody>
  </table>

  <table class="table table-hover datatable files">
    <tbody>
      <tr *ngFor="let file of filteredStandardFiles"
          (click)="onEazeeTrakFileClick(file.eazeeTrakFileId)">
        <td>#{{file.eazeeTrakFileId}}</td>
        <td>{{file.reason}}</td>
        <td>{{file.isClosed ? 'Closed' : 'Open'}}</td>
      </tr>
    </tbody>
  </table>
</div>
