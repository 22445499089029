import { Pipe, PipeTransform } from '@angular/core';

function addHttp(url: string) {
  if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
    url = "http://" + url;
  }
  return url;
}

@Pipe({
  name: 'http'
})
export class HttpPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return addHttp(String(value))
  }
}
