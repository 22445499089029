import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IRecentResult } from 'src-private/app/interfaces/recent-result.interface';
import { ISearchResult } from 'src-private/app/interfaces/search-result.interface';
import { SearchResultService } from 'src-private/app/services/search-result.service';
import { AccountService } from 'src-private//app/areas/account/services/account.service';
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import { ClaimsService } from '../../claims/services/claims.service';

@Component({
  selector: 'app-omni-search',
  templateUrl: './omni-search.component.html',
  styleUrls: ['./omni-search.component.scss']
})
export class OmniSearchComponent implements OnInit {
  @Output() modalResponse: EventEmitter<string> = new EventEmitter<string>();

  public faTimes = faTimes;
  public faSearch = faSearch;

  userId: string;
  public prevSearchTerm: string = "";
  public searchTerm: string = "";
  public FilteredOption: string = "0";
  public isLoading = false;
  public numRecentShown: number = 6;
  public isAuditor: boolean = false;

  public searchResults: ISearchResult[];
  public recentList: IRecentResult[];
  public arrowkeyLocation = 0;

  constructor(
    private accountService: AccountService,
    private searchResultService: SearchResultService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private claimService: ClaimsService
  ) { }

  ngOnInit() {
    this.userId = this.accountService.getUserId();
    this.isAuditor = this.accountService.isGeneralAuditor() || this.accountService.isInsurerAuditor();
    if (this.isAuditor) [
      this.FilteredOption = "4"
    ]
    this.listRecent();
  }

  public search(): Promise<{}> {
    if (this.searchTerm !== "") {
      this.prevSearchTerm = this.searchTerm;
      this.isLoading = true;
      var promise = new Promise((resolve, reject) => {
        this.searchResultService.search(this.searchTerm, this.userId, Number(this.FilteredOption)).subscribe(
          response => {
            this.isLoading = false;
            this.searchResults = [];
            var responseData = response as ISearchResult[];

            for (let data of responseData) {
              this.searchResults.push(data);
            }
            if (this.searchResults.length == 0) {
              var noData = {} as ISearchResult;
              noData.description = "No results found";
              noData.ID = 0;
              noData.type = 0;

              this.searchResults.push(noData);
            }
            this.listRecent();

            resolve(null);
          },
          () => {
            this.isLoading = false;
            this.searchResults = [];
            var noData = {} as ISearchResult;
            noData.description = "Error: no response";
            noData.ID = 0;
            noData.type = 0;

            this.searchResults.push(noData);
            this.listRecent();
            resolve(null);
          }
        );
      });
      return promise;
    }
  }

  searchResultClick(search: ISearchResult) {
    console.log(this.searchTerm)
    console.log(this.prevSearchTerm)
    if (this.prevSearchTerm !== this.searchTerm) {
      this.search();
      return;
    }
    let route = ""
    this.claimService.setSelectedClaim(null)

    //repair center
    if (search.type == 1) {
      route = 'repair-centre/' + search.ID;
    }
    //customer
    else if (search.type == 2) {
      route = 'customer/' + search.ID;
    }
    //warranty
    else if (search.type == 3) {
      route = 'warranty/' + search.ID;
    }
    //claim
    else if (search.type == 4) {
      if (this.isAuditor) {
        route =  'claims/' + search.ID + '/audit';
      }
      else {
        route =  'claims/' + search.ID + '/detail';
      }
     
    }
      this.router.navigateByUrl('/', {
        skipLocationChange: true 
      }).then(() => this.router.navigate([route]));
   
      this.modalResponse.emit();
  }

  private listRecent() {

    var promise = new Promise((resolve, reject) => {
      this.searchResultService.listRecent(this.userId).subscribe(
        response => {
          this.recentList = response as IRecentResult[];
          resolve(null);
        },
        () => {
          reject();
        }
      );
    });
  }

  public searchRecent(recent: IRecentResult) {
    this.searchTerm = recent.description
    this.FilteredOption = recent.type.toString();
    this.search();
  }

  private removeRecent(recent: IRecentResult) {
    this.searchResultService.deleteRecent(recent).subscribe(() => {
      this.listRecent();
    });
  }

  searchTermChange() {
    if (this.searchTerm === "") {
      this.searchResults = [];
    }
  }

  keyDown(event: KeyboardEvent) {
    if(this.searchResults && this.searchResults.length > 0){
      if(this.arrowkeyLocation >= this.searchResults.length) {
        this.arrowkeyLocation = 0
      }
      switch (event.keyCode) {
          case 13 : // this is the ascii of arrow enter
                  this.searchResultClick(this.searchResults[this.arrowkeyLocation]);
                  break;
          case 38: // this is the ascii of arrow up
                  this.arrowkeyLocation--;
                  break;
          case 40: // this is the ascii of arrow down
                  this.arrowkeyLocation++;
                  break;
      }
      document.getElementsByClassName("active")[0].scrollIntoView();
    }
  }
}
