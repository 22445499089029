import { Component, Input } from '@angular/core';
import { Alteration, CoverageChange, DistributionChange } from 'src-private/app/areas/dealers/models/bonus-bucks.Models';
import { Country } from 'src-private/app/enums/bonus-bucks.enums';

@Component({
  selector: 'app-change',
  templateUrl: './change.component.html',
  styleUrls: ['./change.component.scss']
})
export class ChangeComponent {

  @Input() alteration: Alteration;

  constructor() { }

  isCoverage(): boolean {
    return this.alteration instanceof CoverageChange;
  }

  isDistribution(): boolean {
    return this.alteration instanceof DistributionChange;
  }

  get isSin(): boolean {
    return this.alteration.NewValue.userRegistrationFG.sin !== undefined;
  }

  getChangeValueOld(change) {
    if (change.key === 'country') {
      return change.value[0] === 1 ? 'Canada' : 'United States';
    } else if (change.key === 'paymentType') {
      return change.value[0] === 0 ? 'Cheque' : 'EFT';
    } else {
      return change.value[0];
    }
  }

  getChangeValueNew(change) {
    if (change.key === 'country') {
      return change.value[1] === 1 ? 'Canada' : 'United States';
    } else if (change.key === 'paymentType') {
      return change.value[1] === 0 ? 'Cheque' : 'EFT';
    } else {
      return change.value[1];
    }
  }

  getChangeKey(change, type) {
    switch (change.key) {
      case 'currentDistribution':
        if (type === 'old') {
          return 'Old Distribution :';
        } else {
          return 'New Distribution :';
        }
      case 'paymentType':
        return 'Payment Type :';
      case 'postalZipCode':
        return 'Postal / Zip Code :';
      case 'Province/State':
        return 'Province / State :';
      case 'recipientTypeId':
        return 'Recipient Type Id :';
      case 'craNum':
        return 'CRA Number :';
      case 'institutionCheck':
        return 'Institution / Check :';
      case 'branchRouting':
        return 'Branch / Routing # :';
      default:
        return change.key + ' :';
    }
  }

  isCanada(countryCode: number): boolean { return countryCode === Country.Canada; }

}
