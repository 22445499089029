import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CacheHeader, RequestCacheService } from './request-cache.service';

const disableCache = false;

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(private cache: RequestCacheService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    function sendRequest(
      req: HttpRequest<any>,
      next: HttpHandler,
      cache: RequestCacheService): Observable<HttpEvent<any>> {

      // Remove our custom header which will not be understood by the server
      req = req.clone({ headers: req.headers.delete(CacheHeader) });

      return next.handle(req).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            cache.put(req, event);
          }
        })
      );
    }

    // continue if not cachable.
    if (!CacheInterceptor.isCacheable(req)) { return next.handle(req); }

    const cachedResponse = this.cache.get(req);
    return cachedResponse ?
      of(cachedResponse) : sendRequest(req, next, this.cache);
  }

  private static isCacheable(req: HttpRequest<any>) {
    return (
      !disableCache &&
      req.method.toLowerCase() === 'get' &&
      req.headers.get(CacheHeader) === 'true'
    );
  }
}
