import { Component, OnInit, Input, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ProvinceState } from 'src-private/app/areas/dealers/models/bonus-bucks.Models';
import { ProvinceStateService } from 'src-private/app/services/province-states.service';
import { Country } from 'src-private/app/enums/country.enum';
import { PaymentMethod, UserType, RecipientType, DistributionType } from 'src-private/app/enums/bonus-bucks.enums';
import { Dealer } from '../../../../models/dealer.model';
import { IconDefinition, faArrowRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss']
})
export class UserRegistrationComponent implements OnInit {

  @Input() distributionForm: UntypedFormGroup;
  @Input() distType: number;
  @Input() userType: string;
  @Input() dealer: Dealer;

  private provinces: Array<ProvinceState>;
  private states: Array<ProvinceState>;

  constructor(private provStateService: ProvinceStateService, private fb: UntypedFormBuilder, private cd: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userType) {
      this.userTypeChanged(changes.userType.currentValue);
    }
  }

  ngOnInit() {
    this.getProvinceState();
    this.toggleRecipients();
  }

  userTypeChanged(val: string) {
    const userType = UserType[val];
    if (userType === UserType.Dealer) {
      this.recipientTypeControl.patchValue(RecipientType.Dealer);
    } else if (userType === UserType.HoldingCompany) {
      this.recipientTypeControl.patchValue(RecipientType.HoldingCompany);
    } else if (userType === UserType.User) {
      this.recipientTypeControl.patchValue(RecipientType.Individual);
    }
  }

  onCountryChange() {
    this.provinceStateControl.patchValue(null);
    this.postalControl.patchValue(null);
  }

  getProvinceState(): void {
    this.provStateService.currentStates.subscribe((response: ProvinceState[]) => {
      this.states = response.filter(proStat => proStat.CountryId === Country.UnitedStates);
      this.provinces = response.filter(proStat => proStat.CountryId === Country.Canada);
    });
  }

  _keyDown(event: KeyboardEvent) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (keyCode === 8 && this.sinControl.value.includes('x')) {
      this.sinControl.reset();
      this.sinControl.markAsDirty();
    }

    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }

  capitalize() {
    this.postalControl.patchValue(this.postalControl.value.toUpperCase());
  }

  titleCase() {
    this.cityControl.patchValue(this.cityControl.value.replace(/\w\S*/g,
      function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1);
      }
    ));
  }

  toggleRecipients() {
    if (this.nameControl.value === 'Holding Company' || this.nameControl.value === null) {
      this.recipientControl.enable();
    } else {
      this.recipientControl.disable();
    }
  }

  get arrow(): IconDefinition {
    return faArrowRight;
  }

  get isIndividual() : boolean {
    return this.distType === DistributionType.Individual
  }

  get isPercentage() : boolean {
    return this.distType === DistributionType.Percentage
  }

  get control() : UntypedFormGroup { return <UntypedFormGroup>this.distributionForm.get('userRegistrationFG'); }
  get previousDistributionControl() { return this.control.get('previousDistribution'); }
  get currentDistributionControl() { return this.control.get('previousDistribution'); }
  get nameControl() { return this.distributionForm.get('name'); }
  get phoneControl() { return this.control.get('phone'); }
  get addressControl() { return this.control.get('address'); }
  get emailControl() { return this.control.get('email'); }
  get countryControl() { return this.control.get('country'); }
  get isCanada() : boolean { return this.countryControl.value === Country.Canada; }
  get postalControl() { return this.control.get('postalZipCode'); }
  get cityControl() { return this.control.get('city'); }
  get postalZipCodePlaceholder() { return this.isCanada ? 'Postal Code' : 'Zip Code'; }
  get postalZipCodeMask() { return this.isCanada ? 'S0S0S0' : '99999'; }
  get provinceStateControl() { return this.control.get('provinceState'); }
  get provincesOrStates() { return this.isCanada ? this.provinces : this.states; }
  get paymentMethodControl() { return this.control.get('paymentType'); }
  get sinControl() { return this.control.get('sin'); }
  get craNumControl() { return this.control.get('craNum'); }
  get recipientControl() { return this.control.get('recipient'); }
  get recipientTypeControl() { return this.control.get("recipientTypeId") };
  get paymentMethod() { return PaymentMethod; }
  get recipientTypeIdControl() { return this.control.get('recipientTypeId'); }
}
