<p class="empty-message" *ngIf="!latestApplication && initialLoadComplete">None</p>
<table class="table datatable" *ngIf="latestApplication && initialLoadComplete">
  <thead>
    <tr>
      <th>Coverage</th>
      <th *ngIf="bp > 3">Salesperson</th>
      <th *ngIf="bp > 2">Submitted Date</th>
    </tr>
  </thead>
  <tbody>
    <tr (click)="expanded = !expanded">
      <td>{{latestApplication.shortLabel}}</td>
      <td *ngIf="bp > 3">{{latestApplication.firstName + ' ' + latestApplication.lastName}}</td>
      <td *ngIf="bp > 2">{{latestApplication.submittedDate | date: 'medium'}}</td>
    </tr>
    <tr class="responsive-row"
        *ngIf="bp < 4 && expanded">
      <td colspan="3">
        <table class="responsive-table">
          <tbody>
            <tr *ngIf="bp < 4">
              <td class="label">Salesperson</td>
              <td>{{latestApplication.firstName + ' ' + latestApplication.lastName}}</td>
            </tr>
            <tr *ngIf="bp < 3">
              <td class="label">Submitted Date</td>
              <td>{{latestApplication.submittedDate | date: 'medium'}}</td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>
