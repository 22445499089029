import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DatePipe } from '@angular/common'
import { Title } from "@angular/platform-browser";
import { ClaimsService } from '../../services/claims.service';
import { CustomerService } from '../../services/customer.service';
import { ICustomer } from '../../interfaces/customer.interface';
import { CustomerEditComponent } from './customer-edit/customer-edit.component';
import { faEdit, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { BatchFileType, Country } from 'src-private/app/enums/bonus-bucks.enums';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src-private/app/areas/account/services/account.service';
import { ProvinceStateService } from 'src-private/app/services/province-states.service';
import { Subject, of, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CellBuilder } from 'src-private/app/shared/table-adapter/cell-builder/cell-builder';
import { CellType } from 'src-private/app/shared/table-adapter/cell-builder/cell-type';
import { FrameworkComponent } from 'src-private/app/shared/framework/framework.component';
import { ToastrService } from 'ngx-toastr';
import { BatchFile } from 'src-private/app/areas/dealers/models/batch-file.model';
import { DealersService } from 'src-private/app/areas/dealers/services/dealers.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from 'src-private/environments/environment';
import { CustomerNotesService } from '../../services/customer-notes.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent extends FrameworkComponent implements OnInit, AfterViewInit {

  public faEdit = faEdit;
  public faPlus = faPlus;

  public customerId: number
  public claimsId: number;

  public customer$: BehaviorSubject<ICustomer>;

  public postalCodeStateLabel: string
  public postalCodeStateMask: string;
  public provinceStateLabel: string;
  private userId: string;
  public isAdjuster: boolean;
  private emailRegx = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}';
  public isLoading = true;

  private ngUnsubscribe: Subject<any> = new Subject();
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private claimsService: ClaimsService,
    private customerService: CustomerService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private provinceStateService: ProvinceStateService,
    private noteService: CustomerNotesService,
    private dealersService: DealersService,
    private titleService: Title) {
    super();
    this.claimsId = this.route.snapshot.data['claimId']
  }

  public claimsTableColumns: CellBuilder[] = [
    new CellBuilder("ID", "enterpriseId", CellType.text),
    new CellBuilder("Created Date", "claimsDateEntered", CellType.date),
    new CellBuilder("Closed Date", "claimsDateClosed", CellType.date),
    new CellBuilder("Assigned Adjuster", "claimsOwner", CellType.text),
    new CellBuilder("Status", "claimsStatus", CellType.text),
    new CellBuilder("Warranty ID", "warrantyIdFull", CellType.text),
    new CellBuilder("Paid", "claimTotal", CellType.currency)
  ];

  ngOnInit() {
    this.blockUI.start();
    this.customer$ = new BehaviorSubject<ICustomer>({} as ICustomer);
    this.customerId = +this.route.snapshot.paramMap.get('id');
    this.userId = this.accountService.getUserId();
    this.isAdjuster = this.accountService.isAdjuster() || this.accountService.isClaimsManager() || this.accountService.isAssistantClaimsManager();
    this.titleService.setTitle("Customer - " + this.customerId);
    let data = { adjusterId: this.accountService.getUserId(), claimId: this.claimsId, customerId: this.customerId, createdDate: new Date(), note: 'Viewed Customer', id: 0, adjusterName: null}; 
    this.noteService.add(data)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(() => {});
  }

  ngAfterViewInit() {
    this.loadCustomer();
    super.build("CustomerComponent", 'customer-component')
  }

  reload(data: ICustomer): void {
    this.customer$.next(data);

    if (data.countryId == 1) {
      this.customer$.value.countryName = "Canada";
    }

    else if (data.countryId == 2) {
      this.customer$.value.countryName = "USA";
    }

    this.updatePostalCodeStateLabel();
    this.updateProvinceStateLabel();
  }

  editCustomer() {
    let oldData = JSON.parse(JSON.stringify(this.customer$.value))
    let dialogRef = this.dialog.open(CustomerEditComponent, { width: '550px', data: this.customer$.value });
    dialogRef.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (res.data) {
          const data: ICustomer = res.data;
          if (res.data['country'] == "Canada") {
            data.countryId = 1;
          }
          else if (res.data['country'] == "USA") {
            data.countryId = 2;
          }
          if (res.data.toString().toLowerCase() != "cancel") {
            this.provinceStateService.retrieveProvinceByAbbreviation(res.data['province'])
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe(
                response => {
                  var provinceData = response[0] as number
                  data.provinceStateId = provinceData;
                  data.modifiedDate = new Date();
                  data.modifiedBy = this.userId;

                  if (res.data.status == undefined) {
                    this.customerService.update(data)
                      .pipe(takeUntil(this.ngUnsubscribe))
                      .subscribe(result => {
                        if(oldData.address != data.address){
                          var batchfileModel = new BatchFile()
                          batchfileModel.ItemId = oldData.customerId;
                          batchfileModel.CreatedBy = this.accountService.getUserId();
                          batchfileModel.BatchFileTypeId = BatchFileType.VendorCustomerDetails
                          this.dealersService.addBatchFile(batchfileModel).subscribe();
                        }
                        this.blockUI.stop();
                        this.toastr.success("Customer successfully updated. ", 'Update Customer');
                        this.reload(data);
                      },
                      error => {
                        this.toastr.error(environment.messages.apiError, "Unable to update customer");
                      })
                  }
                  else if (res.data.status == 'refresh') {
                    this.blockUI.stop();
                    this.reload(data);
                  }
                }
              )
          }
        }
      })
  }

  loadCustomer() {
    if (this.customerId) {

      this.customerService.retrieve(this.customerId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(customer => {
          this.loadProvinceName(customer.provinceStateId);
          this.reload(customer);
          this.isLoading = false;
          this.blockUI.stop();
        },
        error => {
          this.toastr.error(environment.messages.apiError, "Unable to retrieve customer");
        });
    }
  }

  loadProvinceName(provinceId: number) {
    this.provinceStateService.retrieveProvinceById(provinceId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        response => {
          var data = response[0] as string;
          this.customer$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.customer$.value.provinceName = data);
        }
      );
  }

  updatePostalCodeStateLabel(): void {
    this.postalCodeStateLabel = this.isCanada ? 'Postal Code' : 'Zip Code'
    this.postalCodeStateMask = this.isCanada ? 'S0S 0S0' : '00000'
  }

  updateProvinceStateLabel(): void {
    this.provinceStateLabel = this.isCanada ? 'Province' : 'State'
  }

  get isCanada(): boolean {
    return this.customer$.value.countryName === Country[Country.Canada]
  }

  get isReadOnly(): boolean {
    return this.IsClaimClosed
      || !(this.accountService.isClaimsManager()
        || this.accountService.isAssistantClaimsManager()
        || this.accountService.isAdjuster())
  }
  public get isValidEmail(): boolean {
    return this.customer$.value.email != null && this.customer$.value.email.match(this.emailRegx) != null;
  }

  public get IsClaimClosed(): boolean{
    return this.claimsService.getSelectedClaim?.IsClaimClosed
  }

  public get CanEditClosedClaim(): boolean{
    return this.accountService.isClaimsManager();
  }


  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
