import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { AccountService } from 'src-private/app/areas/account/services/account.service';
import { ClaimsService } from '../../services/claims.service';
import { CellBuilder } from "src-private/app/shared/table-adapter/cell-builder/cell-builder";
import { IClaim } from "../../interfaces/claim";
import { ClaimsRetrieve } from "../../models/claims-retrieve.model";
import { TableAdapterComponent } from "src-private/app/shared/table-adapter/table-adapter.component";
import { ConfirmDialogComponent } from "src-private/app/shared/confirm-dialog/confirm-dialog.component";
import { DialogType } from "src-private/app/enums/dialog-type";
import { Claim } from "../../models/claim-model";
import { ClaimStatus } from "src-private/app/enums/claims.enums";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { environment } from 'src-private/environments/environment';
import { ToastrService } from "ngx-toastr";
import { FrameworkComponent } from "src-private/app/shared/framework/framework.component";
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-claims-table',
  templateUrl: './claims-table.component.html',
  styleUrls: ['./claims-table.component.scss']
})
export class ClaimsTableComponent extends FrameworkComponent implements OnInit {

  public faPlus = faPlus;
  
  private ngUnsubscribe$: Subject<boolean> = new Subject();

  @Input() columns: CellBuilder[];
  @Input() warrantyId: number;
  @Input() customerId: number;
  @Input() canAdd: boolean;

  @ViewChild("claimsTable")
  table: TableAdapterComponent<IClaim>;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private accountService :AccountService,
    private claimsService: ClaimsService,
    private toastr: ToastrService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.build("ClaimsTableComponent", "claim-table-component");
  }

  ngAfterViewInit() { 
    this.refresh();
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }

  refresh() {
    const req = new ClaimsRetrieve();
    if (this.columns.find(c => c.cellName === "Paid")) {
      req.customerClaims = true;
    }
    if (this.warrantyId) {
      req.warrantyId = this.warrantyId;
    }
    if (this.customerId) {
      req.customerId = this.customerId;
    }
    this.table.refresh(this.claimsService.retrieveAll(req));
  }

  addClaim() {
    let data = new Claim();
    data.warrantyId = this.warrantyId;
    data.customerId = this.customerId;
    data.assignedAdjuster = this.accountService.getUserId();
    data.claimsStatus = ClaimStatus[3].toString();

    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: "part-dialog",
      width: "400px",
      autoFocus: true,
      data: {
        message:
          "You are about to add a new claim for this warranty. Do you want to continue?",
        type: DialogType[0].toString(),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.claimsService
          .claimsAdd(data)
          .pipe(takeUntil(this.ngUnsubscribe$))
          .subscribe(res => {
            if (res) {
              this.toastr.success("Claim successfully added.");
              this.router
                .navigateByUrl("/")
                .then(() =>
                  this.router.navigate(["claims", res, "detail"])
                );
            }
          },
          error => {
            this.toastr.error(environment.messages.apiError, "Unable to add claim");
          }
        );
      } else {
        dialogRef.close();
      }
    });
  }
  
  get isAdjuster() : boolean  {
    return this.accountService.isAdjuster() || this.accountService.isClaimsManager() || this.accountService.isAssistantClaimsManager();
  }

  get isClaimClosed(): boolean{
    return this.claimsService.getSelectedClaim?.IsClaimClosed;
  }

  get canEditClosedClaim(): boolean{
    return this.accountService.isClaimsManager();
  }
}
