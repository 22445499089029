<mat-dialog-content>
  <div class="container-fluid dialogContent">
    <div class="row">
      <div class="col-md-12">
        Do you wish to unapprove this line item?
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        Repair Centre:
      </div>
      <div class="col-md-6">
        {{partData.repairCentreName}}
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        Subtotal:
      </div>
      <div class="col-md-6">
        {{rowTotal}}
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        GST:
      </div>
      <div class="col-md-6">
        {{gstAmount}}
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        Total:
      </div>
      <div class="col-md-6 total">
        {{rowTotalWithTax}}
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        Invoice #:
      </div>
      <div class="col-md-6 mb-0">
        <div>{{invoiceNumber}}</div>
      </div>
    </div>

    <hr class="line" />

  </div>
</mat-dialog-content>

<mat-dialog-actions class="float-right">
  <button mat-dialog-close class="btn custom-button-red" type="button" (click)="cancel()">
    <fa-icon [icon]="faWindowClose" class="pr-2 text-danger"></fa-icon>Cancel
  </button>
  <button class="btn custom-button-green" type="button" (click)="save()">
    <fa-icon [icon]="faSave" class="pr-2 text-success"></fa-icon>Unapprove
  </button>
</mat-dialog-actions>
