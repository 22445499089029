import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { RepairCentreEditComponent } from '../../claims/components/repair-centre/repair-centre-edit/repair-centre-edit.component';
import { RepairCentre } from '../../claims/models/repair-centre.model';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ClaimsService } from '../../claims/services/claims.service';
import { OdometerAddDialogComponent } from '../../claims/components/odometer-add-dialog/odometer-add-dialog.component';
import { AccountService } from '../../account/services/account.service';
import { WarrantyService } from '../../claims/services/warranty.service';


@Component({
  selector: 'app-quick-add',
  templateUrl: './quick-add.component.html',
  styleUrls: ['./quick-add.component.scss']
})
export class QuickAddComponent implements OnInit {
  @Output() modalResponse: EventEmitter<string> = new EventEmitter<string>();

  faPlus = faPlus
  warrantyId: number = null

  @HostListener('document:keydown.r', ['$event'])
  openAddRepair(event: KeyboardEvent) {
    event.preventDefault();
    this.openRepairCenterDialog()
  }

  @HostListener('document:keydown.d', ['$event'])
  openAddDealer(event: KeyboardEvent) {
    event.preventDefault();
    this.navigateToDealer();
  }

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private claimService: ClaimsService,
    private accountService: AccountService,
    private warrantyService: WarrantyService
  ) { }

  ngOnInit() {
    this.setOdometerWarrantyID()
  }

  public openRepairCenterDialog() {
    let repairCenterDialog = this.dialog.open(RepairCentreEditComponent, { width: '50vw', data: new RepairCentre() });
    repairCenterDialog.componentInstance.isEdit = false;
    this.modalResponse.emit();

    repairCenterDialog.afterClosed().subscribe(res => {
      if (res.data) {
        this.router.navigate(['repair-centre', res.data.id]);
      }
    })
  }

  public navigateToDealer() {
    this.modalResponse.emit();
    this.router.navigate(['dealers', 'dealer-info'], {skipLocationChange: true, replaceUrl: true});
  }

  OpenOdometerAddDialog() {
    let warrantID: number = this.claimService.getSelectedClaim?.warrantyId ?? this.warrantyId

    if (warrantID != null && !isNaN(warrantID)) {
      let odometerAddDialog = this.dialog.open(OdometerAddDialogComponent, { width: 'fit-content', data: warrantID });
      odometerAddDialog.componentInstance.onClaimChanged.subscribe((data) => {
        this.modalResponse.emit(data);
      });
    }
    this.modalResponse.emit();
  }

  setOdometerWarrantyID() {
    let route = this.router.url;
    if (route.includes('warranty')) {
      this.warrantyId = this.warrantyService.Warranty?.id ?? null
    }
    else{
      this.warrantyId = null
    }

  }

  public get canShownOdometerButton(): boolean {
    let route = this.router.url;
    return ((route.includes('claims') && this.claimService.getSelectedClaim != null) || route.includes('warranty'))
  }

  public get isAdjuster(): boolean {
    return this.accountService.isAdjuster() || this.accountService.isClaimsManager() || this.accountService.isAssistantClaimsManager();
  }
}
