<div [ngClass]="{content : !claimId, standalone : !claimId}">
    <div class="row">
        <div class="col-xl-5">
            <div class="col-lg">
                <form *ngIf="repairCentre">
                    <div *ngIf='isDoNotUse' class="statusAlert">
                        <label class="statusLabel">DO NOT USE</label>
                    </div>
                    <app-repair-centre-info [repairCentre]="repairCentre" (onEdit)="onEdit($event)"></app-repair-centre-info>
                </form>
            </div>
        </div>
        <div class="col-xl-7">
            <div class="col-lg" [ngClass]="{OnStatusAlert : isDoNotUse}">
                <app-repair-centre-notes [repairCentreId]="repairCentreID" [columns]="repairCentreNotesTableColumns"></app-repair-centre-notes>
            </div>
        </div>
    </div>
    
    <div class="row" *ngIf="repairCentre">
        <div class="col-lg-12">
            <div class="col-lg">
                <fieldset class="fieldset-form">
                    <legend>Service Information</legend>
                    <div>
                        <mat-card class="matCardBKColor ServiceMargin">
                            <mat-card-content>
                                <div class="row flex-column CardPad">
                                    <div class="contentPad">
                                        <div *ngIf= "repairCentre.status != null && (repairCentre.status != '' && repairCentre.status != ' ')">  
                                            Status :  {{repairCentre.status}}   
                                        </div>
                                        <div *ngIf="repairCentre.type != null && (repairCentre.type != '' && repairCentre.type != ' ')" >
                                            Type : {{repairCentre.type}}
                                        </div>
                                        <div *ngIf="repairCentre.hourlyRate != null && (repairCentre.hourlyRate != '' && repairCentre.hourlyRate != ' ')" >
                                            Hourly Rate : {{repairCentre.hourlyRate | currency}}
                                        </div>
                                        <div *ngIf="repairCentre.dealerName != null && (repairCentre.dealerName != '' && repairCentre.dealerName != ' ')" >
                                            Affiliated Dealer :  <a [href]="repairCentre.dealerWebsite | http" [target]="_blank" [ngStyle]="{'pointer-events': repairCentre.dealerWebsite == null || repairCentre.dealerWebsite == undefined ?'none':''}">
                                                {{repairCentre.dealerName}}
                                            </a>
                                        </div>
                                        <div *ngIf="repairCentre.yearsInBusiness!= null && (repairCentre.yearsInBusiness!= '' && repairCentre.yearsInBusiness!= ' ')" >
                                            Years in business :  {{repairCentre.yearsInBusiness}}
                                        </div>
                                        <div *ngIf="repairCentre.numberOfLicensedTech!= null && (repairCentre.numberOfLicensedTech!= '' && repairCentre.numberOfLicensedTech!= ' ')" >
                                            Number of licensed techs :  {{repairCentre.numberOfLicensedTech}}
                                        </div>
                                        <div *ngIf="repairCentre.numberOfHoists != null && (repairCentre.numberOfHoists != '' && repairCentre.numberOfHoists != ' ')" >
                                            Number of hoists :  {{repairCentre.numberOfHoists}}
                                        </div>
                                        <div *ngIf="repairCentre.numberOfBays != null && (repairCentre.numberOfBays != '' && repairCentre.numberOfBays != ' ')" >
                                            Number of bays :  {{repairCentre.numberOfBays}}
                                        </div>
                                        <div *ngIf="repairCentre.retailDoorRate != null && (repairCentre.retailDoorRate != '' && repairCentre.retailDoorRate != ' ')" >
                                            Retail Door rate :  {{repairCentre.retailDoorRate | currency}}
                                        </div>
                                        <div *ngIf="repairCentre.globalDoorRate != null && (repairCentre.globalDoorRate != '' && repairCentre.globalDoorRate != ' ')" >
                                            Global Door rate :  {{repairCentre.globalDoorRate | currency}}
                                        </div>
                                        <div *ngIf="repairCentre.afterMarketPartsDiscount != null && (repairCentre.afterMarketPartsDiscount != '' && repairCentre.afterMarketPartsDiscount != ' ')" >
                                            After Market Parts Discount :  {{repairCentre.afterMarketPartsDiscount | currency}}
                                        </div>
                                        <div *ngIf="repairCentre.oemPartsDiscount != null && (repairCentre.oemPartsDiscount != '' && repairCentre.oemPartsDiscount != ' ')" >
                                            OEM Parts Discount :  {{repairCentre.oemPartsDiscount | currency}}
                                        </div>
                                        <div *ngIf="repairCentre.general != null && (repairCentre.general != false || repairCentre.electrical !=  false || repairCentre.diesel != false || repairCentre.airConditioning !=  false  )" >
                                        Type of repairs  :  {{repairCentre.general? (repairCentre.electrical !=  false || repairCentre.diesel != false || repairCentre.airConditioning !=  false|| repairCentre.electronicDiagnosis != false )?'General, ':'General':'' }} 
                                                            {{repairCentre.electrical?( repairCentre.diesel != false || repairCentre.airConditioning !=  false )?' Electrical,  ':' Electrical':''}}
                                                            {{repairCentre.diesel? (repairCentre.airConditioning !=  false )?' Diesel, ':'Diesel':''}} 
                                                            {{repairCentre.airConditioning?( repairCentre.electronicDiagnosis !=  false )?' Air Conditioning,  ':'Air Conditioning':''}} 
                                                            {{repairCentre.electronicDiagnosis?'Electronic Diagnosis ':''}}
                                        </div>
                                    
                                        <div *ngIf="repairCentre.transmissionRebuild != null && (repairCentre.transmissionRebuild != '' || repairCentre.transmissionRnR != ' ')" >
                                            Transmission :  {{repairCentre.transmissionRebuild?'Rebuild'.concat(repairCentre.transmissionRnR ?' and ':''):  ''}} {{repairCentre.transmissionRnR?'RnR': ''}}
                                        </div>
                                        <div *ngIf="repairCentre.oemPartsDiscount != null && (repairCentre.differentialRnR != false || repairCentre.differentialRebuild != false)" >
                                            Differential :  {{repairCentre.differentialRebuild?'Rebuild'.concat(repairCentre.differentialRnR ?' and ':''):  ''}} {{repairCentre.differentialRnR?'RnR': ''}}
                                        </div>
                                        <div *ngIf="repairCentre.oemPartsDiscount != null && (repairCentre.transferCaseRebuild != false || repairCentre.transferCaseRnR != false)" >
                                            Transfer Case :  {{repairCentre.transferCaseRebuild ?'Rebuild'.concat(repairCentre.transferCaseRnR ?' and ':''):  ''}} {{repairCentre.transferCaseRnR?'RnR': ''}}
                                        </div>
                                        <div *ngIf="repairCentre.otherWarrantyCompanies != null && repairCentre.otherWarrantyCompanies != false " >
                                        Have Other Warranty Companies
                                        </div>
                                        <div *ngIf="repairCentre.towingService != null && (repairCentre.towingService != '' && repairCentre.towingService != ' ')" >
                                            Towing Service :  {{towingservice}}
                                        </div>
                                        <div *ngIf="repairCentre.paymentType != null && (repairCentre.paymentType != '' && repairCentre.paymentType != ' ')" >
                                            Type of payment :  {{repairCentre.paymentType}}
                                        </div>
                                        <div *ngIf="repairCentre.specialtyEquipment != null && (repairCentre.specialtyEquipment != '' && repairCentre.specialtyEquipment != ' ')" >
                                            Types of special equipments :  {{repairCentre.specialtyEquipment}}
                                        </div>
                                        <div *ngIf="repairCentre.specialtyRepairs != null && (repairCentre.specialtyRepairs != '' && repairCentre.specialtyRepairs != ' ')" >
                                            Types of special repairs :  {{repairCentre.specialtyRepairs}}
                                        </div>
                                        <div *ngIf="repairCentre.labourDistance != null && (repairCentre.labourDistance != '' && repairCentre.labourDistance != ' ')" >
                                            Labour Distance :  {{repairCentre.labourDistance}} Km
                                        </div>
                                        <div *ngIf="repairCentre.partDistance != null && (repairCentre.partDistance != '' && repairCentre.partDistance != ' ')" >
                                            Parts Distance :  {{repairCentre.partDistance}} Km
                                        </div>
                                        <div *ngIf="repairCentre.partsTime != null && (repairCentre.partsTime != '' && repairCentre.partsTime != ' ')" >
                                            Parts Time :  {{repairCentre.partsTime}} 
                                        </div>
                                        <div *ngIf="repairCentre.labourTime != null && (repairCentre.labourTime != '' && repairCentre.labourTime != ' ')" >
                                            Labour Time :  {{repairCentre.labourTime}} 
                                        </div>

                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>

</div>
