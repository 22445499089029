import { Component, OnInit, Inject, Output, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PartService } from '../../services/part-service';
import { faSave, faWindowClose, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { fromEvent, Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, filter, map, share } from 'rxjs/operators';
import { PartGroupModel } from '../../models/part-group-model';
import { RepairCentre } from '../../models/repair-centre.model';
import { RepairCentreService } from '../../services/repair-centre.service';
import { IRepairCentre } from '../../interfaces/repair-centre.interface';
import { ClaimTransaction } from '../../models/claim-transaction';
import { Part } from '../../models/part-model';
import { ClaimsTransactionService } from '../../services/claims-transaction.service'
import { ConfirmDialogComponent } from 'src-private/app/shared/confirm-dialog/confirm-dialog.component';
import { AppValidators } from 'src-private/app/validators/app-validators';
import { DialogType } from 'src-private/app/enums/dialog-type';
import { WarrantyService } from '../../services/warranty.service'
import { CustomerService } from '../../services/customer.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ICustomerSearch } from '../../interfaces/customer-search.interface';
import { CustomerSearch } from '../../models/customer-search.model';

@Component({
  selector: 'part-dialog',
  templateUrl: './part-dialog.component.html',
  styleUrls: ['./part-dialog.component.scss']
})

export class PartDialogComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  //Google Map
  geocoder: google.maps.Geocoder;

  // Font Awesome
  public faSave = faSave;
  public faWindowClose = faWindowClose;
  public faTrashAlt = faTrashAlt;

  // Transaction
  public transaction: ClaimTransaction;
  public isUpdate: boolean = false;
  public updateStatus: string

  //  FormGroup
  public partForm: UntypedFormGroup;
  public repairCentreName: UntypedFormControl;
  public partGroupName: UntypedFormControl;
  public description: UntypedFormControl;
  public quantity: UntypedFormControl;
  public used: UntypedFormControl;
  public goodwill: UntypedFormControl;
  public ourPrice: UntypedFormControl;
  public repairCentrePrice: UntypedFormControl;
  public paidPrice: UntypedFormControl;
  public shopKeyLabour: UntypedFormControl;
  public repairCentreLabour: UntypedFormControl;
  public paidLabour: UntypedFormControl;
  public formOther: UntypedFormControl;
  public rebuilt: UntypedFormControl;
  public indicator: UntypedFormControl;

  // Repair Centre
  public repairCentres$: Observable<IRepairCentre[]>
  public repairCentres: RepairCentre[] = [];
  public repairCentresFiltered: RepairCentre[] = [];
  public claimCustomerId: number;

  // Customer
  public customers$: Observable<ICustomerSearch[]>
  public customers: CustomerSearch[] = [];
  public customersFiltered: CustomerSearch[] = [];

  // Part Block/Group
  public partGroups$: Observable<PartGroupModel[]>
  public partGroups: PartGroupModel[] = []
  public partGroupsFiltered: PartGroupModel[] = []
  public partGroupId: number
  public partGroupNameSelected: boolean

  // Part (Description)
  public parts$: Observable<Part[]>
  public parts: Part[] = []
  public partsFiltered: Part[] = []

  public indicatorStatuses: string[] = ['RC', 'CU'];

  //Warranty
  public deductibleAmount: number;

  //Event and API Subscription
  private inputEventSubscription: Subscription;
  private repairCenterLookUpSubscription: Subscription;
  private customerLookUpSubscription: Subscription;

  //Read Only
  public readOnly = false;


  constructor(
    public partService: PartService,
    private claimTransactionService: ClaimsTransactionService,
    public repairCentreService: RepairCentreService,
    private warrantyService: WarrantyService,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<PartDialogComponent>,
    private dialog: MatDialog,
    private customerService: CustomerService,
    @Inject(MAT_DIALOG_DATA) data) {
    this.geocoder = new google.maps.Geocoder();
    this.transaction = data.transaction;
    this.claimCustomerId = data.claimCustomerId;
    
    if (data.readOnly) this.readOnly = data.readOnly;

    if (this.transaction.partId != undefined){
      this.isUpdate = true
    }
  }

  ngOnInit() {
    this.setRepairCenterInputChangeEvent();
    this.setCustomerInputChangeEvent();

    if(!this.isUpdate){
      if (this.transaction.claimId != undefined) {
        this.retrieveRepairCenter(this.transaction.claimId)
      } 
      if (this.transaction.warrantyId != undefined) {
        this.retrieveWarrantyDeductible(this.transaction.warrantyId)
      }
    }
    else{
      if (this.transaction.indicator == "RC") {
        let repair = new RepairCentre();
        repair.id = this.transaction.repairCentreId;
        repair.name = this.transaction.repairCentreName
        this.repairCentres.push(repair);
        this.selectRepairCentre(repair, {isUserInput:true})
      }
      else if (this.transaction.indicator == "CU") {
        let customer = new CustomerSearch();
        customer.customerId = this.transaction.customerId;
        customer.fullName = this.transaction.customerName;
        this.customers.push(customer);
        this.selectCustomer(customer, {isUserInput:true})
      }
    }
    this.loadPartGroups();

    this.partForm = this.formBuilder.group({
      // Row One
      repairCentreName: ['', Validators.required],
      customerName: [''],
      // Row Two
      partGroupName: ['', this.isUpdate ? Validators.nullValidator : Validators.required],
      description: ['', this.isUpdate ? Validators.nullValidator : Validators.required],
      quantity: ['', Validators.required],
      usedPart: [false],
      rebuilt: [false],
      goodwill: ['', Validators.required],
      // Row Three
      ourPrice: ['', Validators.required],
      repairCentrePrice: ['', Validators.required],
      paidPrice: ['', Validators.required],
      shopKeyLabour: ['', Validators.required],
      repairCentreLabour: ['', Validators.required],
      paidLabour: ['', Validators.required],
      formOther: [''],
      deductible: ['', Validators.required],
      indicator: ['', Validators.required]
    }); // FormBuilder

    
    this.partForm.patchValue({
      repairCentreName: this.transaction.repairCentreName,
      customerName: this.transaction.customerName,
      partGroupName: this.transaction.partGroupName,
      description: this.transaction.partDescription,
      quantity: this.transaction.quantity,
      usedPart: this.transaction.used,
      goodwill: this.transaction.goodwill,
      ourPrice: (this.transaction.ourPrice != undefined ? this.transaction.ourPrice : 0),
      repairCentrePrice: (this.transaction.repairCentrePrice != undefined ? this.transaction.repairCentrePrice : 0),
      paidPrice: (this.transaction.paidPrice != undefined ? this.transaction.paidPrice : 0),
      shopKeyLabour: (this.transaction.shopKeyLabour != undefined ? this.transaction.shopKeyLabour : 0),
      repairCentreLabour: (this.transaction.repairCentreLabour != undefined ? this.transaction.repairCentreLabour : 0),
      paidLabour: (this.transaction.paidLabour != undefined ? this.transaction.paidLabour : 0),
      formOther: (this.transaction.other != undefined ? this.transaction.other : 0),
      deductible: this.transaction.deductible,
      rebuilt: this.transaction.rebuilt,
      indicator: (this.transaction.indicator != undefined ? this.transaction.indicator : "RC"),
    });

    if (this.partForm.value.indicator == "RC") {
      this.partForm.get("repairCentreName")?.enable();
      this.partForm.get("customerName")?.disable();
      this.partForm.controls["customerName"].setValue("");
    }
    else if (this.partForm.value.indicator == "CU") {
      this.partForm.get("repairCentreName")?.disable();
      this.partForm.get("customerName")?.enable();
      this.partForm.controls["repairCentreName"].setValue("");
    }

    if (this.readOnly) {
      this.partForm.get('repairCentreName')?.disable();
      this.partForm.get('customerName')?.disable();
      this.partForm.get('partGroupName')?.disable();
      this.partForm.get('description')?.disable();
      this.partForm.get('quantity')?.disable();
      this.partForm.get('usedPart')?.disable();
      this.partForm.get('goodwill')?.disable();
      this.partForm.get('ourPrice')?.disable();
      this.partForm.get('repairCentrePrice')?.disable();
      this.partForm.get('paidPrice')?.disable();
      this.partForm.get('shopKeyLabour')?.disable();
      this.partForm.get('repairCentreLabour')?.disable();
      this.partForm.get('paidLabour')?.disable();
      this.partForm.get('formOther')?.disable();
      this.partForm.get('deductible')?.disable();
      this.partForm.get('rebuilt')?.disable();
      this.partForm.get('indicator')?.disable();
    }

    this.dialogRef.beforeClosed().subscribe(()=>{
      this.unSubscribeEventAndAPI();
    })
  }

  async checkData(status: string) {
    if (AppValidators.validateAll(this.partForm)) {
      this.transaction.partGroupId = this.partGroupId;
      this.transaction.quantity = this.partForm.value.quantity;
      this.transaction.used = this.partForm.value.usedPart;
      this.transaction.rebuilt = this.partForm.value.rebuilt;
      this.transaction.goodwill = this.partForm.value.goodwill;
      this.transaction.ourPrice = this.partForm.value.ourPrice;
      this.transaction.repairCentrePrice = this.partForm.value.repairCentrePrice;
      this.transaction.paidPrice = this.partForm.value.paidPrice;
      this.transaction.shopKeyLabour = this.partForm.value.shopKeyLabour;
      this.transaction.repairCentreLabour = this.partForm.value.repairCentreLabour;
      this.transaction.other = this.partForm.value.formOther;
      this.transaction.paidLabour = this.partForm.value.paidLabour;
      this.transaction.deductible = this.partForm.value.deductible;
      this.transaction.indicator = this.partForm.value.indicator;

      if(this.transaction.indicator == 'RC'){
        this.transaction.repairCentreId = this.repairCentresFiltered.length > 0 ? this.repairCentresFiltered[0].id : null;
        this.transaction.customerId = null;
      }
      else if(this.transaction.indicator == 'CU'){
        this.transaction.customerId = this.customersFiltered.length > 0 ? this.customersFiltered[0].customerId : null;
        this.transaction.repairCentreId = null;
      }

      this.transaction.partId = this.partsFiltered.length > 0 ? this.partsFiltered[0].partId : 0;
      this.transaction.partName = this.partsFiltered.length > 0 ? this.partsFiltered[0].label : "";
      if (!this.isUpdate && this.partsFiltered.length == 0) {
        this.partForm.controls["description"].setErrors({ required: true });
        this.partForm.controls["description"].setValue("");
        if (this.partGroupId == undefined || this.partGroupId == null) {
          this.partForm.controls["partGroupName"].setErrors({ required: true });
          this.partForm.controls["partGroupName"].setValue("");
        }
      }
      if (this.repairCentresFiltered.length == 0 && this.transaction.indicator == 'RC') {
        this.partForm.controls["repairCentreName"].setErrors({ required: true });
        this.partForm.controls["repairCentreName"].setValue("");
      }
      if (this.customersFiltered.length == 0 && this.transaction.indicator == 'CU') {
        this.partForm.controls["customerName"].setErrors({ required: true });
        this.partForm.controls["customerName"].setValue("");
      }
      if (!this.isUpdate && (this.partsFiltered.length == 0 || (this.repairCentresFiltered.length == 0 && this.transaction.indicator == 'RC') || (this.customersFiltered.length == 0 && this.transaction.indicator == 'CU'))) {
        return;
      }
      if (this.isUpdate) {
        this.claimTransactionService.retrieveById(this.transaction.claimTransactionId).subscribe(
          data => {
            if (Object.entries(this.transaction).toString() === Object.entries(data[0]).toString()) {
              //If data has not been modified don't save
              this.dialogRef.close({ data: "Cancel" });
            }
            else if (data[0].version == this.transaction.version) {
              this.save(status);
            }
            else {
              let dialogRef = this.dialog.open(ConfirmDialogComponent, {
                panelClass: 'part-dialog',
                width: '400px',
                autoFocus: true,
                data: {
                  message: "Data you wish to save has been expired. Do you wish to reload?",
                  type: DialogType[0].toString()
                }
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result) {
                  this.transaction = data[0];
                  this.partForm.patchValue({
                    repairCentreName: this.transaction["repairCentreName"],
                    customerName: this.transaction["cusomterName"],
                    partGroupName: this.transaction["partGroupName"],
                    description: this.transaction["partDescription"],
                    quantity: this.transaction.quantity,
                    usedPart: this.transaction.used,
                    goodwill: this.transaction.goodwill,
                    ourPrice: this.transaction.ourPrice,
                    repairCentrePrice: this.transaction.repairCentrePrice,
                    paidPrice: this.transaction.paidPrice,
                    shopKeyLabour: this.transaction.shopKeyLabour,
                    repairCentreLabour: this.transaction.repairCentreLabour,
                    paidLabour: this.transaction.paidLabour,
                    formOther: this.transaction.other,
                    deductible: this.transaction.deductible,
                    rebuilt: this.transaction.rebuilt,
                    indicator: this.transaction.indicator
                  });
                }
                else {
                  this.dialogRef.close({ data: "refresh" });
                }
              });
            }
          })
        catchError((error) => {
          if (error.error instanceof ErrorEvent) console.log(`Error: ${error.error.message}`)
          else console.log(`Error: ${error.message}`)
          return of([]);
        })
      }
      else {
        this.save(status);
      }
    }
  }

  save(status: string) {
    this.updateStatus = status;
    this.transaction.repairCentreName = this.partForm.value.repairCentreName;
    this.transaction.customerName = this.partForm.value.customerName;
    this.transaction.partGroupName = this.partForm.value.partGroupName;
    this.transaction.partDescription = this.partForm.value.description;

    this.dialogRef.close({ data: this.transaction })
  }

  private loadRepairCentresAPI(repairCenterAPI:Observable<IRepairCentre[]>) {
    this.repairCentres$ = repairCenterAPI.pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) console.log(`Error: ${error.error.message}`)
        else console.log(`Error: ${error.message}`)
        return of([]);
      }))
    this.repairCenterLookUpSubscription = this.repairCentres$.subscribe(e => {
      this.repairCentres = [];
      e.forEach(rc => {
        if (this.repairCentres.filter(x => x.id == rc.id).length == 0) {
          this.repairCentres.push(new RepairCentre(rc))
        }
      })
      this.repairCentres = this.repairCentres.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      })
      this.repairCentresFiltered = this.repairCentres
    })
  }

  private loadCustomersAPI(customersAPI:Observable<ICustomerSearch[]>) {
    this.customers$ = customersAPI.pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) console.log(`Error: ${error.error.message}`)
        else console.log(`Error: ${error.message}`)
        return of([]);
      }))
    this.customerLookUpSubscription = this.customers$.subscribe(e => {
      this.customers = [];
      e.forEach(c => {
        if (this.customers.filter(x => x.customerId == c.customerId).length == 0) {
          this.customers.push(new CustomerSearch(c))
        }
      })
      this.customers = this.customers.sort((a, b) => {
        if (a.lastName < b.lastName) return -1;
        if (a.lastName > b.lastName) return 1;
        return 0;
      })
      this.customersFiltered = this.customers
    })
  }

  public selectRepairCentre(repairCentre: RepairCentre, event) {
    if (event.isUserInput) {
      this.repairCentresFiltered = [repairCentre];
    }
  }

  public selectCustomer(customer: CustomerSearch, event) {
    if (event.isUserInput) {
      this.customersFiltered = [customer];
    }
  }


  public selectType(type: string, event){
    if(event.isUserInput){
      if(type == "CU"){
        this.partForm.patchValue({repairCentreName: null})
        this.partForm.controls["repairCentreName"].disable();
        this.partForm.controls["repairCentreName"].setValidators([Validators.nullValidator])
        this.partForm.controls["repairCentreName"].updateValueAndValidity();

        this.partForm.controls["customerName"].enable();
        this.partForm.controls["customerName"].setValidators([Validators.required])
        this.partForm.controls["customerName"].updateValueAndValidity();
        if(this.customersFiltered.length != 0)
          this.partForm.patchValue({customerName: this.customersFiltered[0].fullName})
      }
      else if(type == "RC"){
        this.partForm.patchValue({customerName: null})
        this.partForm.controls["customerName"].disable();
        this.partForm.controls["customerName"].setValidators([Validators.nullValidator])
        this.partForm.controls["customerName"].updateValueAndValidity();

        this.partForm.controls["repairCentreName"].enable();
        this.partForm.controls["repairCentreName"].setValidators([Validators.required])
        this.partForm.controls["repairCentreName"].updateValueAndValidity();
        if(this.repairCentresFiltered.length != 0)
          this.partForm.patchValue({repairCentreName: this.repairCentresFiltered[0].name})
      }
    }
  }

  private loadPartGroups() {
    this.partGroups$ = this.partService.retrieveAllPartGroups(this.transaction.warrantyId).pipe(
      share(),
      catchError((error) => {
        this.blockUI.stop() 
        if (error.error instanceof ErrorEvent) console.log(`Error: ${error.error.message}`)
        else console.log(`Error: ${error.message}`)
        return of([]);
      }))
     this.blockUI.start() 
    this.partGroups$.subscribe(e => {
      
      e.forEach(pb => {
        this.partGroups.push(pb)
      })
      if(this.isUpdate){
        if(this.transaction.partGroupId){
          this.selectPartsGroup(this.partGroups.filter(x=>x.partGroupId == this.transaction.partGroupId)[0], {isUserInput:true})
        }
      }
      this.partGroups = this.partGroups.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      })
      this.blockUI.stop() 
    })
    this.partGroupsFiltered = this.partGroups
  }

  public filterPartGroups(pbName: string = null) {
    this.partsFiltered = []
    var filterVal = this.partForm.value.partGroupName
    this.partGroupId = undefined
    this.partForm.patchValue({ description : ''});
    if (filterVal != undefined || pbName != null) {
      if (pbName != null) {
        filterVal = pbName.toLocaleLowerCase()
      }
      this.partGroupsFiltered = this.partGroups.filter(e => e.label.toLocaleLowerCase().includes(filterVal))
    }
    else {
      this.partGroupNameSelected = false;
    }
  }

  public selectPartsGroup(partsGroup: PartGroupModel, event) {
    if (event.isUserInput) {
      this.partGroupNameSelected = false;
      this.partGroupId = partsGroup.partGroupId
      this.partGroupsFiltered = [partsGroup];
      this.partGroupNameSelected = true;
      this.loadParts()
    }
  }

  public selectParts(part: Part, event) {
    if (event.isUserInput) {
      this.partsFiltered = [part];
    }
  }

  public loadParts() {
    this.parts = [];
    this.parts$ = this.partService.retrieveAllPartsFromGroup(this.partGroupId, this.transaction.warrantyId).pipe(
      share(),
      catchError((error) => {
        if (error.error instanceof ErrorEvent) console.log(`Error: ${error.error.message}`)
        else console.log(`Error: ${error.message}`)
        return of([]);
      }))
    this.parts$.subscribe(e => {
      e.forEach(p => {
        this.parts.push(p)
      });

      this.partsFiltered = this.parts;

      if (this.transaction?.partId) {
        const part = this.parts.find(p => p.partId === this.transaction.partId);
        if (part) {
          this.partsFiltered = [part];
        }
      }
    });
  }

  public filterParts(partName: string = null) {
    var filterVal = this.partForm.value.description
    if (filterVal != undefined || partName != null) {
      if (partName != null) {
        filterVal = partName
      }
      filterVal = filterVal.toLocaleLowerCase()
      this.partsFiltered = this.parts.filter(e => e.label.toLocaleLowerCase().includes(filterVal))
    }
  }

  retrieveRepairCenter(claimId) {
    this.repairCentreService.retrieveClaimRepairCenterHistory(claimId).subscribe((repairCenter) => {
      if (repairCenter.name) {
        this.partForm.patchValue({
          repairCentreName: repairCenter.name
        });
        if (this.repairCentres.filter(x => x.id == repairCenter.id).length == 0) {
          this.repairCentres.push(new RepairCentre(repairCenter))
        }
        this.selectRepairCentre(new RepairCentre(repairCenter), {isUserInput:true})
      }
    })
  }

  retrieveWarrantyDeductible(warrantyId) {
    this.warrantyService.retrieve(warrantyId).subscribe((warranty) => {
      if (warranty) {
        this.partForm.patchValue({
          deductible: warranty.deductible
        })
      }
    })
  }

  setRepairCenterInputChangeEvent() {
    this.inputEventSubscription = fromEvent(document.getElementById("repairCenterAutoInput"), "input")
      .pipe(map((ev) => ((ev as InputEvent).currentTarget as HTMLInputElement).value), debounceTime(500))
      .subscribe((resp) => {
        if (!AppValidators.isEmptyOrSpaces(resp)) {
          this.repairCenterDBLookupByName(resp);
        }
        else {
          this.repairCentres = [];
          this.repairCentresFiltered = [];
        }
      })
  }

  repairCenterDBLookupByName(repairCenterName: string) {
    this.loadRepairCentresAPI(this.repairCentreService.repairCentreLookupByName(repairCenterName))
  }

  setCustomerInputChangeEvent() {
    this.inputEventSubscription = fromEvent(document.getElementById("customerAutoInput"), "input")
      .pipe(map((ev) => ((ev as InputEvent).currentTarget as HTMLInputElement).value), debounceTime(500))
      .subscribe((resp) => {
        if (!AppValidators.isEmptyOrSpaces(resp)) {
          this.customerDBLookupByName(resp);
        }
        else {
          this.customers = [];
          this.customersFiltered = [];
        }
      })
  }

  customerDBLookupByName(searchTerm: string) {
    this.loadCustomersAPI(this.customerService.search(searchTerm))
  }

  unSubscribeEventAndAPI() {
    this.inputEventSubscription?.unsubscribe();
    this.repairCenterLookUpSubscription?.unsubscribe();
    this.customerLookUpSubscription?.unsubscribe();
  }
}
