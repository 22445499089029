<div class="empty-message" *ngIf="groupedDealsRebates.length === 0 && _isGroupsDealsRebatesLoaded">None</div>

<mat-accordion>
  <mat-expansion-panel *ngFor="let item of groupedDealsRebates; let i = index">
    <mat-expansion-panel-header>

      <a class="accordion-header-coverage-group"
         [routerLink]="['./', {coverageGroupId: item.coverageGroupId === coverageGroupId ? 0 : item.coverageGroupId}]">{{item.coverageGroup}}</a>

    </mat-expansion-panel-header>

    <div id="accordion{{i}}">
      <div *ngFor="let coverage of item.coverages; let j = index;">
        <h5 class="section-header"
            id="heading{{i}}{{j}}"
            [attr.data-toggle]="'collapse'"
            [attr.data-target]="'#collapse' + i + j"
            [attr.aria-expanded]="coverage.isOpen"
            [attr.aria-controls]="'collapse' + i + j">
          {{coverage.coverage}}

          <i class="float-right fa"></i>
        </h5>

        <div class="collapse"
             id="collapse{{i}}{{j}}"
             [attr.aria-labelledby]="'heading' + i + j"
             [attr.data-parent]="'#accordion' + i"
             [ngClass]="{ 'show': coverage.isOpen }">

          <table class="table table-hover datatable"
                 *ngFor="let deal of coverage.deals">
            <thead>
              <tr>
                <th>Item</th>
                <th *ngIf="bp > 3">
                  <span *ngIf="bp < 5">Age</span>
                  <span *ngIf="bp > 4">Age Band</span>
                </th>
                <th *ngIf="bp > 3">
                  <span *ngIf="bp < 5">Distance</span>
                  <span *ngIf="bp > 4">Distance Band</span>
                </th>
                <th>
                  <span *ngIf="bp < 5">Base</span>
                  <span *ngIf="bp > 4">Base Price</span>
                </th>
                <th *ngIf="bp > 1">
                  <span *ngIf="bp < 5">Adjust.</span>
                  <span *ngIf="bp > 4">Adjustment</span>
                </th>
                <th>
                  <span *ngIf="bp < 5">Net</span>
                  <span *ngIf="bp > 4">Net Price</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let record of deal.records">
                <tr (click)="record.expanded = !record.expanded">
                  <td>
                    <span *ngIf="record.deductible">{{record.deductible}}</span>
                    <span *ngIf="record.partGroup">{{record.partGroup}}</span>
                    <span *ngIf="record.rebateType">{{record.rebateType}}</span>
                    <span *ngIf="!record.deductible && !record.partGroup && !record.rebateType">Coverage</span>
                  </td>
                  <td *ngIf="bp > 3">
                    {{record.coverageAgeBand}}<span *ngIf="record.coverageAgeBand"> years</span>
                  </td>
                  <td *ngIf="bp > 3">
                    {{record.coverageDistanceBand}}
                    <span *ngIf="record.coverageDistanceBand && record.countryId === country.Canada">km</span>
                    <span *ngIf="record.coverageDistanceBand && record.countryId === country.UnitedStates">miles</span>
                  </td>
                  <td>{{record.basePrice | currency: '$'}}</td>
                  <td *ngIf="bp > 1">{{record.adjustment | currency: '$'}}</td>
                  <td>
                    <span *ngIf="record.rebateType">{{record.surcharge | currency: '$'}}</span>
                    <span *ngIf="!record.deductible && !record.partGroup && !record.rebateType">{{(record.basePrice + record.adjustment) | currency: '$'}}</span>
                  </td>
                </tr>
                <tr class="responsive-row"
                    *ngIf="bp < 4 && record.expanded">
                  <td colspan="4">

                    <table class="responsive-table">
                      <tbody>
                        <tr *ngIf="bp < 4">
                          <td class="label">Age Band</td>
                          <td>
                            <span *ngIf="record.coverageAgeBand">{{record.coverageAgeBand}}</span>
                            <span class="band-suffix"
                                  *ngIf="record.coverageAgeBand">years</span>
                            <span *ngIf="!record.coverageAgeBand">N/A</span>
                          </td>
                        </tr>
                        <tr *ngIf="bp < 4">
                          <td class="label">Distance Band</td>
                          <td>
                            <span *ngIf="record.coverageDistanceBand">{{record.coverageDistanceBand}}</span>
                            <span class="band-suffix"
                                  *ngIf="record.coverageDistanceBand && record.countryId === country.Canada">km</span>
                            <span class="band-suffix"
                                  *ngIf="record.coverageDistanceBand && record.countryId === country.UnitedStates">miles</span>
                            <span *ngIf="!record.coverageDistanceBand">N/A</span>
                          </td>
                        </tr>
                        <tr *ngIf="bp < 2">
                          <td class="label">Adjustment</td>
                          <td>{{record.adjustment | currency: '$'}}</td>
                        </tr>
                      </tbody>
                    </table>

                  </td>
                </tr>
              </ng-container>
              <tr *ngIf="deal.records.length > 1">
                <td colspan="5" *ngIf="bp > 3">Total</td>
                <td colspan="2" *ngIf="bp === 1">Total</td>
                <td colspan="3" *ngIf="bp === 2 || bp === 3">Total</td>
                <td>{{deal.total | currency: '$'}}</td>
              </tr>
            </tbody>
          </table>

          <h5 class="section-header transparent mb-0" *ngIf="coverage.deductibles.length">Deductibles</h5>
          <table class="table table-hover datatable"
                 *ngIf="coverage.deductibles.length">
            <thead>
              <tr>
                <th>Item</th>
                <th *ngIf="bp > 3">
                  <span *ngIf="bp < 5">Age</span>
                  <span *ngIf="bp > 4">Age Band</span>
                </th>
                <th *ngIf="bp > 3">
                  <span *ngIf="bp < 5">Distance</span>
                  <span *ngIf="bp > 4">Distance Band</span>
                </th>
                <th>
                  <span *ngIf="bp < 5">Base</span>
                  <span *ngIf="bp > 4">Base Price</span>
                </th>
                <th *ngIf="bp > 1">
                  <span *ngIf="bp < 5">Adjust.</span>
                  <span *ngIf="bp > 4">Adjustment</span>
                </th>
                <th>
                  <span *ngIf="bp < 5">Net</span>
                  <span *ngIf="bp > 4">Net Price</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let deductible of coverage.deductibles">
                <tr (click)="deductible.expanded = !deductible.expanded">
                  <td>
                    <span *ngIf="!deductible.rebateType">{{deductible.deductible}}</span>
                    <span *ngIf="deductible.rebateType">{{deductible.rebateType}}</span>
                  </td>
                  <td *ngIf="bp > 3">
                    {{deductible.coverageAgeBand}}<span *ngIf="deductible.coverageAgeBand">years</span>
                  </td>
                  <td *ngIf="bp > 3">
                    {{deductible.coverageDistanceBand}}
                    <span *ngIf="deductible.coverageDistanceBand && deductible.countryId === country.Canada">km</span>
                    <span *ngIf="deductible.coverageDistanceBand && deductible.countryId === country.UnitedStates">miles</span>
                  </td>
                  <td>{{deductible.basePrice | currency: '$'}}</td>
                  <td *ngIf="bp > 1">{{deductible.adjustment | currency: '$'}}</td>
                  <td>
                    <span *ngIf="deductible.basePrice">{{(deductible.basePrice + deductible.adjustment) | currency: '$'}}</span>
                    <span *ngIf="deductible.surcharge">{{deductible.surcharge | currency: '$'}}</span>
                  </td>
                </tr>
                <tr class="responsive-row"
                    *ngIf="bp < 4 && deductible.expanded">
                  <td colspan="4">

                    <table class="responsive-table">
                      <tbody>
                        <tr *ngIf="bp < 4">
                          <td class="label">Age Band</td>
                          <td>
                            <span *ngIf="deductible.coverageAgeBand">{{deductible.coverageAgeBand}}</span>
                            <span class="band-suffix"
                                  *ngIf="deductible.coverageAgeBand">years</span>
                            <span *ngIf="!deductible.coverageAgeBand">N/A</span>
                          </td>
                        </tr>
                        <tr *ngIf="bp < 4">
                          <td class="label">Distance Band</td>
                          <td>
                            <span *ngIf="deductible.coverageDistanceBand">{{deductible.coverageDistanceBand}}</span>
                            <span class="band-suffix"
                                  *ngIf="deductible.coverageDistanceBand && deductible.countryId === country.Canada">km</span>
                            <span class="band-suffix"
                                  *ngIf="deductible.coverageDistanceBand && deductible.countryId === country.UnitedStates">miles</span>
                            <span *ngIf="!deductible.coverageDistanceBand">N/A</span>
                          </td>
                        </tr>
                        <tr *ngIf="bp < 2">
                          <td class="label">Adjustment</td>
                          <td>{{deductible.adjustment | currency: '$'}}</td>
                        </tr>
                      </tbody>
                    </table>

                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>

          <h5 class="section-header transparent mb-0" *ngIf="coverage.partGroups.length > 0">Options</h5>
          <table class="table table-hover datatable"
                 *ngIf="coverage.partGroups && coverage.partGroups.length > 0">
            <thead>
              <tr>
                <th>Item</th>
                <th *ngIf="bp > 3">
                  <span *ngIf="bp < 5">Age</span>
                  <span *ngIf="bp > 4">Age Band</span>
                </th>
                <th *ngIf="bp > 3">
                  <span *ngIf="bp < 5">Distance</span>
                  <span *ngIf="bp > 4">Distance Band</span>
                </th>
                <th>
                  <span *ngIf="bp < 5">Base</span>
                  <span *ngIf="bp > 4">Base Price</span>
                </th>
                <th *ngIf="bp > 1">
                  <span *ngIf="bp < 5">Adjust.</span>
                  <span *ngIf="bp > 4">Adjustment</span>
                </th>
                <th>
                  <span *ngIf="bp < 5">Net</span>
                  <span *ngIf="bp > 4">Net Price</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let partGroup of coverage.partGroups">
                <tr (click)="partGroup.expanded = !partGroup.expanded">
                  <td>
                    <span *ngIf="!partGroup.rebateType">{{partGroup.partGroup}}</span>
                    <span *ngIf="partGroup.rebateType">{{partGroup.rebateType}}</span>
                  </td>
                  <td *ngIf="bp > 3">{{partGroup.coverageAgeBand}}<span *ngIf="partGroup.coverageAgeBand"> years</span></td>
                  <td *ngIf="bp > 3">
                    {{partGroup.coverageDistanceBand}}
                    <span *ngIf="partGroup.coverageDistanceBand && partGroup.countryId === country.Canada"> km</span>
                    <span *ngIf="partGroup.coverageDistanceBand && partGroup.countryId === country.UnitedStates">miles</span>
                  </td>
                  <td>{{partGroup.basePrice | currency: '$'}}</td>
                  <td *ngIf="bp > 1">{{partGroup.adjustment | currency: '$'}}</td>
                  <td>
                    <span *ngIf="partGroup.basePrice">{{(partGroup.basePrice + partGroup.adjustment) | currency: '$'}}</span>
                    <span *ngIf="partGroup.surcharge">{{partGroup.surcharge | currency: '$'}}</span>
                  </td>
                </tr>
                <tr class="responsive-row"
                    *ngIf="bp < 4 && partGroup.expanded">
                  <td colspan="4">

                    <table class="responsive-table">
                      <tbody>
                        <tr *ngIf="bp < 4">
                          <td class="label">Age Band</td>
                          <td>
                            <span *ngIf="partGroup.coverageAgeBand">{{partGroup.coverageAgeBand}}</span>
                            <span *ngIf="partGroup.coverageAgeBand"> years</span>
                            <span *ngIf="!partGroup.coverageAgeBand">N/A</span>
                          </td>
                        </tr>
                        <tr *ngIf="bp < 4">
                          <td class="label">Distance Band</td>
                          <td>
                            <span *ngIf="partGroup.coverageDistanceBand">{{partGroup.coverageDistanceBand}}</span>
                            <span class="band-suffix"
                                  *ngIf="partGroup.coverageDistanceBand && partGroup.countryId === country.Canada">km</span>
                            <span class="band-suffix"
                                  *ngIf="partGroup.coverageDistanceBand && partGroup.countryId === country.UnitedStates">miles</span>
                            <span *ngIf="!partGroup.coverageDistanceBand">N/A</span>
                          </td>
                        </tr>
                        <tr *ngIf="bp < 2">
                          <td class="label">Adjustment</td>
                          <td>{{partGroup.adjustment | currency: '$'}}</td>
                        </tr>
                      </tbody>
                    </table>

                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>

        </div>
      </div>
    </div>

  </mat-expansion-panel>
</mat-accordion>
