import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FrameworkComponent } from 'src-private/app/shared/framework/framework.component';
import { CellBuilder } from 'src-private/app/shared/table-adapter/cell-builder/cell-builder';
import { CellType } from 'src-private/app/shared/table-adapter/cell-builder/cell-type';
import { WarrantyListComponent } from 'src-private/app/shared/warranty-list/warranty-list.component';
import { IWarranty } from '../../../interfaces/warranty.interface';
import { WarrantyRetrieve } from '../../../models/warranty-retrieve.model';
import { WarrantyService } from '../../../services/warranty.service';

@Component({
  selector: 'app-warranties-table',
  templateUrl: './warranties-table.component.html',
  styleUrls: ['./warranties-table.component.scss']
})
export class WarrantiesTableComponent extends FrameworkComponent implements AfterViewInit {

  @Input() customerId: number;
  @Input() readOnly: boolean = false;
  @Input() smallComponent: boolean = false;

  private ngUnsubscribe: Subject<any> = new Subject();
  
  constructor(private warrantyService: WarrantyService) {
    super();
  }

  @ViewChild("warrantiesTable") warrantiesTable: WarrantyListComponent
  
  public warrantiesTableColumns: CellBuilder[] = [
    new CellBuilder("Warranty", "description", CellType.text),
    new CellBuilder("Action", "contextmenu", CellType.contextmenu)
  ];

  ngAfterViewInit(): void {
    this.loadWarranties();
    super.build("WarrantiesTableComponent", 'warranties-table-component')
  }
  
  loadWarranties() {
    const warrantyRetrieve = new WarrantyRetrieve();
    warrantyRetrieve.customerId = this.customerId;
    this.warrantiesTable.refresh(this.warrantyService.retrieveAll(warrantyRetrieve))
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
