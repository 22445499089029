import { Routes, RouterModule } from '@angular/router';

import { DealsStatsComponent } from './components/deals-stats/deals-stats.component';
import { DealersComponent } from './components/dealers.component';
import { DealerInfoComponent } from './components/dealer-info/dealer-info.component';
import { NotesContactsComponent } from './components/notes-contacts/notes-contacts.component';
import { BonusBucksComponent } from './components/bonus-bucks/bonus-bucks.component';
import { RoleGuard } from 'src-private/app/guards/role.guard';
import {AutoLoginPartialRoutesGuard} from "angular-auth-oidc-client";

const dealerRoutes: Routes = [
  {
    path: 'dealers',
    component: DealersComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      {
        path: 'deals-stats',
        component: DealsStatsComponent,
        canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
        data: { role: ['GlobalWarrantyClaimsAdjuster', 'GlobalWarrantyDealerServices-Claims', 'GlobalWarrantyClaimsManager', 'GlobalWarrantyAssistantClaimsManager', 'GlobalWarrantyAccountant'] },
      },
      {
        path: 'bonus-bucks',
        component: BonusBucksComponent,
        canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
        data: { role: ['GlobalWarrantyClaimsAdjuster', 'GlobalWarrantyDealerServices-Claims', 'GlobalWarrantyClaimsManager', 'GlobalWarrantyAssistantClaimsManager', 'GlobalWarrantyAccountant'] },
      },
      {
        path: 'other',
        component: NotesContactsComponent,
        data: { animation: 'NotesContactsComponent', role: ['GlobalWarrantyClaimsAdjuster', 'GlobalWarrantyDealerServices-Claims', 'GlobalWarrantyClaimsManager', 'GlobalWarrantyAssistantClaimsManager', 'GlobalWarrantyAccountant'] },
        canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
      },
      {
        path: 'dealer-info',
        component: DealerInfoComponent,
        pathMatch: 'full',
        data: { animation: 'DealerInfoComponent', role: ['GlobalWarrantyClaimsAdjuster', 'GlobalWarrantyDealerServices-Claims', 'GlobalWarrantyClaimsManager', 'GlobalWarrantyAssistantClaimsManager', 'GlobalWarrantyAccountant'] },
        canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
      },
      {
        path: '',
        redirectTo: 'dealer-info',
        pathMatch: 'full',
      },
    ]
  }
];

export const dealersRouting = RouterModule.forChild(dealerRoutes);
