import {
  Component,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
  OnInit,
} from "@angular/core";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { EventEmitter } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { CellBuilder } from "../table-adapter/cell-builder/cell-builder";
import { CellAdapter } from "../table-adapter/table-cell-adapter/cell-adapter";
import { TableDatasourceAdapter } from "../table-adapter/table-datasource-adapter";
import { environment } from 'src-private/environments/environment';
import { catchError, share, takeUntil } from "rxjs/operators";
import {
  faEllipsisV,
  faDownload,
  faTrash,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import { IWarranty } from "src-private/app/areas/claims/interfaces/warranty.interface";
import { ActivatedRoute, Router } from "@angular/router";
import { WarrantyStatus } from "src-private/app/enums/warranty-status";
import { Claim } from "src-private/app/areas/claims/models/claim-model";
import { AccountService } from "src-private/app/areas/account/services/account.service";
import { DialogType } from "src-private/app/enums/dialog-type";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { ClaimStatus } from "src-private/app/enums/claims.enums";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ClaimsService } from "src-private/app/areas/claims/services/claims.service";
import { ToastrService } from "ngx-toastr";
import { IClaim } from "src-private/app/areas/claims/interfaces/claim";

@Component({
  selector: 'app-warranty-list',
  templateUrl: './warranty-list.component.html',
  styleUrls: ['./warranty-list.component.scss']
})
export class WarrantyListComponent implements AfterViewInit, OnInit {

  public faEllipsisV = faEllipsisV;
  public faDownload = faDownload;
  public faTrash = faTrash;
  public faPlus = faPlus;
  public isLoading = false;
  public claimsId: number;
  public claim :IClaim

  @Input() columns: CellBuilder[];
  @Input() tableLabel: string;
  @Input() canAdd: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() smallComponent: boolean = false;
  @Output() update = new EventEmitter();
  @Output() context = new EventEmitter();

  private dataSource: MatTableDataSource<IWarranty> = new MatTableDataSource();
  private data$: Observable<[]>;

  public columnNames = [];
  public columnModelNames = [];
  public length = 5;
  public pageSize = 5;
  public warrantyStatus = WarrantyStatus;
  private ngUnsubscribe: Subject<any> = new Subject();

  @ViewChild(MatTable) table: MatTable<IWarranty>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private route: ActivatedRoute
    , private router: Router
    , private accountService: AccountService
    , public dialog: MatDialog
    , private claimsService: ClaimsService
    , private toastr: ToastrService
    ) {
      this.claimsId = this.route.snapshot.data['claimId']
    }

  ngOnInit(): void {
    if (this.claimsId) {
      this.retrieveClaimById();
    }
  }

  /**
   * Set Columns
   */
   private setColumns() {
    this.columnNames = [];
    this.columns.forEach((element) => {
      this.columnNames.push(element["cellName"]);
      this.columnModelNames.push(element["modelAttrName"]);
    });
  }

  /**
   * Row Clicked, Emitter
   * @param row
   */
  onRowClick(row) {
    this.update.emit(row.data);
  }

  /**
   * Update the datasource with a subscription to trigger the change.
   */
  private mapDataSource() {
    this.isLoading = true;
    this.data$.subscribe((data) => {
      this.isLoading = false;
      this.dataSource = new MatTableDataSource(this.setDataSource(data));
      this.dataSource.sort = this.sort;
      this.paginator.pageSize = this.pageSize;
      this.paginator.length = this.length;
      this.dataSource.paginator = this.paginator;
    });
  }

  private setDataSource(data) {
    var mappedData = [];
    data.forEach((datum) => {
      var mappedDatum = [];
      this.columns.forEach((cb) => {
        if (cb.modelAttrName.split(".").length > 1) {
          var tdata = datum[cb.modelAttrName.split(".")[0]];
          var tvalue = tdata[cb.modelAttrName.split(".")[1]];
          var cellAdapter = new CellAdapter(tvalue, cb.cellType, cb.actions);
          mappedDatum.push(cellAdapter);
        } else {
          var cellAdapter = new CellAdapter(
            datum[cb.modelAttrName],
            cb.cellType,
            cb.actions
          );
          mappedDatum.push(cellAdapter);
        }
      });
      mappedData.push(new TableDatasourceAdapter(mappedDatum, datum));
    });
    if (data.length < 5 && data.length > 0) {
      this.length = data.length;
      this.pageSize = data.length;
    } else if (data.length == 0) {
      this.length = 0;
      this.pageSize = 1;
    } else if(data.length >= 5) {
      this.length = data.length;
      this.pageSize = 5;
    }
    return mappedData;
  }

  sortData(sort: Sort) {
    this.data$.subscribe((data) => {
      data.sort((a, b) => {
        const isAsc = sort.direction === "asc";
        return this.compare(
          a[this.columnModelNames[this.columnNames.indexOf(sort.active)]],
          b[this.columnModelNames[this.columnNames.indexOf(sort.active)]],
          isAsc
        );
      });
      this.dataSource = new MatTableDataSource(this.setDataSource(data));
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  addClaim(warranty: IWarranty) {
    let data = new Claim();
    data.warrantyId = warranty.id;
    data.customerId = warranty.customerId;
    data.assignedAdjuster = this.accountService.getUserId();
    data.claimsStatus = ClaimStatus[3].toString();

    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'part-dialog',
      width: '400px',
      autoFocus: true,
      data: {
        message: "You are about to add a new claim for this warranty. Do you want to continue?",
        type: DialogType[0].toString()
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.claimsService.claimsAdd(data)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res) => {
            if (res) {
              this.toastr.success("Claim successfully added. ");
              this.router.navigateByUrl('/').then(()=>this.router.navigate(['claims/' + res.toString() + '/detail']));
            }
            error => {
              this.toastr.error(environment.messages.apiError, "Unable to add claim");
            }
          });
      }
      else {
        dialogRef.close();
      }
    });
  }

  /**
   * Set columns. Undefined check accounts for async datasource.
   * Map the datasource and update the table.
   */
  private refreshDataSource() {
    if (this.columns != undefined) {
      this.setColumns();
    }
    this.mapDataSource();
  }

  /**
   * Create the observable - required for the datasource to see a change and trigger a re-render.
   * @param source Service method results
   */
  refresh(source) {
    this.data$ = source.pipe(
      share(),
      catchError((error) => {
        if (error.error instanceof ErrorEvent)
          console.log(`Error: ${error.error.message}`);
        else console.log(`Error: ${error.message}`);
        return of([]);
      })
    );
    this.refreshDataSource();
  }

  /**
   * Take an action as specified by the switch.
   * @param element Element data of the row
   * @param action
   */
  contextMenuUpdate(element, action): void {
    this.context.emit({ element: element, action: action });
  }

  retrieveClaimById(){
    this.claimsService.retrieve(this.claimsId).subscribe(
      claim => {
        if (claim) {
          this.claim = claim;
        }
      },
      error => {
        this.toastr.error(environment.messages.apiError, "Unable to retrieve claim");
      }
    );
  }

  public isActive(warranty: IWarranty): boolean {
    return WarrantyStatus[warranty.status] == 'Active';
  }

  public get isClaimClosed(): boolean {
    return this.claim ? this.claim.claimsStatus == "Closed" : true;
  }

  public get canEditClosedClaim(): boolean {
    return this.accountService.isClaimsManager();
  }

  public get isAdjuster(): boolean {
    return this.accountService.isAdjuster() || this.accountService.isAssistantClaimsManager() || this.accountService.isClaimsManager();
  }

  ngAfterViewInit() {
    var elems = document.getElementsByTagName("table-adapter");
    for (let i = 0; i < elems.length; i++) {
      elems[i].parentElement.classList.add("noPadding");
    }
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
