<mat-dialog-content (scroll)="closeAutoComplete()">
  <fieldset class="fieldset-form">
    <div class="container-fluid">
      <div class="row">
        <div class="col-4">
          <mat-form-field class="w-100">
            <label>Enter City</label>
            <input type="text" matInput [matAutocomplete]="city" (keyup)="citiesAutoComplete($event.target.value)"
              [(ngModel)]="selectedCity" (click)="openAutocomplete(citiesTrigger)" #citiesAutoCompleteRef
              #citiesTrigger="matAutocompleteTrigger">
            <mat-autocomplete placeholder="Enter City" autoActiveFirstOption #city="matAutocomplete">
              <mat-option *ngFor="let city of predictedCities" [value]="city"
                (onSelectionChange)="selectCity(city, $event)">
                {{city}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-8">
          <mat-form-field class="w-100">
            <label>Choose the Appropriate Repair Centre</label>
            <input #repairCentreInput id="repairCentreInput" type="text" matInput [matAutocomplete]="repaircentre"
              #repairCenterTrigger="matAutocompleteTrigger" (click)="openAutocomplete(repairCenterTrigger)"
              (keyup)="selectedCity != (null || '') ? repairCentreAutoComplete($event.target.value): null">
            <span id="spanRuler" hidden>&nbsp;</span>
            <mat-autocomplete autoActiveFirstOption #repaircentre="matAutocomplete"
              [displayWith]="getRepairCentreDisplay"
              (optionSelected)="setRepairCentre(this.optionsMap.get($event.option.value))">
              <mat-table [dataSource]="repairCentreRows">
                <ng-container [matColumnDef]="column.raw" *ngFor="let column of repairCentreColumns">
                  <mat-header-cell *matHeaderCellDef><span *ngIf="column.friendly !== 'Status'">{{column.friendly}}</span></mat-header-cell>
                  <mat-cell *matCellDef="let element" [ngClass]="isInUse(element) ? 'do-not-use' : ''">
                    <span *ngIf="column.raw !== 'postalCode'"><span *ngIf="column.raw !== 'status'">{{element[column.raw]}}</span></span>
                    <span *ngIf="column.raw === 'postalCode'">{{formatPostalCode(element[column.raw])}}</span>
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="repairCentreColumnsNames"></mat-header-row>
                <mat-option *matRowDef="let row; columns: repairCentreColumnsNames" [value]="row">
                  <mat-row></mat-row>
                </mat-option>
              </mat-table>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <div id="toolbar" class="form-row row d-flex justify-content-end" [formGroup]="statusForm">
        <div class="col-2 header-field-outer text-nowrap px-3">
          <div class="header-field-inner">
            <div class="custom-control custom-radio custom-control-inline m-0">
              <input class="mr-1 rc-filter" formControlName="status" id="all" type="radio" value="All"
                (click)="setFilter($event.target.value)">
              <label class="m-0" for="all">All</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input class="mr-1 rc-filter" formControlName="status" id="preferred" type="radio" value="Preferred"
                (click)="setFilter($event.target.value)">
              <label class="m-0" for="preferred">Preferred Repair Centres</label>
            </div>
          </div>
        </div>
        <div class="col-2 header-button-outer custom-button-gold ml-2 text-nowrap px-3" (click)="clear()">
          <div class="header-button-inner">
            <span class="header-button-label">Clear Search</span>
          </div>
        </div>
        <div class="col-3 header-button-outer custom-button-gold ml-2 text-nowrap px-3" (click)="showLocation()">
          <div class="header-button-inner">
            <span class="header-button-label">Show Me to the Location on the Map</span>
          </div>
        </div>
      </div>
    </div>
  </fieldset>

  <block-ui name="repair"><div #mapContainer id="map" class="mapContainer"></div></block-ui> 
  <div  *ngIf="selectedRepairCentre != null"   #mapWidget id="map_canvas" class="mapWidget"></div>
  <div id="buildingIcon" style="display:none">
    <fa-icon [icon]="faCarBuilding" class="pr-2 fa-10x"></fa-icon>
  </div>

</mat-dialog-content>
<mat-dialog-actions class="float-right">
  <button mat-button mat-dialog-close class="btn" (click)="cancel()">
    <fa-icon [icon]="faWindowClose" class="pr-2 text-danger"></fa-icon> Cancel
  </button>
  <button mat-button class="btn" (click)="confirm()">
    <fa-icon [icon]="faSave" class="pr-2 text-success"></fa-icon> Save
  </button>
</mat-dialog-actions>
