import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

/*-- Helpers --*/
import { RouteParamsHelper } from '../../../../helpers/route-params-helper';

/*-- Interfaces --*/
import { IDealer } from '../../interfaces/dealer.interface';

/*-- Services --*/
import { BreakpointService } from '../../../../services/breakpoint.service';
import { DealersService } from '../../services/dealers.service';
import { DealersSharedService } from '../../services/dealers-shared.service';

@Component({
  selector: 'app-deals-stats',
  templateUrl: './deals-stats.component.html',
  styleUrls: ['./deals-stats.component.css']
})
export class DealsStatsComponent implements OnDestroy, OnInit {
  private static routeParamsHelper: RouteParamsHelper;

  private component: string;
  private dealer: IDealer;

  public bp: number;
  public showDealsRebates = false;
  public showLatestApplication = false;
  public showStatistics = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private breakpointService: BreakpointService,
    private dealerService: DealersService,
    private dealersSharedService: DealersSharedService,
    private routeParamsHelper: RouteParamsHelper,
    private router: Router
  ) {
    this.activatedRoute.parent.queryParams.subscribe(queryParams => {
      if (Object.keys(queryParams).length) {
        this.component = queryParams['c'];

        switch (this.component) {
          case 'deals-rebates':
            this.showDealsRebates = true;
            this.showLatestApplication = false;
            this.showStatistics = false;

            break;
          case 'latest-application':
            this.showDealsRebates = false;
            this.showLatestApplication = true;
            this.showStatistics = false;

            break;
          case 'statistics':
            this.showDealsRebates = false;
            this.showLatestApplication = false;
            this.showStatistics = true;

            break;
        }
      } else {
        const queryParams: Params = { c: 'last-application' };

        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: queryParams,
          queryParamsHandling: 'merge'
        });
      }
    });

    breakpointService.bp.subscribe(x => this.bp = x);

    if (!DealsStatsComponent.routeParamsHelper) {
      DealsStatsComponent.routeParamsHelper = routeParamsHelper;
    }

    this.dealersSharedService.dealer.subscribe(dealer => {
      if (dealer) {
        this.dealer = dealer;
      }
    });
  }

  //#region - Lifecycle
  ngOnDestroy(): void {
    DealsStatsComponent.routeParamsHelper.save(this.activatedRoute);
  }

  ngOnInit(): void {
    if (!this.bp) {
      this.bp = this.breakpointService.getBreakpoint();
    }

    DealsStatsComponent.routeParamsHelper.restore(this.activatedRoute);
  }
  //#endregion
}
