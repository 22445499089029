import {Injectable} from '@angular/core';
import {IEazeeTrakFile} from "../../../interfaces/eazeetrak.interface";
import {DealersService} from "../../../services/dealers.service";
import {DealersSharedService} from "../../../services/dealers-shared.service";
import {Dealer} from "../../../models/dealer.model";
import {BehaviorSubject} from "rxjs";
import {INote} from "../../../interfaces/note.interface";

@Injectable({
  providedIn: 'root'
})
export class FilesSharedService {

  private filesSource = new BehaviorSubject<IEazeeTrakFile[]>(null);
  files = this.filesSource.asObservable();
  private dealer: Dealer;
  private autoTrigger;

  constructor(private dealerService: DealersService) { }

  public retrieveEazeeTrakFiles(vendorId: number, force: boolean = false): void {
    this.dealerService.eazeeTrakFileRetrieveByDealer(vendorId, force).subscribe(
      response => {
        const data: IEazeeTrakFile[] = response;
        this.filesSource.next(data);
      });
  }
}
