import { ChangeType, UserType } from 'src-private/app/enums/bonus-bucks.enums';

export class BonusBucksAuthorization {
  BonusBucksAuthId?: number;
  VendorId: number;
  DealerPrincipalId: string;
  BonusBucksAmount: number;
  DistributionTypeId: number;
  BonusBucksCoverages: CoveragePackage[];
  BonusBucksDistributions: DistributionUser[];
}

export class DistributionUser {
  Id: string;
  FirstName: string;
  LastName: string;
  Email: string;
  GroupName: string;
  RoleLabel: string;
  RoleName: string;
  Title: string;
  UserType?: UserType;
  UserRegistrationDetails?: UserDetails;
}

export class UserDetails {
  RecipientTypeId: number;
  BonusBucksAuthId?: number;
  CRABusinessNumber?: string;
  SocialInsuranceNumber?: string;
  FirstName: string;
  LastName: string;
  Address: string;
  Phone: string;
  CountryId: number;
  PostalZipCode: string;
  City: string;
  ProvinceStateId: number;
  PaymentTypeId: number; // this is for eft, not needed atm
  DistributionPercent: number;
  BranchRouting: number;
  InstitutionCheck: number;
  Account: number;
  EFTImage: string;
  Recipient: string;
}

export class DealerPrincipal {
  FirstName: string;
  LastName: string;
  Email: string;
  constructor(f: string, l: string, em: string) {
    this.FirstName = f;
    this.LastName = l;
    this.Email = em;
  }
}

export class CoveragePackage {
  CoverageTypeId: number;
  CoverageGroupId: number;
  CoverageGroupLabel: string;
  Coverages: Coverage[];
  Amount: number;
  CoverageTypeGroupId: number;
  CoverageTypeGroupName: string;
}

export class Coverage {
  IsChecked: boolean;
  CoverageId: number;
  CoverageLabel: string;
  CoverageShortLabel: string;
  BonusBucksAmount: number;
  Bands: Band[];
}

export class Band {
  CoveragePricingId: number;
  BandId: number;
  IsPrimary: boolean;
  CoverageAgeBandId: number;
  AgeLabel: string;
  CoverageDistanceBandId: number;
  DistanceLabel: string;
  Year: string;
  Make: string;
  Model: string;
  Trim: string;
  Base: number;
  Rebate: number;
  CashBack: number;
  CoverageRebateId: number;
}

export abstract class Alteration {
  Name: string;
  OldValue: any;
  NewValue: any;
  changeType: number;
  modificationMap: Map<string, [any, any]>;

  constructor(n: string, old: any, newV: any, ct: number) {
    this.Name = n;
    this.OldValue = old;
    this.NewValue = newV;
    this.changeType = ct;
    this.modificationMap = new Map();
  }
  get ChangeString() {
    return ChangeType[this.changeType];
  }
}

export class CoverageChange extends Alteration { }
export class DistributionChange extends Alteration { }

export class BonusBucksValue {
  amount: number;
  isActive: boolean;
  rebateTypeId: number;
  constructor(a: number, isAct: boolean) {
    this.amount = a;
    this.isActive = isAct;
    this.rebateTypeId = 8; // magic number
  }
}

export class ProvinceState {
  ProvinceStateId: number;
  CountryId: number;
  Label: string;
  Abbreviation: string;
}
