<div [formGroup]="userRegistrationForm">
    <span>
        <mat-form-field>
            <input [formControl]="userRegistrationForm.controls['branchRouting']" matInput
                [placeholder]="isCanada ? 'Branch Number' : 'Routing Number'" (change)="branchChange()">
        </mat-form-field>
        <mat-form-field>
            <input [formControl]="userRegistrationForm.controls['institutionCheck']" matInput (change)="institutionChange()"
                [placeholder]="isCanada ? 'Institution' : 'Check Number'">
        </mat-form-field>
        <mat-form-field>
            <input [formControl]="userRegistrationForm.controls['account']" matInput 
                placeholder="Account Number">
        </mat-form-field>
    </span>

    <span class="or"> OR </span>

    <span class="eft-pick margin-top">
        <label [ngStyle]="{'text-decoration': userRegistrationForm.get('eftimage').value === null ? 'underline' : '', 
                            'text-decoration-color': userRegistrationForm.get('eftimage').value === null ? 'red' : 'none'}">
            {{eftInputText}}
            <input class="eft-input" type="file" (change)="onChequeUpload($event)" accept="image/jpeg,image/gif,image/png,application/pdf,image/*" />
        </label>
    </span>

    <span class="eft-pick margin-top" *ngIf="userRegistrationForm.get('eftimage').value">
        <label>
            VIEW FILE
            <input class="eft-input" type="button" (click)="viewLastEFT()" />
        </label>
    </span>
    <span class="eft-pick margin-top" *ngIf="userRegistrationForm.get('eftimage').value">
        <label>
            DELETE FILE
            <input class="eft-input" type="button" (click)="deleteEFT()" />
        </label>
    </span>
</div>