<div class="modal-container">
  <div class="modal-header">

    <h4 class="modal-title">{{title}}</h4>

  </div>
  <div class="modal-body">

    <div class="modal-message">Are you sure you want to {{action}} the following dealer?</div>
    <div class="modal-subject">{{dealer}}</div>

  </div>
  <div class="modal-footer">

    <button class="btn btn-block btn-icon btn-save"
            type="button"
            (click)="onYesClick()">
      <fa-icon [icon]="faCheck" class="text-success icon-left"></fa-icon> 
      Yes
    </button>
    <button class="btn btn-block btn-icon btn-cancel"
            type="button"
            (click)="onNoClick()">
      <fa-icon [icon]="faTimes" class="text-danger icon-left"></fa-icon> 
      No
    </button>

  </div>
</div>
