import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BonusBucksValue, CoveragePackage, DistributionUser, BonusBucksAuthorization, DealerPrincipal } from '../models/bonus-bucks.Models';
import { environment } from 'src-private/environments/environment';
import { Dealer } from '../models/dealer.model';


@Injectable({ providedIn: 'root' })
export class BonusBucksService {

  constructor(private http: HttpClient) { }

  public CoverageRebateRetrieveByVendor(vendorId: number): Observable<CoveragePackage[]> {
    return this.http.get<CoveragePackage[]>(environment.serverUrl + 'api/CoverageRebateRetrieveByVendor/' + vendorId);
  }

  public RetrieveBonusBucksValues(vendorId: number): Observable<BonusBucksValue[]> {
    return this.http.get<BonusBucksValue[]>(environment.serverUrl + 'api/BonusBucksValues');
  }

  public RetrieveDistributionUsers(vendorId: number): Observable<DistributionUser[]> {
    return this.http.get<DistributionUser[]>(environment.serverUrl + 'api/DistributionUsers/' + vendorId);
  }

  public LoadSubmissions(vendorId: number): Observable<BonusBucksAuthorization> {
    const actionUrl = 'api/Retrieve/' + vendorId;
    return this.http.post<BonusBucksAuthorization>(environment.serverUrl + actionUrl, {});
  }

  public submitBonusBuck(bba: BonusBucksAuthorization, dealer: Dealer): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
    });
    bba.VendorId = dealer.vendorId;
    return this.http.post(environment.serverUrl + 'api/BonusBucks', {bba, dealer}, {headers: headers, responseType: 'blob'});
  }

  public RetrieveBonusBucksReports(startDate: Date, endDate: Date, vendorId: number): Observable<any> {
    const actionUrl = 'api/RetrieveBonusBucksReports';
    const body = {
      'startDate': startDate,
      'endDate': endDate,
      'vendorId': vendorId
    };
    return this.http.post<any>(environment.serverUrl + actionUrl, body);
  }

  public RetrieveBonusBucks_MissingRecipInfoReport(startDate: Date, endDate: Date): Observable<any> {
    const actionUrl = 'api/RetrieveBB_MissingRecipInfoReport';
    const body = {
      'startDate': startDate,
      'endDate': endDate
    };
    return this.http.post<any>(environment.serverUrl + actionUrl, body);
  }

  public DownloadBatchFile(batchFileType, company): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'text/csv'
    });

    const body = {
      'BatchFileTypeId': batchFileType,
      'CancellationRecipientTypeId': null,
      'CompanyId': company
    };

    return this.http.post(environment.resourceUrl + 'api/doc/service/downloadaccpacbatchfile', JSON.stringify(body), {headers: headers, responseType: 'text'});
  }
}
